/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-off': {
    width: 48,
    height: 31,
    viewBox: '0 0 48 31',
    data: '<path pid="0" d="M21.693 13.514c1.064 0 2.008-.256 2.831-.768s1.456-1.239 1.898-2.181c.442-.943.663-2.04.663-3.29v-.567c0-1.245-.226-2.343-.676-3.294-.451-.95-1.088-1.68-1.911-2.19-.823-.509-1.764-.763-2.823-.763s-2 .254-2.822.763c-.824.51-1.46 1.24-1.911 2.19-.451.951-.677 2.052-.677 3.303v.628c.006 1.222.236 2.302.69 3.242.453.939 1.093 1.662 1.92 2.168.825.506 1.765.759 2.818.759zm0-2.147c-.896 0-1.583-.357-2.06-1.073-.477-.715-.715-1.722-.715-3.019v-.62c.006-1.32.247-2.322.724-3.005.477-.684 1.155-1.025 2.033-1.025.884 0 1.565.346 2.042 1.038.477.692.715 1.704.715 3.036v.62c-.005 1.32-.244 2.325-.715 3.014-.471.69-1.146 1.034-2.024 1.034zm9.912 1.972V8.148h5.025V6.036h-5.025v-3.28h5.654V.636h-8.272v12.703h2.618zm9.79 0V8.148h5.025V6.036h-5.026v-3.28h5.654V.636h-8.271v12.703h2.617zM11.218.296a.852.852 0 011 1.192c-2.325 4.81-1.614 10.557 1.811 14.64 3.425 4.082 8.962 5.78 14.103 4.325a.854.854 0 011 1.191 15.145 15.145 0 01-3.924 5.051c-6.455 5.417-16.112 4.572-21.529-1.883C-1.736 18.357-.89 8.699 5.564 3.282A15.145 15.145 0 0111.219.297zm15.034 22.268a15.31 15.31 0 01-13.24-5.006l-.29-.335A15.304 15.304 0 019.804 3.01l.143-.454-.094.041A13.412 13.412 0 006.66 4.589C.925 9.401.175 17.981 4.987 23.716c4.812 5.734 13.391 6.485 19.126 1.673a13.412 13.412 0 002.517-2.797l.054-.088-.431.06z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
