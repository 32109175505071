/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tornado-dark': {
    width: 57,
    height: 44,
    viewBox: '0 0 57 44',
    data: '<path pid="0" d="M54.714 34.948a2.197 2.197 0 110-4.393 2.197 2.197 0 010 4.393zm-.955-18.142a2.197 2.197 0 110-4.394 2.197 2.197 0 010 4.394zM38.48 41.632a2.197 2.197 0 110-4.393 2.197 2.197 0 010 4.393zM14.609 4.392A2.197 2.197 0 1114.61 0a2.197 2.197 0 010 4.393zM6.97 26.354a2.197 2.197 0 11.002-4.393 2.197 2.197 0 01-.002 4.393zm45.356-16.232H1.72a1.72 1.72 0 010-3.438h50.607a1.72 1.72 0 010 3.438zM7.448 13.368h39.15a1.72 1.72 0 010 3.438H7.447a1.72 1.72 0 010-3.438zm7.639 26.736h9.548a1.72 1.72 0 010 3.438h-9.548a1.72 1.72 0 010-3.438zm1.146-4.965c0-.95.77-1.719 1.718-1.719h14.323a1.72 1.72 0 010 3.438H17.951a1.72 1.72 0 01-1.718-1.72zm3.82-6.684c0-.95.769-1.719 1.718-1.719h24.826a1.72 1.72 0 010 3.438H21.771a1.72 1.72 0 01-1.719-1.72zm-3.82-6.684c0-.95.77-1.719 1.718-1.719h32.466a1.72 1.72 0 010 3.438H17.95a1.72 1.72 0 01-1.718-1.72z" _fill="#A2B0CA" fill-rule="nonzero"/>'
  }
})
