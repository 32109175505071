/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-3': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M13.125 12.5h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h2.5v2.5h-2.5v-2.5zM8.125 20h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-3.75A.625.625 0 008.125 20zM7.5 23.75H5v-2.5h2.5v2.5zm21.875-17.5c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625H26.25V.625A.625.625 0 0025.625 0H4.375a.625.625 0 00-.625.625V2.5H.625A.625.625 0 000 3.125v2.5c0 .345.28.625.625.625h.625v20H.625a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h28.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-.625v-20h.625zM5 1.25h20V2.5H5V1.25zm23.75 27.5H1.25V27.5h27.5v1.25zM16.875 20h-3.75a.625.625 0 00-.625.625v5.625h-10v-20h25v20h-10v-5.625a.625.625 0 00-.625-.625zm-.625 1.25v5h-2.5v-5h2.5zM1.25 5V3.75h27.5V5H1.25zm20.625 20h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75H25v2.5h-2.5v-2.5zm-.625-8.75h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75H25v2.5h-2.5v-2.5zM8.125 7.5h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625zM7.5 11.25H5v-2.5h2.5v2.5zm10 3.125a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-3.75zM16.25 17.5h-2.5V15h2.5v2.5zm5.625 1.25h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zM22.5 15H25v2.5h-2.5V15zM8.125 13.75h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625zM7.5 17.5H5V15h2.5v2.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
