/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-small-30': {
    width: 33,
    height: 30,
    viewBox: '0 0 33 30',
    data: '<path pid="0" d="M11.108.032a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.032zM26.14 22.3a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.137.063 17.717 4.875 23.452 9.687 29.187 18.267 29.937 24 25.125a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zm-3.702-11.494c.895 0 1.615-.225 2.158-.675.544-.45.816-1.047.816-1.792 0-.462-.116-.861-.349-1.2-.232-.338-.585-.591-1.057-.76.38-.173.688-.421.922-.744.234-.323.352-.676.352-1.06 0-.746-.252-1.331-.756-1.756-.503-.424-1.199-.637-2.086-.637-.511 0-.98.098-1.404.294a2.362 2.362 0 00-.991.816c-.237.348-.355.74-.355 1.179h1.666c0-.28.108-.509.323-.683.215-.175.482-.263.801-.263.354 0 .631.094.833.283.202.188.303.451.303.79 0 .35-.102.63-.306.841-.204.211-.517.317-.94.317h-.887V7.06h.882c.922 0 1.383.419 1.383 1.257 0 .345-.112.623-.337.832-.225.21-.535.315-.93.315-.347 0-.638-.1-.874-.3a.965.965 0 01-.355-.773h-1.666c0 .727.267 1.31.799 1.753.532.442 1.217.663 2.055.663zm6.94 0c.926 0 1.63-.308 2.11-.923.48-.614.72-1.502.72-2.663V5.675c-.011-1.126-.26-1.99-.743-2.59-.485-.602-1.184-.903-2.099-.903-.914 0-1.614.304-2.1.911-.487.607-.73 1.497-.73 2.669v1.545c.012 1.126.26 1.99.744 2.594.484.603 1.183.905 2.098.905zm0-1.343c-.411 0-.71-.167-.896-.499-.187-.332-.28-.84-.28-1.525V5.4c.012-.642.11-1.114.297-1.415.186-.302.476-.453.867-.453.404 0 .7.159.891.476.19.317.285.823.285 1.519V7.52c-.007.657-.101 1.145-.282 1.464-.18.32-.475.479-.882.479z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
