import { render, staticRenderFns } from "./Version.vue?vue&type=template&id=0668f505&scoped=true&"
import script from "./Version.vue?vue&type=script&lang=js&"
export * from "./Version.vue?vue&type=script&lang=js&"
import style0 from "./Version.vue?vue&type=style&index=0&id=0668f505&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0668f505",
  null
  
)

export default component.exports