import Vue from 'vue'
import i18n from '@/services/language.service';
import App from '@/App.vue'
import VueRouter from 'vue-router'
import store from '@/store/store';
import router from '@/router'
import moment from 'moment'
import SvgIcon from 'vue-svgicon';
import '@/assets/icons/sprite';

Vue.config.productionTip = false
import { BootstrapVue } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.use(VueRouter);

Vue.use(SvgIcon, {
  tagName: 'az-icon',
  classPrefix: 'az',
});

Vue.filter(
  'formatDate', function (value) {
    return value ? moment(String(value)).format('DD/MM/YYYY HH:mm') : ''
  },
)

Vue.prototype.$back = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
