/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hard-wind': {
    width: 48,
    height: 40,
    viewBox: '0 0 48 40',
    data: '<path pid="0" d="M24.13 21.907c9.621 0 14.486 7.052 10.853 13.296-4.096 7.036-14.498 5.54-16.673-1.77a1.642 1.642 0 013.147-.937c1.368 4.599 8.065 5.562 10.688 1.055 2.28-3.916-.787-8.36-8.016-8.36H6.202a1.642 1.642 0 010-3.284zm-9.262 5.474a2.098 2.098 0 11-.001 4.197 2.098 2.098 0 010-4.197zm23.715-5.473a2.098 2.098 0 11-.001 4.197 2.098 2.098 0 010-4.197zM27.946 1.605C32.312-1.298 37.76-.25 40.464 4.638c1.558 2.817 1.578 6.58-.302 9.532-2.26 3.547-6.853 5.548-13.605 5.548H1.642a1.642 1.642 0 010-3.284h24.915c5.72 0 9.248-1.537 10.835-4.029 1.208-1.895 1.195-4.375.198-6.177-1.737-3.14-5-3.768-7.826-1.889a1.642 1.642 0 11-1.818-2.734zm17.022 12.094a2.098 2.098 0 11-.001 4.197 2.098 2.098 0 010-4.197zM13.16 5.912C14.88.084 22.778-1.001 26.015 4.604c2.894 5.01-.532 10.553-7.994 10.553H3.466a1.642 1.642 0 110-3.284h14.555c4.987 0 6.752-2.855 5.15-5.626-1.775-3.074-5.948-2.501-6.862.595a1.642 1.642 0 11-3.15-.93zM9.395 5.49a2.098 2.098 0 11-.001 4.197 2.098 2.098 0 010-4.197z" _fill="#8999B7" fill-rule="nonzero"/>'
  }
})
