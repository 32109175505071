/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-manual': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M30.085.264a.874.874 0 01.62 1.493c-3.295 3.296-5.395 6.695-6.491 11.678l-.1.445-.185.813-.174.727-.169.658c-.056.21-.112.413-.17.61l-.177.58c-.06.19-.123.379-.19.57l-.208.577c-.094.252-.192.498-.293.74l-.186.124h-1.806l.132-.286c.633-1.38 1.127-2.965 1.605-5l.359-1.57c.966-3.976 2.619-7.092 4.94-9.868l.447-.52-.986.029c-9.492.33-15.978 2.234-19.92 5.338l-.284.23c-2.95 2.442-4.14 5.358-4.14 8.305 0 2.03.624 3.826 1.8 5.248l-.177-.224.204-.33c3.486-5.559 8.377-10.46 13.84-13.27l.566-.284a.877.877 0 011.168.407.876.876 0 01-.406 1.168C13.86 11.484 8.871 16.59 5.48 22.387l.087-.148.164.135c1.202.941 2.71 1.589 4.44 1.898l.437.07.174.2-.001 1.589-.227-.029c-2.381-.301-4.45-1.139-6.057-2.44l.192.15-.283.556-.185.376-.175.37-.32.717c-.6 1.403-.959 2.6-1.01 3.374l-.007.184a.875.875 0 01-1.748 0c0-1.499.92-4.077 2.49-6.917l-.07.125-.169-.192C1.83 20.769 1.054 18.707.968 16.377l-.008-.44c0-3.442 1.368-6.839 4.745-9.636C10.355 2.451 18.27.264 30.085.264zM18.387 17.57l3.26 9.206 3.244-9.206h3.435v12.694H25.7v-3.47l.262-5.99-3.426 9.46H20.74l-3.418-9.451.261 5.98v3.47H14.97V17.57h3.418z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
