/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-small-manual': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.126 0a.874.874 0 01.619 1.493C26.45 4.79 24.35 8.188 23.254 13.171l-.099.445-.185.813-.174.727-.17.658c-.056.21-.112.413-.17.61l-.176.58c-.06.19-.124.38-.19.57l-.208.577c-.095.252-.192.499-.294.74l-.186.124h-1.805l.131-.286c.633-1.38 1.127-2.964 1.605-5l.36-1.57c.966-3.976 2.618-7.092 4.94-9.868l.446-.52-.986.03c-9.492.33-15.977 2.234-19.92 5.337l-.284.23c-2.949 2.443-4.14 5.358-4.14 8.305 0 2.03.624 3.826 1.8 5.248l-.176-.224.203-.329c3.486-5.56 8.377-10.46 13.84-13.271l.567-.283a.877.877 0 011.167.406.876.876 0 01-.405 1.168C12.899 11.22 7.91 16.327 4.519 22.123l.087-.148.164.135c1.202.941 2.71 1.59 4.44 1.898l.438.07.173.2-.001 1.59-.227-.03c-2.381-.301-4.45-1.139-6.057-2.44l.192.15-.283.556c-.063.126-.124.252-.184.376l-.175.37-.32.717c-.602 1.403-.96 2.601-1.01 3.374l-.007.185a.875.875 0 01-1.749 0c0-1.5.92-4.078 2.49-6.918l-.07.125-.168-.192C.87 20.506.095 18.443.008 16.113L0 15.673c0-3.442 1.368-6.839 4.745-9.636C9.395 2.187 17.311 0 29.125 0zM14.723 20.968l2.21 6.243 2.2-6.243h2.33v8.608h-1.78v-2.353l.177-4.061-2.323 6.414h-1.218l-2.317-6.408.177 4.055v2.353h-1.774v-8.608h2.318z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
