/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '0 0 150 150',
    data: '<path pid="0" d="M75 0c41.3 0 75 33.7 75 75s-33.7 75-75 75S0 116.3 0 75 33.7 0 75 0zm0 5.8C36.7 5.8 5.8 36.8 5.8 75s31 69.2 69.2 69.2 69.2-31 69.2-69.2S113.3 5.8 75 5.8zm29.4 45c2.3-2.3 6-2.3 8.1 0 2.1 2.1 2.3 5.6.2 7.9l-.2.2-44.2 44.2c-2.1 2.1-5.6 2.3-7.9.2l-.2-.2-22.9-22.9c-2.3-2.3-2.3-6 0-8.1 2.1-2.1 5.6-2.3 7.9-.2l.2.2 18.8 18.8 40.2-40.1z" _fill="#1d1d1b"/>'
  }
})
