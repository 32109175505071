/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat-bold': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 25.933c-5.478 0-9.933-4.455-9.933-9.933S10.522 6.067 16 6.067s9.933 4.455 9.933 9.933-4.455 9.933-9.933 9.933zm0-18.261c-4.592 0-8.328 3.736-8.328 8.328 0 4.592 3.736 8.328 8.328 8.328 4.592 0 8.328-3.736 8.328-8.328 0-4.592-3.736-8.328-8.328-8.328zm0-2.609a.802.802 0 01-.802-.802V1.652a.802.802 0 011.604 0v2.609c0 .443-.36.802-.802.802zm0 26.087a.802.802 0 01-.802-.802v-2.609a.802.802 0 011.604 0v2.609c0 .443-.36.802-.802.802zM10.13 6.636a.801.801 0 01-.696-.401L8.13 3.975a.804.804 0 011.39-.801l1.305 2.259a.803.803 0 01-.695 1.203zm13.044 22.591a.801.801 0 01-.696-.4l-1.304-2.26a.802.802 0 011.389-.802l1.304 2.26a.802.802 0 01-.693 1.202zM5.833 10.933a.785.785 0 01-.4-.108l-2.26-1.304a.801.801 0 11.802-1.39l2.26 1.305a.802.802 0 01-.402 1.497zm22.592 13.043a.794.794 0 01-.4-.107l-2.26-1.305a.802.802 0 01.802-1.39l2.26 1.305a.802.802 0 01-.402 1.497zM4.261 16.802H1.652a.802.802 0 010-1.604h2.609a.802.802 0 010 1.604zm26.087 0h-2.609a.802.802 0 010-1.604h2.609a.802.802 0 010 1.604zM3.575 23.976a.801.801 0 01-.403-1.497l2.26-1.304a.803.803 0 01.803 1.39l-2.259 1.303a.794.794 0 01-.401.108zm22.592-13.043a.801.801 0 01-.402-1.497l2.259-1.304a.802.802 0 11.802 1.389l-2.258 1.303a.786.786 0 01-.4.109zM8.827 29.227a.802.802 0 01-.695-1.203l1.304-2.259a.802.802 0 011.39.802l-1.305 2.26a.8.8 0 01-.695.4zM21.87 6.637a.802.802 0 01-.695-1.203l1.304-2.26a.802.802 0 011.39.802l-1.305 2.26a.804.804 0 01-.694.4z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
