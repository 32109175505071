/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'widgets': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M11 16a2 2 0 012 2v9a2 2 0 01-2 2H2a2 2 0 01-2-2v-9a2 2 0 012-2h9zm15 0a2 2 0 012 2v9a2 2 0 01-2 2h-9a2 2 0 01-2-2v-9a2 2 0 012-2h9zm-14.5 4.614l-2.537 3.103a.75.75 0 01-1.109.058l-.072-.084-1.36-1.821-4.564 4.655a.746.746 0 01-.357.204L1.5 27a.5.5 0 00.41.492L2 27.5h9a.5.5 0 00.492-.41L11.5 27v-6.386zM26 17.5h-9a.5.5 0 00-.492.41L16.5 18v9a.5.5 0 00.41.492l.09.008h9a.5.5 0 00.492-.41L26.5 27v-9a.5.5 0 00-.41-.492L26 17.5zm-4.45 7a.75.75 0 01.102 1.493L21.55 26h-3a.75.75 0 01-.102-1.493l.102-.007h3zM11 17.5H2a.5.5 0 00-.492.41L1.5 18v6.746l4.465-4.551a.75.75 0 011.062-.009l.074.085 1.308 1.753 2.633-3.219a.747.747 0 01.458-.265V18a.5.5 0 00-.41-.492L11 17.5zM24.55 22a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493L18.55 22h6zm0-2.5a.75.75 0 01.102 1.493L24.55 21h-6a.75.75 0 01-.102-1.493l.102-.007h6zM22.914 1.136l4.95 4.95a2 2 0 010 2.828l-4.95 4.95a2 2 0 01-2.828 0l-4.95-4.95a2 2 0 010-2.828l4.95-4.95a2 2 0 012.828 0zM11 1a2 2 0 012 2v9a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h9zm10.216 1.139l-.07.058-4.95 4.95a.5.5 0 00-.057.637l.058.07 4.95 4.95a.5.5 0 00.637.057l.07-.058 4.95-4.95a.5.5 0 00.057-.637l-.058-.07-4.95-4.95a.5.5 0 00-.637-.057zM11 2.5H2a.5.5 0 00-.492.41L1.5 3v9a.5.5 0 00.41.492L2 12.5h9a.5.5 0 00.492-.41L11.5 12V3a.5.5 0 00-.41-.492L11 2.5zM6.5 4a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0 1.5a2 2 0 100 4 2 2 0 000-4z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
