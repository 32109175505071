/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.88 12.293a.879.879 0 01-.623-1.502L10.461.58c.775-.775 2.105-.774 2.878 0l10.204 10.21a.88.88 0 01-.623 1.502h-1.037v10.626a.878.878 0 01-.879.881h-5.75a.88.88 0 01-.879-.88v-7.75h-4.95v7.75a.88.88 0 01-.879.88h-5.75a.88.88 0 01-.879-.88V12.294H.88zm19.245-.88a.88.88 0 01.694-.86l-8.722-8.728c-.098-.097-.295-.097-.392 0l-8.722 8.728a.88.88 0 01.693.86v10.628h3.992V14.29a.88.88 0 01.88-.88h6.707a.88.88 0 01.88.88v7.751h3.99V11.413z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
