/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ecobee': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g _fill="#1D1D1B" fill-rule="evenodd"><path pid="0" d="M21.969 14.93a.717.717 0 110-1.433.717.717 0 010 1.434m1.364-.716c0-.654-.463-1.201-1.078-1.334V8.698a.286.286 0 10-.572 0v4.183a1.367 1.367 0 00-1.08 1.334c0 .654.464 1.2 1.08 1.333v4.184a.286.286 0 10.572 0v-4.184a1.366 1.366 0 001.078-1.333"/><path pid="1" d="M10.376 1.938c-4.868 0-8.828 3.96-8.828 8.828v7.051c0 4.868 3.96 8.829 8.828 8.829h7.256c4.868 0 8.828-3.961 8.828-8.829v-7.051c0-4.868-3.96-8.828-8.828-8.828h-7.256zm7.256 25.745h-7.256c-5.44 0-9.866-4.426-9.866-9.866v-7.051C.51 5.326 4.936.9 10.376.9h7.256c5.44 0 9.866 4.426 9.866 9.866v7.051c0 5.44-4.426 9.866-9.866 9.866z"/></g>'
  }
})
