/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-1': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.808 13.924L23.75 8.13V3.125a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v1.418L15.431.173a.624.624 0 00-.863 0L.192 13.923A.625.625 0 00.625 15H3.75v14.375c0 .345.28.625.625.625h21.25c.345 0 .625-.28.624-.625V15h3.125a.625.625 0 00.434-1.076zm-8.558-8.3V3.75h1.25v3.185L21.23 5.72c.006-.033.02-.063.02-.096zM20 28.75h-5v-7.5h5v7.5zm5 0h-3.75v-8.125a.625.625 0 00-.625-.625h-6.25a.625.625 0 00-.625.625v8.125H5V15h20v13.75zm-22.818-15L15 1.49l12.818 12.26H2.182zm4.693 10h5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5h3.75v3.75H7.5v-3.75zm5-10c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5-1.121-2.5-2.5-2.5a2.503 2.503 0 00-2.5 2.5zm3.75 0c0 .689-.561 1.25-1.25 1.25s-1.25-.561-1.25-1.25S14.311 7.5 15 7.5s1.25.561 1.25 1.25z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
