/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order-rows': {
    width: 33,
    height: 26,
    viewBox: '0 0 33 26',
    data: '<path pid="0" d="M26.989 22.573v1.796c0 .9-.73 1.631-1.632 1.631h-1.75v-1.285h1.75a.346.346 0 00.346-.346v-1.796h1.286zm-6.347 2.142V26H15.7v-1.285h4.943zm-7.909 0V26H7.79v-1.285h4.943zm-11.448-.698v.352c0 .19.155.346.346.346h3.194V26H1.63C.731 26 0 25.27 0 24.369v-.352h1.285zM30.943 13.84c.9 0 1.631.73 1.631 1.631v3.955c0 .9-.73 1.631-1.631 1.631H7.217c-.901 0-1.631-.73-1.631-1.631V15.47c0-.9.73-1.63 1.63-1.63zM3.608 18.783v1.285H1.631a.346.346 0 00-.346.346v.638H0v-.638c0-.9.73-1.63 1.631-1.63h1.977zm27.335-3.658H7.217a.346.346 0 00-.346.346v3.955c0 .19.155.346.346.346h23.726a.346.346 0 00.346-.346V15.47a.346.346 0 00-.346-.346zM25.357 0c.901 0 1.632.73 1.632 1.631v3.955c0 .9-.73 1.63-1.632 1.63H1.631c-.9 0-1.631-.73-1.631-1.63V1.63C0 .731.73 0 1.631 0zm0 1.285H1.631a.346.346 0 00-.346.346v3.955c0 .19.155.346.346.346h23.726a.346.346 0 00.346-.346V1.63a.346.346 0 00-.346-.346z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
