/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop-bold-lock': {
    width: 35,
    height: 30,
    viewBox: '0 0 35 30',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M25.8 0l.2.1 8.5 14.8v.1h-2.6L24.6 2.3H9.9L2.6 15l7.3 12.7H24V30h1.8H8.7l-.2-.1L0 15.1v-.2L8.5.1l.2-.1z"/><path pid="1" d="M33.1 22.2v-1.8c0-1.9-1.5-3.4-3.4-3.4s-3.4 1.5-3.4 3.4v1.8c-.7 0-1.3.5-1.3 1.3v4.6c0 .8.6 1.4 1.3 1.4H33c.7 0 1.3-.6 1.3-1.4v-4.6c.1-.8-.5-1.3-1.2-1.3zM30.5 27c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8v-2.1c0-.5.4-.8.8-.8.5 0 .8.4.8.8V27zm.7-4.8h-2.9v-2c0-.8.7-1.4 1.5-1.4s1.5.6 1.5 1.4v2h-.1zM8.1 10.9c.5 0 1 .1 1.4.3.4.2.7.5 1 .8.2.3.3.6.3.9v.3l-.2.2H8.9l-.2-.2v-.1c0-.1-.1-.2-.2-.3-.1-.1-.3-.2-.5-.2s-.4 0-.5.1c-.1.1-.2.2-.2.3 0 .1.1.2.2.3.1.1.4.2.7.3l.2.1c.5.2.9.3 1.2.5.8.5 1.2 1.1 1.2 1.9 0 .6-.3 1.2-.7 1.5-.5.4-1.1.5-1.9.5-.6 0-1.1-.1-1.5-.3-.5-.2-.8-.5-1.1-.9-.2-.3-.3-.6-.4-1v-.3l.2-.2H7l.2.2v.2c0 .2.1.3.2.4.1.1.4.2.7.2.2 0 .4 0 .5-.1.1-.1.1-.2.1-.3 0-.2-.1-.3-.2-.4-.1-.1-.3-.2-.6-.3l-.2-.1c-.4-.1-.8-.3-1-.4-.9-.5-1.4-1.1-1.4-1.9 0-.4.1-.8.4-1.1.2-.3.6-.6 1-.7.4-.2.8-.2 1.4-.2zM16.5 10.9l.3.3v1.2l-.3.2h-1.7v5.2l-.2.2H13l-.3-.2v-5.2H11l-.2-.2v-1.2l.2-.3zM22.6 12.5c-.3-.5-.6-.9-1.1-1.2-.5-.3-1-.4-1.6-.4-.6 0-1.1.1-1.6.4-.5.2-.8.7-1.1 1.2-.2.5-.4 1-.4 1.5v1c.1.5.2 1 .4 1.4.3.5.6.9 1.1 1.2.5.3 1 .4 1.6.4.6 0 1.1-.1 1.6-.4.5-.2.8-.7 1.1-1.2.2-.5.4-1 .4-1.5V14c0-.6-.1-1-.4-1.5zM21 14.4v.5c0 .5-.1.8-.3 1.1-.1.3-.4.4-.7.4-.4 0-.6-.1-.8-.4-.2-.2-.3-.6-.3-1.1v-1c.1-.9.4-1.3 1-1.3.3 0 .6.1.8.4.2.2.3.6.3 1.1v.3zM28.7 12.1c-.2-.4-.6-.7-1-.9-.3-.2-.7-.3-1.1-.3h-.3l-2.7.1-.2.2v6.6l.2.2h1.6l.3-.3v-2h1.1c.7 0 1.3-.2 1.7-.6.5-.4.8-1 .8-1.7 0-.5-.2-.9-.4-1.3zm-1.9 1.7c-.1.1-.2.2-.5.2h-.8v-1.4l.8-.1h.1c.2 0 .3.1.4.2.1.2.2.3.2.6 0 .2-.1.4-.2.5z"/></g>'
  }
})
