/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'up': {
    width: 15,
    height: 9,
    viewBox: '0 0 15 9',
    data: '<path pid="0" d="M2.046 8.635a1.164 1.164 0 01-1.693 0 1.263 1.263 0 010-1.752l6.3-6.518a1.164 1.164 0 011.694 0l6.3 6.518c.47.487.47 1.265 0 1.752-.19.196-.398.284-.619.322-.096.017-.178.02-.25.02-.072 0-.154-.003-.25-.02a1.08 1.08 0 01-.622-.325L7.477 2.975l-5.43 5.66z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
