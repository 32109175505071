/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wind-dark': {
    width: 48,
    height: 40,
    viewBox: '0 0 48 40',
    data: '<path pid="0" d="M24.594 22.37c9.45 0 14.229 6.927 10.66 13.058-4.022 6.911-14.24 5.442-16.375-1.738a1.613 1.613 0 013.09-.92c1.345 4.518 7.921 5.463 10.498 1.036 2.238-3.846-.773-8.21-7.873-8.21H6.987a1.613 1.613 0 010-3.225zm15.987 6.541a1.792 1.792 0 110 3.585 1.792 1.792 0 010-3.585zm-4.479-7.166a1.792 1.792 0 110 3.584 1.792 1.792 0 010-3.584zM24.444 6.039c3.138-7.044 12.313-8.41 16.179-1.51 1.55 2.767 1.569 6.465-.3 9.363-2.243 3.475-6.794 5.432-13.488 5.432H1.612a1.612 1.612 0 110-3.225h25.223c5.688 0 9.2-1.51 10.777-3.956 1.197-1.854 1.184-4.276.197-6.038-2.466-4.402-8.288-3.536-10.419 1.246a1.613 1.613 0 11-2.946-1.312zm-4.467 5.852a1.792 1.792 0 110 3.584 1.792 1.792 0 010-3.584zm25.98-1.792a1.792 1.792 0 11-.002 3.584 1.792 1.792 0 01.001-3.584zM33.414 6.516a1.792 1.792 0 11-.001 3.584 1.792 1.792 0 010-3.584z" _fill="#A2B0CA" fill-rule="nonzero"/>'
  }
})
