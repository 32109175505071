/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power-lock': {
    width: 27,
    height: 30,
    viewBox: '0 0 27 30',
    data: '<path pid="0" d="M13.5 7.367c-.715 0-1.372-.47-1.372-1.183V1.183C12.128.469 12.785 0 13.5 0c.715 0 1.372.47 1.372 1.183v5.001c0 .714-.657 1.183-1.372 1.183zM9.906 5.363a1.332 1.332 0 01-.708 1.77c-3.92 1.678-6.453 5.465-6.453 9.648 0 5.806 4.826 10.532 10.755 10.532 5.93 0 10.755-4.726 10.755-10.532 0-4.183-2.532-7.97-6.452-9.648a1.332 1.332 0 01-.708-1.771c.304-.677 1.109-.986 1.805-.692 4.92 2.107 8.1 6.86 8.1 12.111C27 24.071 20.945 30 13.5 30S0 24.071 0 16.781C0 11.53 3.18 6.777 8.104 4.668c.694-.291 1.5.018 1.802.695zM13.454 10c1.854 0 3.358 1.535 3.358 3.42v1.764c.739.002 1.34.54 1.34 1.323v4.63c0 .751-.601 1.363-1.34 1.363H10.09c-.739 0-1.34-.612-1.34-1.363v-4.63c0-.817.601-1.321 1.34-1.321V13.42c0-1.886 1.51-3.421 3.364-3.421zm0 7.083a.833.833 0 00-.834.834V20a.833.833 0 001.667 0v-2.083a.833.833 0 00-.833-.834zm0-5.288c-.804 0-1.459.639-1.459 1.423v1.975h2.917v-1.975c0-.784-.654-1.423-1.458-1.423z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
