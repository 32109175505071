// Router
import VueRouter from 'vue-router';

// Store
import store from '@/store/store';

// Sevices
import AuthService from '@/services/auth.service';
import StorageService from '@/services/storage.service';
import WebService from '@/services/web.service';

// Views
import LoginLayout from '@/pages/Login/LoginLayout';
import Login from '@/pages/Login/Login';
import MainLayout from '@/pages/Main/MainLayout';
import Installation from '@/pages/Main/Installation';
import Firmwares from '@/pages/Main/Firmwares';
import Version from '@/pages/Main/Version';
import Versions from '@/pages/Main/Versions';
import Firmware from '@/pages/Main/Firmware';
import Servers from '@/pages/Main/Servers';
import Device from '@/pages/Main/Device';
import DeviceFirmwares from '@/pages/Main/DeviceFirmwares'
import Users from '@/pages/Main/Users';
import User from '@/pages/Main/User';
import NotFound from '@/pages/NotFound'
import CONSTANTS from '@/constant';

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: {
      name: 'main',
    },
  },
  {
    path: '/login',
    name: 'loginLayout',
    component: LoginLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
      }
    ]
  },
  // Privates routes
  {
    path: '/main',
    name: 'main',
    component: MainLayout,
    redirect: {
      name: 'servers',
    },
    meta: {
      private: true,
    },
    beforeEnter: async (to, from, next) => {
      try {
        const token = StorageService.getItem('access_token');
        if (token) {
          await AuthService.isLogin(token);
        } else {
          next({ name: 'login' });
        }
        next();
      } catch( error ){
        AuthService.clearUser();
        console.log(error);
        next({ name: 'login' });
      }
    },
    children: [
      {
        path: '/servers',
        name: 'servers',
        component: Servers,
        beforeEnter: async (to, from, next) => {
          store.dispatch('navModule/deleteUser')
          store.dispatch('navModule/deleteDevice')
          store.dispatch('navModule/deleteInstallation')
          next()
        }
      },
      {
        path: '/installation/:installationId',
        name: 'installation',
        component: Installation,
        props: (route) => ({
          installationId: route.params.installationId,
        }),
        beforeEnter: async (to, from, next) => {
          try {
            checkNav(from, to)
            next();
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },
      },
      {
        path: '/device/:mac',
        name: 'device',
        component: Device,
        beforeEnter: async (to, from, next) => {
          try {
            checkNav(from, to)
            next();
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },  
      },
      {
        path: 'device/:mac/firmwares',
        name: 'deviceFirmwares',
        component: DeviceFirmwares,
        props: true,
        beforeEnter: async (to, from, next) => {
          try {
            checkNav(from, to)
            next();
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },
      }, 
      {
        path: '/firmwares',
        name: 'firmwares',
        component: Firmwares,
        beforeEnter: async (to, from, next) => {
          store.dispatch('navModule/deleteUser')
          store.dispatch('navModule/deleteDevice')
          store.dispatch('navModule/deleteInstallation')
          next()
        }
      },
      {
        path: '/firmware/:id',
        name: 'firmware',
        component: Firmware,
        beforeEnter: async (to, from, next) => {
          try {
            checkNav(from, to)
            next();
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },

      },
      {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: async (to, from, next) => {
          store.dispatch('navModule/deleteUser')
          store.dispatch('navModule/deleteDevice')
          store.dispatch('navModule/deleteInstallation')
          next()
        }
      },
      {
        path: '/user/:id',
        name: 'user',
        component: User,
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: async (to, from, next) => {
          try {
            const user = store.getters['userModule/getUser']

            if (Object.keys(user).length > 0) {
              checkNav(from, to)
              next();
            } else {
              const response = await WebService.getUser(to.params.id, 0)

              if (response) {
                store.dispatch('userModule/saveUser', response)
                checkNav(from, to)
                next();
              } else {
                next({name: 'users'})
              }
            }
            
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },
      },
      {
        path: '/versions',
        name: 'versions',
        component: Versions,
        beforeEnter: async (to, from, next) => {
          store.dispatch('navModule/deleteUser')
          store.dispatch('navModule/deleteDevice')
          store.dispatch('navModule/deleteInstallation')
          store.dispatch('navModule/deleteFirmware')
          next()
        }
      },
      {
        path: '/version/:version/:platform',
        name: 'version',
        component: Version,
        beforeEnter: async (to, from, next) => {
          try {
            checkNav(from, to)
            next();
          } catch( error ){
            AuthService.clearUser();
            console.log(error);
            next({ name: 'login' });
          }
        },
      },
    ]
  },
  // Routes error
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },

  // Routes not found
  {
    path: '*',
    name: 'notFound',
    redirect: {
      name: '404',
    },
  },
];


/**
 * Guarda la navegación en el store si no viene de pulsar el botón back y borra navegación repetida en el store si procede
 * 
 * @param {Object} from - Objecto de la ruta desde donde se navega
 * @param {Object} to - Objecto de la ruta hacia donde se navega
 */
function checkNav(from, to) {
  if (from.name && !to.query.back) {
    store.dispatch('navModule/savePrev', from.name)
  }
  removeRepeatedNav(to.name)
}

/**
 * Borra navegación repetida en el store si procede
 * 
 * @param {String} name - Nombre de la ruta borrar si existe en el store
 */
function removeRepeatedNav(name) {
  let array_nav = store.getters['navModule/getPrev']
  const i = array_nav.map(item => item).indexOf(name)

  if (i >= 0 ) {
    array_nav.splice(i, 1)
    store.dispatch('navModule/setPrev', array_nav)
  }
}

const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_BASE_URL;

const router = new VueRouter({
  routes,
  mode: 'hash',
  base: baseURL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;