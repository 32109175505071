/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-a_pp': {
    width: 43,
    height: 31,
    viewBox: '0 0 43 31',
    data: '<path pid="0" d="M30.087.264C18.272.264 10.357 2.45 5.707 6.3 2.33 9.098.96 12.495.96 15.937l.009.44c.086 2.33.861 4.392 2.243 6.028l.169.192.069-.125c-1.57 2.84-2.49 5.418-2.49 6.917a.875.875 0 001.75 0l.006-.184c.051-.773.409-1.971 1.01-3.374l.32-.718c.114-.244.234-.493.36-.745l.284-.556-.193-.15c1.606 1.301 3.675 2.139 6.056 2.44l.227.029.001-1.59-.173-.199-.437-.07c-1.73-.309-3.239-.957-4.44-1.898l-.164-.135-.088.148c3.393-5.796 8.38-10.903 14.226-13.735a.876.876 0 00.406-1.168.877.877 0 00-1.168-.407l-.567.284c-5.462 2.81-10.354 7.711-13.84 13.27l-.203.33.176.224c-1.175-1.422-1.8-3.217-1.8-5.248 0-2.947 1.192-5.863 4.14-8.305l.285-.23c3.942-3.104 10.427-5.007 19.92-5.338l.986-.028-.446.519c-2.322 2.776-3.975 5.892-4.941 9.869l-.36 1.569c-.477 2.035-.971 3.62-1.605 5l-.13.286h1.805l.186-.124c.101-.242.199-.488.293-.74l.209-.578c.066-.19.128-.379.19-.57l.176-.579c.057-.197.114-.4.17-.61l.169-.658.174-.727.186-.813.098-.445c1.096-4.983 3.197-8.382 6.49-11.678a.874.874 0 00-.619-1.493zm-16.63 30l.892-2.679h4.696l.902 2.679h2.848l-4.866-13h-2.482l-4.84 13h2.85zm4.865-4.848h-3.25l1.616-4.866 1.634 4.866zm10.723 3.544v-3.643h3.224v-2.33h-3.224v-3.491H26.59v3.491h-3.232v2.33h3.232v3.643h2.455zm9.983 0v-3.643h3.223v-2.33h-3.223v-3.491h-2.456v3.491H33.34v2.33h3.232v3.643h2.456z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
