/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear-night': {
    width: 37,
    height: 39,
    viewBox: '0 0 37 39',
    data: '<defs><linearGradient x1="50%" y1="1.9%" x2="50%" y2="99.353%" id="svgicon_clear-night_a"><stop stop-color="#73CFF9" offset="0%"/><stop stop-color="#57A8F2" offset="100%"/></linearGradient></defs><path pid="0" d="M950.581 701c9.064 1.561 15.96 9.46 15.96 18.97 0 10.631-8.619 19.25-19.25 19.25-7.593 0-14.158-4.395-17.292-10.78 1.07.184 2.17.28 3.292.28 10.631 0 19.25-8.619 19.25-19.25 0-2.972-.674-5.787-1.877-8.3z" transform="translate(-930 -701)" _fill="url(#svgicon_clear-night_a)" fill-rule="evenodd"/>'
  }
})
