/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M21.55 2.6c2.15 0 3.9 1.75 3.9 3.9v15c0 2.15-1.75 3.9-3.9 3.9h-17c-2.151 0-3.9-1.75-3.9-3.9v-15c0-2.15 1.749-3.9 3.9-3.9a.9.9 0 010 1.8c-1.157 0-2.1.943-2.1 2.1v1.099h21.2V6.5a2.104 2.104 0 00-1.95-2.095l-.15-.005a.9.9 0 010-1.8zm2.1 6.799H2.45V21.5c0 1.107.862 2.018 1.95 2.095l.15.005h17c1.157 0 2.1-.943 2.1-2.1V9.399zM6.55.6a.9.9 0 01.9.9v4a.9.9 0 01-1.8 0v-4a.9.9 0 01.9-.9zm13 0a.9.9 0 01.9.9v4a.9.9 0 01-1.8 0v-4a.9.9 0 01.9-.9zm-2 2a.9.9 0 010 1.8h-9a.9.9 0 010-1.8zM8.316 11a1 1 0 011 1v2.519a1 1 0 01-1 1H4.784a1 1 0 01-1-1V12a1 1 0 011-1h3.532zm-.501 1.5H5.284v1.518h2.53V12.5zm.5 4.072a1 1 0 011 1v2.518a1 1 0 01-1 1H4.785a1 1 0 01-1-1v-2.518a1 1 0 011-1h3.532zm-.5 1.499H5.284v1.519h2.53v-1.519zm7-7.071a1 1 0 011 1v2.519a1 1 0 01-1 1h-3.531a1 1 0 01-1-1V12a1 1 0 011-1h3.532zm-.5 1.5h-2.531v1.518h2.53V12.5zm.5 4.072a1 1 0 011 1v2.518a1 1 0 01-1 1h-3.531a1 1 0 01-1-1v-2.518a1 1 0 011-1h3.532zm-.5 1.499h-2.531v1.519h2.53v-1.519zm7-7.071a1 1 0 011 1v2.519a1 1 0 01-1 1h-3.531a1 1 0 01-1-1V12a1 1 0 011-1h3.532zm-.5 1.5h-2.531v1.518h2.53V12.5zm.5 4.072a1 1 0 011 1v2.518a1 1 0 01-1 1h-3.531a1 1 0 01-1-1v-2.518a1 1 0 011-1h3.532zm-.5 1.499h-2.531v1.519h2.53v-1.519z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
