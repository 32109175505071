/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'partly-cloudy-night-dark': {
    width: 52,
    height: 38,
    viewBox: '0 0 52 38',
    data: '<defs><linearGradient x1="50%" y1="1.9%" x2="50%" y2="99.353%" id="svgicon_partly-cloudy-night-dark_a"><stop stop-color="#73CFF9" offset="0%"/><stop stop-color="#57A8F2" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><path pid="0" d="M42.107 0c5.373.947 9.456 5.638 9.456 11.284 0 6.328-5.13 11.458-11.459 11.458-4.313 0-8.07-2.383-10.025-5.904.652.115 1.32.175 2.004.175 6.329 0 11.459-5.13 11.459-11.459 0-1.977-.501-3.838-1.383-5.461z" _fill="url(#svgicon_partly-cloudy-night-dark_a)"/><path pid="1" d="M25.208 37.638v-10.92l6.007.001c.31-5.413 4.743-9.706 10.165-9.706 5.624 0 10.182 4.617 10.182 10.312 0 5.492-4.238 9.98-9.582 10.295v.018H25.207z" _fill="#C9D0DF"/><path pid="2" d="M9.429 37.638v-.017C4.17 37.313 0 32.91 0 27.524c0-5.586 4.485-10.115 10.018-10.115.44 0 .875.03 1.301.085C13.281 11.237 19.08 6.7 25.93 6.7c8.461 0 15.321 6.926 15.321 15.47 0 8.42-6.665 15.27-14.96 15.464l-.361.004h-16.5z" _fill="#F6F9FF"/></g>'
  }
})
