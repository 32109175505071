<template>
  <div class="charts">
    <highcharts
      class="hc"
      v-if="loaded && chartdata"
      :options="chartdata"
      :updateArgs="[true, false, true]"
    ></highcharts>
    <EmptyCard
      v-else
      :condition="loaded && !chartdata"
      :bg-variant="'light'"
      style="padding-top: 8rem; padding-bottom: 15rem"
    ></EmptyCard>
    <div class="text-center loading" v-if="!loaded">
      <b-spinner
        style="width: 3rem; height: 3rem; margin-top: 15%"
        class=""
        variant="brand"
        label="Spinning"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import i18n from "@/services/language.service";
import moment from "moment-timezone";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import ConnectionsService from "@/services/connections.service";
import EmptyCard from "./EmptyCard.vue";

exportingInit(Highcharts);
exportdata(Highcharts);

export default {
  name: "BarChart",
  components: { highcharts: Chart, EmptyCard },
  props: {
    mac: String,
    type: String,
    formatDate: String,
    precision: String,
    timeZone: String,
    startDate: [String, Date],
    finishDate: [String, Date],
  },
  data: () => ({
    loaded: false,
    chartdata: null,
  }),
  watch: {
    startDate() {
      this.redraw();
    },

    timeZone() {
      this.redraw();
    },
  },
  methods: {
    async redraw() {
      this.loaded = false;
      await this.getDataAndDrawChart();
      this.loaded = true;
    },
    setData(chartdata) {
      let easeOutBounce = function (pos) {
        if (pos < 1 / 2.75) {
          return 7.5625 * pos * pos;
        }
        if (pos < 2 / 2.75) {
          return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
        }
        if (pos < 2.5 / 2.75) {
          return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
        }
        return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
      };

      Math.easeOutBounce = easeOutBounce;
      let chartData = {
        // labels: [],
        chart: {
          type: "column",
          // borderRadius: 5,
          //'#6c757d'
          // backgroundColor: {
          //   linearGradient: [0, 0, 500, 500],
          //   stops: [
          //     [0, "rgb(200, 200, 200)"],
          //     [1, "rgb(77, 77, 77)"],
          //   ],
          // },
          // 'rgba(255, 255, 255, 0.05)',
        },
        plotOptions: {
          series: {
            animation: {
              duration: 500,
              // easing: "easeOutBounce",
            },
          },
          column: {
            zones: [
              {
                value: 3,
                color: "#d45087",
              },
              {
                color: "#1991d2",
              },
            ],
          },
        },
        title: {
          text: "",
          /*x: -75,
          y: 12,
          style: {
            fontFamily: 'HelveticaNeueLTStd-Cn, sans-serif',
            color: '#b7c0c7',
            fontSize: 12
          } */
        },
        legend: {
          // title: { text: 'Nivel de señal' },
          floating: true,
          verticalAlign: "center",
          itemStyle: {
            fontFamily: "HelveticaNeueLTStd-Cn, sans-serif",
            fontSize: 12,
            fontWeight: "normal",
            color: "#33404d", //"#b7c0c7",
          },
          itemHoverStyle: {
            color: "#A0A0A0",
          },
          itemHiddenStyle: {
            color: "#444",
          },
        },
        navigation: {
          buttonOptions: {
            enabled: true,
          },
        },
        credits: {
          enabled: false,
        },
        colors: ["#1991d2", "#d45087"],
        exporting: {
          sourceWidth: 1000,
          sourceHeight: 600,
          enabled: true,
          // csv: {
          //   itemDelimiter: ';',
          //   columnHeaderFormatter: function(item, key) {
          //     if (!key) {
          //         return "Airzonecloud";
          //     }
          //     return false
          //   }
          // },
          // tableCaption: false,//"Airzonecloud",
          buttonSpacing: 3,
          buttons: {
            contextButton: {
              // menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.filter(function(item){ return item !== 'openInCloud' }),
              menuItems: [
                {
                  text: i18n.t("full_page"),
                  onclick: function () {
                    const graphContainer = document.querySelector(
                      ".highcharts-container"
                    );
                    const items = document.querySelector(".highcharts-menu");
                    if (
                      graphContainer &&
                      graphContainer.style.height !== "500px"
                    ) {
                      // Hide Data Table and change menu text
                      items.childNodes[0].innerHTML = i18n.t("full_page");
                      this.fullscreen.close();
                    } else {
                      // Show Full Screen and change menu text
                      this.fullscreen.toggle();
                      items.childNodes[0].innerHTML = i18n.t("out_page");
                    }
                  },
                },
                {
                  text: i18n.t("print_chart"),
                  onclick: function () {
                    this.print();
                  },
                },
                {
                  separator: true,
                },
                {
                  text: i18n.t("download_png"),
                  onclick: function () {
                    this.exportChart();
                  },
                },
                {
                  text: i18n.t("download_jpg"),
                  onclick: function () {
                    this.exportChart({
                      type: "image/jpeg",
                    });
                  },
                },
                {
                  text: i18n.t("download_pdf"),
                  onclick: function () {
                    this.exportChart({
                      type: "application/pdf",
                    });
                  },
                },
                {
                  text: i18n.t("download_svg"),
                  onclick: function () {
                    this.exportChart({
                      type: "image/svg+xml",
                    });
                  },
                },
                {
                  separator: true,
                },
                {
                  text: i18n.t("download_csv"),
                  onclick: function () {
                    this.downloadCSV();
                  },
                },
                {
                  text: i18n.t("download_xls"),
                  onclick: function () {
                    this.downloadXLS();
                  },
                },
                {
                  text: i18n.t("view_data_table"),
                  onclick: function () {
                    const tableDiv = document.querySelector(
                      ".highcharts-data-table"
                    );
                    const items = document.querySelector(".highcharts-menu");
                    if (tableDiv && tableDiv.style.display === "block") {
                      // Hide Data Table and change menu text
                      items.childNodes[10].innerHTML =
                        i18n.t("view_data_table");
                      tableDiv.style.display = "none";
                    } else {
                      // Show Data Table and change menu text
                      this.viewData();
                      items.childNodes[10].innerHTML =
                        i18n.t("hide_data_table");
                    }
                  },
                },
              ],
              // x: 10
            },
          },
        },
        tooltip: {
          useHTML: true,
          // formatter: function () {
          //   return '<div style="text-align:center">' + Highcharts.dateFormat('%H:%M', this.x) + '</div><br />' +
          //     '<div><b>' + this.y + '</b> ' + i18n.t('signal_level') + '</div>'
          // }
          borderRadius: 5,
          xDateFormat: this.formatDate.replaceAll("'", ""),
          // pointFormat: '<b>' + this.y + '</b><br/>',
          // valuePreffix:
          // valueSuffix: i18n.t('signal_level'),
          // shared: true
          // formatter() {
          //   return (
          //     '<div style="text-align:center">' + Highcharts.dateFormat(this.formatDate, this.x) + '</div><br />' +
          //     '<div><b>' + this.y + '</b> ' + i18n.t('signal_level') + '</div>'
          //   );
          // }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: { day: this.formatDate },
          max: this.max,
          min: this.min,
          labels: {
            style: { color: "#33404d" },
          },
        },
        yAxis: {
          max: 5,
          labels: {
            style: { color: "#33404d" },
            formatter: function () {
              let yaxis = "";
              if (this.value !== 5 && this.value !== 0) {
                yaxis = "quality." + this.value + ".title";
              }
              return i18n.t(yaxis);
            },
          },
          title: {
            text: "",
          },
        },
        series: [
          {
            // color: "#56722a",
            name: i18n.t("signal_level"),
            // label: "Última hora",
            // backgroundColor: "#28a745",
            // "#6c757d",
            data: [],
          },
        ],
      };

      chartData.series[0].data = this.fillChartData(chartdata);

      return chartData;
    },

    /**
     * Devuelve array con datos del gráfico rellenado con valores 0 en fechas sin datos desde la fecha de inicio hasta fecha final de la consulta
     *
     * @param {Array} chartdata - Datos del gráfico
     * @return {Array} - Datos del gráfico rellenado con valores 0 en fechas sin datos desde la fecha de inicio hasta fecha final
     */
    fillChartData(untidyChartdata) {
      let itemArray = [];
      let dataArray = [];
      let j = 0;

      // Sort the data
      let chartdata = untidyChartdata.sort(
        (a, b) => moment(a.dt) - moment(b.dt)
      );

      for (let i = 0; i < chartdata.length; i++) {

        let date = moment(chartdata[i].dt)

        if (this.type === 'hour' || this.timeZone !== moment.tz.guess()) {
          date = moment.tz(chartdata[i].dt, this.timeZone);
        }
        
        // Set offset to show date from 0 to 24 hour of days
        if (this.type === 'day' && this.timeZone !== moment.tz.guess()) {
          // Calculo la diferencia de offset de la zona horaria de aquí y la del webserver
          // Y se la añado para renderizar los datos desde 00:00 hasta 23:55
          const offset = date.utcOffset() + moment().utcOffset();
          date = date.utcOffset(offset)
          // console.log(offset, moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z")
        }
        
        let date_local = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        itemArray.push(moment(date_local).valueOf());
        itemArray.push(parseInt(chartdata[i].q));
        dataArray.splice(j, 0, itemArray);
        itemArray = [];
        j++;
      }

      return dataArray;
    },

    async getDataAndDrawChart() {
      let start = this.startDate
      let finish = this.finishDate

      if (this.type === 'day' && this.timeZone !== moment.tz.guess()) {
        let startTimeZone = moment.tz(this.startDate, this.timeZone)
        let finishTimeZone = moment.tz(this.finishDate, this.timeZone)
        // console.log(startTimeZone.utcOffset(), moment().utcOffset())
        // Calculo el offset de la zona horaria del webserver para pedir lo opuesto
        // del offset de las fechas inicio y fin. Así al renderizar datos se verán desde la 0 a 24h
        const offset = startTimeZone.utcOffset()
        // console.log(startTimeZone, finishTimeZone)
        const s = startTimeZone.utcOffset(-offset)
        const f = finishTimeZone.utcOffset(-offset)
        start = moment(s._d).toISOString()
        finish = moment(f._d).toISOString()
        // console.log(s, f ,start, finish)
      }

      const hourlist = await ConnectionsService.getLogs(
        this.mac,
        this.precision,
        start,
        finish
      );

      // Convert start and finish date in local date
      let startDate = moment(this.startDate)

      if (this.type === 'hour'){
        startDate = moment.tz(this.startDate, this.timeZone);
      }

      let startDate_local =
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      this.min = moment(startDate_local).valueOf();

      let finishDate = moment(this.finishDate)

      if (this.type === 'hour'){
        finishDate = moment.tz(this.finishDate, this.timeZone);
      }
      
      let finishDate_local =
      moment(finishDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      this.max = moment(finishDate_local).valueOf();

      // Fill chartdata or render within data
      if (hourlist && hourlist.length !== 0) {
        this.chartdata = this.setData(hourlist);
      } else {
        this.chartdata = null;
      }
    },
  },

  async mounted() {
    this.loaded = false;
    try {
      await this.getDataAndDrawChart();
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.hc {
  height: 500px;
}

.loading {
  padding-top: 1rem;
  padding-bottom: 17rem;
  p {
    color: var(grey5);
  }
}
</style>