/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-2': {
    width: 29,
    height: 21,
    viewBox: '0 0 29 21',
    data: '<path pid="0" d="M28.98 10.603c-.005-.031-.001-.063-.012-.094l-2.521-7.566a.634.634 0 00-.6-.43H11.979c-.007 0-.015.004-.024.006L9.931.206a.65.65 0 00-.95 0L6.304 3.265V1.883a.63.63 0 00-.63-.63H3.152a.63.63 0 00-.63.63v5.674c0 .009.005.016.005.025L.155 10.293c-.025.03-.035.063-.054.095-.02.032-.042.061-.056.097-.019.049-.024.1-.03.151-.002.025-.015.047-.015.073v8.826c0 .348.282.63.63.63h27.74a.63.63 0 00.63-.63V10.71c0-.038-.014-.07-.02-.106zm-3.586-6.829l2.102 6.305H18.57l-5.516-6.305h12.34zM3.783 2.514h1.26v1.89c0 .088.018.171.05.245l-1.31 1.498V2.514zm7.565 16.39H7.565v-1.89c0-1.043.849-1.892 1.892-1.892 1.042 0 1.89.849 1.89 1.892v1.89zm13.87 0h-6.305v-1.26h6.304v1.26zm0-2.52h-6.305v-1.262h6.304v1.261zm2.521 2.52h-1.26v-4.412a.63.63 0 00-.631-.63h-7.565a.63.63 0 00-.63.63v4.413h-5.044v-1.891a3.157 3.157 0 00-3.152-3.153 3.157 3.157 0 00-3.153 3.153v1.89H1.261v-7.959l8.196-9.366 8.352 9.545c.003.005.01.006.015.011.048.052.104.091.167.124.014.008.026.021.04.028a.617.617 0 00.252.053h9.456v7.565zM13.24 8.819H5.674a.63.63 0 00-.63.63v2.522c0 .348.282.63.63.63h7.565a.63.63 0 00.63-.63V9.448a.63.63 0 00-.63-.63zm-.63 2.522H6.304v-1.261h6.305v1.26z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
