/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'acs': {
    width: 24,
    height: 30,
    viewBox: '0 0 24 30',
    data: '<path pid="0" d="M2.611 21.52C2.175 22.151 0 25.375 0 26.875A3.13 3.13 0 003.125 30a3.13 3.13 0 003.125-3.125c0-1.5-2.175-4.724-2.61-5.355-.235-.339-.795-.339-1.029 0zm.514 1.485C4.025 24.411 5 26.192 5 26.875a1.877 1.877 0 01-1.875 1.875 1.877 1.877 0 01-1.875-1.875c0-.684.975-2.465 1.875-3.87zM9.375 0A1.877 1.877 0 007.5 1.875c0 1.034.841 1.875 1.875 1.875.396 0 1.843-.161 3.126-.429V5h-.626a3.131 3.131 0 00-3.062 2.5H6.875A6.882 6.882 0 000 14.375v3.75c0 .345.28.625.625.625h5c.345 0 .625-.28.625-.625v-2.5c0-1.034.841-1.875 1.875-1.875h.688a3.131 3.131 0 003.062 2.5h2.5a3.131 3.131 0 003.062-2.5h5.688c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-5.688A3.131 3.131 0 0014.375 5h-.625V3.321c1.282.268 2.729.429 3.125.429a1.877 1.877 0 001.875-1.875A1.877 1.877 0 0016.875 0c-.462 0-2.356.22-3.75.571C11.731.22 9.837 0 9.375 0zm-2.5 8.75H8.75v3.75h-.625A3.13 3.13 0 005 15.625V17.5H1.25v-3.125A5.632 5.632 0 016.875 8.75zm5-2.5h2.5c1.034 0 1.875.841 1.875 1.875v5A1.877 1.877 0 0114.375 15h-2.5A1.877 1.877 0 0110 13.125v-5c0-1.034.841-1.875 1.875-1.875zm5.625 2.5h5v3.75h-5V8.75zm-4.375-6.842a.62.62 0 00-.09.006l.09-.006a.628.628 0 00-.161.02c-1.298.348-3.215.572-3.589.572a.625.625 0 010-1.25c.374 0 2.291.224 3.589.571a.64.64 0 00.322 0c1.298-.347 3.215-.571 3.589-.571a.625.625 0 010 1.25c-.374 0-2.291-.224-3.589-.571l-.055-.013a.618.618 0 00-.016-.002l.071.015a.643.643 0 00-.161-.021z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
