/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reset': {
    width: 23,
    height: 20,
    viewBox: '0 0 23 20',
    data: '<path pid="0" d="M12.55 0c5.523 0 10.001 4.478 10.001 10 0 5.454-4.369 9.9-9.812 10l-.02-1.071a8.93 8.93 0 10-9.09-9.317l1.14-1.14a.521.521 0 01.737.737l-2.083 2.083a.519.519 0 01-.737 0L.603 9.21a.521.521 0 01.736-.737l1.215 1.216C2.719 4.309 7.131 0 12.55 0z" _fill="#000" fill-rule="evenodd"/>'
  }
})
