/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scenes-bold': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.13 23.8H5.67A5.677 5.677 0 010 18.13V5.67A5.677 5.677 0 015.67 0h12.46a5.677 5.677 0 015.67 5.67v12.46a5.677 5.677 0 01-5.67 5.67zM5.67 1.758a3.918 3.918 0 00-3.912 3.913v12.458a3.918 3.918 0 003.913 3.913h12.458a3.918 3.918 0 003.913-3.913V5.671a3.918 3.918 0 00-3.913-3.913H5.671zm3.834 15.334a.877.877 0 01-.88-.88l.001-8.625c0-.322.176-.62.456-.77a.873.873 0 01.898.032l6.708 4.311a.877.877 0 01.002 1.48l-6.71 4.313a.881.881 0 01-.475.139zm5.082-5.192l-4.203-2.702v5.404l4.203-2.702z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
