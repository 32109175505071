<template>
  <transition name="fromRight" appear>
    <div class="py-3 background">
      <b-button @click="goBack()" variant="warning" class="shadow mx-4">
        <az-icon
          name="left"
          scale="1"
          class="mr-1"
          style="margin-top: -0.1rem"
        ></az-icon>
        {{ $t("buttons.back") }}
      </b-button>
    </div>
  </transition>
</template>

<script>
import store from "@/store/store";

export default {
  methods: {
    /**
     * Obtiene la última navegación del store y actualiza el array en el store
     *
     * @return {String} - Devuelve el nombre de la ruta de la última navegación almacenada en el store
     */
    getLastNavigation() {
      let array_nav = store.getters["navModule/getPrev"];
      const lastNav = array_nav.pop();
      store.dispatch("navModule/setPrev", array_nav);
      return lastNav;
    },

    /**
     * Navega a la vista anterior
     *
     * @return {Object} - Devuelve navegación de la anterior vista
     */
    goBack() {
      let name = this.getLastNavigation();
      if (name === this.$route.name) {
        name = this.getLastNavigation();
      }
      if (name) {
        let path;
        switch (name) {
          case "user":
            path = store.getters["navModule/getUser"];
            break;
          case "device":
            path = store.getters["navModule/getDevice"];
            break;
          case "installation":
            path = store.getters["navModule/getInstallation"];
            break;
          case "firmware":
            path = store.getters["navModule/getFirmware"];
            break;
          case "main":
            path = { name: "main" };
            break;
        }
        if (!path) {
          path = { name: name };
        }
        path.query = { back: true };

        return this.$router.push(path).catch(() => {});
      } else {
        return this.$router.push({ name: "servers" }).catch(() => {});
      }
    },
  },
};
</script>
