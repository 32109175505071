/* eslint-disable no-undef */
import Vue from 'vue';
import Constants from '@/constant';
// import RollbarService from 'Core/services/Rollbar.service';

/**
 * Convierte un string cualquiera al mismo con la primera letra mayúscula y el resto minúsculas
 *
 * @param {String} s
 * @returns string
 */

const capitalize = s => {
  if (typeof s !== 'string') return ''
  const lowerString = s.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1)
}

/**
 * Rellena un número con ceros a la izquierda. Por ejemplo, para formatear horas o fechas
 *
 * @param {number} number - Número a complentar con ceros
 * @param {number} width - número de dígitos final que se espera
 * @returns
 */
const zfill = (number, width) => {
  const numberOutput = Math.abs(number); /* Valor absoluto del número */
  const length = number.toString().length; /* Largo del número */
  const zero = "0"; /* String de cero */

  if (width <= length) {
    if (number < 0) {
          return (`-${  numberOutput.toString()}`);
    }
    return numberOutput.toString();
  }

  if (number < 0) {
    return (`-${  zero.repeat(width - length)  }${numberOutput.toString()}`);
  }

  return ((zero.repeat(width - length)) + numberOutput.toString());
}

/**
 * Comprueba si una cada es un ASCII válido
 *
 * @param {string} str
 * @returns
 */
function isASCII(str) {
    // /^[\x00-\x7F]*$/  <- ASCII sin acentos
    // eslint-disable-next-line no-control-regex
    return /^[\x00-\x7FÀ-ÖØ-öø-ÿ]*$/.test(str);
}

/**
 * Comprueba si un SSID válido
 *
 * @param {string} str
 * @returns
 */
function isValidSSID(str) {
  // eslint-disable-next-line no-useless-escape
  return /^[!#;].|[+\[\]/"\t\s].*$/.test(str);
}

/**
 * Comprueba si una versión válida
 *
 * @param {string} str
 * @returns
 */
function isValidVersion(str) {
  return Constants.REGEX.VERSION.test(str)
}

/**
 * Típica función "debounce" que impide una ejecución continuada de una función sin dejar un espacio de guarda entre cada una
 *
 * @param {function} func - función a ejecutar con el efecto aplicado
 * @param {Number} wait - Espera en milisegundos antes de ejecutar de nuevo la función
 * @param {*} immediate - Indica si la primera ejecución es inmediata o si espera el tiempo de rebote
 * @returns
 */
function debounce(func, wait, immediate) {
	let timeout;
	// eslint-disable-next-line func-names
	return function() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
		// eslint-disable-next-line func-names
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * Función "throttle". Introduce un intervalo de espera
 * @param {function} func - función a ejecutar
 * @param {Number} wait  - intervalo de ejecución en ms
 * @returns
 */
function throttle(func, wait) {
  let context; let args; let result;
  let timeout = null;
  let previous = 0;

  // eslint-disable-next-line func-names
  const later = function () {
    previous = Date.now();
    timeout = null;
    result = func.apply(context, args);
    // eslint-disable-next-line no-multi-assign
    if(!timeout) context = args = null;
  };

  // eslint-disable-next-line func-names
  return function() {
    const now = Date.now();
    if(!previous) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if( remaining <= 0 || remaining > wait) {
      if(timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      if( !timeout ) context = args = null;
    } else if (!timeout ){
      timeout = setTimeout(later, remaining);
    }
    return result;
  }
}

/**
 * Función que recibe un array de entrada y lo fragmenta en otros más pequeños según una longitud dada
 *
 * @param {Array} input Array a fragmentar
 * @param {number} itemsEachFragment Items que tendrá como máximo cada fragmento
 * @returns Array que contiene los arrays más pequeños.
 */
const fragmentArray = (input, itemsEachFragment) => {

  const arrayOfFragments = [];

  for (let i = 0; i < input.length; i+= itemsEachFragment) {
    const fragment = input.slice(i, i + itemsEachFragment);
    arrayOfFragments.push(fragment);
  }

  return arrayOfFragments;
}

const requestLocationAuthorization = () => {
  return new Promise ((resolve, reject) => {
    cordova.plugins.diagnostic.requestLocationAuthorization( async status => {
      console.log("Geolocation activa", status);
      switch(status){
        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
          try {
            await this.getGeolocation()
            resolve(status);
          } catch ( error ) {
            reject(error);
          }
          console.log("Permission not requested");
          break;
        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
          console.log("Permission denied");
          Vue.prototype.$locationActive = false;
          break;
        case cordova.plugins.diagnostic.permissionStatus.GRANTED:
          console.log("Permission granted always");
          Vue.prototype.$locationActive = true;
          break;
        case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          console.log("Permission granted only when in use");
          Vue.prototype.$locationActive = true;
          break;
        default:
      }
      resolve(status);
    },
    error => {
      console.log("Debemos activar geolocation",error);
      Vue.prototype.$locationActive = false;
      reject(error);
    });
  });
}

const getCurrentNetwork = async () => {
  let network = ''
  try {
    network = await WifiWizard2.getConnectedSSID()
  } catch( error ) {
    console.log('Unnable to get network ssid', error)
  }

  return network;
}

const getGeolocation = () => {
  return new Promise (( resolve, reject ) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position);
        Vue.prototype.$locationActive = true;
        resolve(position);
      },
      error => {
        console.log(error.message);
        // Asignamos a undefined para diferenciarlo de false y así, saber que hemos pasado por aquí
        Vue.prototype.$locationActive = undefined;
        reject(error);
      });
  })
}


const getNetworkState = () => {

  const states = {};
  let networkState;
  //
  // Test de 5G en USA. Vamos a deshabilitar temporalmente la obtención de la conexión
  // a través de 'cordova-plugin-network-information'.
  //
  // Obtendremos el estado de la red en cualquier caso a través de navigator.onLine
  //
  // Añadimos monitorización por rollbar del estado de red
  //

  if(Constants.IS_MOBILE) {
    networkState = navigator.connection.type;


    states[Connection.UNKNOWN]  = Constants.NETWORK.UNKNOWN;
    states[Connection.ETHERNET] = Constants.NETWORK.ETHERNET;
    states[Connection.WIFI]     = Constants.NETWORK.WIFI;
    states[Connection.CELL_2G]  = Constants.NETWORK.CELL_2G;
    states[Connection.CELL_3G]  = Constants.NETWORK.CELL_3G;
    states[Connection.CELL_4G]  = Constants.NETWORK.CELL_4G;
    states[Connection.CELL_5G]  = Constants.NETWORK.CELL_5G;
    states[Connection.CELL]     = Constants.NETWORK.CELL;
    states[Connection.NONE]     = Constants.NETWORK.NONE;

    // RollbarService.info(`getNetworkState [Cordova plugin mobile]. navigator.connection.type: ${networkState} - STATE: ${states[networkState]}`);
  }
  else {
    networkState = navigator.onLine.toString();

    states.true = Constants.NETWORK.ONLINE;
    states.false = Constants.NETWORK.NONE;

    // RollbarService.info(`getNetworkState [web]. navigator.onLine: ${networkState} - STATE: ${states[networkState]}`);

  }

  console.log("Estado de la red", states[networkState]);

  return states[networkState];
}

const sanitizeMacAddress = mac => {
  const pairs = mac.split(':');

  for(let i = 0; i < pairs.length; i++){
    if(pairs[i].length < 2){
      pairs[i] = `0${pairs[i]}`
    }
  }

  return pairs.join(':');
};

const getLocalTimeString = (StringUTCdate, timezone) => {
  return (new Date(StringUTCdate)).toLocaleString({timezone});
};

const getParamURI = (pageURL, sParam) => {
  const splitPageURL = pageURL.split("?");
  const sPageURL = splitPageURL[1];
  if(sPageURL !== undefined){
    const sURLVariables = sPageURL.split("&");
    let sParameterName = null;

    for (let i = 0; i < sURLVariables.length; i += 1) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  } else if(splitPageURL.length === 1) {
    const sURLVariables = splitPageURL[0].split("&");
    let sParameterName = null;

    for (let i = 0; i < sURLVariables.length; i += 1) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  return null;
};

const isValidParamURI = (pageURL, sParam) => {
  const splitPageURL = pageURL.split("?");
  let isValid = false;

  if(splitPageURL && splitPageURL.length >= 1){
    for(let i=0; i < splitPageURL.length; i++){

      const sURLVariables = splitPageURL[i].split("&")
      let sParameterName = null;

      console.log(sURLVariables, sParam);


      for(let j=0; j < sURLVariables.length; j++){
        console.log(sURLVariables[j]);
        if(sURLVariables[j] && sURLVariables[j].includes("=")){
          sParameterName = sURLVariables[j].split("=");

          if(sParameterName[0] === sParam){
            isValid = true;
            break;
          }
        }

      }


      if(isValid) {
        break;
      }


    }

  }

  return isValid;
};

const isMyScriptLoaded = url => {
  const scripts = document.getElementsByTagName('script');

  for (let index = 0; index < scripts.length; index += 1) {
    if (scripts[index].src.startsWith(url)) return true;
  }

  return false;
};

/**
     * Añade la librería de google maps para las sugerencias de localizaciones
     */
const  addGoogleMapScript = lang => {
  const scripts = document.getElementsByTagName('script');
  const key = Constants.CONNECT.GOOGLEMAP.KEY; // eslint-disable-line
  const src = `${Constants.CONNECT.GOOGLEMAP.API_URL}?key=${key}&language=${lang}&libraries=places`;
  if (scripts.length && !isMyScriptLoaded('https://maps.googleapis.com')) {
    const scriptMapsGeocode = document.createElement('script');
    scriptMapsGeocode.setAttribute('async', true);
    scriptMapsGeocode.setAttribute('defer', true);
    scriptMapsGeocode.setAttribute('src', src);
    document.body.appendChild(scriptMapsGeocode);
  }
};

export {
  zfill,
  capitalize,
  getGeolocation,
  requestLocationAuthorization,
  getNetworkState,
  getCurrentNetwork,
  fragmentArray,
  isASCII,
  isValidSSID,
  isValidVersion,
  debounce,
  throttle,
  sanitizeMacAddress,
  getLocalTimeString,
  getParamURI,
  isValidParamURI,
  isMyScriptLoaded,
  addGoogleMapScript
};
