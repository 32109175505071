<template>
  <b-container fluid class="m-0 p-0">
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-0">
        <template header>

          <b-row v-if="!isAdminVendor()">
            <b-col class="buttonsAddServer">
              <b-button variant="brand" v-b-toggle.add-server>
                <az-icon name="addDevice" scale=".7"></az-icon>
                <span class="ml-2">
                  {{ $t('buttons.add_server') }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </template>

        <template lead>
          <b-collapse id="add-server" class="mb-3">
            <b-card>
              <b-row>
                <b-col sm="5">
                  <b-form class="needs-validation" novalidate>
                    <b-form-group
                      :label="$t('buttons.add_server')"
                      label-cols-lg="3"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >
                      <b-form-group
                        :label="`${$t('mac')}:`"
                        label-for="input-newMac"
                        label-cols-sm="6"
                        label-align-sm="right"
                      >
                        <b-form-input
                          @input.native="macPattern($event, false)"
                          @focus="errors.mac = null"
                          v-model="newMac"
                          id="input-newMac"
                          size="lg"
                          autocomplete="newMac"
                          autofocus
                          placeholder="12:AB:34:CD:45:FD"
                          :class="{ borderError: errors.mac }"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        :label="`${$t('pin')}:`"
                        label-for="input-newPin"
                        label-cols-sm="6"
                        label-align-sm="right"
                      >
                        <b-form-input
                          @input.native="pinPattern($event)"
                          @focus="errors.pin = null"
                          v-model="newPin"
                          id="input-newPin"
                          size="lg"
                          minlength="4"
                          maxlength="4"
                          autocomplete="newPin"
                          autofocus
                          placeholder="1234"
                          :class="{ borderError: errors.pin }"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        :label="`${$t('airtools.device')}:`"
                        label-for="input-newType"
                        label-cols-sm="6"
                        label-align-sm="right"
                      >
                        <b-form-select
                          v-model="newType"
                          :options="newTypeOptions"
                          @change="handlerTypeSelect"
                          id="input-newType"
                          class="mb-3 shadow"
                          :class="{ borderError: errors.type }"
                          size="lg"
                          value-field="value"
                          text-field="text"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-for="sumbit-newServer"
                        label-cols-sm="6"
                        label-align-sm="right"
                      >
                        <b-button
                          id="sumbit-newServer"
                          :disabled="!newMac || !newPin"
                          class="btn btn-brand"
                          @click="saveServer"
                          >{{ $t("buttons.save") }}</b-button
                        >
                      </b-form-group>
                    </b-form-group>
                  </b-form>
                </b-col>

                <b-col sm="6">
                  <b-row>
                    <b-col sm="12">
                      <div class="error my-2 pb-2" v-if="errors.mac">
                        {{ $t("messages.errors.macLength") }}
                      </div>
                    </b-col>
                    <b-col sm="12">
                      <div
                        class="error my-2 py-3"
                        :class="{ marginTop: !errors.mac }"
                        v-if="errors.pin"
                      >
                        {{ $t("messages.errors.pinLength") }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col sm="1">
                  <b-row>
                    <b-col sm="12">
                      <az-icon
                        name="close"
                        scale="1"
                        style="float: right"
                        v-b-toggle.add-server
                      ></az-icon>
                    </b-col>
                  </b-row>
                </b-col>
                
              </b-row>
            </b-card>
          </b-collapse>
        </template>

        <b-row class="my-3">
          <b-col lg="8" sm="12">
            <b-input-group>
              <b-input-group-prepend>
                <div class="az-input__value">
                  <div class="az-input__body">
                    <b-form-input
                      v-model="macFilter"
                      id="macFilter"
                      class="searchFilter"
                      placeholder="MAC (12:AB:34:CD:45:FD)"
                      size="lg"
                      autocomplete="macFilter"
                      autofocus
                      @keyup.enter="findServer()"
                      @input.native="macPattern($event, true)"
                    >
                    </b-form-input>
                    <div class="az-input__search">
                      <div
                        class="eyeIcon searchIcon"
                        :class="{
                          rightIcon: macFilter === null || macFilter === '',
                        }"
                        @click="findServer()"
                      >
                        <az-icon name="search" scale="1"></az-icon>
                      </div>
                      <div
                        class="clearIcon"
                        @click="macFilter = ''"
                        v-if="macFilter !== null && macFilter !== ''"
                      >
                        <az-icon name="clear" scale="1"></az-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        
          <b-col lg="4" sm="12" class="h4 mt-1 pt-1 text-right">
            <az-icon
              name="device"
              scale="1"
              style="margin-top: -0.3rem"
            ></az-icon>
            {{ total }}
            <span v-show="total !== 1">
              {{ $t("airtools.devices") }}
            </span>
            <span v-show="total === 1">
              {{ $t("airtools.device") }}
            </span>
          </b-col>
        </b-row>
        

        <EmptyCard
          class="mt-5"
          :condition="total === 0 && !isLoading"
        ></EmptyCard>

        <b-table
          v-if="!isLoading && total > 0"
          id="table-servers"
          responsive
          selectable
          outlined
          hover
          head-variant="dark"
          @row-clicked="infoDevice"
          :tbody-tr-class="rowClass"
          :items="servers"
          :fields="fields"
          busy.sync="isLoading"
        >
          <template #cell(mac)="data">
            <div class="font-weight-bold">
              {{ data.item._id }}
            </div>
          </template>

          <template #cell(ws_type)="data">
            <div class="font-weight-bold">
              <b-badge
                variant="brand"
                v-if="wsType(data.item.ws_type).isAirzone"
                >{{ wsType(data.item.ws_type).text }}</b-badge
              >
              <b-badge
                variant="light"
                v-if="wsType(data.item.ws_type).isAidoo"
                >{{ wsType(data.item.ws_type).text }}</b-badge
              >
              <b-badge
                variant="success"
                v-if="wsType(data.item.ws_type).isCentral"
                >{{ wsType(data.item.ws_type).text }}</b-badge
              >
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge variant="danger" v-if="!data.item.status.isConnected">{{
              $t("disconnected")
            }}</b-badge>
            <b-badge variant="success" v-if="data.item.status.isConnected">{{
              $t("connected")
            }}</b-badge>
          </template>

          <template #cell(ws_fw)="data">
            <div class="font-weight-bold">
              {{ data.item.config.ws_fw }}
              <span v-if="data.item.config.lmachine_fw">/{{ data.item.config.lmachine_fw }}</span>
            </div>
          </template>
        </b-table>

        <div class="row" v-if="isLoading">
          <div class="col-12 d-flex justify-content-center">
            <div class="text-center" style="width: 30rem; height: 35rem">
              <b-spinner
                style="width: 3rem; height: 3rem; margin-top: 25%"
                class=""
                variant="brand"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
        </div>
      </b-jumbotron>
    </transition>
    <div class="pb-3">
      <Pagination
        v-if="total > 10"
        v-model="currentPage"
        :totalRows="total"
        :perPage="perPage"
        :ariaControls="'table-servers'"
        :align="'center'"
      >
      </Pagination>
    </div>
  </b-container>
</template>

<script>
import store from "@/store/store";
import WebService from "@/services/web.service";
import EmptyCard from "@/components/EmptyCard.vue";
import Pagination from "@/components/Pagination.vue";
import setStoreFilters from "@/mixins/SetStoreFilters.mixin";
import setFirstPage from "@/mixins/CheckFilter.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import CONSTANTS from "@/constant";
import i18n from "@/services/language.service";
import Role from '@/mixins/Role.mixin'

export default {
  name: "Servers",
  props: {},
  mixins: [setStoreFilters, wsType, rowClass, setFirstPage, showMsgOk, Role],
  components: {
    EmptyCard,
    Pagination,
  },
  data() {
    return {
      macFilter: "",
      total: 0,
      perPage: 10,
      currentPage: 1,
      isLookingFor: false,
      isLoading: false,
      servers: {},
      fields: [
        {
          key: "mac",
          label: "Mac",
        },
        {
          key: "ws_type",
          label: i18n.t("airtools.device"),
        },
        {
          key: "status",
          label: i18n.t("state"),
        },
        {
          key: "ws_fw",
          label: i18n.t("system.firm_ws"),
        },
      ],
      newMac: null,
      newPin: null,
      newType: null,
      newTypeOptions: [
        { value: null, text: i18n.t("buttons.select") },
        { value: "airzone", text: CONSTANTS.TAG.WS_AZ },
        { value: "az_8cb", text: CONSTANTS.TAG.WS_8CB },
        { value: "aidoo", text: CONSTANTS.TAG.WS_AIDOO },
        { value: "aidoo_mlf", text: CONSTANTS.TAG.WS_AIDOO_MLF },
      ],
      errors: {},
      file: null,
    };
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;

      if (!this.isLookingFor) {
        // Borra el filtro de busqueda
        if (this.macFilter) {
          this.macFilter = "";
        }
      }
      this.findServers();
    },
  },

  methods: {
    /**
     * Obtiene el webserver buscado desde el filtro de búsqueda
     */
    async findServer() {
      this.isLookingFor = true;
      // Asigna filtro y página actual
      this.setFirstPage(this.macFilter);
      this.findServers();
    },

    /**
     * Obtiene los webservers paginados
     */
    async findServers() {
      try {
        this.isLoading = true;

        // Obtengo página y filtro del store si ha habido navegación anterior en esta vista
        if (!this.isLookingFor) {
          const storeParams = this.setStoreFilters();

          if (storeParams && Object.keys(storeParams).length > 0) {
            this.macFilter = storeParams.filter;
            this.currentPage = storeParams.page;
          }
        }

        const response = await WebService.getServers(
          this.macFilter,
          this.currentPage - 1
        );

        // this.$root.$emit("bv::toggle::collapse", "add-server");
        this.total = response.total;
        this.servers = response.wwss;
        this.animateTableRows()
        this.isLoading = false;
      } catch(err) {
        console.log(err)
        this.isLoading = false;
      } 
    },

    /**
     * Enlaza con la vista del dispositivo
     * @param {Object} item - Objecto con los datos del dispositivo
     */
    infoDevice(item) {
      const path = { name: "device", params: { mac: item._id } };
      // Salvo la ruta de navegación del webserver en VUEX
      store.dispatch("navModule/saveDevice", path);
      // Borra el filtro de busqueda si se escribe en el input sin buscar y se clica en un webserver dentro de la página
      store.dispatch("navModule/savePageAndFilter", {
        filter: this.macFilter,
        page: this.currentPage,
      });
      this.$router.push(path);
    },

    /**
     * Dispara el evento onblur en todos los dispositivos
     * @param {Object} e - Evento del input
     */
    hideKeyboard(e) {
      e.preventDefault();
      setTimeout(() => {
        document.getElementById("macFilter").blur();
      }, 10);
    },

    /**
     * Máscara en input del pin
     *
     * @param {Object} event - Evento del input
     */
    pinPattern(event) {
      let value = event.target.value;
      let str = value.replace(CONSTANTS.REGEX.PIN_DIGITS, "");
      this.newPin = str;
      // Valida dinamicamente el formulario para visualizar mensajes de error
      if (this.isPin(this.newPin)) {
        this.errors.pin = false;
      }
    },

    /**
     * Máscara en input de la mac
     *
     * @param {Object} event - Evento del input
     * @param {Boolean} filter - Indica si es la mac del filtro de búsqueda o de añadir servidor
     */
    macPattern(event, filter) {
      let value = event.target.value;
      const r = CONSTANTS.REGEX.MAC_DIGITS;
      let str = value.replace(/[^a-f0-9]/gi, "");

      while (r.test(str)) {
        str = str.replace(r, "$1:$2");
      }

      value = str.slice(0, 17).toUpperCase();

      if (filter) {
        this.macFilter = value;
      } else {
        this.newMac = value;
        // Valida dinamicamente el formulario para visualizar mensajes de error
        if (this.isMac(this.newMac)) {
          this.errors.mac = false;
        }
      }
    },

    handlerTypeSelect() {
      if(this.isValidType(this.newType)) {
        this.errors.type = false;
      } else {
        this.errors.type = true;
      }
    },

    /**
     * Valido expresión regular de la mac
     *
     * @param {String} mac - Mac a validar
     */
    isMac(mac) {
      var re = CONSTANTS.REGEX.MAC;
      return re.test(mac);
    },

    /**
     * Valido expresión regular del tipo de dispositivo
     *
     * @param {String} mac - Mac a validar
     */
    isValidType(type) {
      return type !== null;
    },

    /**
     * Valido expresión regular del pin
     *
     * @param {String} pin - Contraseña a validar
     */
    isPin(pin) {
      var re = CONSTANTS.REGEX.PIN;
      return re.test(pin);
    },

    /**
     * Valido mac y pin
     */
    validate() {
      const errors = {};

      if (!this.isMac(this.newMac)) {
        errors.mac = true;
      }

      if (!this.isPin(this.newPin)) {
        errors.pin = true;
      }

      if(!this.isValidType(this.newType)) {
        errors.type = true;
      }

      return errors;
    },

    /**
     * Valida y guarda la mac y el pin del nuevo servidor
     */
    async saveServer() {
      if (this.newMac && this.newPin && this.newType) {
        //
        // Inicializamos valores
        //
        this.errors = {};

        if (this.newMac === null) {
          this.newMac = "";
        }

        if (this.newPin === null) {
          this.newPin = "";
        }

        //
        // Si hay una petición en curso no hago nada
        //
        if (this.isLoading) return;

        //
        // Valido formulario
        //
        this.errors = this.validate();
        if (Object.keys(this.errors).length !== 0) return;

        //
        // Realizo la petición
        //
        this.isLoading = true;

        const data = {
          mac: this.newMac,
          pin: this.newPin,
          type: this.newType,
        };
        const response = await WebService.provision(data);
        this.isLoading = false;

        if (response) {
          this.showMsgOk(
            i18n.t("messages.sleepingTitle"),
            i18n.t("messages.sleepingMsg")
          );
          this.$root.$emit("bv::toggle::collapse", "add-server");
        } else {
          this.$bvModal.show("show-modal-error");
        }
      } else {
        this.errors = this.validate();
      }
    }
  },

  async mounted() {
    // Resetea el store para página y filtro
    store.dispatch("navModule/deletePageAndFilter");
    await this.findServers();
  },
};
</script>

<style lang="scss" scoped>
.az-input__search {
  display: flex;
}

.borderError {
  border: 2px solid red;
}

.marginTop {
  margin-top: 3.5rem !important;
}

label[for="input-newPin"] {
  text-transform: capitalize;
}

.buttonsAddServer {
  float: left;
  padding-top: 1.3rem;
}

.buttonsAddServer .az-icon {
  position: relative;
  top: -0.1rem;
  fill: var(--light);
}

.form-group {
  input,
  select {
    box-shadow: 1px 1px 5px #000 !important;
  }
}

</style>