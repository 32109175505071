/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-20': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M21.221 25.386a.626.626 0 01-.594.824l-5.626-.001v3.126a.625.625 0 11-1.25 0v-3.126H8.126a.62.62 0 01-.506-.26.622.622 0 01-.087-.563l1.809-5.428H7.5v3.126a.625.625 0 11-1.25 0v-3.126H.625a.62.62 0 01-.506-.26.622.622 0 01-.087-.563L6.283.383c.171-.51 1.017-.51 1.187 0l4.198 12.597 2.116-6.347c.171-.51 1.016-.51 1.186 0l2.115 6.345L21.284.384c.171-.51 1.016-.51 1.186 0l6.251 18.752a.626.626 0 01-.593.823l-5.626-.001v3.126a.625.625 0 11-1.25 0v-3.126h-1.84l1.81 5.428zM14.377 8.809l-2.114 6.34a.619.619 0 01-.002.005l-1.457 4.376-.005.009-1.807 5.42h4.759v-1.875a.625.625 0 011.25 0v1.875h4.759l-1.807-5.418a.65.65 0 01-.003-.01l-1.458-4.375-.002-.005-2.113-6.342zM6.876 2.558L1.49 18.708H6.25v-1.875a.625.625 0 011.25 0v1.875h2.259l1.249-3.75-4.132-12.4zm15.002 0l-4.133 12.398 1.25 3.752h2.257v-1.875a.625.625 0 011.25 0v1.875h4.759l-5.383-16.15z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
