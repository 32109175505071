/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-fill': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M24.5 22.6l-4.9-1.2-.4-1.8c1.2-1.2 2-3 2.2-5.1.4 0 .9-.1 1-.9l.5-2.5c.1-.6-.1-1.2-.4-1.6-.2-.2-.4-.3-.6-.4V6.2l.5-.5c.8-.9 1.5-1.9.4-3.5C22 .8 20.3 0 18.2 0h-.4c-1.1 0-2.9 0-4.4 1C9.1 1.1 7 2.7 7 6c0 .6.4 2 .6 2.8-.3.1-.5.3-.7.5-.3.4-.5.9-.4 1.4l.3 2.5c.1.8.7 1.4 1.5 1.5.2 1.7 1.1 3.5 2.3 4.7l-.5 1.9-4.9 1.2c-3.1.8-5.2 3.6-5.2 6.8v.3c0 .2.2.4.5.4h29c.3 0 .5-.2.5-.5 0-3.1-2.4-6.2-5.5-6.9z"/>'
  }
})
