import S3Service from '@/services/s3.service'

const FirmwareMixin = {


  computed: {
    
  },

  methods: {
    /**
     * Saca el último archivo de los arrays correspondientes
     */
    remove() {
      this.errors = {};
      this.files.pop()
      this.loadedFiles.pop()
    },

    /**
     * Añade los archivos al array de cargados
     */
    inputChanged() {
      this.errors = {}
      if (this.loadedFiles.length < 2) {
        this.loadedFiles = [
          ...this.loadedFiles,
          ...this.files
        ]
      }
    },

    /**
     * Sube archivos de firmware a S3 de AWS
     * 
     * @param {Object} data - Objecto con los archivos a subir (nombre y url de subida)
     * @param {Array} files - Array con los nombres de los archivos cargados
     */
    async uploadFirmwareFiles(data, files) {
      // Compruebo si hay archivos de subida al S3
      if (data?.post_files?.length > 0) {

        for(let i = 0; i < data.post_files.length; i++) {
          const file = files.find(f => f.name === data?.post_files[i]?.file_name)

          if (file) {
            const resp = await S3Service.put(data.post_files[i]?.url, file)
            console.log(resp)
          } else {
            this.$bvModal.show("bv-modal-error")
            return true
          }
        }

        return false
      }
    },

    /**
     * Asigna la pestaña activa
     *
     * @param {Number} value - Valor del tab clickado
     */
     clickTab(value, isAdmin) {
      if (isAdmin) {
        this.tabAdmin = value;
        this.$nextTick(() => {
          this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[0])
          this.editorStyle(document.getElementsByClassName('ql-editor')[0])
        })
      } else {
        this.tab = value;
        this.$nextTick(() => {
          this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[1])
          this.editorStyle(document.getElementsByClassName('ql-editor')[1])
        })
      }
      
    },

    /**
     * Comprueba que el valor es igual que el tab actual
     *
     * @param {String} Value - Valor del tab
     * @param {Boolean} isAdmin - Indica si es texto de cambio o texto de versión en app
     * @return {Boolean} - True si es el mismo valor que el tab actual o false si es distinto
     */
    checkTab(value, isAdmin) {
      if (isAdmin) {
        value === this.tabAdmin;
      } else {
        value === this.tab;
      }
      return value
    },

    /**
     * Scroll al elemento del dom con errores
     * 
     * @param {String} id - Id del elemento del dom con error
     */
     scrollTo(id) {
      const container = document.getElementById("container-app")

      if (container !== null) {
        container.scrollTo(0, container.scrollHeight);
      }
      const input = document.getElementById(id)
      input.focus()
    },

    /**
     * Estilo de icono HTML en la barra de herramientas
     * de los campos de texto área
     * 
     * @param {Object} toobar - Elemento del dom de la barra de herramientas
     */
    htmlButtonStyle(toolbar) {
      const htmlButton = toolbar?.lastElementChild
      if (htmlButton) {
        htmlButton.childNodes[0].style = "display: inline-flex !important; color: #555; font-weight: 500; position: relative; top: -0.12rem; left: -0.7rem;"
      }
    },

    editorStyle(editor) {
      if (editor) editor.style = "min-height: 100px; background-color: white !important";
    },
  }
}

export default FirmwareMixin;