/* eslint-disable no-undef */
export default {


    /**
     * CONNECT
     *
     * @constant {Object} CONNECT - Parámetros de conexión con el backend
     * @property {Object} APP - Parámetros del host y conexión api
     */
    CONNECT: {
      APP: {
        AZCLOUD_BASE_URL: process.env.VUE_APP_AZCLOUD_BASE_URL,
        AZCLOUD_API_URL: process.env.VUE_APP_AZCLOUD_API_URL,
        AZCLOUD_SOCKET_PATH: process.env.VUE_APP_AZCLOUD_SOCKET_PATH,
        AZCLOUD_API_V2_URL: process.env.VUE_APP_AZCLOUD_API_V2_URL,
      },
    },

    /**
     * @constant {Object} REGEX Regular expressions to validate forms
     */
    REGEX: {
      MAC: /^(([A-F0-9]{2}:){5}([A-F0-9]{2}))$/,
      PIN: /^\d{4}$/,
      EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      MAC_DIGITS: /([a-f0-9]{2})([a-f0-9]{2})/i,
      PIN_DIGITS: /[^0-9]/ig,
      VERSION: /^(\d+\.)?(\d+\.)?(\*|\d+)$/,
    },
    
    /**
     * TAG
     *
     * @constant {Object} TAG - Tipo de webserver
     */
    TAG: {
      WS_AZ: 'AIRZONE',
      WS_8CB: 'CENTRAL BLE',
      WS_AIDOO: 'AIDOO/AIDOO PRO',
      WS_AIDOO_MLF: 'AIDOO PRO FANCOIL',
      WS_AQSENSOR_WIFI: 'AIRQ SENSOR WIFI',
    },
    
    /**
     * AIDOOTYPE
     *
     * @constant {Object} AIDOOTYPE - Tipo de Aidoo
     */
    AIDOOTYPE: {
      AIDOO: 'AIDOO',
      AIDOO_PRO:'AIDOO PRO',
      AIDOO_MLF: 'AIDOO PRO FANCOIL',
    },

    AIDOO_GROUP: {
      AIDOO_C3_MINI: "LAIDOO_ESP_C3_MINI"
    },
    /**
     * WSTYPE
     *
     * @constant {Object} WSTYPE - Arquitectura de webserver
     */
    WEBSERVERTYPE:  {
      AIRZONE: 'ws_az',
      AIDOO: 'ws_aidoo',
      AQSENSOR_WIFI: 'ws_az',
    },

    /**
     * WSTYPE
     *
     * @constant {Object} WSTYPE - Arquitectura de webserver
     */
    WSTYPE:  {
      wifi2016: 'Webserver WIFI 2016 (K70)',
      eth2016: 'Webserver Cable 2016/ wsCCP (K70)',
      wifi5G: 'Webserver WIFI 2020 (IMX6)',
      hub: 'Webserver HUB (IMX6)'
    },

    /**
     * WSTYPE
     *
     * @constant {Object} WSHARD - Hardware de webserver
     */
    WSHARD:  {
      AIRQSENSOR_WIFI: 'airqsensorWifi',
    },

    /**
     * WSTYPE
     *
     * @constant {Object} FIRWMWAREFILES - Nombre de archivos de firmware
     * @constant {Object} ws_az - Nombre de archivos de firmware para webservers airzone
     * @constant {Object} ws_aidoo - Nombre de archivos de firmware para webservers aidoo
     */
    FIRMWAREFILES: {
      ws_az: {
        update: 'update.sh',
        ota: 'ota.zip',
        firmware: 'AirQSensorConnectivity.bin'
      },
      ws_aidoo: {
        update: 'firmware.sh',
        ota: 'laidoo.zip',
        firmware: 'laidoo.zip'
      },
    },

    /**
     * COVERAGE
     *
     * @constant {Object} COVERAGE - Intensidad de señal
     */
    COVERAGE: {
      WIFI: {
        HIGHEST: 220,
        HIGH: 210,
        MEDIUM: 200,
        LOW: 190,
        LOWEST: 180,
      },
      HIGHEST: -55,
      HIGH: -60,
      MEDIUM: -65,
      LOW: -70,
      LOWEST: -75,
  },
    
  /**
   * TIME UPDATING WEBSERVER
   */
  TIMEOUT: {
    MAX_TIME_UPDATING_WS: 240000
  },

  /**
    * PLATFORM
    *
    * @type {Boolean} - Indica si la plataforma es Android, ios o web
    */
  PLATFORM: {
    IOS: 'ios',
    ANDROID: 'android',
    WEB: undefined
  },

  VALID_VERSIONS_NAME: {
    AIRZONE: 'XX.XX',
    AIDOO: 'XX.XX',
    airqsensorWifi: 'XX.XX.XX',
  }
};