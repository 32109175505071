<template>
  <b-card v-if="condition" :text-variant="textColor" class="block">
    <b-card-body :bg-variant="bgColor" :align="align">
      <b-card-title :bg-variant="bgColor">{{ message }}</b-card-title>
    </b-card-body>
  </b-card>
</template>

<script>
import i18n from "@/services/language.service";
export default {
  name: "EmptyCard",
  props: {
    condition: {
      type: Boolean,
      require: true,
    },
    message: {
      type: String,
      default() {
        return i18n.t("messages.noResult");
      },
    },
    bgColor: {
      type: String,
      default: "gray6",
    },
    textColor: {
      type: String,
      default: "dark",
    },
    align: {
      type: String,
      default: "center",
    },
  },
};
</script>
<style scoped>
.block {
  display: block;
}
</style>