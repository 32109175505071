/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down-solid': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" d="M1.188 1.182a.674.674 0 000 .935l3.36 3.476a.62.62 0 00.904 0l3.36-3.476a.674.674 0 000-.935.574.574 0 00-.33-.172.72.72 0 00-.134-.01.72.72 0 00-.133.01c-.118.02-6.776-.087-7.027.172z" _fill="#FFF" fill-rule="nonzero" _stroke="#FFF" stroke-width=".2"/>'
  }
})
