/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear-night-dark': {
    width: 36,
    height: 38,
    viewBox: '0 0 36 38',
    data: '<defs><linearGradient x1="50%" y1="1.9%" x2="50%" y2="99.353%" id="svgicon_clear-night-dark_a"><stop stop-color="#73CFF9" offset="0%"/><stop stop-color="#57A8F2" offset="100%"/></linearGradient></defs><path pid="0" d="M951.215 702c8.901 1.534 15.673 9.292 15.673 18.631 0 10.442-8.465 18.906-18.906 18.906-7.457 0-13.905-4.316-16.983-10.587 1.05.18 2.13.275 3.233.275 10.441 0 18.906-8.465 18.906-18.906 0-2.951-.676-5.744-1.882-8.233z" transform="translate(-931 -702)" _fill="url(#svgicon_clear-night-dark_a)" fill-rule="evenodd"/>'
  }
})
