/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rows': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M25.133 18.571c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 25.133v-5.695c0-.479.388-.867.867-.867h24.266zm-.26 1.127H1.127v5.175h23.746v-5.175zm.26-10.412c.479 0 .867.388.867.866v5.696a.867.867 0 01-.867.866H.867A.867.867 0 010 15.848v-5.696c0-.478.388-.866.867-.866h24.266zm-.26 1.126H1.127v5.175h23.746v-5.175zM25.133 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 6.562V.867C0 .388.388 0 .867 0h24.266zm-.26 1.127H1.127v5.175h23.746V1.127z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
