/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-off': {
    width: 52,
    height: 34,
    viewBox: '0 0 52 34',
    data: '<path pid="0" d="M13.332 3.994c7.158 0 12.961 5.803 12.961 12.961 0 .448-.023.89-.067 1.327h-2.353c.054-.435.082-.877.082-1.327 0-5.867-4.756-10.623-10.623-10.623-5.867 0-10.623 4.756-10.623 10.623 0 5.867 4.756 10.623 10.623 10.623 2.459 0 4.722-.835 6.522-2.237v2.817a12.901 12.901 0 01-6.522 1.758c-7.158 0-12.96-5.803-12.96-12.96 0-7.159 5.802-12.962 12.96-12.962zm-.297 3.897c.645 0 1.169.523 1.169 1.169v7.309l4.374 4.374a1.169 1.169 0 01-1.653 1.653l-4.393-4.42a1.169 1.169 0 01-.666-1.055V9.06c0-.646.523-1.17 1.169-1.17zM10.823.744c.289.578.055 1.28-.523 1.569L4.011 5.457a1.169 1.169 0 11-1.045-2.091L9.255.221a1.169 1.169 0 011.568.523zm15.433 19.467c1.058 0 2 .254 2.822.763.823.51 1.46 1.24 1.911 2.19.451.951.676 2.05.676 3.294v.567l-.006.37c-.04 1.099-.259 2.072-.657 2.92-.442.942-1.074 1.67-1.897 2.181-.824.512-1.767.768-2.832.768-1.053 0-1.992-.253-2.818-.76-.826-.505-1.466-1.228-1.92-2.167-.453-.94-.683-2.02-.689-3.242v-.628l.007-.37c.04-1.1.264-2.077.67-2.933.45-.95 1.087-1.68 1.91-2.19.823-.509 1.764-.763 2.823-.763zm15.583.174v2.12h-5.654v3.281h5.026v2.112h-5.026v5.191h-2.617V20.385h8.271zm9.79 0v2.12h-5.654v3.281H51v2.112h-5.026v5.191h-2.618V20.385h8.272zm-25.373 1.99c-.879 0-1.556.341-2.033 1.025-.477.683-.719 1.685-.724 3.006v.62l.004.293c.034 1.157.27 2.065.711 2.725.477.716 1.163 1.073 2.06 1.073.878 0 1.552-.344 2.023-1.033.472-.69.71-1.695.716-3.015v-.62l-.004-.301c-.034-1.184-.271-2.096-.712-2.735-.477-.692-1.157-1.038-2.041-1.038zM16.589.22l6.29 3.145a1.169 1.169 0 01-1.046 2.091l-6.29-3.144A1.169 1.169 0 0116.59.22z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
