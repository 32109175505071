/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thunderstorm-dark': {
    width: 52,
    height: 47,
    viewBox: '0 0 52 47',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_thunderstorm-dark_a"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><path pid="0" _fill="url(#svgicon_thunderstorm-dark_a)" d="M23.04 33.23h6.843l-3.574 5.74h2.598l-8.282 7.718 2.414-6.594h-2.414z"/><path pid="1" d="M25.208 30.938v-10.92l6.007.001c.31-5.413 4.743-9.706 10.165-9.706 5.624 0 10.182 4.617 10.182 10.312 0 5.492-4.238 9.98-9.582 10.295v.017H25.207z" _fill="#7484A6"/><path pid="2" d="M9.429 30.938v-.018C4.17 30.613 0 26.21 0 20.823 0 15.237 4.485 10.71 10.018 10.71c.44 0 .875.029 1.301.085C13.281 4.536 19.08 0 25.93 0 34.39 0 41.25 6.926 41.25 15.469c0 8.42-6.665 15.27-14.96 15.464l-.361.005h-16.5z" _fill="#A2B0CA"/></g>'
  }
})
