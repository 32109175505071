/**
 * Guarda los datos del usuario en el store
 *
 * @param {Object} userData - User Data
 */
const saveUser = ({ commit }, userData) => {
  commit('ADD_USER', userData);
};

/**
 * Elimina los datos del usuario del store
 */
const deleteUser = ({ commit }) => {
  commit('ADD_USER', {});
};

export default {
  saveUser,
  deleteUser,
};
