<template>
  <b-container fluid class="m-0 p-0">
    <GoBack />
    <b-jumbotron class="pb-0 pt-2">
      <transition name="fade-in-transition" appear>
        <b-row>
          <b-col sm="5">
            <b-card
              class="shadow"
              bg-variant="gray5"
              text-variant="white"
              border-variant="gray5"
              align="center"
              :title="$t('user.user')"
              title-tag="h3"
            >
              <b-card-header header-bg-variant="brand-dark">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem">
                      <az-icon name="user" scale="2"></az-icon>
                    </b-avatar>
                  </transition>
                  <div>
                    <Badge
                      v-if="isAdmin"
                      :class="{ roleAdmin: isAdmin }"
                      :bgBadge="colorAdmin"
                      :pillable="'badge-pill'"
                      :textColor="'text-light'"
                      :text="$t('login.admin')"
                    >
                    </Badge>
                  </div>
                </b-card-title>
                <!--b-card-sub-title>
                
              </b-card-sub-title-->
                <b-card-text>
                  <b-container class="">
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.name")
                      }}</b-col>
                      <b-col class="text-right"
                        >{{ user.data.name }} {{ user.data.lastName }}</b-col
                      >
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("login.email")
                      }}</b-col>
                      <b-col class="text-right">{{ user.email }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("login.signUpLink")
                      }}</b-col>
                      <b-col class="text-right">{{
                        user.created_at | formatDate
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.confirmation")
                      }}</b-col>
                      <b-col>
                        <div class="text-right" v-if="user.confirmation_date">
                          {{ user.confirmation_date | formatDate }}
                        </div>
                        <div
                          class="text-right text-danger"
                          v-if="!user.confirmation_date"
                        >
                          <az-icon name="error-check" scale="2"></az-icon>
                          {{ $t("user.pendingConfirmation") }}
                        </div>
                      </b-col>
                    </b-row>

                    <b-row v-if="!user.confirmation_date">
                      <b-col>
                        <b-button
                          @click="resendConfirmationEmail(email)"
                          h-align="center"
                          variant="brand"
                        >
                          {{ $t("buttons.resend_confirmation") }}
                        </b-button>
                      </b-col>
                      <b-col v-if="user.data.name && user.data.lastName">
                        <b-button
                          @click="manualConfirmationEmail(user._id)"
                          h-align="center"
                          variant="brand"
                        >
                          {{ $t("buttons.manual_confirmation") }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <hr v-if="user.confirmation_email_date" />
                    <b-row
                      align-h="between"
                      v-if="user.confirmation_email_date"
                    >
                      <b-col class="text-left text-black">{{
                        $t("user.emailConfirmationDate")
                      }}</b-col>
                      <b-col class="text-right">{{
                        user.confirmation_email_date | formatDate
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("login.language")
                      }}</b-col>
                      <b-col class="text-right">
                        <span>{{ language() }}</span>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.integrations")
                      }}</b-col>
                      <b-col
                        class="text-right"
                        v-if="Object.keys(user.integrations).length > 0"
                      >
                        <div
                          v-for="(value, key) in user.integrations"
                          :key="key"
                        >
                          <b-row v-if="value.unauthorized_at">
                            <b-col>
                              <b-row>
                                <b-col>
                                  <az-icon
                                    name="error-check"
                                    scale="2"
                                    class="text-warning"
                                  ></az-icon>
                                </b-col>
                                <b-col class="leftLabel">
                                  <span class="text-capitalize">{{ key }}</span>
                                </b-col>
                                <!--b-col>
                                <az-icon
                                  name="check"
                                  scale="2"
                                  class="text-success"
                                ></az-icon>
                              </b-col-->
                              </b-row>
                              <b-row class="centerDate">
                                <span v-if="value.unauthorized_at">
                                  {{ value.unauthorized_at | formatDate }}
                                </span>
                              </b-row>
                            </b-col>
                          </b-row>

                          <b-row v-else>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <az-icon
                                    name="check"
                                    scale="2"
                                    class="text-success"
                                  ></az-icon>
                                </b-col>
                                <b-col class="leftLabel">
                                  <span class="text-capitalize">{{ key }}</span>
                                </b-col>
                                <!--b-col>
                                <az-icon
                                  name="check"
                                  scale="2"
                                  class="text-success"
                                ></az-icon>
                              </b-col-->
                              </b-row>
                              <b-row class="centerDate">
                                <span v-if="value.authorized_at">
                                  {{ value.authorized_at | formatDate }}
                                </span>
                              </b-row>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col
                        class="text-right"
                        v-if="Object.keys(user.integrations).length === 0"
                      >
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("login.marketing")
                      }}</b-col>
                      <b-col class="text-right">
                        <az-icon
                          name="check"
                          scale="2"
                          class="text-success"
                          v-if="user.data.commercial"
                        ></az-icon>
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                          v-if="!user.data.commercial"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <hr />
                    <!--b-row align-h="between">
                    <b-col class="text-left text-black">{{
                      $t("user.notifications")
                    }}</b-col>
                    <b-col class="text-right">
                      <az-icon
                        name="check"
                        scale="2"
                        class="text-success"
                        v-if="user.config.notification"
                      ></az-icon>
                      <az-icon
                        name="error-check"
                        scale="2"
                        class="text-warning"
                        v-if="!user.config.notification"
                      ></az-icon>
                    </b-col->
                  </b-row>
                  <hr />
                  <b-row align-h="between">
                    <b-col class="text-left text-black">{{
                      $t("user.units")
                    }}</b-col>
                    <b-col class="text-right">
                      <span>{{ units() }}</span>
                    </b-col>
                  </b-row>
                  <hr /-->
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.ampm")
                      }}</b-col>
                      <b-col class="text-right">
                        <az-icon
                          name="check"
                          scale="2"
                          class="text-success"
                          v-if="user.config.ampm"
                        ></az-icon>
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                          v-if="!user.config.ampm"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.sundayFirst")
                      }}</b-col>
                      <b-col class="text-right">
                        <az-icon
                          name="check"
                          scale="2"
                          class="text-success"
                          v-if="user.config.sundayFirst"
                        ></az-icon>
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                          v-if="!user.config.sundayFirst"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <hr />
                    <!--b-row align-h="between">
                    <b-col class="text-left text-black">{{ $t('user.noHaptic') }}</b-col>
                    <b-col class="text-right">
                      <az-icon name="check" scale="2" class="text-success" v-if="user.config.noHaptic"></az-icon>
                      <az-icon  name="error-check" scale="2" class="text-warning" v-if="!user.config.noHaptic"></az-icon>
                    </b-col>
                  </b-row-->
                  </b-container>
                </b-card-text>
              </b-card-header>
              <!-- b-card-body>
            </b-card-body-->
            </b-card>
          </b-col>
          <b-col sm="7">
            <b-card
              class="shadow"
              :class="{
                'card-user': isLoading,
                'card-user-active': !isLoading,
              }"
              bg-variant="gray5"
              text-variant="white"
              border-variant="brand-a20"
              align="center"
              :title="$t('installations.installations')"
              title-tag="h3"
            >
              <b-card-header header-bg-variant="brand-a20">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem" variant="secondary">
                      <az-icon name="groups" scale="2"></az-icon>
                    </b-avatar>
                  </transition>
                </b-card-title>
                <!--b-card-sub-title>
                
              </b-card-sub-title-->
                <b-card-text>
                  <b-spinner v-show="isLoading"></b-spinner>

                  <b-card-group
                    class="mb-1"
                    id="table-installations"
                    v-show="!isLoading && user.total > 0"
                    v-for="(installation, index) in user.installations"
                    :style="`transition-delay: ${index * 0.1}s; opacity: 0`"
                    :key="installation._id"
                  >
                    <b-card
                      bg-variant="brand-dark"
                      text-variant="light"
                      :title="installation.name"
                      v-b-toggle="`component-${installation._id}`"
                      title-tag="h4"
                    >
                      <b-row v-if="installation.confirmed === false">
                        <b-col></b-col>
                        <b-col cols="2">
                          <Badge
                            bgBadge="warning-light"
                            scale="1"
                            textColor="text-danger"
                            name="warning"
                            :text="$t('user.pending')"
                          >
                          </Badge>
                        </b-col>
                        <b-col cols="3">
                          <div class="my-1 font-weight-bold">
                            {{ installation.added_at | formatDate }}
                          </div>
                        </b-col>
                        <b-col></b-col>
                      </b-row>

                      <b-collapse :id="`component-${installation._id}`">
                        <b-card-header>
                          <b-card-title align="center">
                            <b-row>
                              <b-col
                                v-if="installation.confirmed === false"
                              ></b-col>
                              <b-col
                                class="h5 col-12"
                                v-if="installation.location_text"
                              >
                                {{ installation.location_text.city.es }} ({{
                                  installation.location_text.country.es
                                }})
                              </b-col>
                              <b-col cols="12" class="">
                                <DetailsButton
                                  :route="{
                                    name: 'installation',
                                    params: {
                                      installationId:
                                        installation.installation_id,
                                    },
                                  }"
                                  :icon="'groups'"
                                >
                                </DetailsButton>
                              </b-col>
                            </b-row>
                          </b-card-title>

                          <b-card-text>
                            <b-container class="">
                              <b-row align-h="between">
                                <b-col class="text-left text-black">{{
                                  $t("user.typeTitle")
                                }}</b-col>
                                <b-col class="text-right">
                                  <Badge
                                    :bgBadge="
                                      accessTypeColor(installation.access_type)
                                    "
                                    :pillable="'badge-pill'"
                                    :text="
                                      $t(`user.${installation.access_type}`)
                                    "
                                  >
                                  </Badge>
                                </b-col>
                              </b-row>
                              <hr v-if="installation.confirmed !== false" />
                              <b-row
                                align-h="between"
                                v-if="installation.confirmed !== false"
                              >
                                <b-col class="text-left text-black">{{
                                  $t("airtools.devices")
                                }}</b-col>
                                <b-col
                                  class="text-right"
                                  v-if="
                                    installation.ws_ids &&
                                    installation.ws_ids.length > 0
                                  "
                                >
                                  <div
                                    class="mt-2"
                                    v-for="(mac, index) in installation.ws_ids"
                                    :key="mac"
                                    :style="`transition-delay: ${index * 0.1}s`"
                                  >
                                    <DetailsButton
                                      :route="{
                                        name: 'device',
                                        params: { mac: mac },
                                      }"
                                      :bgButton="'brand'"
                                      :icon="'device'"
                                      :pillable="'rounded-pill'"
                                      :text="mac"
                                      :arrowRight="false"
                                    >
                                    </DetailsButton>
                                  </div>
                                </b-col>
                                <b-col
                                  class="text-right"
                                  v-if="
                                    installation.ws_ids &&
                                    installation.ws_ids.length === 0
                                  "
                                >
                                  <az-icon
                                    name="error-check"
                                    scale="2"
                                    class="text-warning"
                                  ></az-icon>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-card-text>
                        </b-card-header>
                      </b-collapse>
                    </b-card>
                  </b-card-group>

                  <EmptyCard
                    :condition="user.total === 0"
                    :bgColor="'light'"
                  ></EmptyCard>

                  <Pagination
                    v-if="!isLoading && user.total > 10"
                    v-model="currentPage"
                    :totalRows="user.total"
                    :perPage="perPage"
                    :ariaControls="'table-installations'"
                    :align="'center'"
                  >
                  </Pagination>

                  <!--/b-jumbotron-->
                </b-card-text>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
      </transition>
    </b-jumbotron>
  </b-container>
</template>

<script>
import WebService from "@/services/web.service";
import { LANGUAGES } from "@/services/language.service";
import store from "@/store/store";
import GoBack from "@/components/GoBack";
import DetailsButton from "@/components/DetailsButton";
import Badge from "@/components/Badge";
import EmptyCard from "@/components/EmptyCard";
import Pagination from "@/components/Pagination";
import resendConfirmationEmail from "@/mixins/ResendConfirmationEmail.mixin";
import accessTypeColor from "@/mixins/AccessTypeColor.mixin";
import animateCardGroup from "@/mixins/AnimateCardGroup.mixin";
import i18n from "@/services/language.service";

export default {
  name: "User",
  props: {
    user: {
      type: Object,
      default() {
        return store.getters["userModule/getUser"];
      },
    },
  },
  mixins: [resendConfirmationEmail, accessTypeColor, animateCardGroup],
  components: {
    GoBack,
    DetailsButton,
    Badge,
    EmptyCard,
    Pagination,
  },
  data() {
    return {
      email: this.$route.params.email,
      currentPage: 1,
      perPage: 10,
      totalPendingInstallations: 0,
      isLoading: false,
    };
  },
  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;
      this.getUserInstallations(this.user.user_id);
    },
  },

  computed: {
    /**
     * Devuelve si el usuario tiene el modo administrador
     */
     isAdmin() {
      return this.user.config.admin_mode !== undefined;
    },

    /**
     * Devuelve el color del badge si tiene el modo admin disponible
     */
    colorAdmin() {
      return this.isAdmin ? "success" : "warning";
    },
  },

  methods: {
    /**
     * Devuelve el idioma del usuario
     *
     * @return {String} - El idioma del usuario
     */
    language() {
      return LANGUAGES[this.user.config.lang];
    },

    /**
     * Devuelve las unidades de temperatura traducido
     *
     * @return {String} - Texto de las unidades de temperatura traducido
     */
    units() {
      return this.user.config.units
        ? i18n.t("user.unitsName.C")
        : i18n.t("user.unitsName.F");
    },

    /**
     * Obtiene la información de las instalaciones del usuario
     */
    async getUserInstallations() {
      try {
        this.isLoading = true;
        const response = await WebService.getUserInstallations(
          this.user._id,
          this.currentPage - 1
        );

        this.user.installations = response.installations;
        this.user.total = response.total;
        this.totalPendingInstallations = response.pendingInstallations;

        if (this.totalPendingInstallations > 0) {
          const pendingInstallations =
            await WebService.getUserPendingInstallations(this.user._id, 0);

          pendingInstallations.installations.forEach((pendingInstallation) => {
            this.user.installations.unshift(pendingInstallation);
          });
        }

        this.isLoading = false;
        this.animateCardGroup();
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    },
  },

  mounted() {
    this.getUserInstallations();
  },
  created() {},
  beforeRouteLeave: function (to, from, next) {
    // Clean user of VUEX
    store.dispatch("userModule/deleteUser");
    next();
  },
};
</script>

<style scoped>
#table-installations:hover {
  border: 1px solid hsl(207, 15%, 86%);
}

.roleAdmin {
  justify-content: center;
  align-items: center;
}

.leftLabel {
  text-align: left;
  margin-top: 0.25rem;
}

.centerDate {
  justify-content: center;
}
</style> 