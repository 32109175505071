/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-block': {
    width: 29,
    height: 27,
    viewBox: '0 0 29 27',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M2.774.083h1.8v11.5h-1.8zM20.774.083h1.8v16.5h-1.8zM11.774.083h1.8v3.5h-1.8zM2.774 16.483h1.8v7.5h-1.8zM9.974 5.083h5.5v1.8h-5.5zM.974 13.083h5.5v1.8h-5.5zM27.374 20.983v-1.5c0-1.5-1.2-2.7-2.8-2.7-1.6 0-2.8 1.2-2.7 2.7v1.4c-.6 0-1.1.4-1.1 1v3.7c0 .6.5 1.1 1.1 1.1h5.6c.6 0 1.1-.5 1.1-1.1v-3.7c-.2-.5-.7-.9-1.2-.9zm-2.2 3.8c0 .3-.3.6-.7.6-.3 0-.7-.2-.7-.6v-1.7c0-.3.2-.6.7-.6.4 0 .7.3.7.6v1.7zm.5-5.4v1.6h-2.4v-1.6c0-.6.6-1.1 1.2-1.1.7 0 1.3.4 1.2 1.1zM11.774 8.483h1.8v15.5h-1.8z"/></g>'
  }
})
