/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M18.913 20.87h-.652v-7.174a.652.652 0 00-.652-.653H12.39A1.959 1.959 0 0010.435 15c0 1.079.878 1.957 1.956 1.957h.652v3.913h-.652a1.959 1.959 0 00-1.956 1.956c0 1.079.878 1.957 1.956 1.957h6.522a1.959 1.959 0 001.957-1.957 1.959 1.959 0 00-1.957-1.956zm0 2.608h-6.522a.652.652 0 010-1.304h1.305c.36 0 .652-.292.652-.652v-5.218a.652.652 0 00-.652-.652H12.39a.652.652 0 010-1.304h4.566v7.174c0 .36.292.652.652.652h1.304a.652.652 0 010 1.304zM15 0C6.73 0 0 6.73 0 15s6.73 15 15 15 15-6.73 15-15S23.27 0 15 0zm0 28.696C7.448 28.696 1.304 22.552 1.304 15S7.448 1.304 15 1.304 28.696 7.448 28.696 15 22.552 28.696 15 28.696zm0-16.957a3.265 3.265 0 003.26-3.26A3.265 3.265 0 0015 5.216a3.265 3.265 0 00-3.26 3.261A3.265 3.265 0 0015 11.74zm0-5.217c1.079 0 1.957.878 1.957 1.956A1.959 1.959 0 0115 10.435a1.959 1.959 0 01-1.957-1.957c0-1.078.878-1.956 1.957-1.956z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
