import ApiService from '@/services/api.service';
import CONSTANTS from "@/constant";

const WebService = {
  /**
   * Devuelve los servidores paginados por filtros
   *
   * @param {String} filterValor - Valor del filtro (mac)
   * @param {number} page - Número de página
   * @param {String} installationId - Identifcador de la instalación (opcional)
   * @return {Object} - Objeto con el array de servidores y el total de servidores
   */
  async getServers(filterValue, page, installationId) {
    let requestUrl = '/admin/devices/wwss?';
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    
    requestUrl += `filterParam=mac&filterValue=${filterValue}`;
    if(page) requestUrl += `&page=${page}`;
    if(installationId) requestUrl += `&installation_id=${installationId}`;
    // console.log("In get servers", requestUrl);
    //
    // Realizo la petición y transformo los datos
    //
    try {
      // console.log(requestUrl);
      const response = await ApiService.get(requestUrl);
      // console.log('WebService: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve los usuarios con el filtro y paginado
   *
   * @param {String} filterParam - Tipo de filtro
   * * @param {String} filterValue - Valor del filtro
   * @param {number} page - Número de página
   * @return {Object} - Objeto con el array de usuarios y el total de páginas
   */
   async getUsers(filterParam, filterValue, page) {
    let requestUrl = `/admin/user?filterParam=${filterParam}&filterValue=${filterValue}`;
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    // if(items) requestUrl += `&items=${items}`;
    if(page) requestUrl += `&page=${page}`;
    // console.log("In get users", requestUrl);
    //
    // Realizo la petición y transformo los datos
    //
    try {
      const response = await ApiService.get(requestUrl);
      this.users = response.data.users;
      this.count = response.data.total;
      // console.log('WebService users: ', this);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve el usuario con el filtro y paginado
   *
   * @param {String} userId - Id del usuario
   * @return {Object} - Objeto con la información del usuario
   */
   async getUser(userId) {
    let requestUrl = `/admin/user/${userId}`;
    //
    // Realizo la petición y transformo los datos
    //
    try {
      const response = await ApiService.get(requestUrl);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de una instalación
   *
   * @param {String} installationId - Id de la instalación para filtrar sus usuarios
   * @return {Object} - El objeto con la información de la instalación
   */
   async getInstallation(installationId) {
    let requestUrl = `/admin/installations/${installationId}`;
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getInstallation: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Obtiene los datos de la localización de la instalación
   *
   * @param {String} locationID - ID de la localizacion
   * @returns {Object} Datos de la localización
   */
  async getInstallationLocation(locationID) {
    // Request
    const resource = `/admin/installations/location/${locationID}`;
    const response = await ApiService.get(resource);

    return response.data;
  },

  /**
   * Devuelve los usuarios de una instalación por páginas
   *
   * @param {String} installationId - Id de la instalación para filtrar sus usuarios
   * @return {Array} - El array de usuarios de la instalación
   */
   async getInstallationUsers(installationId) {
    let requestUrl = `/admin/installations/${installationId}/users`;

    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getInstallationUsers: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve las instalaciones del usuario paginadas
   *
   * @param {number} page - Número de página
   * @param {String} userId - Id del usuario para filtrar sus instalaciones
   * @return {Array} - El array de instalaciones del usuario
   */
   async getUserInstallations(userId, page) {
    let requestUrl = `/admin/installations?userId=${userId}`;
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    if(page) requestUrl += `&page=${page}`;
    // console.log("In getUserInstallations", requestUrl);
    //
    // Realizo la petición y transformo los datos
    //
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('userInstallations Service: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve las instalaciones del usuario paginadas
   *
   * @param {number} page - Número de página
   * @param {String} userId - Id del usuario para filtrar sus instalaciones
   * @return {Array} - El array de instalaciones pendientes del usuario
   */
   async getUserPendingInstallations(userId, page) {
    let requestUrl = `/admin/installations/notconfirmed?userId=${userId}`;
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    if(page) requestUrl += `&page=${page}`;
    // console.log("In get pending installations", requestUrl);
    //
    // Realizo la petición y transformo los datos
    //
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('pendingInstallations Service: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de un webserver
   *
   * @param {String} mac - Mac del webserver para filtrar sus usuarios
   * @return {Object} - El objeto con la información del webserver
   */
   async getDevice(mac) {
    let requestUrl = `admin/devices/ws/${mac}`;
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getDevice: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la instalacion de un webserver
   *
   * @param {String} mac - Mac del webserver para filtrar sus usuarios
   * @return {Object} - El objeto con la información de la instalación a la que pertenece el webserver
   */
   async getDeviceInstallation(mac) {
    let requestUrl = `admin/installations/ws/${mac}`;
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getDeviceInstallation: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de la configuración de un webserver
   *
   * @param {String} deviceId - Mac del webserver para filtrar su configuración
   * @return {Object} - El objeto con la configuración del webserver
   */
   async getDeviceConfig(deviceId) {
    let requestUrl = `admin/devices/${deviceId}/config?type=advanced&cached=true`;
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getDeviceConfig: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de las versiones disponibles y texto para actualizar un webserver
   *
   * @param {String} deviceId - Mac del webserver
   * @return {Object} - El objeto con la información de las versiones y texto para actualizar el webserver
   */
   async getDeviceVersion(deviceId) {
    let requestUrl = `admin/devices/ws/${deviceId}/versions/latest`;
    
    try {
      const response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL)
      // console.log('In getDeviceVersion: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Actualiza un webserver a la versión especifica
   *
   * @param {String} deviceId - Id del webserver a actualizar
   * @param {String} versionId - Id de la version a actualizar
   * @return {Object} - El objeto vacío
   */
   async upgradeDeviceVersion(deviceId, versionId, lmachineId) {    
    let requestUrl = `admin/devices/ws/${deviceId}/upgrade/versions?`
    if (versionId) requestUrl += `wsFwVersionId=${versionId}&`;
    if (lmachineId) requestUrl += `lmachineFwVersionId=${lmachineId}`
    try {
      // const response = await ApiService.patch(requestUrl);
      // console.log('In updateDeviceVersion: ', response.data);
      const response = await ApiService.request(requestUrl, {}, 'patch', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL)
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Reinicia un webserver Aidoo
   *
   * @param {String} deviceId - Id del webserver a actualizar
   * @return {Object} - El objeto vacío
   */
   async resetAidoo(deviceId) {
    let requestUrl = `admin/devices/ws/${deviceId}/discovery`;
    
    try {
      const response = await ApiService.patch(requestUrl);
      // console.log('In resetAidoo: ', response.data);
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Configura la OTA de un webserver
   *
   * @param {String} deviceId - Id del webserver a configurar OTA
   * @param {Boolean} value - Valor de la configuración OTA
   * @return {Object} - El objeto vacío
   */
  async disableOTA(deviceId, value) {
    let requestUrl = `admin/devices/ws/${deviceId}/upgrade/first`;
    const data = { disable: value }
    
    try {
      const response = await ApiService.patch(requestUrl, data);
      // console.log('In resetAidoo: ', response.data);
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Aprovisiona un webserver
   *
   * @param {String} device - Objeto con los datos del webserver a aprovisionar
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
  async provision(device) {
    let requestUrl = `mgmt/provision`;
    
    try {
      const response = await ApiService.post(requestUrl, device);
      // console.log('In saveDevice: ', response.data);
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Publica versión de un webserver
   *
   * @param {Object} data - Objeto con los datos de la versión del webserver a publicar
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async publishVersion(data) {
    let requestUrl = `mgmt/ws/fw`;
    
    try {
      const response = await ApiService.post(requestUrl, data);
      console.log('In upload Firmware: ', response.data);

      return response?.data
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },

  /**
   * Edita versión de un webserver
   *
   * @param {Object} data - Objeto con los datos a editar de la versión del webserver
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async editVersion(id, data) {
    let requestUrl = `mgmt/ws/fw/${id}`;
    
    try {
      const response = await ApiService.patch(requestUrl, data);
      console.log('In edit Firmware: ', response.data);

      return response?.data
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },

  /**
   * Edita versión de un webserver
   *
   * @param {Object} data - Objeto con los datos a editar de la versión del webserver
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async editVersionText(id, data) {
    let requestUrl = `mgmt/ws/fw/text/${id}`;
    
    try {
      const response = await ApiService.patch(requestUrl, data);
      // console.log('In edit Firmware text: ', response.data);

      return response?.data
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },
   
  /**
   * Devuelve la información de las versiones disponibles y texto para actualizar un webserver
   *
   * @param {String} type - Tipo de firmware
   * @param {number} page - Número de página
   * @return {Object} - El objeto con la información de las versiones firmwares
   */
   async getFirmwares(items, page, type, beta, group, isAQSensorWifi) {
     let requestUrl = 'mgmt/ws/fw/versions?'
     if (type) {
       requestUrl += `ws_type=${type}&ws_fw_items=${items}&ws_fw_page=${page}`;

       if (type === CONSTANTS.WEBSERVERTYPE.AIDOO) {
        requestUrl += `&lmachine_fw_items=${items}&lmachine_fw_page=${page}`;
        // Si es un grupo LAIDOO solo se pide el ws_fw
        if (group === 'LAIDOO') {
          requestUrl += '&selector=ws_fw'
        }
       }

       if(isAQSensorWifi) {
        requestUrl += `&group=airqsensorWifi&arch=esp32_c3_mini`;
       } 
     }

     if (beta) requestUrl += `&beta=${beta}`;
     if (group) requestUrl += `&aidoo_group=${group}`;
     
     try {
      const response = await ApiService.request(requestUrl, {}, 'get', CONSTANTS.CONNECT.APP.AZCLOUD_API_V2_URL);
      // const response = await ApiService.get(requestUrl);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de la version de firmware
   *
   * @param {String} deviceId - Mac del webserver
   * @return {Object} - El objeto con la información de la version de firmware
   */
   async getFirmware(id) {
    let requestUrl = `mgmt/ws/fw/${id}`;
    // console.log(requestUrl)
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getFirmwares: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve los textos de la version de firmware
   *
   * @param {String} deviceId - Mac del webserver
   * @return {Object} - El objeto con la información de la version de firmware
   */
   async getFirmwareText(id) {
    let requestUrl = `mgmt/ws/fw/text/${id}`;
    // console.log(requestUrl)
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getFirmwareText: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Publica versión de Airzone cloud
   *
   * @param {Object} data - Objeto con los datos de la versión de Airzone cloud a publicar
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async publishAppVersion(data) {
    let requestUrl = `admin/app-manager/version/latest`;
    
    try {
      const response = await ApiService.post(requestUrl, data);
      // console.log('In publish App version: ', response.data);

      return response
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },

  /**
   * Edita versión de Airzone cloud
   *
   * @param {Object} data - Objeto con los datos a editar de la versión de Airzone cloud
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async editAppVersion(data) {
    let requestUrl = 'admin/app-manager/version/latest';
    
    try {
      const response = await ApiService.put(requestUrl, data);
      // console.log('In edit version: ', response.data);

      return response
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },

  /**
   * Fuerza actualización de una versión de Airzone cloud
   *
   * @param {Object} data - Objeto con los datos a editar de la versión de Airzone cloud
   * @return {Object} - El objeto con la respuesta satisfactoria o no de la petición
   */
   async forceUpdateAppVersion(data) {
    let requestUrl = 'admin/app-manager/version/force-update';
    
    try {
      const response = await ApiService.patch(requestUrl, data);
      // console.log('In force update version: ', response.data);

      return response
    } catch (error) {
      console.log(error);
      throw error?.response;
    }
  },
  
  /**
   * Devuelve la información de las versiones disponibles y texto para actualizar Airzone cloud
   *
   * @param {String} type - Tipo de firmware
   * @param {number} page - Número de página
   * @return {Object} - El objeto con la información de las versiones Airzone cloud
   */
   async getVersions(platform, version, items, page) {
    let requestUrl = 'admin/app-manager/versions'
    if (platform) requestUrl += `?platform=${platform}`;
    if (version) requestUrl += `&version=${version}`
    if (items && page) requestUrl += `&items=${items}&page=${page}`;

    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getVersions: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Devuelve la información de la version de Airzone cloud
   *
   * @param {String} deviceId - Identificador de versión Airzone cloud
   * @return {Object} - El objeto con la información de la versión de Airzone cloud
   */
   async getVersion(version, platform) {
    let requestUrl = `admin/versions?version=${version}&platform${platform}`;
    
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('In getFirmwares: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export default WebService;