/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-24': {
    width: 17,
    height: 26,
    viewBox: '0 0 17 26',
    data: '<path pid="0" d="M8.036 25.714C3.604 25.714 0 22.107 0 17.672 0 13.871 7.259.808 7.567.254c.19-.339.747-.339.936 0 .31.554 7.568 13.617 7.568 17.418 0 4.434-3.604 8.042-8.035 8.042zm0-24.09C5.386 6.46 1.07 15.014 1.07 17.671c0 3.843 3.125 6.97 6.965 6.97S15 21.515 15 17.672c0-2.657-4.315-11.212-6.964-16.049zm0 20.873a4.83 4.83 0 01-4.822-4.825.536.536 0 111.072 0 3.756 3.756 0 003.75 3.753.536.536 0 010 1.072z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
