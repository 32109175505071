/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scenes': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M22.852.35H7.148A6.807 6.807 0 00.354 6.91l-.004.238v15.704a6.807 6.807 0 006.56 6.794l.238.004h15.704a6.807 6.807 0 006.794-6.56l.004-.238V7.148A6.807 6.807 0 0023.09.354L22.852.35zm0 1.516a5.289 5.289 0 015.277 5.065l.005.217v15.704a5.289 5.289 0 01-5.065 5.277l-.217.005H7.148a5.289 5.289 0 01-5.277-5.065l-.005-.217V7.148a5.289 5.289 0 015.065-5.277l.217-.005h15.704zm-10.464 7.06a.752.752 0 00-.776-.024.75.75 0 00-.383.56l-.007.102v10.872a.756.756 0 00.758.758c.11 0 .217-.023.315-.068l.094-.051 8.456-5.436a.762.762 0 00.087-1.211l-.088-.066-8.456-5.436zm.35 10.121v-8.094L19.033 15l-6.295 4.047z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
