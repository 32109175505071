/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 24.783c-5.395 0-9.783-4.388-9.783-9.783S9.605 5.217 15 5.217 24.783 9.605 24.783 15 20.395 24.783 15 24.783zm0-18.261c-4.675 0-8.478 3.803-8.478 8.478s3.803 8.478 8.478 8.478 8.478-3.803 8.478-8.478S19.675 6.522 15 6.522zm0-2.609a.652.652 0 01-.652-.652V.652a.652.652 0 011.304 0v2.609c0 .36-.292.652-.652.652zM15 30a.652.652 0 01-.652-.652v-2.609a.652.652 0 011.304 0v2.609c0 .36-.292.652-.652.652zM9.13 5.486a.652.652 0 01-.566-.326L7.26 2.9a.654.654 0 011.13-.651l1.305 2.259a.653.653 0 01-.565.978zm13.044 22.591a.652.652 0 01-.566-.326l-1.305-2.259a.652.652 0 011.13-.652l1.304 2.26a.652.652 0 01-.563.977zM4.833 9.783a.636.636 0 01-.325-.088l-2.26-1.304A.651.651 0 112.9 7.26l2.26 1.305a.652.652 0 01-.327 1.217zm22.592 13.043a.644.644 0 01-.326-.087l-2.259-1.305a.652.652 0 01.652-1.13l2.26 1.305a.652.652 0 01-.327 1.217zM3.261 15.652H.652a.652.652 0 010-1.304h2.609a.652.652 0 010 1.304zm26.087 0h-2.609a.652.652 0 010-1.304h2.609a.652.652 0 010 1.304zM2.575 22.826a.652.652 0 01-.328-1.217l2.26-1.304a.653.653 0 01.653 1.13L2.9 22.738a.644.644 0 01-.325.087zM25.167 9.783a.652.652 0 01-.327-1.217L27.1 7.26a.652.652 0 11.651 1.13l-2.259 1.304a.636.636 0 01-.325.088zM7.827 28.077a.652.652 0 01-.565-.978l1.304-2.259a.652.652 0 011.13.652l-1.305 2.26a.651.651 0 01-.565.325zM20.87 5.487a.652.652 0 01-.565-.978l1.304-2.26a.652.652 0 011.13.652L21.434 5.16a.654.654 0 01-.564.326z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
