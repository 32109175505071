/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-9': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M23.036 23.57H2.679A2.682 2.682 0 010 20.893V9.106a2.682 2.682 0 012.679-2.678h20.357a2.682 2.682 0 012.678 2.678v11.786a2.682 2.682 0 01-2.678 2.679zM2.679 7.5A1.61 1.61 0 001.07 9.105v11.786A1.61 1.61 0 002.68 22.5h20.357a1.61 1.61 0 001.607-1.607V9.106A1.61 1.61 0 0023.036 7.5H2.679zm11.785 13.928H5.893a3.754 3.754 0 01-3.75-3.75V12.32a3.754 3.754 0 013.75-3.75h8.571a3.754 3.754 0 013.75 3.75v5.357a3.754 3.754 0 01-3.75 3.75zM5.893 9.642a2.682 2.682 0 00-2.679 2.679v5.357a2.682 2.682 0 002.679 2.678h8.571a2.682 2.682 0 002.679-2.678V12.32a2.682 2.682 0 00-2.679-2.679H5.893zm16.071 1.071h-2.143a.536.536 0 010-1.07h2.143a.536.536 0 010 1.07zm0 2.143h-2.143a.536.536 0 010-1.071h2.143a.536.536 0 010 1.071zm0 2.143h-2.143a.536.536 0 010-1.071h2.143a.536.536 0 010 1.071zm0 6.429h-2.143a.536.536 0 01-.535-.536v-4.286c0-.295.24-.535.535-.535h2.143c.296 0 .536.24.536.535v4.286c0 .296-.24.536-.536.536zm-1.607-1.072h1.072v-3.214h-1.072v3.214zM3.75 25.713a.536.536 0 01-.536-.535v-2.143a.536.536 0 011.072 0v2.143c0 .295-.24.535-.536.535zm18.214 0a.536.536 0 01-.535-.535v-2.143a.536.536 0 011.071 0v2.143c0 .295-.24.535-.536.535zM11.25 7.5a.538.538 0 01-.38-.156L4.443.914A.536.536 0 015.2.157l6.05 6.049L17.3.156a.536.536 0 01.757.758l-6.429 6.429a.534.534 0 01-.378.156z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
