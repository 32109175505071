/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-a_p': {
    width: 33,
    height: 31,
    viewBox: '0 0 33 31',
    data: '<path pid="0" d="M30.086.264a.874.874 0 01.62 1.493c-3.295 3.296-5.395 6.695-6.491 11.678l-.099.445-.185.813-.175.727-.169.658c-.056.21-.112.413-.17.61l-.176.58c-.061.19-.124.379-.19.57l-.209.577c-.094.252-.192.498-.293.74l-.186.124h-1.805l.13-.286c.634-1.38 1.128-2.965 1.606-5l.36-1.57c.966-3.976 2.618-7.092 4.94-9.868l.446-.52-.986.029c-9.492.33-15.978 2.234-19.92 5.338l-.284.23c-2.95 2.442-4.14 5.358-4.14 8.305 0 2.03.624 3.826 1.8 5.248l-.177-.224.204-.33c3.486-5.559 8.377-10.46 13.84-13.27l.566-.284a.877.877 0 011.168.407.876.876 0 01-.406 1.168C13.86 11.484 8.872 16.59 5.48 22.387l.087-.148.164.135c1.202.941 2.71 1.589 4.44 1.898l.437.07.174.2-.001 1.589-.227-.029c-2.381-.301-4.45-1.139-6.057-2.44l.192.15-.283.556c-.063.126-.125.252-.184.376l-.175.37-.32.717c-.602 1.403-.96 2.6-1.01 3.374l-.007.184a.875.875 0 01-1.75 0c0-1.499.92-4.077 2.49-6.917l-.069.125-.169-.192C1.831 20.769 1.055 18.707.97 16.377l-.008-.44c0-3.442 1.368-6.839 4.745-9.636 4.65-3.85 12.565-6.037 24.38-6.037zm-11.464 17l4.866 13H20.64l-.902-2.679h-4.696l-.893 2.679H11.3l4.839-13h2.482zm11.116 2.232v3.491h3.223v2.33h-3.223v3.643h-2.455v-3.643H24.05v-2.33h3.232v-3.491h2.455zM17.381 20.55l-1.616 4.866h3.25l-1.634-4.866z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
