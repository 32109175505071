/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M9 9v4.5c0 .9-.6 1.5-1.5 1.5S6 14.4 6 13.5V9H1.5C.6 9 0 8.4 0 7.5S.6 6 1.5 6H6V1.5C6 .6 6.6 0 7.5 0S9 .6 9 1.5V6h4.5c.9 0 1.5.6 1.5 1.5S14.4 9 13.5 9H9z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
