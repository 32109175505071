/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-90': {
    width: 36,
    height: 31,
    viewBox: '0 0 36 31',
    data: '<path pid="0" d="M11.108.732a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.732zM26.14 23a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.837.063 18.417 4.875 24.152 9.687 29.887 18.267 30.637 24 25.825a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zm-7.74-9.497l.622-.008c1.942-.082 3.443-.732 4.501-1.948 1.059-1.217 1.588-2.89 1.588-5.019V5.59c-.006-1.018-.19-1.922-.552-2.711-.363-.79-.872-1.395-1.527-1.817-.655-.42-1.404-.631-2.246-.631-.819 0-1.556.194-2.211.583a4.022 4.022 0 00-1.531 1.632 4.893 4.893 0 00-.548 2.303c0 1.316.339 2.362 1.017 3.137.679.775 1.583 1.162 2.711 1.162.884 0 1.656-.325 2.317-.974-.182 1.971-1.375 3.007-3.58 3.106l-.561.009v2.114zm2.447-6.194c-.579 0-1.028-.224-1.346-.671-.32-.447-.479-1.02-.479-1.715 0-.702.164-1.286.492-1.75.327-.466.746-.698 1.254-.698.55 0 .989.237 1.316.71.328.474.492 1.141.492 2.001V6.23a2.11 2.11 0 01-.671.763c-.296.21-.648.316-1.058.316zm10.362 6.247c1.41 0 2.48-.468 3.211-1.404.731-.936 1.097-2.287 1.097-4.053V5.747c-.018-1.713-.395-3.028-1.132-3.943C33.649.888 32.584.43 31.192.43s-2.458.462-3.198 1.386c-.74.924-1.11 2.278-1.11 4.062V8.23c.018 1.714.395 3.03 1.132 3.948.737.919 1.802 1.378 3.194 1.378zm0-2.044c-.626 0-1.08-.253-1.364-.76-.284-.505-.426-1.279-.426-2.32V5.326c.018-.976.168-1.694.452-2.154.284-.459.724-.688 1.32-.688.614 0 1.066.241 1.356.724.29.482.434 1.253.434 2.311v3.036c-.012 1-.155 1.743-.43 2.228-.275.486-.722.729-1.342.729z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
