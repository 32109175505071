/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-18': {
    width: 23,
    height: 26,
    viewBox: '0 0 23 26',
    data: '<path pid="0" d="M10.704 10.714a1.61 1.61 0 00-1.607 1.607 1.61 1.61 0 001.607 1.608 1.61 1.61 0 001.607-1.608 1.61 1.61 0 00-1.607-1.607zm0 2.143a.536.536 0 110-1.072.536.536 0 010 1.072zm-2.143 6.429a1.61 1.61 0 00-1.607 1.607A1.61 1.61 0 008.561 22.5a1.61 1.61 0 001.607-1.607 1.61 1.61 0 00-1.607-1.607zm0 2.143a.536.536 0 110-1.072.536.536 0 010 1.072zm3.75-2.679a1.61 1.61 0 001.607 1.607 1.61 1.61 0 001.608-1.607 1.61 1.61 0 00-1.608-1.607 1.61 1.61 0 00-1.607 1.607zm1.607-.536a.536.536 0 110 1.072.536.536 0 010-1.072zm8.291 4.455l-7.218-12.633V3.214h.535a1.61 1.61 0 001.607-1.607A1.61 1.61 0 0015.526 0H6.954a1.61 1.61 0 00-1.607 1.607 1.61 1.61 0 001.607 1.607h.537v6.822L.27 22.67a2.014 2.014 0 00.007 2.032 2.016 2.016 0 001.76 1.013h18.403a2.02 2.02 0 001.762-1.013c.367-.635.37-1.394.006-2.032zM6.954 2.143a.536.536 0 010-1.072h8.572a.536.536 0 010 1.072H6.954zm1.537 8.301a.539.539 0 00.07-.265V3.214h5.356v6.965c0 .093.025.184.071.265l3.215 5.627H5.274l3.217-5.627zm12.783 13.719a.954.954 0 01-.834.48H2.037a.954.954 0 01-.833-.48.954.954 0 01-.003-.962l3.476-6.085c.044.012.087.027.134.027h12.857c.048 0 .09-.015.132-.027l3.477 6.085a.954.954 0 01-.003.962z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
