/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry-bold-lock': {
    width: 23,
    height: 29,
    viewBox: '0 0 23 29',
    data: '<path pid="0" d="M9.54.354c.307.55.822 1.493 1.431 2.638.97 1.825 1.93 3.699 2.82 5.527.996 2.047 1.832 3.89 2.479 5.482h-1.574C13.336 10.74 11.248 6.53 8.9 2.23 4.782 9.771 1.467 17.03 1.467 19.392c0 4.102 3.335 7.44 7.433 7.44.73 0 1.435-.106 2.101-.303v1.52a8.885 8.885 0 01-2.101.25c-4.908 0-8.9-3.995-8.9-8.907 0-1.85 1.495-5.707 4.01-10.874.888-1.828 1.848-3.7 2.818-5.527A158.826 158.826 0 018.26.354c.263-.472 1.018-.472 1.28 0zM17.704 16c1.854 0 3.358 1.535 3.358 3.42v1.764c.739.002 1.34.54 1.34 1.323v4.63c0 .751-.601 1.363-1.34 1.363H14.34c-.739 0-1.34-.612-1.34-1.363v-4.63c0-.817.601-1.321 1.34-1.321V19.42c0-1.886 1.51-3.421 3.364-3.421zm0 7.083a.833.833 0 00-.834.834V26a.833.833 0 001.667 0v-2.083a.833.833 0 00-.833-.834zM4.234 18.66c.404 0 .733.329.733.734A3.94 3.94 0 008.9 23.33a.734.734 0 010 1.467 5.408 5.408 0 01-5.4-5.404c0-.405.328-.734.733-.734zm13.47-.864c-.804 0-1.459.639-1.459 1.423v1.975h2.917v-1.975c0-.784-.654-1.423-1.458-1.423z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
