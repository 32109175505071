<template>
  <b-container fluid class="m-0 p-0">
    <GoBack />
    <b-jumbotron class="pb-0 pt-2">
      <transition name="fade-in-transition" appear>
        <b-row>
          <b-col sm="5">
            <b-card
              bg-variant="gray5"
              text-variant="white"
              border-variant="gray5"
              align="center"
              :title="$t('installations.installationData')"
              title-tag="h3"
            >
              <b-card-header
                header-bg-variant="brand-a20"
                style="position: relative"
              >
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem">
                      <az-icon name="groups" scale="2"></az-icon>
                    </b-avatar>
                  </transition>
                </b-card-title>
                <!--b-card-sub-title>
                
              </b-card-sub-title-->

                <b-card-text
                  style="
                     {
                      position: relative;
                      top: -2rem;
                      z-index: 12;
                    }
                  "
                >
                  <b-spinner v-show="isLoading"></b-spinner>
                  <b-container class="" v-show="!isLoading">
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("user.name")
                      }}</b-col>
                      <b-col class="text-right">{{ installation.name }} </b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("locality")
                      }}</b-col>
                      <b-col class="text-right">{{
                        installation.city
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("country")
                      }}</b-col>
                      <b-col class="text-right">{{
                        installation.country
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("migrated")
                      }}</b-col>
                      <b-col class="text-right">{{
                        installation.migrated_at | formatDate
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between">
                      <b-col class="text-left text-black">{{
                        $t("login.signUpLink")
                      }}</b-col>
                      <b-col class="text-right">{{
                        installation.created_at | formatDate
                      }}</b-col>
                    </b-row>
                    <hr />
                    <b-row align-h="between" v-if="installation.plugins">
                      <b-col class="text-left text-black">{{
                        $t("installations.enable_schedules_label")
                      }}</b-col>
                      <b-col class="text-right">
                        <az-icon
                          name="check"
                          scale="2"
                          class="text-success"
                          v-show="installation.plugins.schedules.activated"
                        ></az-icon>
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                          v-show="!installation.plugins.schedules.activated"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <hr />

                    <b-row align-h="between" v-if="installation.groups">
                      <b-col class="text-left text-black">{{
                        $t("installations.groups")
                      }}</b-col>
                      <b-col
                        class="text-right"
                        v-if="installation.groups.length === 0"
                      >
                        <az-icon
                          name="error-check"
                          scale="2"
                          class="text-warning"
                        ></az-icon>
                      </b-col>
                    </b-row>

                    <b-card-group
                      class="my-2 py-0"
                      id="table-groups"
                      v-show="!isLoading"
                      v-for="(group, index) in installation.groups"
                      :key="group.group_id"
                      :style="`transition-delay: ${index * 0.1}s; opacity: 0`"
                    >
                      <b-card
                        bg-variant="brand-dark"
                        text-variant="black"
                        :title="
                          group.name
                            ? group.name
                            : `${$t('installations.group')} ` + (index + 1)
                        "
                        v-b-toggle="`component-${group.group_id}`"
                      >
                        <b-collapse :id="`component-${group.group_id}`">
                          <b-card-body
                            v-for="(device, index) in group.devices"
                            :key="index"
                            body-text-variant="light"
                            body-bg-variant="gray4"
                            body-class="mb-1"
                          >
                            <b-row align-h="between">
                              <b-col class="text-left">
                                <Badge
                                  :bgBadge="deviceType(device.type).bgColor"
                                  :textColor="'text-light'"
                                  :pillable="'badge-pill'"
                                  :text="$t(deviceType(device.type).text)"
                                >
                                </Badge>
                              </b-col>
                              <b-col
                                class=""
                                v-show="deviceType(device.type).hasSystem"
                              >
                                {{ $t("system.system_number") }}
                                {{ device.meta.system_number }}
                              </b-col>
                              <b-col
                                class=""
                                v-show="deviceType(device.type).hasZone"
                              >
                                {{ $t("installations.zone") }}
                                {{ device.meta.zone_number }}
                              </b-col>
                              <b-col
                                class=""
                                v-show="!deviceType(device.type).hasZone"
                              >
                                &nbsp;
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12" class="mr-3 text-right">
                                <DetailsButton
                                  :route="{
                                    name: 'device',
                                    params: { mac: device.ws_id },
                                  }"
                                  :bgButton="'brand'"
                                  :icon="'device'"
                                  :pillable="'rounded-pill'"
                                  :text="device.ws_id"
                                  :arrowRight="false"
                                >
                                </DetailsButton>
                              </b-col>
                            </b-row>
                          </b-card-body>

                          <b-col
                            class="text-right"
                            v-if="installation.length === 0"
                          >
                            <az-icon
                              name="error-check"
                              scale="2"
                              class="text-warning"
                            ></az-icon>
                          </b-col>
                        </b-collapse>
                      </b-card>
                    </b-card-group>
                  </b-container>
                </b-card-text>
                <div class="iconInst" v-if="!isLoading">
                  <az-icon :name="iconInstallation" scale="6"></az-icon>
                </div>
              </b-card-header>
            </b-card>
          </b-col>
          <b-col sm="7">
            <b-card
              bg-variant="gray5"
              text-variant="white"
              border-variant="gray5"
              align="center"
              :title="$t('user.title')"
              title-tag="h3"
            >
              <b-card-header header-bg-variant="brand-dark">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem" variant="secondary">
                      <az-icon name="user" scale="2"></az-icon>
                    </b-avatar>
                  </transition>
                </b-card-title>

                <b-card-text>
                  <b-spinner v-show="isLoading"></b-spinner>
                  <EmptyCard
                    :condition="users.length === 0"
                    :bgColor="'light'"
                  ></EmptyCard>

                  <b-card-group
                    class="mb-1"
                    id="table-installation-users"
                    v-show="!isLoading"
                    v-for="(user, index) in users"
                    :key="user.user_id"
                    :style="`transition-delay: ${index * 0.1}s; opacity: 0`"
                  >
                    <b-card
                      bg-variant="gray3"
                      text-variant="white"
                      v-if="user.data"
                      :title="user.data.name + ' ' + user.data.lastName"
                      v-b-toggle="`component-${user.user_id}`"
                      title-tag="h4"
                    >
                      <b-row
                        class=""
                        v-if="user.installation_data.confirmed === false"
                      >
                        <b-col></b-col>
                        <b-col cols="2">
                          <Badge
                            bgBadge="warning-light"
                            scale="1"
                            textColor="text-danger"
                            name="warning"
                            :text="$t('user.pending')"
                          >
                          </Badge>
                        </b-col>
                        <b-col cols="3">
                          <div class="my-1 font-weight-bold">
                            {{ user.installation_data.added_at | formatDate }}
                          </div>
                        </b-col>
                        <b-col></b-col>
                      </b-row>
                      <b-collapse :id="`component-${user.user_id}`">
                        <b-card-header class="bg-gray5">
                          <b-card-title align="center">
                            <b-row>
                              <b-col class="h5">
                                {{ user.email }}
                              </b-col>
                              <b-col cols="12" class="mr-3 text-right" v-if="!isAdminVendor()">
                                <DetailsButton
                                  :route="{
                                    name: 'user',
                                    params: { id: user.user_id },
                                  }"
                                  :icon="'user'"
                                >
                                </DetailsButton>
                              </b-col>
                            </b-row>
                          </b-card-title>

                          <b-card-text>
                            <b-container class="">
                              <b-row align-h="between">
                                <b-col class="text-left text-black">{{
                                  $t("user.typeTitle")
                                }}</b-col>
                                <b-col class="text-right">
                                  <Badge
                                    :bgBadge="
                                      accessTypeColor(
                                        user.installation_data.access_type
                                      )
                                    "
                                    :pillable="'badge-pill'"
                                    :text="
                                      $t(
                                        `user.${user.installation_data.access_type}`
                                      )
                                    "
                                  >
                                  </Badge>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-card-text>
                        </b-card-header>
                      </b-collapse>
                    </b-card>
                  </b-card-group>

                  <EmptyCard
                    :total="users.length === 0 && !isLoading"
                    :bgColor="'success'"
                  ></EmptyCard>

                  <!--/b-jumbotron-->
                </b-card-text>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
      </transition>
    </b-jumbotron>
  </b-container>
</template>

<script>
import WebService from "@/services/web.service";
import GoBack from "@/components/GoBack";
import DetailsButton from "@/components/DetailsButton";
import Badge from "@/components/Badge";
import EmptyCard from "@/components/EmptyCard";
import accessTypeColor from "@/mixins/AccessTypeColor.mixin";
import deviceType from "@/mixins/DeviceType.mixin";
import animateCardGroup from "@/mixins/AnimateCardGroup.mixin";
import Role from '@/mixins/Role.mixin'

export default {
  name: "Installation",
  props: {},
  mixins: [accessTypeColor, deviceType, animateCardGroup, Role],
  components: {
    GoBack,
    DetailsButton,
    Badge,
    EmptyCard,
  },
  data() {
    return {
      installationId: this.$route.params.installationId,
      installation: {},
      users: [],
      isLoading: true,
      isHovered: false,
    };
  },
  computed: {
    iconInstallation() {
      if (!this.installation.icon) return "group-1";
      return `group-${this.installation.icon}`;
    },
  },
  methods: {
    /**
     * Obtiene la información de la instalación y los usuarios que le pertenecen
     */
    async getInstallationAndUsers() {
      try {
        this.isLoading = true;
        const installation = await WebService.getInstallation(
          this.installationId
        );

        this.installation = installation;
        const location = await WebService.getInstallationLocation(this.installation.location_id)
        this.installation.city = location.text.city[this.language]
        this.installation.country = location.text.country[this.language]

        const users = await WebService.getInstallationUsers(
          this.installationId
        );

        this.users = users;

        this.isLoading = false;
        this.animateCardGroup();
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    },
  },

  mounted() {
    this.getInstallationAndUsers();
  },
};
</script>

<style scoped>
#table-installation-users:hover,
#table-groups:hover {
  border: 1px solid hsl(207, 15%, 86%);
}

.iconInst {
  position: absolute;
  top: 10rem;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.1;
  background: transparent;
  z-index: 1;
}
</style>