/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zones': {
    width: 19,
    height: 24,
    viewBox: '0 0 19 24',
    data: '<path pid="0" d="M5.76 17.28c1.06 0 1.92.86 1.92 1.92v2.88c0 1.06-.86 1.92-1.92 1.92H1.92A1.92 1.92 0 010 22.08V19.2c0-1.06.86-1.92 1.92-1.92h3.84zm10.56 0c1.06 0 1.92.86 1.92 1.92v2.88c0 1.06-.86 1.92-1.92 1.92h-3.84a1.92 1.92 0 01-1.92-1.92V19.2c0-1.06.86-1.92 1.92-1.92h3.84zM5.76 18.72H1.92a.48.48 0 00-.48.48v2.88c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48V19.2a.48.48 0 00-.48-.48zm10.56 0h-3.84a.48.48 0 00-.48.48v2.88c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48V19.2a.48.48 0 00-.48-.48zM5.76 8.64c1.06 0 1.92.86 1.92 1.92v2.88c0 1.06-.86 1.92-1.92 1.92H1.92A1.92 1.92 0 010 13.44v-2.88C0 9.5.86 8.64 1.92 8.64h3.84zm10.56 0c1.06 0 1.92.86 1.92 1.92v2.88c0 1.06-.86 1.92-1.92 1.92h-3.84a1.92 1.92 0 01-1.92-1.92v-2.88c0-1.06.86-1.92 1.92-1.92h3.84zM5.76 10.08H1.92a.48.48 0 00-.48.48v2.88c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48v-2.88a.48.48 0 00-.48-.48zm10.56 0h-3.84a.48.48 0 00-.48.48v2.88c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48v-2.88a.48.48 0 00-.48-.48zM5.76 0c1.06 0 1.92.86 1.92 1.92V4.8c0 1.06-.86 1.92-1.92 1.92H1.92A1.92 1.92 0 010 4.8V1.92C0 .86.86 0 1.92 0h3.84zm10.56 0c1.06 0 1.92.86 1.92 1.92V4.8c0 1.06-.86 1.92-1.92 1.92h-3.84a1.92 1.92 0 01-1.92-1.92V1.92c0-1.06.86-1.92 1.92-1.92h3.84zM5.76 1.44H1.92a.48.48 0 00-.48.48V4.8c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48V1.92a.48.48 0 00-.48-.48zm10.56 0h-3.84a.48.48 0 00-.48.48V4.8c0 .265.215.48.48.48h3.84a.48.48 0 00.48-.48V1.92a.48.48 0 00-.48-.48z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
