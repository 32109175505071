/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.375 30H.625A.625.625 0 010 29.375a7.016 7.016 0 015.321-6.819l4.789-1.197.435-1.741a8.672 8.672 0 01-2.299-4.653 1.881 1.881 0 01-1.503-1.609l-.313-2.5a1.876 1.876 0 011.296-2.019C7.636 8.075 7.5 6.811 7.5 6.25c0-2.413.725-4.886 6.05-4.998C15.415 0 17.419 0 18.287 0c2.206 0 3.798.726 4.736 2.158 1.22 1.862.636 3.146-.068 3.895l-.467.468-.115 2.354a1.87 1.87 0 011.196 1.983l-.313 2.5a1.877 1.877 0 01-1.355 1.572 8.615 8.615 0 01-2.419 4.799l.408 1.63 4.789 1.197A7.015 7.015 0 0130 29.373c0 .344-.28.627-.625.627zM1.282 28.75h27.434a5.768 5.768 0 00-4.341-4.981l-5.153-1.288a.627.627 0 01-.453-.455l-.588-2.348a.623.623 0 01.189-.617c1.326-1.188 2.201-2.958 2.343-4.736a.625.625 0 01.623-.575c.375 0 .643-.235.682-.548l.312-2.5a.608.608 0 00-.149-.485.615.615 0 00-.465-.213.629.629 0 01-.618-.655l.152-3.129a.624.624 0 01.183-.411l.625-.625c.39-.417.748-1.073-.082-2.342-.691-1.056-1.931-1.592-3.689-1.592-1.28 0-2.778.135-4.173 1.134a.632.632 0 01-.358.116C9.132 2.546 8.75 4.3 8.75 6.25c0 .611.21 2.406.29 3.048a.625.625 0 01-.62.702h-.129a.616.616 0 00-.469.211.617.617 0 00-.15.491l.312 2.5a.625.625 0 00.62.548h.204c.326 0 .597.25.623.575a7.328 7.328 0 002.235 4.642.623.623 0 01.179.608l-.613 2.451a.63.63 0 01-.456.455L5.625 23.77a5.77 5.77 0 00-4.343 4.981z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
