/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M23.753 30c-1.55 0-3.118-.728-4.302-1.998-1.375-1.478-2.011-2.94-1.947-4.471.037-.849-.343-1.737-1.044-2.437l-8.781-8.783a5.815 5.815 0 01-1.429.188 6.28 6.28 0 01-4.549-1.962 6.202 6.202 0 01-1.69-4.664.625.625 0 01.325-.512.67.67 0 01.647 0l3.42 2.051a3.396 3.396 0 001.83-2.694l-3.82-2.292a.621.621 0 01-.07-1.022C3.46.504 4.783.018 6.173.001 7.715-.026 9.33.698 10.52 1.968c1.395 1.488 2.042 2.961 1.975 4.504-.037.846.34 1.732 1.039 2.429l7.649 7.648c.612.613 1.51.95 2.527.95h.019c3.466 0 6.27 2.804 6.27 6.25 0 .217-.026.424-.055.633l-.021.152a.627.627 0 01-.346.485.628.628 0 01-.595-.026l-4.01-2.405a3.398 3.398 0 00-1.832 2.694l4.182 2.509a.624.624 0 01.04 1.045A6.211 6.211 0 0123.762 30c-.002-.002-.006-.002-.008-.002zM7.863 10.986c.163 0 .323.064.442.182l9.039 9.04c.947.947 1.461 2.177 1.41 3.375-.051 1.197.461 2.33 1.612 3.565.948 1.017 2.182 1.6 3.387 1.6h.007a4.912 4.912 0 002.01-.433l-3.591-2.155a.624.624 0 01-.304-.537c0-1.78.989-3.38 2.581-4.177l.264-.131a.618.618 0 01.6.023l3.415 2.05a5.003 5.003 0 00-4.985-4.64h-.024c-1.367 0-2.578-.467-3.426-1.316l-7.649-7.649c-.945-.943-1.456-2.17-1.405-3.367.053-1.205-.467-2.346-1.635-3.594C8.658 1.808 7.438 1.236 6.19 1.25a4.86 4.86 0 00-2.294.61l3.3 1.98a.625.625 0 01.304.535c0 1.78-.989 3.382-2.581 4.178l-.264.131a.62.62 0 01-.6-.024L1.306 7.012a4.959 4.959 0 001.305 2.666A4.954 4.954 0 006.25 11.25c.443 0 .898-.075 1.433-.236a.592.592 0 01.18-.026z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
