/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tank': {
    width: 18,
    height: 31,
    viewBox: '0 0 18 31',
    data: '<path pid="0" d="M9 0c2.238 0 4.474.448 6.705 1.342a2.465 2.465 0 011.54 2.111l.007.177v20.815a2.465 2.465 0 01-1.547 2.287c-1.274.51-2.55.876-3.827 1.096l-.413.063v1.725c0 .309-.205.57-.486.655l-.097.022-.1.007a.684.684 0 01-.654-.486l-.023-.1-.008-.098v-1.579l-.258.017-.419.015-.42.005c-.279 0-.558-.007-.837-.02l-.258-.017v1.579c0 .309-.206.57-.487.655l-.097.022-.1.007a.684.684 0 01-.654-.486l-.023-.1-.008-.098V27.89l-.412-.063a18.3 18.3 0 01-2.734-.693l-.547-.192-.546-.21a2.465 2.465 0 01-1.54-2.11l-.007-.177V3.63c0-1.007.612-1.913 1.547-2.288C4.527.448 6.763 0 9.001 0zm6.883 24.232l-.152.072-.205.075a21.652 21.652 0 01-6.525 1.025c-2.177 0-4.353-.342-6.526-1.025a2.476 2.476 0 01-.204-.075l-.153-.071v.212c0 .403.22.77.568.96l.12.057c2.07.83 4.133 1.244 6.195 1.244 2.061 0 4.125-.414 6.194-1.244.374-.15.632-.491.68-.885l.008-.133v-.212zM9 4.039c-2.037 0-4.075.32-6.115.962a1.096 1.096 0 00-.762.928l-.006.118v15.98c0 .48.31.903.768 1.046 2.04.642 4.078.963 6.115.963s4.074-.32 6.115-.963c.419-.131.715-.498.761-.927l.006-.118V6.047c0-.479-.31-.902-.767-1.046a20.285 20.285 0 00-6.115-.962zm-.585 5.406c.22-.397.787-.425 1.057-.085l.057.086.219.397.379.71.333.648.364.732.249.52.224.488c.517 1.158.78 1.965.78 2.457a3.108 3.108 0 01-3.105 3.107 3.108 3.108 0 01-3.104-3.107c0-.515.288-1.38.862-2.64l.263-.563.284-.582.308-.612.298-.573.28-.523.252-.46zm.556 1.652l-.224.429-.227.444-.245.495c-.704 1.446-1.132 2.543-1.132 2.933 0 1.01.821 1.83 1.829 1.83a1.832 1.832 0 001.829-1.83c0-.41-.473-1.6-1.238-3.148l-.251-.5-.341-.653zM9 1.368c-2.062 0-4.125.414-6.195 1.244-.374.15-.632.492-.68.885l-.008.133v.211l.153-.07.204-.075A21.652 21.652 0 019.001 2.67c2.177 0 4.352.342 6.525 1.025.07.022.138.047.205.074l.152.072V3.63c0-.403-.22-.77-.568-.96l-.12-.058c-2.07-.83-4.133-1.244-6.194-1.244z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
