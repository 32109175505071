/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-19': {
    width: 26,
    height: 24,
    viewBox: '0 0 26 24',
    data: '<path pid="0" d="M7.5 2.143A5.363 5.363 0 002.143 7.5a.536.536 0 001.071 0A4.29 4.29 0 017.5 3.214a.536.536 0 000-1.071zM18.214 0c-2.029 0-3.949.814-5.357 2.253A7.477 7.477 0 007.5 0C3.364 0 0 3.364 0 7.5c0 1.866.753 3.693 2.177 5.282 2.45 2.732 10.223 10.553 10.3 10.63a.53.53 0 00.38.16c.143 0 .28-.057.38-.157.079-.078 7.85-7.899 10.301-10.632 1.423-1.589 2.176-3.414 2.176-5.283 0-4.135-3.364-7.5-7.5-7.5zm4.526 12.066c-2.12 2.366-8.288 8.6-9.883 10.209-1.596-1.608-7.762-7.843-9.883-10.208-.868-.97-1.903-2.547-1.903-4.567A6.434 6.434 0 017.5 1.071c1.916 0 3.718.847 4.945 2.323a.553.553 0 00.824 0 6.412 6.412 0 014.945-2.323A6.435 6.435 0 0124.643 7.5c0 2.02-1.035 3.597-1.903 4.566z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
