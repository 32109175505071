/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Combined Shape': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M15.216.2c8.272 0 15 6.728 15 15s-6.728 15-15 15-15-6.728-15-15 6.728-15 15-15zm0 1.609C7.833 1.809 1.825 7.817 1.825 15.2s6.008 13.391 13.391 13.391S28.607 22.583 28.607 15.2 22.6 1.809 15.217 1.809zM10.39 8.492l4.826 4.826 4.826-4.826a1.33 1.33 0 111.882 1.882L17.098 15.2l4.826 4.826a1.33 1.33 0 11-1.882 1.882l-4.826-4.826-4.826 4.826a1.33 1.33 0 11-1.882-1.882l4.826-4.826-4.826-4.826a1.33 1.33 0 111.882-1.882z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
