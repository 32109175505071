/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'OFF': {
    width: 26,
    height: 11,
    viewBox: '0 0 26 11',
    data: '<path pid="0" d="M4.556 10.957c.893 0 1.685-.215 2.376-.645.691-.43 1.222-1.04 1.593-1.83.371-.792.557-1.712.557-2.762v-.476c0-1.045-.19-1.966-.568-2.765-.378-.798-.913-1.41-1.604-1.838C6.22.214 5.43 0 4.541 0c-.889 0-1.678.214-2.37.64-.69.428-1.225 1.04-1.603 1.84C.189 3.277 0 4.201 0 5.25v.528c.005 1.025.198 1.932.579 2.72.38.79.918 1.396 1.61 1.82.694.426 1.483.638 2.367.638zm0-1.802c-.752 0-1.328-.3-1.729-.9-.4-.601-.6-1.446-.6-2.535V5.2c.004-1.108.207-1.95.607-2.523.4-.574.97-.86 1.707-.86.742 0 1.313.29 1.714.871.4.581.6 1.43.6 2.549v.52c-.004 1.108-.205 1.952-.6 2.53-.396.579-.962.868-1.7.868zm8.32 1.656V6.453h4.219V4.68h-4.219V1.926h4.746V.146H10.68v10.665h2.197zm8.218 0V6.453h4.218V4.68h-4.218V1.926h4.746V.146h-6.944v10.665h2.198z" _fill="#7B8794" fill-rule="nonzero"/>'
  }
})
