/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clipboard': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<path pid="0" d="M22.219 5.886c-.7 0-1.358.273-1.853.768l-1.38 1.379-.145.146V4.386c0-.827-.673-1.5-1.5-1.5h-2.592c-.207-.581-.757-1-1.408-1h-1.774a2 2 0 00-1.726-1 2 2 0 00-1.726 1H6.34c-.651 0-1.201.419-1.408 1H2.34c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-7.794l3.85-3.85.003-.002c0-.001 0-.002.002-.003l1.377-1.377a2.6 2.6 0 00.768-1.854 2.624 2.624 0 00-2.622-2.62zM5.84 3.386a.5.5 0 01.5-.5h2.092a.5.5 0 00.47-.333 1 1 0 01.938-.667 1 1 0 01.937.667.5.5 0 00.47.333h2.093a.5.5 0 01.5.5v.5h-8v-.5zm12 20a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h2.5v.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-.5h2.5a.5.5 0 01.5.5v4.793L11 16.019l-.01.01-.003.004-.003.005a.474.474 0 00-.111.197c-.004.01-.014.019-.017.03l-1 4a.499.499 0 00.606.605l4-1c.012-.003.02-.014.03-.018a.503.503 0 00.17-.095c.009-.008.023-.01.032-.018l3.147-3.147v6.794zm-6.128-6.423c.323.094.8.302 1.274.776.474.474.683.953.777 1.275l-2.736.685.685-2.736zm2.893 1.45a4.021 4.021 0 00-.913-1.381 4.012 4.012 0 00-1.38-.911l7.028-7.027 2.292 2.292-7.027 7.027zm8.76-8.76l-1.026 1.026-2.292-2.292 1.026-1.027a1.623 1.623 0 012.292 2.293z"/>'
  }
})
