/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-bold': {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    data: '<path pid="0" d="M1.5 0h16a1.5 1.5 0 010 3h-16a1.5 1.5 0 010-3zm0 6h9a1.5 1.5 0 010 3h-9a1.5 1.5 0 010-3zm0 6h13a1.5 1.5 0 010 3h-13a1.5 1.5 0 010-3z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
