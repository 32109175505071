/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'groups': {
    width: 35,
    height: 30,
    viewBox: '0 0 35 30',
    data: '<path pid="0" d="M34.458 15.164L22.168 2.875c-.742-.743-2.04-.744-2.785 0L7.094 15.164a.576.576 0 00.409.986h1.731v13.273c0 .318.259.577.577.577h6.925a.577.577 0 00.578-.577v-9.81h6.925v9.81c0 .318.258.577.577.577h6.925a.576.576 0 00.576-.577V16.15h1.731a.577.577 0 00.41-.986zM21.353 3.692l11.303 11.303h-.915a.577.577 0 00-.577.577v13.274h-5.77v-9.81a.577.577 0 00-.577-.578h-8.08a.577.577 0 00-.577.577v9.81h-5.77V15.573a.577.577 0 00-.578-.577h-.915L20.2 3.692a.84.84 0 011.153 0zM15.243.558l2.412 2.412-.816.816-2.411-2.41a.84.84 0 00-1.153 0L1.972 12.677h.915c.319 0 .577.259.577.577V26.53H8.08v1.154H2.886a.577.577 0 01-.577-.577V13.832H.578a.577.577 0 01-.409-.985L12.458.557c.745-.744 2.043-.742 2.785 0z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
