import AuthService from '@/services/auth.service';
import Router from '@/router'

const ResendConfirmationEmail = {

  methods: {
    /**
     * Reenvía el email de confirmación y muestra alerta del feedback
     * 
     * @param {String} email - Email a enviar la confirmación
     */
    async resendConfirmationEmail(email) {
      this.$emit('isLoading', true)
      if (await AuthService.sendConfirmEmail(email)) {
        this.$emit('isLoading', false);
        this.$bvModal.msgBoxOk(this.$i18n.t('user.emailConfirmationSuccess'), {
          title: this.$i18n.t('user.emailConfirmation'),
          okVariant: 'primary',
          headerBgVariant: 'success',
          headerClass: 'p-2 border-bottom-1',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
      } else {
        this.$emit('isLoading', false);
        this.$bvModal.msgBoxOk(this.$i18n.t('user.emailConfirmationFailed'), {
          title: 'Error',
          okTitle: 'Cerrar',
          okVariant: 'secondary',
          headerBgVariant: 'danger',
          headerClass: 'p-2 border-bottom-1',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
      }
    },

     /**
     * Confirma el email de registro y muestra alerta del feedback
     * 
     * @param {String} email - Email a confirmar el registro
     */
     async manualConfirmationEmail(userID) {
      this.$emit('isLoading', true)
      try {
        await AuthService.sendManualConfirmationEmail(userID)
        this.$emit('isLoading', false);
        this.$bvModal.msgBoxOk(this.$i18n.t('user.emailManualConfirmationSuccess'), {
          title: this.$i18n.t('user.emailConfirmation'),
          okVariant: 'primary',
          headerBgVariant: 'success',
          headerClass: 'p-2 border-bottom-1',
          footerClass: 'p-2 border-top-0',
          centered: true
        }).then(async () => {
          console.log("reload");
          Router.go();
        })
      } catch(err) {
        this.$emit('isLoading', false);
        this.$bvModal.msgBoxOk(this.$i18n.t('user.emailManualConfirmationFailed'), {
          title: 'Error',
          okTitle: 'Cerrar',
          okVariant: 'secondary',
          headerBgVariant: 'danger',
          headerClass: 'p-2 border-bottom-1',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
      }
    },
  }
}

export default ResendConfirmationEmail;