<template>
    
  <b-modal
    id="bv-modal-error"
    size="md"
    centered
    header-bg-variant="warning"
    body-text-variant="light"
    body-bg-variant="secondary"
    class="text-center"
    hide-footer>
      <template #modal-title>
        <span class="h3 font-weight-bold">{{ $t('messages.errors.general.title') }}</span>
      </template>
      <div class="d-block text-center py-4">
        <h3>{{ $t('messages.errors.general.message') }}</h3>
      </div>
      <div class="row">
        <div class="col-12 text-right d-flex justify-content-center py-2">
          <b-button class="mt-3 w-25" variant="primary" size="md" @click="$bvModal.hide('bv-modal-error')">{{ $t('buttons.close') }}</b-button>
        </div>
      </div>
    </b-modal>
  
</template>

<script>
export default {
  name: 'ModalError',
  props: {},
  methods: {},
}
</script>