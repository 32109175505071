/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speed-block': {
    width: 31,
    height: 29,
    viewBox: '0 0 31 29',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M4.89 8.083h-1.5c-1.7 0-2.8 1-2.8 2.3v13.3c0 1.4 1.3 2.4 2.8 2.4h1.5c1.5 0 2.8-1.1 2.8-2.4v-13.3c0-1.3-1.3-2.3-2.8-2.3zm1.2 15.6c0 .5-.5 1-1.2 1h-1.5c-.6 0-1.2-.4-1.2-1v-13.3c0-.6.4-1 1.2-1h1.5c.6 0 1.2.4 1.2 1v13.3zM13.79 5.083h-1.5c-1.7 0-2.8 1.1-2.8 2.7v15.5c0 1.6 1.3 2.8 2.8 2.8h1.5c1.5 0 2.8-1.3 2.8-2.8v-15.5c0-1.5-1.3-2.7-2.8-2.7zm1.2 18.2c0 .6-.5 1.2-1.2 1.2h-1.5c-.6 0-1.2-.5-1.2-1.2v-15.5c0-.7.4-1.2 1.2-1.2h1.5c.6 0 1.2.5 1.2 1.2v15.5zM21.29 2.583h1.5c.6 0 1.2.5 1.2 1.2v14.3h1.6v-14.3c0-1.6-1.3-2.8-2.8-2.8h-1.5c-1.7 0-2.8 1.2-2.8 2.8v19.5c0 1.6 1.3 2.8 2.8 2.8h.7v-1.6h-.7c-.6 0-1.2-.5-1.2-1.2v-19.5c0-.7.4-1.2 1.2-1.2z"/><path pid="1" d="M29.59 22.983v-1.5c0-1.5-1.2-2.7-2.8-2.7-1.6 0-2.8 1.2-2.7 2.7v1.4c-.6 0-1.1.4-1.1 1v3.7c0 .6.5 1.1 1.1 1.1h5.6c.6 0 1.1-.5 1.1-1.1v-3.7c-.2-.5-.7-.9-1.2-.9zm-2.2 3.8c0 .3-.3.6-.7.6-.3 0-.7-.2-.7-.6v-1.7c0-.3.2-.6.7-.6.4 0 .7.3.7.6v1.7zm.5-5.4v1.6h-2.4v-1.6c0-.6.6-1.1 1.2-1.1.7 0 1.3.4 1.2 1.1z"/></g>'
  }
})
