import Router from '@/router/index';
import io from 'socket.io-client';
import AuthService from '@/services/auth.service';
import StorageService from '@/services/storage.service';
import CONSTANTS from '@/constant';


/**
 * @type {import('socket.io-client').Socket}
 */
let userSocket = null;
/**
 * @type {string}
 */
let jwt = null;
// const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

let reconnectAttemps = 0;

const SocketService = {
  //
  // Ver si tenemos conexión al socket
  //
  hasSocketConnection() {
    if(userSocket) {
      return true;
    }

    return false;
  },

  //
  // Comenzamos a escuchar los eventos de un webserver
  //
  listenWebserverAdmin(webserverID) {
    return new Promise ((resolve, reject) => {
        if(!userSocket) resolve();
        this.clearListeners();

        const command = 'listen_ws_admin';

        userSocket.emit(command, webserverID, async err => {
          //
          // err es el callback de respuesta. Será null si todo ha ido bien o un error en otro caso.
          //
          if(err !== null){
            switch(err._id) {
              case 'notAuthorized':
                return reject('notAuthorized');
              case 'tooManyConnections':
              default:
                console.error('Error en listenWebserverAdmin: ', err);
                reconnectAttemps++;
                if(reconnectAttemps <= 5) {
                  console.log("Intento reconexión", reconnectAttemps)

                  this.disconnectSocket();
                  await this.connectSocket();
                  await this.listenWebserverAdmin(webserverID);
                } else {
                  // Si no conseguimos reconectar limpio el usuario y redirijo al login
                  reconnectAttemps = 0; // Reiniciamos el contador de intentos
                  AuthService.clearUser();
                  Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
                  console.error('Error tratando de reconectar desde listen_ws_admin');
                }
            }
          } else {
            reconnectAttemps = 0;
          }

          reconnectAttemps = 0;
          return resolve();
        });


        // return resolve();

    });
  },

  //
  // Dejar de escuchar los eventos de una instalación (liberamos tráfico del socket)
  //
  clearListeners() {
    return new Promise ( (resolve, reject) => {
      if(!userSocket) resolve();
      userSocket.emit('clear_listeners', err => {
        if(err !== null){
          console.log(err);
          return reject({ clearListeners: 'Error en clear_listeners'})
        }
        return resolve();
      });

    });
  },


  connectSocket() {
    return new Promise( (resolve, reject)=> {
      //
      // Comprobamos si hay socket de usuario previamente. Si hay no hacemos nada.
      //
      if( userSocket ) resolve(true);
      //
      // Obtento el token de usuario si existe
      //
      jwt = StorageService.getItem('access_token');
      //
      // Preparamos la conexión con el socket
      //
      userSocket = io(CONSTANTS.CONNECT.APP.AZCLOUD_BASE_URL,
      {
        path: CONSTANTS.CONNECT.APP.AZCLOUD_SOCKET_PATH,
        secure: true,
        query: {
          jwt,
        },
        transports: ['websocket'],
        transportOptions: {
          polling: {
            extraHeaders: {
              'Authorization': `Bearer ${jwt}`
            }
          }
        }
      });
      //
      // Capturo cualquier evento que me venga por el socket y lo muestro
      //
      userSocket.onAny(async (event, ...args) => {
        //
        // Los datos recibidos del evento
        //
        const receivedData = args[0];
        console.log('receivedData: ', receivedData)
        let eventProps;

        if (typeof event === 'string') {
          eventProps = event.split('.');
        }

        const eventName = eventProps[0];
        // const eventDeviceMac = (eventProps[1] === undefined) ? null : eventProps[1];
        const eventDeviceID = eventProps[2] === undefined ? null : eventProps[2];
        const eventType = eventProps[1] === undefined ? null : eventProps[1];

        console.log(eventName, eventType, eventDeviceID);

        switch(eventName){
          case 'DEVICES':
            if(eventType === 'finished-ota-ws'){
              console.log('finished-ota-ws')
              document.dispatchEvent(new CustomEvent('linkEnd'));
            }
            break;
          default:
        }
      })


      userSocket.on('connect', async () => {
        console.log(`Usuario conectado al socket`);
        reconnectAttemps = 0; // Si no obtenemos error reiniciamos contador de intentos
        resolve(true);
      });

      userSocket.on('connect_error', err => {
        console.log("CONNECT ERROR", err);
        reject(err);
      })

      /**
      * El evento 'disconnect' me saca fuera del socket
      * Puede ser porque nos han cerrado todas las sesiones o porque nos han eliminado el usuario
      * Limpio
      */
       userSocket.on('disconnect', async reason => {
        // log.info(`Disconnect: ${JSON.stringify(reason)}`);
        console.log("Disconnect")
        if (!window.navigator.onLine) return;


        if (reason === 'io server disconnect') {
          try {
            jwt = await AuthService.refreshToken();
            // console.log('New JWT:', jwt)
            userSocket.io.opts.query.jwt = jwt;
            // eslint-disable-next-line dot-notation
            userSocket.io.opts.transportOptions.polling.extraHeaders['Authorization'] = `Bearer ${jwt}`;
            userSocket.connect();
            await this.refreshListeners();

          } catch ( error ) {
            console.log('ERROR en disconect', error);
            //
            // Si nos han desconectado limpiamos el usuario y redirigimos al login
            //
            AuthService.clearUser();
            Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
          }
        } else {
          //
          // log.info(`Disconnect por desconexión de red: ${JSON.stringify(reason)}`);
          console.log(`Disconnect por desconexión de red`);

        }

      });

      userSocket.io.on('error', async err => {
        console.log('Desconexion socket error', err);
        if (!window.navigator.onLine) return;

        try {
          jwt = await AuthService.refreshToken();
          userSocket.io.opts.query.jwt = jwt;
          // eslint-disable-next-line dot-notation
          userSocket.io.opts.transportOptions.polling.extraHeaders['Authorization'] = `Bearer ${jwt}`;
          // userSocket.connect();
          // await this.refreshListeners();

        } catch ( error ) {
          console.log(error)
          //
          // Si el backend está disponible
          //
          if (error && error.name !== 'backendDown') {
            //
            // Si nos han desconectado limpiamos el usuario y redirigimos al login
            //
            this.disconnectSocket();
            AuthService.clearUser();
            Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
          }
          reject('socketConnectError');
        }
      });

      userSocket.io.on('reconnect', async attempt => {
        console.log(`Reconectado tras ${attempt} intentos`);
        this.refreshListeners();
      })

      userSocket.on('auth', async (event, callback) => {

        // console.log(`Recibido evento: ${event}`);
        if(event === 'authenticate') callback(StorageService.getItem('access_token'));
          // log.info('Respondo con token válido');
      });
    });

  },
  /**
   * Desconecta los sockets del usuario
   */
  disconnectSocket() {
    console.log('DISCONNECT SOCKET lanzado');
    if(userSocket === null) {
      return this;
    }
    userSocket.close();
    // userSocket.removeAllListeners();

    userSocket = null;

    return this;
  },



  /**
   * Recupera la información de todos los Devices de una instalación actualizada
   * NOTA: Comprueba la ruta en la que está por si es necesario actualizarla
   */
  async refreshListeners() {
    //
    // Si estoy dentro de las vistas de una instalación refresco los listeners
    //
    //
    // Limpiamos listeners
    //
    this.clearListeners();
      //
      // Reconectamos para obtener la información actualizada
      //
      await this.listenWebserverAdmin(Router.history.current.params.webserverID);
  },

}

export default SocketService;
