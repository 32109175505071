/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry-solid': {
    width: 19,
    height: 29,
    viewBox: '0 0 19 29',
    data: '<path pid="0" d="M10.2 1.167l.954 1.748.455.851a171.637 171.637 0 012.777 5.443l.68 1.418.617 1.332.555 1.247c.087.2.171.397.253.59l.46 1.117.395 1.029c.667 1.805 1.007 3.166 1.007 4.065 0 4.959-4.03 8.993-8.986 8.993S.381 24.967.381 20.007c0-.898.341-2.26 1.007-4.065l.396-1.029.46-1.116.522-1.204.586-1.29.649-1.374.347-.72c.633-1.3 1.317-2.656 2.04-4.044l1.355-2.553.791-1.445c.347-.623 1.319-.623 1.665 0zm-5.423 18.35a.49.49 0 00-.49.49 5.088 5.088 0 005.08 5.084.49.49 0 000-.98c-2.261 0-4.1-1.84-4.1-4.104a.49.49 0 00-.49-.49z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
