/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'light-rain-dark': {
    width: 52,
    height: 45,
    viewBox: '0 0 52 45',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M24.213 34.952a1.146 1.146 0 012.05 1.017l-.06.12-4.583 8.021a1.146 1.146 0 01-2.05-1.017l.06-.12 4.583-8.02z" _fill="#40C3F7" fill-rule="nonzero"/><path pid="1" d="M25.208 30.938v-10.92l6.007.001c.31-5.413 4.743-9.706 10.165-9.706 5.624 0 10.182 4.617 10.182 10.312 0 5.492-4.238 9.98-9.582 10.295v.017H25.207z" _fill="#C9D0DF"/><path pid="2" d="M9.429 30.938v-.018C4.17 30.613 0 26.21 0 20.823 0 15.237 4.485 10.71 10.018 10.71c.44 0 .875.029 1.301.085C13.281 4.536 19.08 0 25.93 0 34.39 0 41.25 6.926 41.25 15.469c0 8.42-6.665 15.27-14.96 15.464l-.361.005h-16.5z" _fill="#F6F9FF"/></g>'
  }
})
