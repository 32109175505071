/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'small': {
    width: 25,
    height: 19,
    viewBox: '0 0 25 19',
    data: '<path pid="0" d="M3.528 9.886a1 1 0 11-2-.001 1 1 0 012 0zm18.5 6.5a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v13zm-17-.5h16v-12h-16v12zm19 .5c0 1.378-1.122 2.5-2.5 2.5h-19a2.503 2.503 0 01-2.5-2.5v-13c0-1.378 1.122-2.5 2.5-2.5h19c1.378 0 2.5 1.122 2.5 2.5v13zm-21.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-13c0-.827-.673-1.5-1.5-1.5h-19c-.827 0-1.5.673-1.5 1.5v13c0 .827.673 1.5 1.5 1.5z"/>'
  }
})
