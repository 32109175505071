/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 23,
    height: 30,
    viewBox: '0 0 23 30',
    data: '<path pid="0" d="M12.829 0c1.191 0 2.262.661 2.795 1.727l.387.773h3.364A3.129 3.129 0 0122.5 5.625v1.25c0 .345-.28.625-.625.625h-.625v18.125A4.38 4.38 0 0116.875 30H5.625a4.38 4.38 0 01-4.375-4.375V7.5H.625A.625.625 0 010 6.875v-1.25A3.129 3.129 0 013.125 2.5h3.364l.386-.773A3.108 3.108 0 019.67 0h3.159zM20 7.5H2.5v18.125a3.129 3.129 0 003.125 3.125h11.25A3.129 3.129 0 0020 25.625V7.5zM5.625 10c.345 0 .625.28.625.625v15a.625.625 0 01-1.25 0v-15c0-.345.28-.625.625-.625zm3.75 0c.345 0 .625.28.625.625v15a.625.625 0 01-1.25 0v-15c0-.345.28-.625.625-.625zm3.75 0c.345 0 .625.28.625.625v15a.625.625 0 01-1.25 0v-15c0-.345.28-.625.625-.625zm3.75 0c.345 0 .625.28.625.625v15a.625.625 0 01-1.25 0v-15c0-.345.28-.625.625-.625zM12.83 1.25H9.671c-.716 0-1.358.398-1.677 1.036l-.56 1.119a.626.626 0 01-.559.345h-3.75A1.877 1.877 0 001.25 5.625v.625h20v-.625a1.877 1.877 0 00-1.875-1.875h-3.75a.626.626 0 01-.559-.345l-.558-1.119A1.866 1.866 0 0012.83 1.25z" _fill="#000" fill-rule="nonzero"/>'
  }
})
