/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 31,
    height: 30,
    viewBox: '0 0 31 30',
    data: '<path pid="0" d="M29.773 28.03a.985.985 0 010 1.97H.985a.985.985 0 010-1.97h28.788zM6.288 6.06a2.5 2.5 0 012.5 2.5v15.152a2.5 2.5 0 01-2.5 2.5h-3.03a2.5 2.5 0 01-2.5-2.5V8.561a2.5 2.5 0 012.5-2.5h3.03zm10.606 4.546a2.5 2.5 0 012.5 2.5v10.606a2.5 2.5 0 01-2.5 2.5h-3.03a2.5 2.5 0 01-2.5-2.5V13.106a2.5 2.5 0 012.5-2.5h3.03zM27.5 0A2.5 2.5 0 0130 2.5v21.212a2.5 2.5 0 01-2.5 2.5h-3.03a2.5 2.5 0 01-2.5-2.5V2.5a2.5 2.5 0 012.5-2.5h3.03zM6.288 8.03h-3.03a.53.53 0 00-.53.53v15.152c0 .293.237.53.53.53h3.03a.53.53 0 00.53-.53V8.561a.53.53 0 00-.53-.53zm10.606 4.546h-3.03a.53.53 0 00-.53.53v10.606c0 .293.237.53.53.53h3.03a.53.53 0 00.53-.53V13.106a.53.53 0 00-.53-.53zM27.5 1.97h-3.03a.53.53 0 00-.53.53v21.212c0 .293.237.53.53.53h3.03a.53.53 0 00.53-.53V2.5a.53.53 0 00-.53-.53z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
