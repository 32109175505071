/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M7.5 5.511l5.1-5.1a1.406 1.406 0 111.988 1.99L9.488 7.5l5.1 5.1a1.406 1.406 0 11-1.989 1.988L7.5 9.488l-5.1 5.1A1.406 1.406 0 11.413 12.6l5.1-5.099-5.1-5.1A1.406 1.406 0 012.4.413l5.099 5.1z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
