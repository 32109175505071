/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning-modal-old': {
    width: 32,
    height: 30,
    viewBox: '0 0 32 30',
    data: '<path pid="0" d="M16.214 0c1.883 0 3.551.874 4.466 2.354L31.19 23.38c.847 1.373.803 3.047-.124 4.423C30.141 29.18 28.508 30 26.703 30H5.728c-1.806 0-3.439-.82-4.366-2.197-.925-1.377-.965-3.042-.114-4.438L11.732 2.387C12.659.88 14.322 0 16.214 0zm0 1.154c-1.457 0-2.71.643-3.433 1.734l-.043.067-10.48 20.97-.025.041c-.617 1.012-.587 2.191.086 3.193.685 1.016 1.898 1.647 3.275 1.685l.134.002h20.975c1.431 0 2.702-.639 3.406-1.687.653-.97.704-2.111.156-3.077l-.057-.096-.028-.044L19.675 2.925l-.042-.066c-.688-1.033-1.889-1.665-3.283-1.703l-.136-.002zm0 21.068c1.255 0 2.284.908 2.284 2.037s-1.029 2.037-2.284 2.037c-1.254 0-2.284-.908-2.284-2.037s1.03-2.037 2.284-2.037zm0 1.154c-.649 0-1.13.427-1.13.883s.481.883 1.13.883c.65 0 1.13-.427 1.13-.883s-.48-.883-1.13-.883zM18.27 8.214c.533.52.793 1.219.714 1.92l-1.19 10.504c-.046.414-.43.72-.88.72h-1.4c-.449 0-.832-.306-.88-.72l-1.19-10.504c-.08-.701.18-1.4.713-1.92 1.056-1.03 3.058-1.03 4.113 0zm-.805.826c-.608-.592-1.895-.592-2.502 0-.262.255-.392.574-.379.891l.006.073 1.156 10.2h.935l1.157-10.2a1.147 1.147 0 00-.315-.904l-.058-.06z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
