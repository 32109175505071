/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'device': {
    width: 30,
    height: 26,
    viewBox: '0 0 30 26',
    data: '<path pid="0" d="M15.881 0a3.34 3.34 0 013.191 2.35l.063.204h3.978a3.34 3.34 0 013.098 2.093l3.521 8.733c.172.357.268.757.268 1.18v8.13a2.727 2.727 0 01-2.727 2.727H2.727A2.727 2.727 0 010 22.69V8.853c0-.527.15-1.02.409-1.437H.408l3.364-5.76A3.34 3.34 0 016.657 0h9.224zm2.377 7.353H2.728a1.5 1.5 0 00-1.5 1.5V22.69a1.5 1.5 0 001.5 1.5h24.545a1.5 1.5 0 001.5-1.5v-8.13a1.5 1.5 0 00-1.5-1.5h-5.947a1.568 1.568 0 01-1.568-1.568V8.853a1.5 1.5 0 00-1.5-1.5zm2.48.366c.159.345.247.73.247 1.134v2.639c0 .188.153.34.341.34h5.947c.182 0 .36.019.533.053l-2.733-6.78a2.114 2.114 0 00-1.96-1.323l-3.597-.001 1.223 3.938zm3.083 1.818a.682.682 0 110 1.364.682.682 0 010-1.364zm-.754-2.32a.614.614 0 110 1.228.614.614 0 010-1.227zm-7.186-5.99H6.657c-.751 0-1.446.4-1.825 1.048L2.579 6.13c.05-.003.099-.004.148-.004h15.53c.255 0 .5.034.734.1L17.9 2.714a2.114 2.114 0 00-2.02-1.488zm6.568 3.808a.545.545 0 110 1.09.545.545 0 010-1.09z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
