/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'left': {
    width: 9,
    height: 15,
    viewBox: '0 0 9 15',
    data: '<path pid="0" d="M8.635 12.954c.487.47.487 1.222 0 1.693-.487.47-1.265.47-1.752 0l-6.518-6.3a1.164 1.164 0 010-1.694l6.518-6.3a1.263 1.263 0 011.752 0c.196.19.284.398.322.619.017.096.02.178.02.25 0 .072-.003.154-.02.25a1.08 1.08 0 01-.325.622L2.975 7.523l5.66 5.43z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
