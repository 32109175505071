/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wind': {
    width: 49,
    height: 41,
    viewBox: '0 0 49 41',
    data: '<path pid="0" d="M25.041 22.778c9.623 0 14.488 7.052 10.854 13.294-4.096 7.037-14.498 5.541-16.673-1.77a1.642 1.642 0 113.147-.936c1.369 4.6 8.065 5.562 10.688 1.054 2.28-3.915-.786-8.359-8.016-8.359H7.115a1.642 1.642 0 010-3.283zm16.278 6.66a1.825 1.825 0 110 3.649 1.825 1.825 0 010-3.65zm-4.56-7.298a1.825 1.825 0 11-.002 3.65 1.825 1.825 0 01.001-3.65zM24.888 6.148c3.195-7.17 12.536-8.562 16.472-1.537 1.579 2.817 1.598 6.583-.306 9.533-2.282 3.538-6.917 5.532-13.732 5.532H1.642a1.642 1.642 0 010-3.284h25.68c5.792 0 9.368-1.538 10.974-4.028 1.218-1.887 1.206-4.354.2-6.148-2.51-4.482-8.439-3.6-10.608 1.269a1.642 1.642 0 01-3-1.337zm-4.549 5.959a1.825 1.825 0 110 3.65 1.825 1.825 0 010-3.65zm26.452-1.824a1.825 1.825 0 11-.001 3.649 1.825 1.825 0 010-3.65zm-12.77-3.649a1.825 1.825 0 110 3.65 1.825 1.825 0 010-3.65z" _fill="#8999B7" fill-rule="nonzero"/>'
  }
})
