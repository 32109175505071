/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.65 16.367v7.5h-1.8v-7.5h1.8zm9-8v15.5h-1.8v-15.5h1.8zM3.65 0v11.5h-1.8V0h1.8zm9 0v3.5h-1.8V0h1.8zM0 13h5.5v1.8H0V13zm9-8h5.5v1.8H9V5zm12.65 16.367v2.5h-1.8v-2.5h1.8zM21.65 0v16.5h-1.8V0h1.8zM18 18h5.5v1.8H18V18z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
