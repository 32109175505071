/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'path': {
    width: 16,
    height: 16,
    viewBox: '0 0 149 78',
    data: '<path pid="0" d="M133.3 77.3h-.5c-8.6-1-13.8-16.1-18.9-30.6-3.3-9.4-7-20-10.2-20.4-2.8-.3-4.5.7-5.9 1.6-1.2.7-2.5 1.5-4 1.1-2-.6-3.9-3.2-7.1-9.7l-1.2-2.5c-3.6-7.5-6.7-14-9.7-14-2.5 0-5.8 4.7-9.8 13.8-1.6 3.5-2.9 6.7-4.2 9.6-7.6 17.6-10.2 23.5-18 24.4-3.3.4-6.2-2.8-8.7-5.7-1.1-1.2-3-3.5-3.7-3.4 0 0-.4.1-.8 1.3s-.8 2.3-1.1 3.4c-1.8 5.6-3.1 9.3-8.6 12-5.8 2.8-9.3.1-12-2-.5-.4-.9-.7-1.3-1-2.3-1.6-4.6-.1-4.7 0-.6.4-1.3.2-1.7-.3-.4-.6-.3-1.3.3-1.7.2-.1 3.8-2.6 7.6 0 .5.3 1 .7 1.4 1.1 2.6 2 5 3.9 9.4 1.7 4.6-2.2 5.5-5 7.3-10.5.3-1.1.7-2.2 1.2-3.4.2-.7.9-2.6 2.7-3 2-.4 3.9 1.7 6 4.2 2 2.3 4.5 5.1 6.5 4.9 6.4-.8 8.7-5.9 16-23 1.2-2.9 2.6-6 4.2-9.6C68.5 4.9 72.1.3 75.9.3h.1c4.5 0 7.6 6.5 11.9 15.4l1.2 2.5c.9 1.9 3.8 7.9 5.5 8.4.4.1 1-.2 2-.8 1.6-1 3.8-2.4 7.5-2 4.8.5 8.1 10 12.3 22 4.4 12.5 9.8 28.1 16.9 29 .3 0 .7 0 1.2-.4 5.9-4.7 9.4-37.4 11.4-57 .1-.7.7-1.2 1.4-1.1.7.1 1.2.7 1.1 1.4-3.6 33.8-6.7 54.2-12.4 58.7-1 .5-1.8.9-2.7.9z"/>'
  }
})
