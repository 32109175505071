import ApiService from '@/services/api.service';

const ConnectionsService = {

  /**
   * Devuelve los usuarios vinculados a una instalación
   *
   * @param {String} filterParam - Nombre del filtro (email)
   * @param {String} filterParam - Valor del filtro
   * @param {Number} items - Límite de usuarios a devolver por página
   * @param {number} page - Número de página
   * @param {String} email - Email para autentificar consulta
   * @param {String} token - Token para autentificar consulta
   * @return {Object} - Objeto con el array de usuarios, el total de páginas y el total de usuarios
   */
  async getLogs(mac, precision, startDate, finishDate) {
    let requestUrl = `/admin/bg/coverage/${mac}?`;
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    if(precision) requestUrl += `precision=${precision}`;
    if(startDate && finishDate) {
      requestUrl += `&startdate=${startDate}&finishdate=${finishDate}`;
    }
    // console.log("In get connections", requestUrl);
    //
    // Realizo la petición y transformo los datos
    //
    try {
      const response = await ApiService.get(requestUrl);
      // console.log('ConnectionsService: ', response.data);
      return response && response.data && response.data.values ? response.data.values : []
    } catch (error) {
      console.log(error)
      throw error;
    }
  },

};

export default ConnectionsService;
