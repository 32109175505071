/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 30,
    height: 28,
    viewBox: '0 0 30 28',
    data: '<path pid="0" d="M29.098.879A3.03 3.03 0 0130 3.049a3.017 3.017 0 01-.903 2.153l-3.239 3.203a.559.559 0 01-.277.276A3398.565 3398.565 0 0015.165 19.02l-.673.672-.422.422-.781.782-.534.535-.331.333a272.285 272.285 0 00-2.88 2.924.58.58 0 01-.319.337c-.16.166-.252.269-.298.326a.588.588 0 01-.294.26l-5.834 2.333a.578.578 0 01-.629-.128.58.58 0 01-.128-.63l2.333-5.832a.577.577 0 01.129-.196L21.382 4.283a.589.589 0 01.038-.042l.041-.034L24.792.879c1.15-1.15 3.156-1.15 4.306 0zM5.5 21.812l-.085.085-1.784 4.458 4.493-1.797.056-.066-2.68-2.68zM21.836 5.48L6.324 20.987l2.643 2.643c.757-.8 2.02-2.087 4.128-4.197l.385-.386c2.45-2.45 5.963-5.939 11-10.922l-2.644-2.644zM9.125 12.5a.625.625 0 010 1.25h-8a.625.625 0 010-1.25h8zm4-4a.625.625 0 010 1.25h-12a.625.625 0 010-1.25h12zm12.492-6.798L22.66 4.656l2.648 2.65 2.968-2.935c.358-.352.556-.823.557-1.325a1.866 1.866 0 00-.551-1.336c-.727-.725-1.955-.717-2.665-.008zM17.125 4.5a.625.625 0 010 1.25h-16a.625.625 0 010-1.25h16zm3-4a.625.625 0 010 1.25h-19a.625.625 0 010-1.25h19z" _fill="#000" fill-rule="nonzero"/>'
  }
})
