<template>
  <b-container fluid class="m-0 p-0">
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-0">
        <template header>
          <b-row class="my-3">
            <b-col lg="8" sm="12">
              <b-input-group>
                <b-input-group-prepend>
                  <div class="az-input__value">
                    <div class="az-input__body">
                      <b-form-input
                        v-model="emailSearch"
                        id="emailSearch"
                        class="searchFilter"
                        :placeholder="placeholder"
                        size="lg"
                        autocomplete="emailSearch"
                        autofocus
                        @keyup.enter="findUser()"
                      >
                      </b-form-input>
                      <div class="az-input__search">
                        <div
                          class="eyeIcon searchIcon"
                          :class="{
                            rightIcon:
                              emailSearch === null || emailSearch === '',
                          }"
                          @click="findUser()"
                        >
                          <az-icon name="search" scale="1"></az-icon>
                        </div>
                        <div
                          class="clearIcon"
                          @click="emailSearch = ''"
                          v-if="emailSearch !== null && emailSearch !== ''"
                        >
                          <az-icon name="clear" scale="1"></az-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <b-dropdown
                      class="filterBtn searchFilter"
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content>
                        <div>
                          <az-icon name="settings" scale="1"></az-icon>
                        </div>
                      </template>
                      <b-dropdown-item
                        href="#"
                        :active="isFilterParam('email')"
                        @click="setFilterParam('email')"
                        >{{ $t("login.email") }}</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        @click="setFilterParam('fullName')"
                        :active="isFilterParam('fullName')"
                        >{{ $t("name") }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </b-input-group-prepend>
              </b-input-group>
            </b-col>

            <b-col lg="4" sm="12" class="h4 mt-1 pt-1 text-right">
              <az-icon
                name="user"
                scale="1"
                style="margin-top: -0.3rem"
              ></az-icon>
              {{ total }}
              <span v-show="total !== 1">
                {{ $t("user.title") }}
              </span>
              <span v-show="total === 1">
                {{ $t("user.user") }}
              </span>
            </b-col>
          </b-row>
        </template>

        <EmptyCard
          class="mt-5"
          :condition="total === 0 && !isLoading"
        ></EmptyCard>

        <b-table
          v-if="!isLoading && total > 0"
          id="table-users"
          responsive
          selectable
          outlined
          hover
          head-variant="dark"
          @row-clicked="infoUser"
          :tbody-tr-class="rowClass"
          :items="users"
          :fields="fields"
          busy.sync="isLoading"
        >
          <template #cell(email)="data">
            <div class="font-weight-bold">
              {{ data.item.email }}
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.data.name }} {{ data.item.data.lastName }}
          </template>

          <template #cell(confirmation_date)="data">
            <div v-if="data.item.confirmation_date">
              {{ data.item.confirmation_date | formatDate }}
            </div>
            <div class="text-danger" v-if="!data.item.confirmation_date">
              <az-icon name="error-check" scale="2"></az-icon>
              {{ $t("user.pendingConfirmation") }}
            </div>
          </template>

          <template #cell(confirmation_email)="data" >
            <div class="d-flex justify-center">
              
              <div class="font-weight-bold" v-if="data.item.config.admin_mode">
                {{ $t("user.role.admin") }}
              </div>
              <div class="ml-1" v-if="data.item.confirmation_email_date">
                <span v-if="data.item.config.admin_mode"> - </span>
                  {{ $t("user.emailConfirmationDate") }}:
                {{ data.item.confirmation_email_date | formatDate }}
              </div>  
            </div>
          </template>


          <template #cell(actions)="data">
            <div v-if="!data.item.confirmation_date">
              <b-button
                size="sm"
                @click="resendConfirmationEmail(data.item.email)"
                class="mr-1"
                variant="brand"
              >
                {{ $t("buttons.resend_confirmation") }}
              </b-button>
            </div>
          </template>
        </b-table>

        <div class="row mt-5" v-if="isLoading">
          <div class="col-12 d-flex justify-content-center">
            <div class="text-center" style="width: 30rem; height: 35rem">
              <b-spinner
                style="width: 3rem; height: 3rem; margin-top: 25%"
                class=""
                variant="brand"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
        </div>
      </b-jumbotron>
    </transition>

    <div class="pb-3">
      <Pagination
        v-if="total > 10"
        v-model="currentPage"
        :totalRows="total"
        :perPage="perPage"
        :ariaControls="'table-users'"
        :align="'center'"
      >
      </Pagination>
    </div>
  </b-container>
</template>

<script>
import store from "@/store/store";
import WebService from "@/services/web.service";
import EmptyCard from "@/components/EmptyCard.vue";
import Pagination from "@/components/Pagination.vue";
import setStoreFilters from "@/mixins/SetStoreFilters.mixin";
import setFirstPage from "@/mixins/CheckFilter.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import resendConfirmationEmail from "@/mixins/ResendConfirmationEmail.mixin";
import i18n from "@/services/language.service";

export default {
  name: "Users",
  props: {},
  mixins: [setStoreFilters, setFirstPage, rowClass, resendConfirmationEmail],
  components: {
    EmptyCard,
    Pagination,
  },
  data() {
    return {
      filterParam: "email",
      emailSearch: "",
      total: 0,
      perPage: 10,
      currentPage: 1,
      isLookingFor: false,
      isLoading: false,
      sizes: ["Small", "Medium", "Large", "Extra Large"],
      users: [],
      fields: [
        {
          key: "email",
          label: i18n.t("login.email"),
          sorteable: true,
        },
        {
          key: "name",
          label: i18n.t("user.name"),
        },
        {
          key: "confirmation_date",
          label: i18n.t("user.confirmation"),
        },
        {
          key: "confirmation_email",
          label: i18n.t("user.observations"),
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  computed: {
    placeholder() {
      let value = "";
      switch (this.filterParam) {
        case "name":
          value = this.$i18n.t("name");
          break;
        case "lastName":
          value = this.$i18n.t("surname");
          break;

        default:
          value = this.$i18n.t("login.email");
          break;
      }
      return value;
    },
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;
      if (!this.isLookingFor) {
        // Borra el filtro de busqueda
        if (this.emailSearch) {
          this.emailSearch = "";
        }
        if (this.filterParam !== "email") {
          this.filterParam = "email";
        }
      }
      this.findUsers();
    },
  },

  methods: {
    /**
     * Asigna el filtro de búsqueda
     */
    setFilterParam(filter) {
      this.filterParam = filter;
    },

    /**
     * Devuelve true si el filtro de búsqueda es el asignado
     */
    isFilterParam(filter) {
      return this.filterParam === filter;
    },

    /**
     * Obtiene el usuario buscado desde el filtro de búsqueda
     */
    async findUser() {
      this.isLookingFor = true;
      // Asigna filtro y página actual
      this.setFirstPage(this.emailSearch);
      this.findUsers();
    },

    /**
     * Obtiene los usuarios paginados
     */
    async findUsers() {
      this.isLoading = true;

      // Obtengo página y filtro del store si ha habido navegación anterior en esta vista
      if (!this.isLookingFor) {
        const storeParams = this.setStoreFilters();

        if (storeParams && Object.keys(storeParams).length > 0) {
          this.macFilter = storeParams.filter;
          this.currentPage = storeParams.page;
        }
      }
      const response = await WebService.getUsers(
        this.filterParam,
        this.emailSearch,
        this.currentPage - 1
      );

      this.total = response.total;
      this.users = response.users;
      this.animateTableRows()
      this.isLoading = false;
    },

    /**
     * Enlaza con la vista del usuario
     *
     * @param {Object} item - Objecto con los datos del usuario
     */
    infoUser(item) {
      const path = { name: "user", params: { id: item._id } };
      // Salvo los datos del usuario en VUEX
      store.dispatch("userModule/saveUser", item);
      // Salvo la ruta de navegación del usuario en VUEX
      store.dispatch("navModule/saveUser", path);
      // Borra el filtro de busqueda si se escribe en el input sin buscar y se clica en un webserver dentro de la página
      store.dispatch("navModule/savePageAndFilter", {
        filter: this.emailSearch,
        page: this.currentPage,
      });
      this.$router.push(path);
    },
  },

  async mounted() {
    await this.findUsers();
  },
};
</script>

<style scoped>
.az-input__search {
  display: flex;
}

.filterBtn {
  border-radius: 5px;
  background-color: #fff;
  left: -10px;
}
</style>
