/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-7': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M23.125 23.75h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zM23.75 20h2.5v2.5h-2.5V20zm5.89-11.189a.63.63 0 00-.665.085L22.5 14.293V9.376a.624.624 0 00-1.025-.481L15 14.291V9.375a.624.624 0 00-1.047-.461l-5.307 4.865L7.498.57A.624.624 0 006.875 0h-3.75a.625.625 0 00-.622.571l-2.5 28.75a.619.619 0 00.16.477.628.628 0 00.462.202h28.75c.345 0 .625-.28.625-.623v-20a.624.624 0 00-.36-.566zM1.306 28.75l2.392-27.5H6.3l2.392 27.5H1.306zM9.188 20h2.062v2.5H9.405L9.188 20zm19.562 8.75H9.948l-.435-5h2.362c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625H9.08l-.296-3.4 4.967-4.554v4.829a.623.623 0 001.025.48l6.475-5.396v4.916a.623.623 0 001.025.48l6.475-5.396V28.75zm-9.375-10h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625zm-.625 3.75h-2.5V20h2.5v2.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
