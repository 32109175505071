/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chance-storm-dark': {
    width: 58,
    height: 55,
    viewBox: '0 0 58 55',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_chance-storm-dark_a"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_chance-storm-dark_b"><stop stop-color="#8F571E" offset="0%"/><stop stop-color="#8F501D" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_chance-storm-dark_c"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><path pid="0" _fill="url(#svgicon_chance-storm-dark_a)" d="M13.873 41.25h6.843l-3.574 5.74h2.598l-8.282 7.72 2.415-6.596h-2.415z"/><g transform="translate(28.646)"><circle pid="1" _fill="url(#svgicon_chance-storm-dark_b)" cx="14.323" cy="14.323" r="14.323"/><circle pid="2" _fill="url(#svgicon_chance-storm-dark_c)" cx="14.323" cy="14.323" r="9.8"/></g><path pid="3" d="M25.208 38.958V28.04h6.007c.31-5.413 4.743-9.706 10.165-9.706 5.624 0 10.182 4.617 10.182 10.313 0 5.491-4.238 9.98-9.582 10.295v.017H25.207z" _fill="#7484A6"/><path pid="4" d="M9.429 38.958v-.017C4.17 38.634 0 34.231 0 28.844 0 23.258 4.485 18.73 10.018 18.73c.44 0 .875.029 1.301.085C13.281 12.557 19.08 8.02 25.93 8.02c8.461 0 15.321 6.925 15.321 15.469 0 8.42-6.665 15.27-14.96 15.464l-.361.004h-16.5z" _fill="#A2B0CA"/><path pid="5" d="M28.797 42.973a1.146 1.146 0 012.05 1.017l-.06.12-4.584 8.021a1.146 1.146 0 01-2.049-1.017l.06-.12 4.583-8.02zM39.11 42.973a1.146 1.146 0 012.049 1.017l-.06.12-4.583 8.021a1.146 1.146 0 01-2.05-1.017l.06-.12 4.584-8.02z" _fill="#40C3F7" fill-rule="nonzero"/></g>'
  }
})
