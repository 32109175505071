import WebService from "@/services/web.service";
import SocketService from "@/services/socket.service";
import i18n from "@/services/language.service";
import CONSTANTS from "@/constant";
import Role from '@/mixins/Role.mixin'

const DeviceType = {
  mixins: [ Role ],
  data() {
    return {
      language: this.$i18n.locale
    }
  },

  methods: {
    /**
      * Devuelve el texto y el color de fondo para el componente de bootstrap 'bagde', según el tipo de dispositivo
      * 
      * @param {String} type - Tipo de dispositivo
      * @return {Object} - Objecto con el estilo del color de fondo para badge, texto del bagde y booleano si tiene sistema y/o zona
      */
    deviceType(type) {
      let deviceType = {};

      switch (type){
        case 'az_system': 
          deviceType.text = 'system.systems'
          deviceType.bgColor = 'brand-dark'
          deviceType.hasSystem = true
          break
        case 'aidoo':
        case 'aidoo_it':
          deviceType.text = 'installations.zone'
          deviceType.bgColor = 'brand-light'
          deviceType.hasZone = true
          break
        case 'az_zone': 
          deviceType.text = 'installations.zone'
          deviceType.bgColor = 'brand-light'
          deviceType.hasSystem = true
          deviceType.hasZone = true
          break
        case 'az_ccp':
          deviceType.text = 'system.ccp'
          deviceType.bgColor = 'danger-light'
          break
        case 'az_acs':
        case 'aidoo_acs':
          deviceType.text = 'system.acs_system'
          deviceType.bgColor = 'warning-light'
          break
        case 'az_energy_clamp':
          deviceType.text = 'system.clamp'
          deviceType.bgColor = 'success-light'
          deviceType.hasSystem = true
          break
        case 'az_ws_purifier':
          deviceType.text = 'purifier.ion'
          deviceType.bgColor = 'brand-darker'
          deviceType.hasSystem = true
          break
        case 'az_dehumidifier':
          deviceType.text = 'system.dehumidifier'
          deviceType.bgColor = 'brand-lighter'
          // deviceType.hasSystem = true
          break
        case 'az_airqsensor':
          deviceType.text = 'system.airq_sensor'
          deviceType.bgColor = 'warning'
          // deviceType.hasSystem = true
          break
      }

      return deviceType
    },

    /**
     * Comprueba si la versión tiene info en el idioma seleccionado en la app.
     * @param {Object} versionInfo - Objecto a comprobar si tiene textos en el idioma de la app
     *
     * @returns {boolean} - Tiene texto en idioma de la app
     */
    hasOwnLanguageText(versionInfo) {
      return versionInfo?.admin_text[this.language] !== undefined && versionInfo?.admin_text[this.language] !== "";
    },

    upgradeDeviceMessage() {
      let news = "";
      let versionMessage = `${this.$i18n?.t('messages.update_server_info')} `
      if (this.device.versionWS.ws_fw?.versions[0]?.version) {
        versionMessage += `${this.device.versionWS.ws_fw?.versions[0].version}`
      }

      if (this.device.versionWS.ws_fw?.versions[0]?.version && this.device.versionWS.lmachine_fw?.versions[0]?.version) {
        versionMessage += '/'
      }

      if (this.device.versionWS.lmachine_fw?.versions[0]?.version) {
        versionMessage += `${this.device.versionWS.lmachine_fw.versions[0].version}`
      }

      // Si ha texto de versión, se concatenan dos puntos y el título Novedades con sus espacios antes y después de este texto
      if ((this.device.versionWS.ws_fw?.versions[0]?.version !== this.device.config.ws_fw && this.hasOwnLanguageText(this.device.versionWS.ws_fw?.versions[0])) ||
        (this.device.versionWS.lmachine_fw?.versions[0].version !== this.device.config.lmachine_fw && this.hasOwnLanguageText(this.device.versionWS.lmachine_fw?.versions[0]))) {
        versionMessage += ':<p></p><p>' + i18n.t('messages.news') + '</p>'
      }

      if (this.device.versionWS.version !== this.device.config.ws_fw && this.hasOwnLanguageText(this.device.versionWS.ws_fw?.versions[0])){
        news +=`<p>${this.device.versionWS.ws_fw?.versions[0].admin_text[this.language]}</p>`;
      }

      if (this.device.versionWS.lmachine_fw?.versions[0]?.version !== this.lmachineFirmware && this.hasOwnLanguageText(this.device.versionWS.lmachine_fw?.versions[0])){
        news +=`<p>${this.device.versionWS.lmachine_fw?.versions[0].admin_text[this.language]}</p>`;
      }
      
      versionMessage += news;
      this.confirmBeforeUpdateWS(versionMessage);
    },

    updatedDeviceMessage() {
      let message = null;

      console.log("El Webserver ya estaba actualizado");
      message = i18n.t("messages.updateSameVersion", {
        version: this.device.versionWS.version,
      });
      this.$bvModal.msgBoxOk(message, {
        title: i18n.t("messages.updatedWebserver"),
        okVariant: "primary",
        okTitle: i18n.t("buttons.accept"),
        headerBgVariant: "warning",
        headerClass: "p-2 border-bottom-1",
        footerClass: "p-2 border-top-0",
        hideHeaderClose: true,
        centered: true,
      });
    },

    /**
     * Check if version to upgrade is equal to it has 
     * @returns 
     */
    checkVersionToUpdate() {
      let fwVersionState = true

      if (this.device.versionWS?.lmachine_fw?.versions?.length > 0) {
        // Si tiene la misma versión y no tiene role de actualización,
        // entonces muestro mensaje de no se puede actualizar por la misma versión
        // Si tiene role de actualización, le dejo actualizar a la misma versión
        if (this.device.versionWS.lmachine_fw.versions[0].version === this.device.config.lmachine_fw && !this.isUpdateRole()) {
          fwVersionState = true
        } else {
          fwVersionState = false
          return this.upgradeDeviceMessage()
        }
      }
      // Si tiene la misma versión y no tiene role de actualización,
      // entonces muestro mensaje de no se puede actualizar por la misma versión
      // Si tiene role de actualización, le dejo actualizar a la misma versión
      if(this.device.versionWS?.ws_fw?.versions?.length > 0 && this.device.config.ws_fw === this.device.versionWS.ws_fw.versions[0].version && !this.isUpdateRole()) {
        fwVersionState = true
      } else {
        fwVersionState = false
      }
      if (!fwVersionState) {
        this.upgradeDeviceMessage()
      } else {
        this.updatedDeviceMessage()
      }
    },

    /**
     * Asigna el campo del lmachine para mostrar el mensaje de confirmación antes de actualizar el webserver
     */
    async updateLmachineInfo(item) {
      try {
        // set last version if user isn't fwversions_write,
        // else set the selected version
        if (this.$route.params.device) {
          console.log(item)
          const versions = []
          versions.push(item)
          this.device.versionWS = {
            lmachine_fw: {
              versions
            }
          } 
        } else {
          this.device.versionWS = await WebService.getDeviceVersion(this.mac);
          console.log('updateServerInfo: ', this.device.versionWS)
        }
        console.log(this.device.versionWS)
        this.checkVersionToUpdate()
      } catch (error) {
        console.log(error);
        // this.$bvModal.show("bv-modal-error");
        this.updatedDeviceMessage()
      }
    },

    /**
     * Procesa la información a mostrar en el mensaje de confirmación antes de actualizar el webserver
     */
     async updateServerInfo(item) {
      try {
        // set last version if user isn't fwversions_write,
        // else set the selected version
        if (this.$route.params.device) {
          console.log(item)
          const versions = []
          versions.push(item)
          this.device.versionWS = {
            ws_fw: {
              versions
            }
          } 
        } else {
          this.device.versionWS = await WebService.getDeviceVersion(this.mac);
          console.log('updateServerInfo: ', this.device.versionWS)
        }
        console.log(this.device.versionWS)
        this.checkVersionToUpdate()
      } catch (error) {
        console.log(error);
        // this.$bvModal.show("bv-modal-error");
        this.updatedDeviceMessage()
      }
    },

    /**
     * Muestra el mensaje de confirmación antes de actualizar el webserver
     *
     * @param {String} message - Mensaje de la alerta
     */
    async confirmBeforeUpdateWS(message) {
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h("div", {
        domProps: { innerHTML: i18n.t("buttons.update") },
      });
      // More complex structure
      const messageVNode = h('div', { 
        domProps: { innerHTML: message }
      });

      // We must pass the generated VNodes as arrays
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: [titleVNode],
          okVariant: "primary",
          okTitle: i18n.t("buttons.accept"),
          cancelTitle: i18n.t("buttons.cancel"),
          headerBgVariant: "warning",
          headerClass: "p-2 border-bottom-1",
          footerClass: "p-2 border-top-0",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.execAction("updateWS");
          }
        })
        .catch((cancel) => {
          console.log(cancel);
        });
    },

    /**
     * Método que controla la actualización del webserver: Conectar socket y escuchar eventos iniciando un tiempo límite, si no hay respuesta
     */
     async updateDeviceVersion() {
      try {
        this.progress = 0;
        this.updatingWS = true;
        await SocketService.connectSocket();
        await SocketService.listenWebserverAdmin(this.mac);
        let id, lmachine_id = ''

        if (this.device.versionWS.ws_fw?.versions?.length > 0) {
          id = this.device.versionWS.ws_fw?.versions[0]._id
        }
        if (this.device.versionWS.lmachine_fw?.versions?.length > 0) {
          lmachine_id = this.device.versionWS.lmachine_fw?.versions[0]._id
        }
        await WebService.upgradeDeviceVersion(
          this.mac,
          id, lmachine_id
        );
        this.startTimeout(CONSTANTS.TIMEOUT.MAX_TIME_UPDATING_WS*2);
      } catch (error) {
        console.log(error);
        const h = this.$createElement;
        // Using HTML string
        const titleVNode = h("div", {
          domProps: { innerHTML: error._id },
        });
        // More complex structure
        const messageVNode = h('div', { 
          domProps: { innerHTML: error.msg }
        });
        this.$bvModal
          .msgBoxOk([messageVNode], {
            title: [titleVNode],
            okVariant: "warning",
            headerBgVariant: "warning",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          })
          .then(() => {
            this.updatingWS = false;
            this.clearTimeoutAndListeners();
          })
      }
    },

    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    startInterval(time) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        // console.log("this.progress: ", this.progress);
        this.progress += 1;
        if (this.progress >= this.randomIntFromInterval(4, 7)) {
          this.startInterval(40 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(9, 12)) {
          this.startInterval(52 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(14, 17)) {
          this.startInterval(this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(24, 27)) {
          this.startInterval(32 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(34, 37)) {
          this.startInterval(12 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(44, 47)) {
          this.startInterval(this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(64, 67)) {
          this.startInterval(8 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(84, 87)) {
          this.startInterval(16 * this.minIntervalTime);
        }
        if (this.progress >= this.randomIntFromInterval(90, 94)) {
          this.startInterval(54 * this.minIntervalTime);
        }
        if (this.progress === 97) {
          this.startInterval(96 * this.minIntervalTime);
        }
        if (this.progress >= 99) {
          this.progress = 99;
          clearInterval(this.interval);
        }
      }, time);
    },

    /**
     * Lanza un error si aún no se ha obtenido respuesta desde el socket tras un tiempo
     *
     * @param {Number} timeout - Tiempo en milisegundos que espera la respuesta del websocket para la actualización
     */
    startTimeout(timeout) {
      this.startInterval(this.minIntervalTime);
      this.timeoutUpgrading = setTimeout(async () => {
        console.log("Finish timeout. ");
        // Lanza un error si aún no se ha obtenido respuesta desde el socket tras un tiempo
        if (this.updatingWS) {
          this.animateProgress = false;
          // Modal de error actualizando webserver y oculta el actualizando al pulsar ok
          console.log("Timeout upgrading webserver");
          this.$bvModal
            .msgBoxOk(
              i18n.t("messages.updateError", {
                version: this.device.versionWS.version,
              }),
              {
                title: i18n.t("messages.updatingWebserver"),
                okVariant: "primary",
                okTitle: i18n.t("buttons.accept"),
                headerBgVariant: "warning",
                headerClass: "p-2 border-bottom-1",
                footerClass: "p-2 border-top-0",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then(() => {
              console.log("HIDE UPDATING WS");
              this.updatingWS = false;
            });
        }

        await this.clearTimeoutAndListeners();
      }, timeout);
    },

    /**
     * Captura evento de terminado para limpiar el timeout y listener websocket. Muestra mensaje de conectado correctamente
     */
    async upgradingWebserverFinish(e) {
      console.log("upgradingWebserver => Finish Event: ", e);
      // Actualizo la versión que se muestra en los detalles del webserver
      if (this.device.versionWS.ws_fw) {
        this.device.config.ws_fw = this.device.versionWS.ws_fw?.versions[0]?.version
      }
      if (this.device.versionWS.lmachine_fw) {
        this.device.config.lmachine_fw = this.device.versionWS.lmachine_fw?.versions[0]?.version
      }
      await this.clearTimeoutAndListeners();
      this.progress = 100;
      this.animateProgress = false;
      // Muestra modal de webserver actualizado y oculta el actualizando al pulsar ok
      this.$bvModal
        .msgBoxOk(
          i18n.t("messages.updateSuccess", {
            version: this.device.versionWS.version,
          }),
          {
            title: i18n.t("messages.updatedWebserver"),
            okVariant: "primary",
            okTitle: i18n.t("buttons.accept"),
            headerBgVariant: "success",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(() => {
          this.updatingWS = false;
        });
    },

    /**
     * Limpia el timeout y listener websocket. Oculta 'Conectando' y 'Actualizando'
     */
    async clearTimeoutAndListeners() {
      console.log("Limpio timeout y listeners websockets");
      this.updatingWS = false;
      clearTimeout(this.timeoutUpgrading);
      await SocketService.clearListeners();
    },

    /**
     * Ejecuta la acción, sino muestra modal de error
     *
     * @param {String} command - Acción a ejecutar
     */
     async execAction(command, value) {
      try {
        if (command === "updateWS") {
          console.log("Updating ws .....", this.device);
          await this.updateDeviceVersion();
        } else if (command === "restoreWS") {
          console.log("Restoring ws .....");
        } else if (command === "resetWS") {
          console.log("Reseting ws .....");
          await WebService.resetAidoo(this.mac);
          this.showMsgOk(
            i18n.t("messages.release"),
            i18n.t("messages.releaseUnitSuccess.message")
          );
        } else if (command === "disableOTA") {
          console.log("Disabling OTA .....", value);
          // Si no elige opción y pincha fuera del modal, no hacer petición
          if (value === null) return;
          await WebService.disableOTA(this.mac, value)
            .then(() => {
              this.$bvModal
                .msgBoxOk(i18n.t("messages.disableOTASuccess"), {
                  title: i18n.t("buttons.disable_ota"),
                  okVariant: "primary",
                  okTitle: i18n.t("buttons.accept"),
                  headerBgVariant: "success",
                  headerClass: "p-2 border-bottom-1",
                  footerClass: "p-2 border-top-0",
                  hideHeaderClose: true,
                  centered: true,
                })
                .then(async () => {
                  console.log("reload");
                  // window.location.reload();
                  this.isLoading = true;
                  const ws = await WebService.getDevice(this.mac);
                  this.device.config.disable_first_ota =
                    ws.config.disable_first_ota;
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              console.log(error);
              this.$bvModal.show("bv-modal-error");
            });
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    },

    /**
     * Devuelve la URL de la imagen del webserver
     *
     * @return {String} - URL de la imagen del webserver
     */
    getWsImage() {
      if (this.device.ws_type === CONSTANTS.WEBSERVERTYPE.AIRZONE && this.device.config.ws_hw) {
        this.wsImage = `ws/${this.device.config.ws_hw}.png`
      } else if (this.device.ws_type === CONSTANTS.WEBSERVERTYPE.AIDOO) {
        if (this.device.config.wsmmgroup === 'APFANCOIL') {
          this.wsImage = `ws/AIDOO_PRO.png`
        } else {
          this.wsImage = `ws/AIDOO.png`
        }
      } else if (this.device.ws_type === 'ws_8cb') {
        this.wsImage = `ws/CENTRAL_BLE.png`
      } else {
        this.wsImage = ''
      }
    },

    /**
     * Devuelve el texto del tipo de hardware del webserver
     *
     * @param {String} value - Type de hardware de webserver
     * @return {String} - Texto del tipo de hardware del webserver
     */
    wsTypeHardware(value) {
      return CONSTANTS.WSTYPE[value];
    },

  },

  async mounted() {
    document.addEventListener("linkEnd", this.upgradingWebserverFinish);
  },

  destroyed() {
    document.removeEventListener("linkEnd", this.upgradingWebserverFinish);
  },
}

export default DeviceType;