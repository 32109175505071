const CheckFilter = {

  methods: {

    /**
     * Asigna el filtro de búsqueda en la primera página si hay filtro de búsqueda
     * @param {String} filter - Filtro de búsqueda
     */
    setFirstPage(filter) {     
      if (filter !== "") {
        this.currentPage = 1
      }
    },

  },

}

export default CheckFilter;