/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'blocks': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M11.133 18.571c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 25.133v-5.695c0-.479.388-.867.867-.867h10.266zm14 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H14.867a.867.867 0 01-.867-.867v-5.695c0-.479.388-.867.867-.867h10.266zm-14.26 1.127H1.127v5.175h9.746v-5.175zm14 0h-9.746v5.175h9.746v-5.175zM11.133 9.286c.479 0 .867.388.867.866v5.696a.867.867 0 01-.867.866H.867A.867.867 0 010 15.848v-5.696c0-.478.388-.866.867-.866h10.266zm14 0c.479 0 .867.388.867.866v5.696a.867.867 0 01-.867.866H14.867a.867.867 0 01-.867-.866v-5.696c0-.478.388-.866.867-.866h10.266zm-14.26 1.126H1.127v5.175h9.746v-5.175zm14 0h-9.746v5.175h9.746v-5.175zM11.133 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 6.562V.867C0 .388.388 0 .867 0h10.266zm14 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H14.867A.867.867 0 0114 6.562V.867c0-.479.388-.867.867-.867h10.266zm-14.26 1.127H1.127v5.175h9.746V1.127zm14 0h-9.746v5.175h9.746V1.127z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
