/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit3': {
    width: 27,
    height: 28,
    viewBox: '0 0 27 28',
    data: '<path pid="0" d="M23.308 14.433a.625.625 0 01.883 0l2.5 2.5a.625.625 0 010 .883l-2.497 2.498-.006.006-7.497 7.496a.62.62 0 01-.441.184h-2.5a.625.625 0 01-.625-.625v-2.5a.63.63 0 01.183-.442zM21.25.5c1.034 0 1.875.841 1.875 1.875V13a.625.625 0 01-1.25 0V2.375a.625.625 0 00-.625-.625H2.5a.625.625 0 00-.625.625v23.75c0 .345.28.625.625.625h8.75a.625.625 0 010 1.25H2.5a1.877 1.877 0 01-1.875-1.875V2.375C.625 1.341 1.466.5 2.5.5zm0 17.759l-6.875 6.875v1.616h1.616l6.875-6.875-1.616-1.616zm-9.966 2.116c.12 0 .216.28.216.625s-.097.625-.216.625H4.716c-.12 0-.216-.28-.216-.625s.097-.625.216-.625h6.568zm12.466-4.616l-1.616 1.616 1.616 1.616 1.616-1.616-1.616-1.616zm-9.559 1.116c.17 0 .309.28.309.625s-.138.625-.309.625H4.81c-.17 0-.309-.28-.309-.625s.138-.625.309-.625h9.382zm2.908-3c.221 0 .401.28.401.625s-.18.625-.401.625H4.9c-.221 0-.401-.28-.401-.625s.18-.625.401-.625H17.1zm1.938-3c.256 0 .463.28.463.625s-.207.625-.463.625H4.963c-.256 0-.463-.28-.463-.625s.207-.625.463-.625h14.074zm0-3c.256 0 .463.28.463.625s-.207.625-.463.625H4.963c-.256 0-.463-.28-.463-.625s.207-.625.463-.625h14.074zm0-3.375c.256 0 .463.28.463.625s-.207.625-.463.625H4.963c-.256 0-.463-.28-.463-.625s.207-.625.463-.625h14.074z" _fill="#000" fill-rule="nonzero"/>'
  }
})
