/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'holidays': {
    width: 36,
    height: 30,
    viewBox: '0 0 36 30',
    data: '<path pid="0" d="M23.128 0c1.274 0 2.323.982 2.43 2.228l.009.21v1.995h7.906c1.199 0 2.198.87 2.4 2.012l.03.217.008.21v20.69a2.442 2.442 0 01-2.228 2.429l-.21.009H2.438A2.442 2.442 0 01.01 27.772L0 27.562V6.872c0-1.274.982-2.323 2.228-2.43l.21-.009h7.907V2.438c0-1.198.87-2.198 2.011-2.4l.217-.029.21-.009h10.345zM7.39 6.355H2.44a.519.519 0 00-.507.413l-.01.104v20.69c0 .249.177.458.413.506l.103.01H7.39V6.356zm2.956 0H9.31v21.724h1.035V6.355zm13.3 0H12.266v21.724h11.38V6.355zm2.956 0h-1.034v21.724H26.6V6.355zm6.872 0h-4.95v21.724h4.95a.519.519 0 00.507-.413l.01-.104V6.872a.519.519 0 00-.413-.507l-.104-.01zM23.128 1.92H12.783a.519.519 0 00-.506.413l-.011.104v1.995h11.38V2.438a.519.519 0 00-.414-.506l-.104-.01z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
