/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-10': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M20.625 0a3.13 3.13 0 013.125 3.125v2.326l3.661 6.103a.616.616 0 01.089.321 3.131 3.131 0 01-2.5 3.062V26.25h.625a3.13 3.13 0 013.125 3.125c0 .345-.28.625-.625.625H.625A.625.625 0 010 29.375a3.13 3.13 0 013.125-3.125h.625V14.937a3.131 3.131 0 01-2.5-3.062c0-.114.03-.225.089-.321L5 5.45V3.125A3.13 3.13 0 018.125 0h12.5zm4.999 27.5H3.125a1.88 1.88 0 00-1.769 1.25h26.037a1.88 1.88 0 00-1.77-1.25zm-3.76-13.768A3.06 3.06 0 0119.374 15a3.123 3.123 0 01-2.5-1.253 3.119 3.119 0 01-5 0 3.119 3.119 0 01-4.992.01A3.167 3.167 0 015 14.938V26.25h10v-9.375c0-.345.28-.625.625-.625h6.25c.345 0 .625.28.625.625v9.375h1.25V14.937a3.132 3.132 0 01-1.887-1.205zM21.25 17.5h-5v8.75h5V17.5zm-8.125-1.25c.345 0 .625.28.625.625v6.25c0 .345-.28.625-.625.625h-6.25a.625.625 0 01-.625-.625v-6.25c0-.345.28-.625.625-.625h6.25zM12.5 17.5h-5v5h5v-5zM7.364 6.25H5.979l-3.473 5.786a1.878 1.878 0 001.869 1.714 1.877 1.877 0 001.875-1.875L7.364 6.25zm8.886 0H12.5v5.625c0 1.034.841 1.875 1.875 1.875a1.877 1.877 0 001.875-1.875V6.25zm6.521 0h-1.383l1.1 5.502c.012 1.157.853 1.998 1.887 1.998.98 0 1.786-.755 1.869-1.714L22.77 6.25zm-2.657 0H17.5v5.625c0 1.034.841 1.875 1.875 1.875a1.877 1.877 0 001.875-1.875L20.114 6.25zm-8.864 0H8.637l-1.15 5.748c.013.91.854 1.752 1.888 1.752a1.877 1.877 0 001.875-1.875V6.25zm9.375-5h-12.5A1.877 1.877 0 006.25 3.125V5H22.5V3.125a1.877 1.877 0 00-1.875-1.875z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
