import Vue from 'vue';
import Vuex from 'vuex';
import userModule from './user_module';
import navModule from './nav_module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userModule,
    navModule,
  },
});
