import state from './nav.state';
import actions from './nav.actions';
import getters from './nav.getters';
import mutations from './nav.mutations';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
