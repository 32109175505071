/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-8': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.954 4.15c-.009-.023-.024-.042-.035-.064a.688.688 0 00-.082-.124c-.02-.021-.04-.039-.062-.057a.598.598 0 00-.195-.114c-.016-.006-.03-.018-.047-.021L15.158.02a.64.64 0 00-.315 0L.47 3.77c-.019.006-.033.016-.049.022a.579.579 0 00-.08.036.562.562 0 00-.115.078c-.021.019-.043.036-.061.056a.593.593 0 00-.083.124c-.012.022-.026.041-.036.065A.612.612 0 000 4.376v24.999c0 .345.28.625.625.625h28.75c.344 0 .624-.28.624-.625v-25a.612.612 0 00-.045-.224zM15 1.27l9.504 2.48H5.494L15 1.27zM7.5 28.75H3.75V25H7.5v3.75zm-3.75-5v-2.5h2.5v2.5h-2.5zm8.75 5H8.75v-2.5h3.75v2.5zm16.25 0H20V25h8.75v3.75zm0-5H22.5v-2.5h6.25v2.5zm0-3.75h-6.876a.625.625 0 00-.625.625v3.125h-1.875a.625.625 0 00-.625.625v4.375h-5v-3.125a.625.625 0 00-.624-.625H8.75v-.625a.625.625 0 00-.625-.625H7.5v-3.125A.625.625 0 006.875 20h-3.75a.625.625 0 00-.625.625v8.125H1.25v-12.5h27.5V20zm0-5H1.25v-2.5h27.5V15zm0-3.75H1.25v-2.5h27.5v2.5zm0-3.75H1.25V5h27.5v2.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
