/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'emergencyHeat': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M15.2 26.1c.4 0 .8.4.8.8v2.6c0 .4-.4.8-.8.8s-.8-.4-.8-.8v-2.6c-.1-.5.3-.8.8-.8zm6.5-1.2l1.3 2.3c.2.4.1.9-.3 1.1-.1.1-.3.1-.4.1-.3 0-.6-.1-.7-.4l-1.3-2.3c-.2-.4-.1-.9.3-1.1.4-.2.9-.1 1.1.3zm-12-.3c.4.2.5.7.3 1.1L8.7 28c-.1.3-.4.4-.7.4-.1 0-.3 0-.4-.1-.4-.2-.5-.7-.3-1.1l1.3-2.3c.2-.4.7-.5 1.1-.3zM23.5 0C27.1 0 30 2.9 30 6.5c0 3.1-2.2 5.8-5.2 6.4.2.7.3 1.5.3 2.3 0 5.5-4.5 9.9-9.9 9.9-5.5 0-9.9-4.5-9.9-9.9 0-5.5 4.5-9.9 9.9-9.9.7 0 1.3.1 1.9.2.5-3.2 3.2-5.5 6.4-5.5zm-8.3 6.8c-4.6 0-8.3 3.7-8.3 8.3 0 4.6 3.7 8.3 8.3 8.3 4.6 0 8.3-3.7 8.3-8.3 0-.7-.1-1.5-.3-2.2-3.3-.1-5.9-2.7-6.2-6-.6 0-1.2-.1-1.8-.1zm10.5 13.5l2.3 1.3c.4.2.5.7.3 1.1-.1.3-.4.4-.7.4-.1 0-.3 0-.4-.1l-2.3-1.3c-.4-.2-.5-.7-.3-1.1.2-.4.7-.5 1.1-.3zm-20 .3c.2.4.1.9-.3 1.1L3.1 23c-.1.1-.3.1-.4.1-.3 0-.6-.1-.7-.4-.2-.4-.1-.9.3-1.1l2.3-1.3c.4-.2.9-.1 1.1.3zm-2.3-6.3c.4 0 .8.4.8.8s-.4.8-.8.8H.8c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h2.6zm26.1 0c.4 0 .8.4.8.8s-.4.8-.8.8h-2.6c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h2.6zm-6-12.9c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1zM3.1 7.3l2.3 1.3c.4.2.5.7.3 1.1-.1.3-.4.4-.7.4-.1 0-.3 0-.4-.1L2.3 8.7c-.4-.2-.5-.7-.3-1.1.3-.4.7-.5 1.1-.3zm22.6-4v1.1h-3v1.5h2.5v1h-2.5v1.7h3v1.1h-4.3V3.3h4.3zm-17-1L10 4.6c.2.4.1.9-.3 1.1-.1.1-.3.1-.4.1-.3 0-.6-.1-.7-.4L7.3 3.1c-.2-.4-.1-.9.3-1.1.4-.2.8-.1 1.1.3zM15.2 0c.4 0 .8.4.8.8v2.6c0 .4-.4.8-.8.8s-.8-.4-.8-.8V.8c-.1-.4.3-.8.8-.8z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
