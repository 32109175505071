/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-13': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M3.125 18.75h3.75c.345 0 .625-.28.625-.625v-2.5A.625.625 0 006.875 15h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zm.625-2.5h2.5v1.25h-2.5v-1.25zM3.125 25h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zm.625-2.5h2.5v1.25h-2.5V22.5zm-.625-10h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zM3.75 10h2.5v1.25h-2.5V10zm18.125 8.75h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zm.625-2.5H25v1.25h-2.5v-1.25zM21.875 25h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zm.625-2.5H25v1.25h-2.5V22.5zm5.625-16.25H20V.625A.625.625 0 0019.375 0h-10a.625.625 0 00-.625.625V6.25H.625A.625.625 0 000 6.875v20c0 .345.28.625.625.625H8.75v1.25h-.625a.625.625 0 000 1.25h12.5a.625.625 0 000-1.25H20V27.5h8.125c.345 0 .625-.28.625-.625v-20a.625.625 0 00-.625-.625zm-19.375 20h-7.5V7.5h7.5v18.75zm7.5 2.5H12.5v-5h3.75v5zm2.5 0H17.5v-5.625a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v5.625H10V1.25h8.75v27.5zm8.75-2.5H20V7.5h7.5v18.75zm-15.625-12.5h5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5h3.75v3.75H12.5V8.75zM13.125 5h.625v.625a.625.625 0 001.25 0V5h.625a.625.625 0 000-1.25H15v-.625a.625.625 0 00-1.25 0v.625h-.625a.625.625 0 000 1.25zm8.75 7.5h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625zM22.5 10H25v1.25h-2.5V10zM11.875 21.25h5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5h3.75V20H12.5v-3.75z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
