const state = {
  prev: [],
  pageAndFilter: {},
  user: {},
  device: {},
  installation: {},
  firmware: {},
};

export default state;
