<template>
  <b-container fluid class="m-0 p-0">
    <GoBack />
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-2 box">
        <b-row>
          <b-col class="mb-3">
            <b-card
              border-variant="gray5"
              bg-variant="gray5"
              text-variant="white"
              align="center"
              :title="$t('airtools.device')"
              title-tag="h3"
            >
              <b-card-header header-bg-variant="brand-a20">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem">
                      <span v-if="wsImage !== ''">
                        <img :src=wsImage width="150" height="100"/>
                      </span>
                      <span v-else>
                        <az-icon name="device" scale="2"></az-icon>
                      </span>
                    </b-avatar>
                  </transition>

                  <b-row class="h4 mt-1">
                    <b-col></b-col>
                    <b-col cols="" v-if="!wsType(device.ws_type).isAidoo">
                      <Badge
                        :pillable="'badge-pill'"
                        :bgBadge="wsType(device.ws_type).bgColor"
                        :textColor="wsType(device.ws_type).textColor"
                        :text="wsType(device.ws_type).text"
                      >
                      </Badge>
                    </b-col>
                    <b-col cols="" v-if="wsType(device.ws_type).isAidoo">
                      <Badge
                        :pillable="'badge-pill'"
                        :bgBadge="wsType(device.ws_type).bgColor"
                        :textColor="wsType(device.ws_type).textColor"
                        :text="aidooType(device.devices)"
                      >
                      </Badge>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                </b-card-title>

                <b-card-text>
                  <b-spinner v-show="isLoading"></b-spinner>
                  <b-container
                    class=""
                    v-show="!isLoading"
                    v-if="device.config !== undefined"
                  >
                    <b-row align-h="between">
                      <b-col class="text-left text-dark">{{
                        $t("mac")
                      }}</b-col>
                      <b-col class="text-right">{{ mac }}</b-col>
                    </b-row>
                    <b-row align-h="between">
                      <b-col class="text-left text-dark">{{
                        $t("pin")
                      }}</b-col>
                      <b-col class="text-right">{{
                        device.config.pin
                      }}</b-col>
                    </b-row>
                    <b-row align-h="between" v-if="device.config.ws_hw">
                      <b-col class="text-left text-dark">{{
                        $t("ws_hw")
                      }}</b-col>
                      <b-col class="text-right">{{
                        device.config.ws_hw.toUpperCase()
                      }}</b-col>
                    </b-row>
                    <b-row align-h="between">
                      <b-col class="text-left text-dark">{{
                        $t("system.firm_ws")
                      }}</b-col>
                      <b-col class="text-right">{{
                        device.config.ws_fw
                      }}
                        <span v-if="device.config.lmachine_fw">/{{
                          device.config.lmachine_fw
                        }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row align-h="between">
                      <b-col class="text-left text-dark">{{
                        $t("state")
                      }}</b-col>
                      <b-col
                        class="text-right"
                        v-if="device.status && !device.status.isConnected"
                      >
                        <Badge
                          :bgBadge="'danger'"
                          :textColor="'text-light'"
                          :pillable="'badge-pill'"
                          :text="$t('disconnected')"
                        >
                        </Badge>
                      </b-col>
                      <b-col
                        class="text-right"
                        v-if="device.status.isConnected"
                      >
                        <Badge
                          :bgBadge="'success-dark'"
                          :textColor="'text-light'"
                          :pillable="'badge-pill'"
                          :text="$t('connected')"
                        >
                        </Badge>
                      </b-col>
                      <b-col
                        class="text-right"
                        v-if="
                          !device.status.isConnected &&
                          device.status.disconnection_date
                        "
                      >
                        {{ device.status.disconnection_date | formatDate }}
                      </b-col>
                      <!--b-col
                        class="text-right"
                        v-if="
                          device.status.isConnected &&
                          device.status.connection_date
                        "
                      >
                        {{ device.status.connection_date | formatDate }}
                      </b-col-->
                    </b-row>
                    <b-row
                      align-h="between"
                      v-if="device.config.disable_first_ota"
                    >
                      <b-col class="text-left text-dark">{{
                        $t("disabledOta")
                      }}</b-col>
                      <b-col class="text-right">
                        <az-icon
                          name="error-check"
                          scale="1.5"
                          class="text-center text-danger"
                        ></az-icon>
                      </b-col>
                    </b-row>
                    <b-row align-h="between" v-if="device.config.stat_ssid">
                      <b-col class="text-left text-dark">{{
                        $t("wifi")
                      }}</b-col>
                      <b-col class="text-right">
                        {{ device.config.stat_ssid }}
                      </b-col>
                    </b-row>
                    <b-row align-h="between" v-if="device.config.ws_hw">
                      <b-col class="text-left text-dark">{{
                        $t("hardware")
                      }}</b-col>
                      <b-col class="text-right">
                        {{ wsTypeHardware(device.config.ws_hw) }}
                      </b-col>
                    </b-row>
                    <b-row
                      align-h="between"
                      v-if="device.config.stat_channel"
                    >
                      <b-col class="text-left text-dark">{{
                        $t("channel")
                      }}</b-col>
                      <b-col class="text-right">
                        {{ device.config.stat_channel }}
                      </b-col>
                    </b-row>
                    <b-row align-h="between" v-if="device.config.stat_ap_mac">
                      <b-col class="text-left text-dark">{{
                        $t("ap_mac")
                      }}</b-col>
                      <b-col class="text-right">
                        {{ device.config.stat_ap_mac }}
                      </b-col>
                    </b-row>
                    <b-row
                      align-h="between"
                      v-if="device.status.stat_quality"
                    >
                      <b-col class="text-left text-dark">{{
                        $t("connection")
                      }}</b-col>
                      <b-col class="text-right">
                        <SignalIcon
                          :type="'quality'"
                          :signal="device.status.stat_quality"
                        >
                        </SignalIcon>
                      </b-col>
                    </b-row>
                    <b-row align-h="between" v-if="device.status.stat_rssi">
                      <b-col class="text-left text-dark">{{
                        $t("currentSignal")
                      }}</b-col>
                      <b-col class="text-right">
                        <SignalIcon
                          :type="'rssi'"
                          :signal="device.status.stat_rssi"
                        >
                        </SignalIcon>
                      </b-col>
                    </b-row>
                  </b-container>
                  <b-row
                    class="mt-2"
                    v-if="device.status && !wsType(device.ws_type).isCentral"
                  >
                    <b-col v-if="wsType(device.ws_type).isAidoo && !isAdminVendor">
                      <DetailsButton
                        :disabled="!device.status.isConnected"
                        @click="
                          confirmBeforeAction(
                            $t('buttons.reset'),
                            $t('messages.reset_server_confirmation'),
                            'resetWS',
                            $t('buttons.accept'),
                            $t('buttons.cancel'),
                            'primary'
                          )
                        "
                        :text="$t('buttons.reset')"
                        :arrowRight="false"
                        :bgButton="'danger'"
                        :colorTextButton="'text-dark'"
                      >
                      </DetailsButton>
                    </b-col>
                    <b-col v-if="false">
                      <b-button
                        :disabled="!device.status.isConnected"
                        @click="
                          confirmBeforeAction(
                            $t('buttons.restore_factory'),
                            $t('messages.restore_server'),
                            'restoreWS',
                            $t('buttons.accept'),
                            $t('buttons.cancel'),
                            'primary'
                          )
                        "
                        h-align="center"
                        variant="danger-dark"
                      >
                        {{ $t("buttons.restore_factory") }}
                      </b-button>
                    </b-col>
                    <b-col v-if="!isAdminVendor()">
                      <DetailsButton
                        :disabled="!showUpdateButton()"
                        @click="showFirmwaresInfo"
                        :text="$t('buttons.update')"
                        :icon="'upgrade-icon'"
                        :scale="'.4'"
                        :arrowRight="false"
                        :colorTextButton="'text-dark'"
                      >
                      </DetailsButton>
                    </b-col>
                    <b-col v-if="!isAdminVendor()">
                      <DetailsButton
                        @click="
                          confirmBeforeAction(
                            $t('buttons.disable_ota'),
                            $t('messages.disable_ota_confirmation'),
                            'disableOTA',
                            $t('buttons.disable'),
                            $t('buttons.enable'),
                            'danger'
                          )
                        "
                        :text="$t('buttons.disable_ota')"
                        :arrowRight="false"
                        :bgButton="'warning'"
                        :colorTextButton="'text-dark'"
                      >
                      </DetailsButton>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-header>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              bg-variant="gray5"
              text-variant="white"
              :title="$t('state')"
              align="center"
              class=""
            >
              <b-card-text>
                <b-table
                  id="table-device"
                  responsive
                  thead-tr-class="d-none"
                  tbody-tr-class="statusRow"
                  :items="statusTable"
                  :fields="statusHead"
                >
                  <template #cell(stage)="data">
                    <div class="statusItemBox"></div>
                    <div class="statusItemBox"></div>
                    <div
                      class="mt-3 pt-1 text-center"
                      v-if="data.item.stage.type"
                    >
                      <az-icon name="group-7" scale="2"></az-icon>
                    </div>
                    <div
                      class="mt-4 pt-1 text-center"
                      v-if="!data.item.stage.type"
                    >
                      <az-icon name="unit-external" scale="2"></az-icon>
                    </div>
                    <div class="mt-2 text-center text-light">
                      {{ data.item.stage.name }}
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(stage0)="data">
                    <div class="statusItemBox"></div>
                    <div class="text-center">
                      <div
                        v-if="data.item.stage0.status === 0"
                        class="badge badge-success-dark"
                      >
                        {{ $t("sleeping") }}
                      </div>
                      <div
                        class="mt-3 py-2"
                        v-if="data.item.stage0.status === null"
                      ></div>
                    </div>
                    <div
                      class="py-3 text-center text-light"
                      v-if="data.item.stage0.date"
                      style="min-width: 8rem"
                    >
                      {{ data.item.stage0.date | formatDate }}
                    </div>
                    <div
                      class="mt-3 py-3"
                      v-if="!data.item.stage0.date"
                    ></div>
                    <div
                      class="statusItemBox text-center"
                      style="width: auto"
                    >
                      <hr />
                      <az-icon
                        name="check"
                        scale="2"
                        v-if="data.item.stage0.status === 0"
                        class="h3 text-center iconStatus text-success-dark"
                      ></az-icon>
                      <az-icon
                        name="error-check"
                        scale="2"
                        v-if="data.item.stage0.status === 1"
                        class="h3 text-center iconStatus text-danger"
                      ></az-icon>
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(webserver)="">
                    <div class="statusItemBox"></div>
                    <div class="statusItemBox"></div>
                    <div class="mt-4 pt-1 text-center">
                      <az-icon name="device" scale="2"></az-icon>
                    </div>
                    <div class="mt-3 text-center text-light">
                      {{ $t("airtools.device") }}
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(stage1)="data">
                    <div class="statusItemBox"></div>
                    <div class="text-center">
                      <div
                        v-if="data.item.stage1.status"
                        class="badge badge-success-dark"
                      >
                        {{ $t("first_connection") }}
                      </div>
                      <div
                        class="badge badge-danger"
                        v-if="!data.item.stage1.status"
                      >
                        {{ $t("airtools.notConnected") }}
                      </div>
                    </div>
                    <div
                      v-if="data.item.stage1.date"
                      class="py-3 text-center text-light"
                      style="min-width: 8rem"
                    >
                      {{ data.item.stage1.date | formatDate }}
                    </div>
                    <div
                      class="mt-4 py-3 statusItemBox"
                      v-if="!data.item.stage1.date"
                    ></div>
                    <div
                      class="statusItemBox text-center"
                      style="width: auto"
                    >
                      <hr />
                      <az-icon
                        name="check"
                        scale="2"
                        v-if="data.item.stage1.status"
                        class="h3 text-center iconStatus text-success-dark"
                      ></az-icon>
                      <az-icon
                        name="error-check"
                        scale="2"
                        v-if="!data.item.stage1.status"
                        class="h3 text-center iconStatus text-danger"
                      ></az-icon>
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(cloud)="">
                    <div class="statusItemBox"></div>
                    <div class="statusItemBox"></div>
                    <div class="mt-3 pt-1 text-center">
                      <az-icon name="cloud" scale="2"></az-icon>
                    </div>
                    <div class="mt-3 text-center text-light">
                      {{ $t("cloud") }}
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(stage2)="data">
                    <div class="statusItemBox"></div>
                    <div class="text-center">
                      <div
                        class="badge badge-success-dark"
                        v-if="data.item.stage2.type"
                      >
                        {{ $t("associated") }}
                      </div>
                      <div
                        class="badge badge-danger"
                        v-if="!data.item.stage2.type"
                      >
                        {{ $t("notAssociated") }}
                      </div>
                    </div>
                    <div
                      v-if="!data.item.stage2.date"
                      class="mt-4 py-3 statusItemBox"
                    ></div>
                    <div
                      v-if="data.item.stage2.date"
                      class="py-3 text-center text-light"
                      style="min-width: 8rem"
                    >
                      {{ data.item.stage2.date | formatDate }}
                    </div>
                    <div
                      class="statusItemBox text-center"
                      style="width: auto"
                    >
                      <hr />
                      <az-icon
                        name="check"
                        scale="2"
                        v-if="data.item.stage2.status"
                        class="h3 text-center iconStatus text-success-dark"
                      ></az-icon>
                      <az-icon
                        name="error-check"
                        scale="2"
                        v-if="!data.item.stage2.status"
                        class="h3 text-center iconStatus text-danger"
                      ></az-icon>
                    </div>
                    <div class="statusItemBox"></div>
                  </template>

                  <template #cell(user)="">
                    <div class="statusItemBox"></div>
                    <div class="statusItemBox"></div>
                    <div class="mt-3 text-center">
                      <az-icon name="user" scale="2"></az-icon>
                    </div>
                    <div class="mt-3 text-center text-light">
                      {{ $t("user.user") }}
                    </div>
                    <div class="statusItemBox"></div>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col class="mb-3">
            <b-card
              v-if="
                wsType(device.ws_type).isAirzone && device.devices.length > 0
              "
              bg-variant="gray5"
              text-variant="white"
              border-variant="gray5"
            >
              <b-card-title
                class="h3 title-expand"
                align="center"
                @click="showHideComponents"
                >{{ $t("components") }}</b-card-title
              >
              <b-card-group
                v-for="component in device.devices"
                :key="component.device_id"
                id="components"
                :class="{ showComponents: showComponents }"
              >
                <EmptyCard
                  :condition="
                    wsType(device.ws_type).isAidoo ||
                    device.devices.length === 0
                  "
                ></EmptyCard>

                <b-card
                  v-if="
                    wsType(device.ws_type).isAirzone &&
                    hasInfo(component)
                  "
                  :class="{
                    'item-card-group': clickInfo(component),
                    'item-no-cursor': !clickInfo(component),
                  }"
                  bg-variant="gray4"
                  text-variant="white"
                  v-b-toggle="`component-${component.device_id}`"
                >
                  <b-row v-if="component.config" class="">
                    <b-col>
                      <Badge
                        :pillable="'badge-pill'"
                        :bgBadge="deviceType(component.device_type).bgColor"
                        :text="$t(deviceType(component.device_type).text)"
                      >
                      </Badge>
                    </b-col>
                    <b-col>{{
                      componentName(component.device_type, component.config)
                    }}</b-col>
                    <b-col>{{ component.config.name }}</b-col>
                    <b-col>{{ master(component.config.master_conf) }}</b-col>
                  </b-row>
                  <b-collapse
                    :id="`component-${component.device_id}`"
                    v-if="clickInfo(component)"
                  >
                    <b-card-header>
                      <b-card-title
                        class="h2"
                        align="center"
                        v-show="component.config.setup_type"
                      >
                        {{ $t("system.setup_type") }}
                        {{ component.config.setup_type }}
                      </b-card-title>
                      <b-card-text>
                        <b-container class="">
                          <!-- Firmware central -->
                          <b-row
                            align-h="between"
                            v-show="component.config.system_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.system_fw")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.system_fw
                            }}</b-col>
                          </b-row>
                          <!-- Tipo de configuración CCP -->
                          <b-row
                            align-h="between"
                            v-show="component.config.installation_type_conf && installationTypeMap[component.config.installation_type_conf]"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.setup_type")
                            }}</b-col>
                            <b-col class="text-right">{{
                              installationTypeMap[component.config.installation_type_conf]
                            }}</b-col>
                          </b-row>
                          <!-- Firmware medidor de consumo -->
                          <b-row
                            align-h="between"
                            v-show="component.config.firmMC"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.firmMC")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.firmMC
                            }}</b-col>
                          </b-row>
                          <!-- Firmware deshumectador -->
                          <b-row
                            align-h="between"
                            v-show="component.config.dehumidifier_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.dehumidifier_fw")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.dehumidifier_fw
                            }}</b-col>
                          </b-row>
                          <!-- Firmware módulo elementos radiantes -->
                          <b-row
                            align-h="between"
                            v-show="component.config.rele_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.rele_fw")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.rele_fw
                            }}</b-col>
                          </b-row>
                          <!-- Firmware AirQ Sensor -->
                          <b-row
                            align-h="between"
                            v-show="component.config.aq_sensor_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.firm_aq_sensor")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.aq_sensor_fw
                            }}</b-col>
                          </b-row>
                          <!-- Bluefaces -->
                          <b-row
                            align-h="between"
                            v-show="component.config.bf_num"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.blueface_number")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.bf_num
                            }}</b-col>
                          </b-row>
                          <!-- Tacto C -->
                          <b-row
                            align-h="between"
                            v-show="component.config.tact_number"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.tact_number")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.tact_number
                            }}</b-col>
                          </b-row>
                          <!-- Tacto R -->
                          <b-row
                            align-h="between"
                            v-show="component.config.radio_number"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.radio_number")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.radio_number
                            }}</b-col>
                          </b-row>
                          <!-- Sondas -->
                          <b-row
                            align-h="between"
                            v-show="component.config.probe_number"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.probe_number")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.probe_number
                            }}</b-col>
                          </b-row>
                          <!-- Firmware termostato -->
                          <b-row
                            align-h="between"
                            v-show="component.config.thermostat_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("firm_term")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.thermostat_fw
                            }}</b-col>
                          </b-row>
                          <!-- Firmware módulo local -->
                          <b-row
                            align-h="between"
                            v-show="component.config.local_module_fw"
                          >
                            <b-col class="text-left text-dark">{{
                              $t("system.firmm")
                            }}</b-col>
                            <b-col class="text-right">{{
                              component.config.local_module_fw
                            }}</b-col>
                          </b-row>
                        </b-container>
                      </b-card-text>
                    </b-card-header>
                  </b-collapse>
                </b-card>
              </b-card-group>
            </b-card>
          </b-col>

          <b-col v-if="device.installation">
            <b-card
              bg-variant="gray5"
              text-variant="white"
              border-variant="gray5"
            >
              <b-card-title
                class="h3 title-expand"
                align="center"
                @click="showInstallations = !showInstallations"
                >{{ $t("installations.installations") }}</b-card-title
              >

              <b-card
                id="installations"
                :class="{ showInstallations: showInstallations }"
                bg-variant="brand-a20"
              >
                <b-card-title align="center" v-if="device.installation">
                  <b-avatar size="6rem" variant="secondary">
                    <az-icon name="groups" scale="2"></az-icon>
                  </b-avatar>
                  <b-row>
                    <b-col class="mt-2">
                      {{ device.installation.name }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col style="font-size: 1rem">
                      {{ `${device.installation.city} (${device.installation.country})` }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-3">
                      <DetailsButton
                        :cols="'4'"
                        :route="{
                          name: 'installation',
                          params: { installationId: device.installation._id },
                        }"
                        :icon="'rows'"
                      >
                      </DetailsButton>
                    </b-col>
                  </b-row>
                  <EmptyCard
                    :condition="!device.installation"
                    :bgColor="'brand-a20'"
                  ></EmptyCard>
                </b-card-title>
              </b-card>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12 mt-3">
            <b-card
              bg-variant="gray5"
              text-variant="white"
              border-variant="brand-a20"
              align="center"
              :title="$t('connection')"
              title-tag="h3"
            >
              <!--b-card no-body class="connection-box">
              <b-card-body>
                <div class="connection-title d-flex justify-content-center">
                  <div v-if="device.status.stat_quality" class="signal-icon">
                    <SignalIcon
                      :type="'quality'"
                      :signal="device.status.stat_quality"
                    >
                    </SignalIcon>
                  </div>
                  <div class="text-light">
                    {{ $t("connection") }}
                  </div>
                </div>
                <b-spinner v-show="isLoading"></b-spinner>
                <b-container
                  class=""
                  v-show="!isLoading"
                  v-if="device.config !== undefined"
                >
                  <b-row>
                    <b-col>
                      <row align-h="between" v-if="device.config.stat_ssid">
                        <b-col class="text-left text-dark">{{
                          $t("wifi")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_ssid }}
                        </b-col>
                      </row>
                      <row
                        align-h="between"
                        v-if="device.config.stat_channel"
                      >
                        <b-col class="text-left text-dark">{{
                          $t("channel")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_channel }}
                        </b-col>
                      </row>
                    </b-col>
                    <b-col>
                      <row align-h="between" v-if="device.config.stat_ap_mac">
                        <b-col class="text-left text-dark">{{
                          $t("ap_mac")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_ap_mac }}
                        </b-col>
                      </row-->
              <!--b-row align-h="between" v-if="device.status.stat_quality">
                        <b-col class="text-left text-dark">{{
                          $t("connection")
                        }}</b-col>
                        <b-col class="text-right">
                          <SignalIcon
                            :type="'quality'"
                            :signal="device.status.stat_quality"
                          >
                          </SignalIcon>
                        </b-col>
                      </b-row-->
              <!--row align-h="between" v-if="device.status.stat_rssi">
                        <b-col class="text-left text-dark">{{
                          $t("currentSignal")
                        }}</b-col>
                        <b-col class="text-right">
                          <SignalIcon
                            :type="'rssi'"
                            :signal="device.status.stat_rssi"
                          >
                          </SignalIcon>
                        </b-col>
                      </row>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card-body-->
              <b-tabs style="background-color: #ccc" fill lazy>
                <b-tab
                  @click="clickTab(1)"
                  id="lastHour"
                  :class="{ active: checkTab(1) }"
                  :title="$t('last_hour_calculated')"
                >
                  <div show class="bg-white">
                    <b-row class="pt-4">
                      <b-col sm="3">
                        <b-form>
                          <b-form-group
                            :label="`${$t('filters')}:`"
                            label-for="input-filterHour"
                            label-cols-sm="3"
                            label-align-sm="right"
                            class="mt-1 textFilterHour"
                          >
                            <b-form-select
                              v-model="filterHour"
                              :options="hourList"
                              @change="setChartDate(filterHour, 'hour')"
                              id="input-filterHour"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col sm="3">
                        <b-form>
                          <b-form-group
                            :label="`${$t('timeZone')}:`"
                            label-for="input-timeZoneHour"
                            label-cols-sm="3"
                            label-align-sm="right"
                            class="mt-1 textFilterHour"
                          >
                            <b-form-select
                              v-model="timeZone"
                              :options="timeZoneList"
                              id="input-timeZoneHour"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col sm="3" class="pt-3 text-left textFilterHour">{{ `${timeZone} (${offset})` }}</b-col>
                      <b-col sm="3"></b-col>
                    </b-row>
                    <BarChart
                      v-if="dataLoaded"
                      :mac="mac"
                      type="hour"
                      precision="minute"
                      formatDate="%H:%M"
                      :timeZone="timeZone"
                      :startDate="startDate"
                      :finishDate="finishDate"
                    >
                    </BarChart>
                    <b-spinner v-show="!dataLoaded"></b-spinner>
                  </div>
                </b-tab>

                <b-tab
                  @click="clickTab(2)"
                  id="lastDay"
                  :class="{ active: checkTab(2) }"
                  :title="$t('last_day_calculated')"
                >
                  <div show class="bg-white">
                    <b-row class="pt-4">
                      <b-col sm="3">
                        <b-form>
                          <b-form-group
                            :label="`${$t('filters')} :`"
                            label-for="input-filterDay"
                            label-cols-sm="3"
                            label-align-sm="right"
                            class="mt-1 text-black"
                          >
                            <b-form-select
                              v-model="filterDay"
                              :options="dayList"
                              @change="setChartDate(filterDay, 'day')"
                              id="input-filterDay"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col sm="3">
                        <b-form>
                          <b-form-group
                            :label="`${$t('timeZone')}:`"
                            label-for="input-timeZoneDay"
                            label-cols-sm="3"
                            label-align-sm="right"
                            class="mt-1 textFilterDay"
                          >
                            <b-form-select
                              v-model="timeZone"
                              :options="timeZoneList"
                              id="input-timeZoneDay"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col sm="3" class="pt-3 text-left textFilterHour">{{ `${timeZone} (${offset})`  }}</b-col>
                      <b-col sm="3"></b-col>
                    </b-row>
                    <BarChart
                      v-if="tab === 2"
                      :mac="mac"
                      type="day"
                      precision="minute"
                      formatDate="%H:%M"
                      :timeZone="timeZone"
                      :startDate="startDate"
                      :finishDate="finishDate"
                    >
                    </BarChart>
                    <b-spinner v-show="!dataLoaded"></b-spinner>
                  </div>
                </b-tab>

                <b-tab
                  @click="clickTab(3)"
                  id="lastWeek"
                  :class="{ active: checkTab(3) }"
                  :title="$t('last_week_calculated')"
                >
                  <div show class="bg-white">
                    <b-row class="pt-4">
                      <b-col sm="3">
                        <b-form>
                          <b-form-group
                            :label="`${$t('timeZone')}:`"
                            label-for="input-timeZoneWeek"
                            label-cols-sm="3"
                            label-align-sm="right"
                            class="mt-1 textFilterWeek"
                          >
                            <b-form-select
                              v-model="timeZone"
                              :options="timeZoneList"
                              id="input-timeZoneWeek"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col sm="3" class="pt-3 text-left textFilterHour">{{ `${timeZone} (${offset})`  }}</b-col>
                      <b-col sm="6"></b-col>
                    </b-row>
                    <BarChart
                      v-if="tab === 3"
                      :mac="mac"
                      type="week"
                      precision="hour"
                      formatDate="%d/%m/%Y %H:%M"
                      :timeZone="timeZone"
                      :startDate="startDate"
                      :finishDate="finishDate"
                    >
                    </BarChart>
                    <b-spinner v-show="!dataLoaded"></b-spinner>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-jumbotron>
    </transition>
  </b-container>
</template>

<script>
import WebService from "@/services/web.service";
import GoBack from "@/components/GoBack";
import BarChart from "@/components/BarChart";
import DetailsButton from "@/components/DetailsButton";
import Badge from "@/components/Badge";
import EmptyCard from "@/components/EmptyCard";
import SignalIcon from "@/components/Signal";
import deviceType from "@/mixins/DeviceType.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import Role from '@/mixins/Role.mixin'
import moment from "moment";
import i18n from "@/services/language.service";
import mapTranslations from '@/utils/mapTranslations';


export default {
  name: "Device",
  props: {},
  mixins: [rowClass, deviceType, wsType, showMsgOk, Role],
  components: {
    GoBack,
    BarChart,
    DetailsButton,
    Badge,
    EmptyCard,
    SignalIcon,
  },
  data() {
    return {
      mac: this.$route.params.mac,
      isLoading: true,
      device: {},
      dataLoaded: false,
      wsImage: '',
      timeZone: moment.tz.guess(),
      timeZoneList: [],
      startDate: null,
      finishDate: null,
      showComponents: false,
      showInstallations: false,
      installationTypeMap: mapTranslations.INSTALLATION_TYPE_CCP,
      filterHour: 0,
      filterDay: 0,
      dayList: [],
      tab: 1,
      table: {
        stage: {
          type: {},
          status: {},
          date: {},
          name: {},
        },
        stage0: {
          type: {},
          status: {},
          date: {},
          name: {},
        },
        stage1: {
          type: {},
          status: {},
          date: {},
          name: {},
        },
        stage2: {
          type: {},
          status: {},
          date: {},
          name: {},
        },
      },
      statusHead: [
        {
          key: "stage",
          label: "",
        },
        {
          key: "stage0",
          label: "",
        },
        {
          key: "webserver",
          label: "",
        },
        {
          key: "stage1",
          label: "",
        },
        {
          key: "cloud",
          label: "",
        },
        {
          key: "stage2",
          label: "",
        },
        {
          key: "user",
          label: "",
        },
      ],
      statusTable: [],
    };
  },

  computed: {
    offset() {
      return moment().tz(this.timeZone).format('Z')
    },

    /**
     * Devuelve el listado de horas según zona horaria para las opciones de los select en el filtrado de los gráficos de conexiones por hora
     */
    hourList() {
      let hourList = [];
      hourList.push({ text: i18n.t("last_hour_calculated"), value: 0 });
      for (let i = 1; i <= 24; i++) {
        let date = moment().tz(this.timeZone);
        date.set({ minute: 0 });
        let hour = date.subtract({ hours: i });
        let hourFormatted = hour.format("HH:mm DD/MM");
        hourList.push({ text: hourFormatted, value: i });
      }
      return hourList
    },
  },

  methods: {
    /**
     * Notifica una alerta antes de realizar la acción
     *
     * @param {any} title - Título de la alerta
     * @param {any} message - Mensaje de la alerta
     * @param {String} action - Acción a ejecutar si está deacuerdo
     * @param {any} okTitle - Texto del botón aceptar
     * @param {any} cancelTitle - Texto del botón cancelar
     * @param {String} okBg - Color de fondo del botón aceptar
     */
     async confirmBeforeAction(
      title,
      message,
      action,
      okTitle,
      cancelTitle,
      okBg
    ) {
      this.$bvModal
        .msgBoxConfirm(message, {
          title: title,
          okVariant: okBg,
          okTitle: okTitle,
          cancelTitle: cancelTitle,
          headerBgVariant: "warning",
          headerClass: "p-2 border-bottom-1",
          footerClass: "p-2 border-top-0",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (action !== "disableOTA") {
            if (value) {
              this.execAction(action);
            }
          } else if (action === "disableOTA") {
            this.execAction(action, value);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvModal.show("bv-modal-error");
        });
    },

    /**
     * Devuelve true si tiene información y no es un dispositivo relé, pinza o salidas
     *
     * @param {Object} device - Device a comprobar su tipo de dispisitivo y si tiene información
     * @return {Boolean} - True si tiene información o false si no tiene información
     */
    hasInfo(device) {
      return (
        device.device_type !== "az_relay" &&
        device.device_type !== "az_energy_clamp" &&
        device.device_type !== "az_outputs" &&
        device.config
      );
    },

    /**
     * Devuelve true si no es un dispositivo ACS, relé, pinza o salidas
     *
     * @param {Object} device - Device a comprobar su tipo de dispisitivo
     * @return {Boolean} - True si no es un dispositivo ACS, relé, pinza o salidas o false en caso contrario
     */
     clickInfo(device) {
      return (
        device.device_type !== "az_acs" &&
        device.device_type !== "az_relay" &&
        device.device_type !== "az_energy_clamp" &&
        device.device_type !== "az_outputs"
      );
    },

    /**
     * Devuelve un booleano si el webserver está conectado y tiene ota
     *
     * @return {Boolean} - True si el webserver está conectado y tiene ota o false en caso contrario
     */
    showUpdateButton() {
      return this.device.status.isConnected && this.device.config.ota;
    },

    /**
     * Asigna la fecha de inicio y el tab activo
     *
     * @param {Number} value - Valor del tab clickado
     */
    clickTab(value) {
      let date;
      this.filterHour = 0;
      this.filterDay = 0;
      if (value === 1) {
        const date = this.setStartEndTime(0, "hour", false);
        this.startDate = date.startDate.toISOString();
        this.finishDate = date.finishDate.toISOString();
      } else if (value === 2) {
        const date = this.setStartEndTime(0, "day", false);
        if (this.timeZone !== moment.tz.guess()) {
          this.startDate = date.startDate // .toISOString();
          this.finishDate = date.finishDate // .toISOString();
        } else {
          this.startDate = date.startDate.toISOString();
          this.finishDate = date.finishDate.toISOString();
        }
      } else if (value === 3) {
        const now = moment();
        this.finishDate = now.toISOString();
        date = now
          .subtract({ days: 7 })
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        this.startDate = date.toISOString();
      }
      this.tab = value;
    },

    /**
     * Comprueba que el valor es igual que el tab actual
     *
     * @param {String} Valor - Valor del tab
     * @return {Boolean} - True si es el mismo valor que el tab actual o false si es distinto
     */
    checkTab(value) {
      return value === this.tab;
    },

    /**
     * Devuelve el texto traducido de maestro si tiene valor
     *
     * @param {String} value - Valor que indica si es maestro
     * @return {String} - Texto traducido de maestro si procede
     */
    master(value) {
      if (value) {
        return i18n.t("master");
      } else {
        return "";
      }
    },

    /**
     * Devuelve el texto traducido del sistema y zona al que pertenece ese componente
     *
     * @param {Object} type - Tipo de componente
     * @param {Object} value - Valor que indica el número de sistema y zona
     * @return {String} - Texto traducido del sistema y zona si procede
     */
    componentName(type, value) {
      const component = this.deviceType(type);
      let name = "";
      if (component.hasZone) {
        name =`${i18n.t(component.text)} ${value.zone_number ? value.zone_number : ''} - ${i18n.t("system.systems")} ${value.system_number ? value.system_number : ''}`
      } else if (component.hasSystem) {
        name = `${i18n.t(component.text)} ${value.system_number ? value.system_number : ''}`
      }

      return name;
    },

    /**
     * Obtiene la información del webserver, configuración de sus dispositivos, las versiones disponibles para actualizar y la instalación a la que pertenece
     */
    async getDevice() {
      try {
        this.isLoading = true;
        const ws = await WebService.getDevice(this.mac);
        this.isLoading = false;
        this.device = ws;
        // console.log(ws)
        this.getWsImage()
        const promises = [];
        let result = [];
        // iterate devices if it's connected to get their configuration
        ws.devices.forEach(async (item) => {
          const config = WebService.getDeviceConfig(item.device_id);
          promises.push(config);
        });
        if (promises.length > 0) {
          result = await Promise.all(promises);
        }

        for (let i = 0; i < result.length; i++) {
          this.device.devices[i].config = result[i];
        }

        // get installation of device
        const inst = await WebService.getDeviceInstallation(this.mac);
        if (inst._id !== "installationNotFound") {
          this.device.installation = inst;
          const resp = await WebService.getInstallationLocation(ws.meta.installation.location_id)
          this.device.installation.city = resp.text.city[this.language]
          this.device.installation.country = resp.text.country[this.language]
          this.timeZoneList.push(
            {
              text: 'Webserver',
              value: resp.timezoneId
            }
          )
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    },

    /**
     * Construye los datos del estado del webserver en un objeto que se representará en la tabla de bootstrap
     */
    buildStatus() {
      this.table.stage.type = this.wsType(this.device.ws_type).isAidoo
        ? false
        : true;
      this.table.stage.name = this.wsType(this.device.ws_type).isAidoo
        ? i18n.t("ac")
        : i18n.t("factory");
      this.table.stage0.status = this.readyStatus();
      this.table.stage0.date = this.sleepingDate();
      this.table.stage0.type =
        this.device.status &&
        this.device.status.machineNotReady &&
        this.wsType(this.device.ws_type).isAidoo
          ? false
          : true;
      this.table.stage0.name = i18n.t("airtools.device");
      this.table.stage1.status = this.device.status.isConnected ? true : false;
      this.table.stage1.date = this.connectionStatus();
      this.table.stage1.type =
        this.device.status && this.device.status.isConnected ? true : false;
      this.table.stage1.name = i18n.t("cloud");
      this.table.stage2.status = this.device.installation;
      this.table.stage2.date = this.associateDate();
      this.table.stage2.type = this.device.installation ? true : false;
      this.table.stage2.name = i18n.t("user.user");
      this.statusTable.push(this.table);
    },

    /**
     * Devuelve la fecha de asociación el webserver con el usuario si existe
     * @return {String} - Fecha de webserver asociado con el usuario
     */
    associateDate() {
      if (this.device.installation && this.device.installation.created_at) {
        return this.device.installation.created_at;
      }
    },

    /**
     * Devuelve la fecha de aprovisionamiento el webserver Airzone
     * @return {String} - Fecha de aprovisionamiento del webserver Airzone
     */
    sleepingDate() {
      if (
        this.wsType(this.device.ws_type).isAirzone &&
        this.device.provisioned_at
      ) {
        return this.device.provisioned_at;
      }
    },

    /**
     * Devuelve la fecha de conexión o desconexión el webserver
     * @return {String} - Fecha de conexión o desconexión del webserver
     */
    connectionStatus() {
      if (this.device.status && this.device.status.isConnected) {
        return this.device.status.first_connection_date;
      } else if (this.device.status && this.device.status.disconnection_date) {
        return this.device.status.disconnection_date;
      }
    },

    /**
     * Devuelve el estado del webserver Aidoo con la unidad interior o si es Airzone que está aprovisionado
     * @return {Integer} - 0 -> Airzone aprovisionado, 1 - Aidoo no conectado a la unidad interior, 2 - Aidoo conectado a la unidad interior
     */
    readyStatus() {
      if (
        this.wsType(this.device.ws_type).isAirzone ||
        this.wsType(this.device.ws_type).isCentral
      ) {
        return 0; // sleeping Airzone
      } else if (this.wsType(this.device.ws_type).isAidoo) {
        return null; // Aidoo has't sleeping
      }
    },

    /**
     * Inicia el cargando hasta asignar la fecha fecha inicio y fin del gráfico de conexiones
     * @params {Integer} from - Número de días a restar para realizar consulta
     * @params {String} type - Tipo de consulta: "hour", "day", "week"
     */
    setChartDate(from, type) {
      this.dataLoaded = false;
      let date = this.setStartEndTime(from, type, true);
      this.startDate = date.startDate.toISOString();
      this.finishDate = date.finishDate.toISOString();
      this.dataLoaded = true;
    },

    /**
     * Asigna fecha de inicio y fin para realizar la consulta del gráfico de conexiones
     * @params {Integer} count - Número de días a restar para realizar consulta
     * @params {String} type - Tipo de consulta: "hour", "day", "week"
     * @return {Object} - Objecto con fecha inicio y fecha fin asignadas
     */
    setStartEndTime(count, type) {
      let finishDate = new Date();

      if (type === "hour") {
        finishDate.setHours(finishDate.getHours() - (count - 1));
      } else if (type === "day") {
        finishDate.setDate(finishDate.getDate() - (count - 1));
      }

      let startDate = new Date();
      if (type === "hour") {
        startDate.setHours(startDate.getHours() - count);
      } else {
        startDate.setDate(startDate.getDate() - count);
      }

      // Initialize the time or day
      startDate.setMinutes(0);
      finishDate.setMinutes(0);
      if (type === "hour") {
        finishDate.setMinutes(finishDate.getMinutes() + 5)
      }
      startDate.setSeconds(0);
      finishDate.setSeconds(0);
      startDate.setMilliseconds(0);
      finishDate.setMilliseconds(0);
      if (type === "day") {
        startDate.setHours(0);
        finishDate.setHours(0);
      }

      return { startDate, finishDate };
    },

    /**
     * Initializa el listado de fechas para las opciones de los select en el filtrado de los gráficos de conexiones
     */
    initDateList() {
      this.dayList = [];
      this.dayList.push({ text: i18n.t("last_day_calculated"), value: 0 });
      for (let j = 1; j <= 14; j++) {
        let day = new Date();
        day.setDate(day.getDate() - j);
        this.dayList.push({ text: day.toLocaleDateString(), value: j });
      }
    },

    /**
     * Muestra y oculta los componentes del webserver con animación
     */
    showHideComponents() {
      this.showComponents = !this.showComponents;
      const elements = document.getElementsByClassName("card-group");

      for (let i = 0; i <= elements.length; i++) {
        if (this.showComponents && elements[i]) {
          elements[i].style.opacity = 1;
          elements[i].style["transition-delay"] = i * 0.1 + "s";
        } else if (elements[i]){
          elements[i].style.opacity = 0;
          elements[i].style["transition-delay"] = 0 + "s";
        }
      }
    },

    /**
     * Muestra y oculta los componentes del webserver con animación
     */
    async showFirmwaresInfo() {
      if (this.isUpdateRole()) {
        this.$router.push({ name: "deviceFirmwares", params: { mac: this.mac, device: this.device } }).catch(() => {})
      } else {
        await this.updateServerInfo()
      }
    },
  },

  async mounted() {
    this.initDateList();
    await this.getDevice();
    this.buildStatus();
    this.timeZoneList.push({
      text: i18n.t('local'),
      value: this.timeZone
    })
    const date = this.setStartEndTime(0, "hour", false);
    this.startDate = date.startDate.toISOString();
    this.finishDate = date.finishDate.toISOString();
    this.dataLoaded = true;
  },

  
};
</script>

<style lang="scss" scoped>
.statusItemBox {
  width: 2rem;
  height: 2rem;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.box {
  min-height: 52rem;
}
.iconStatus {
  position: relative;
  top: -2rem;
  background: var(--brand-dark);
}

.title-expand {
  cursor: pointer;
}

.title-expand:hover {
  color: hsl(211, 10%, 53%);
}

.item-card-group:hover {
  border: 1px solid hsl(207, 15%, 86%);
}

.item-no-cursor {
  cursor: default;
}

#components.showComponents,
#installations.showInstallations {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-out;
  height: 100%;
  opacity: 1;
}

#components,
#installations {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.pre-formatted {
  white-space: pre-line;
}

.updating {
  position: relative;
  top: -15rem;
}

.textFilterHour {
  color: #495057 !important
}

#input-filterHour {
  font-size: 1rem
}

/* .connection-box {
  background: var(--gray5);
  // border-color: var(--color-gray5);

  .connection-title {
    font-size: 1.75rem;
    text-align: center;

    .signal-icon {
      position: relative;
      right: 1rem;

      .signalBox {
        position: relative;
        top: -0.3rem;
        svg.signal3 {
          color: red !important;
        }
        .signal0,
        .signal1,
        .signal2 .signal3 {
          position: absolute !important;
          top: 0 !important;
          right: 0 !important;
        }
      }
    }
  }
}*/

@keyframes animatedShadow {
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes bounce {
  0% {
    transform: scaleY(1) translateY(0);
  }

  80% {
    transform: scaleY(1) translateY(40px);
  }

  100% {
    transform: scaleY(0.85) translateY(60px);
  }
}

.gear {
  position: relative;
  top: -60px;
  animation: bounce 0.5s alternate infinite ease-in;
  transform-origin: 0 100% 0;
  fill: var(--brand);

  &-pause {
    animation-play-state: paused;
  }
}

.customShadow {
  width: 70px;
  height: 1px;
  background: #555;
  box-shadow: 0 0 3px 3px #555;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 35px);
  opacity: 0.2;
  transform: scale(0.5);
  animation: animatedShadow 0.5s alternate infinite ease-in;

  &-pause {
    animation-play-state: paused;
  }
}
</style> 