/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 32,
    height: 31,
    viewBox: '0 0 32 31',
    data: '<path pid="0" d="M18.27 8.414c.533.52.793 1.219.714 1.92l-1.19 10.504c-.046.414-.43.72-.88.72h-1.4c-.449 0-.832-.306-.88-.72l-1.19-10.504c-.08-.701.18-1.4.713-1.92 1.056-1.03 3.058-1.03 4.113 0zm-1.045 1.756a.787.787 0 00-.248-.662c-.389-.378-1.132-.378-1.52 0a.78.78 0 00-.25.661l1.008 8.91 1.01-8.91zm13.841 17.833c-.925 1.376-2.558 2.197-4.363 2.197H5.728c-1.806 0-3.439-.82-4.366-2.197-.925-1.377-.965-3.042-.114-4.438L11.732 2.587C12.659 1.08 14.322.2 16.214.2c1.883 0 3.551.874 4.466 2.354L31.19 23.58c.847 1.373.803 3.047-.124 4.423zm-1.51-.836c.596-.887.618-1.949.05-2.868L19.103 3.28c-.565-.911-1.658-1.475-2.888-1.475-1.237 0-2.326.57-2.905 1.506L2.846 24.256c-.016.03-.016.03-.034.057-.559.904-.537 1.967.06 2.854.6.894 1.667 1.43 2.854 1.43h20.975c1.188 0 2.254-.536 2.855-1.43zm-13.342-4.745c1.255 0 2.284.908 2.284 2.037s-1.029 2.037-2.284 2.037c-1.254 0-2.284-.908-2.284-2.037s1.03-2.037 2.284-2.037zm0 1.235c-.483 0-.884.355-.884.802 0 .448.401.803.884.803.484 0 .885-.355.885-.803 0-.447-.401-.802-.885-.802z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
