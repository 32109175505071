/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'palm': {
    width: 16,
    height: 25,
    viewBox: '0 0 16 25',
    data: '<path pid="0" d="M12.19 2.886h-.01a.496.496 0 00-.496.5c0 .276.23.5.505.5a.5.5 0 000-1zm.98-2h-10a2.503 2.503 0 00-2.5 2.5v19c0 1.378 1.121 2.5 2.5 2.5h10c1.377 0 2.5-1.122 2.5-2.5v-19c0-1.378-1.123-2.5-2.5-2.5zm1.5 21.5c0 .827-.674 1.5-1.5 1.5h-10c-.828 0-1.5-.673-1.5-1.5v-2.5h13v2.5zm0-3.5h-13v-13h13v13zm0-14h-13v-1.5c0-.827.672-1.5 1.5-1.5h10c.826 0 1.5.673 1.5 1.5v1.5zm-6.5 18a1 1 0 10-.001-2.001 1 1 0 000 2zm1-20h-2a.5.5 0 000 1h2a.5.5 0 000-1z"/>'
  }
})
