<template>
  <div class="signalBox">
    <az-icon class="signal" name="signal" scale="1"></az-icon>
    <az-icon
      class="signal0"
      v-if="icon === 0"
      name="signal1"
      scale="1"
    ></az-icon>
    <az-icon
      class="signal1"
      v-if="icon === 1"
      name="signal2"
      scale="1"
    ></az-icon>
    <az-icon
      class="signal2"
      v-if="icon === 2"
      name="signal3"
      scale="1"
    ></az-icon>
    <az-icon
      class="signal3"
      v-if="icon === 3"
      name="signal"
      scale="1"
    ></az-icon>
  </div>
</template>

<script>
import CONSTANTS from "@/constant";

export default {
  name: "Signal",
  components: {},
  props: {
    type: {
      type: String,
      require: true,
    },
    signal: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      icon: 0,
    };
  },
  methods: {
    /**
     * Devuelve el icono de la calidad de la conexión wifi
     */
    qualityIcon() {
      if (!this.signal) {
        return 1;
      } else if (this.signal >= 3) {
        return 3;
      } else if (this.signal < 3) {
        return 2;
      }
    },

    /**
     * Devuelve el icono del nivel de señal
     */
    signalIcon() {
      let icon = 0;

      if (this.signal >= CONSTANTS.COVERAGE.HIGH) {
        icon = 3;
      } else if (
        this.signal >= CONSTANTS.COVERAGE.MEDIUM &&
        this.signal < CONSTANTS.COVERAGE.HIGH
      ) {
        icon = 2;
      } else if (
        this.signal >= CONSTANTS.COVERAGE.LOW &&
        this.signal < CONSTANTS.COVERAGE.MEDIUM
      ) {
        icon = 1;
      }

      return icon;
    },
  },
  mounted() {
    if (this.type === "quality") {
      this.icon = this.qualityIcon();
    } else {
      this.icon = this.signalIcon();
    }
  },
};
</script>

<style scoped>
.signal {
  /* background-color: var(--gray7); */
  border-radius: 3px;
  fill: var(--gray8);
}

.signal0 {
  position: absolute;
  top: 1.1rem;
  right: 1.6rem;
  fill: var(--danger-dark);
}

.signal1 {
  position: absolute;
  top: 0.75rem;
  right: 1.3rem;
  fill: var(--danger-dark);
}

.signal2 {
  position: absolute;
  top: 0.5rem;
  right: 1.13rem;
  fill: var(--warning-dark);
}

.signal3 {
  position: absolute;
  top: 0.25rem;
  right: 0.94rem;
  fill: var(--success-darker);
}
</style> 