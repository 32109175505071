/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'partly-cloudy-night': {
    width: 53,
    height: 39,
    viewBox: '0 0 53 39',
    data: '<defs><linearGradient x1="50%" y1="1.9%" x2="50%" y2="99.353%" id="svgicon_partly-cloudy-night_a"><stop stop-color="#73CFF9" offset="0%"/><stop stop-color="#57A8F2" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><path pid="0" d="M42.873 0C48.343.964 52.5 5.74 52.5 11.489c0 6.443-5.223 11.666-11.667 11.666a11.664 11.664 0 01-10.206-6.01c.663.116 1.344.177 2.04.177 6.443 0 11.666-5.223 11.666-11.667 0-1.982-.494-3.85-1.367-5.484z" _fill="url(#svgicon_partly-cloudy-night_a)"/><path pid="1" d="M25.667 38.322V27.204l6.116.001c.315-5.512 4.828-9.883 10.35-9.883 5.725 0 10.367 4.701 10.367 10.5 0 5.592-4.315 10.162-9.757 10.482v.018H25.666z" _fill="#7484A6"/><path pid="2" d="M9.6 38.322v-.017C4.247 37.99 0 33.508 0 28.025c0-5.688 4.567-10.299 10.2-10.299.45 0 .891.03 1.325.086 1.998-6.371 7.902-10.99 14.875-10.99 8.616 0 15.6 7.052 15.6 15.75 0 8.574-6.786 15.548-15.232 15.746l-.368.004H9.6z" _fill="#A2B0CA"/></g>'
  }
})
