/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down': {
    width: 15,
    height: 9,
    viewBox: '0 0 15 9',
    data: '<path pid="0" d="M2.046.365l5.431 5.66 5.43-5.657c.191-.199.4-.287.62-.325.097-.017.18-.02.251-.02.072 0 .154.003.25.02.221.038.43.126.62.322.47.487.47 1.265 0 1.752L8.346 8.635a1.164 1.164 0 01-1.694 0l-6.3-6.518a1.263 1.263 0 010-1.752 1.164 1.164 0 011.693 0z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
