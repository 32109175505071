/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedules-bold': {
    width: 20,
    height: 23,
    viewBox: '0 0 20 23',
    data: '<path pid="0" d="M14.018 15.895a.9.9 0 11-1.273 1.273l-3.382-3.403a.9.9 0 01-.513-.812V6.9a.9.9 0 111.8 0v5.627l3.368 3.368zm-4.04 7.063C4.469 22.958 0 18.49 0 12.979 0 7.468 4.468 3 9.979 3c5.511 0 9.979 4.468 9.979 9.979 0 5.511-4.468 9.979-9.98 9.979zm0-1.8a8.179 8.179 0 100-16.358 8.179 8.179 0 000 16.358zM8.049.498a.9.9 0 01-.403 1.207L2.803 4.126a.9.9 0 11-.805-1.61L6.84.096a.9.9 0 011.207.402zm9.28 2.018a.9.9 0 01-.804 1.61l-4.842-2.42a.9.9 0 01.805-1.61l4.842 2.42z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
