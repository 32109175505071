/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'partly-cloudy-day': {
    width: 68,
    height: 52,
    viewBox: '0 0 68 52',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M11.041 32.559a1.8 1.8 0 010 2.545L9.044 37.1a1.801 1.801 0 01-2.546-2.547l1.996-1.996a1.803 1.803 0 012.547.001zM22 8c4.157 0 7.891 1.812 10.455 4.689-6.787 1.98-11.857 8.015-12.391 15.336a10.782 10.782 0 00-7.745 4.088A13.96 13.96 0 018 22c0-7.732 6.268-14 14-14zM4.8 20a1.8 1.8 0 010 3.6h-3a1.8 1.8 0 010-3.6h3zM37.102 6.497a1.8 1.8 0 010 2.545l-1.997 1.998a1.802 1.802 0 01-2.546-.001 1.802 1.802 0 010-2.546l1.997-1.995a1.799 1.799 0 012.546 0zm-28.058 0l1.997 1.997a1.802 1.802 0 01-.001 2.546 1.802 1.802 0 01-2.545 0L6.498 9.042a1.8 1.8 0 012.546-2.545zM21.8 0a1.8 1.8 0 011.8 1.8v3a1.8 1.8 0 01-3.6 0v-3A1.8 1.8 0 0121.8 0z" _fill="#F1D500"/><path pid="1" d="M39.3 14c6.83 0 12.922 3.995 15.72 10.07 7.077.62 12.58 6.55 12.58 13.73 0 7.622-6.178 13.8-13.8 13.8h-31c-5.965 0-10.8-4.835-10.8-10.8 0-5.717 4.442-10.397 10.064-10.775C22.719 21.045 30.199 14 39.3 14zm0 3.6c-7.566 0-13.7 6.134-13.7 13.7 0 .524.04 1.067.116 1.697l.145 1.185-1.15-.317A7.184 7.184 0 0022.8 33.6a7.2 7.2 0 00-.121 14.399h31.265C59.511 47.922 64 43.385 64 37.8c0-5.633-4.567-10.2-10.2-10.2-.13 0-.24.006-.496.023l-.04.003-.121.008-.508.033c-3.552.41-6.622 2.673-8.104 5.96l-.339.753-.253-.124a1.787 1.787 0 01-1.842.157 1.799 1.799 0 01-.82-2.404.927.927 0 01.094-.156 13.798 13.798 0 019.687-7.578A13.696 13.696 0 0039.3 17.6z" _fill="#52606D"/></g>'
  }
})
