/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-8': {
    width: 26,
    height: 24,
    viewBox: '0 0 26 24',
    data: '<path pid="0" d="M20.914 17.143a.536.536 0 000-1.072h-.01c-.296 0-.53.24-.53.536a.54.54 0 00.54.536zm-8.057.536a1.072 1.072 0 100-2.144 1.072 1.072 0 000 2.144zM23.036 0H2.679A2.682 2.682 0 000 2.679v13.928a2.682 2.682 0 002.679 2.679h7.485C10.086 21.41 9.556 22.5 8.57 22.5a.536.536 0 000 1.071h8.572a.535.535 0 10-.001-1.071c-.985 0-1.515-1.09-1.592-3.214h7.486a2.682 2.682 0 002.678-2.679V2.68A2.682 2.682 0 0023.036 0zM10.495 22.5c.445-.712.683-1.799.734-3.214h3.256c.05 1.415.288 2.502.734 3.214h-4.724zm14.148-5.893a1.61 1.61 0 01-1.607 1.607H2.679a1.61 1.61 0 01-1.608-1.607V15h23.572v1.607zm0-2.678H1.07V2.679A1.61 1.61 0 012.68 1.07h20.357a1.61 1.61 0 011.607 1.608v11.25z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
