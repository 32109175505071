/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleet': {
    width: 53,
    height: 48,
    viewBox: '0 0 53 48',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M25.667 31.5V20.382l6.116.001c.315-5.512 4.828-9.883 10.35-9.883C47.858 10.5 52.5 15.201 52.5 21c0 5.591-4.315 10.162-9.757 10.482v.018H25.666z" _fill="#233B51"/><path pid="1" d="M9.6 31.5v-.017C4.247 31.169 0 26.686 0 21.202c0-5.688 4.567-10.298 10.2-10.298.45 0 .891.03 1.325.086C13.523 4.619 19.427 0 26.4 0 35.016 0 42 7.052 42 15.75c0 8.574-6.786 15.548-15.232 15.746l-.368.004H9.6z" _fill="#4B5F71"/><circle pid="2" _fill="#A2B0CA" cx="15.75" cy="36.75" r="1.75"/><circle pid="3" _fill="#A2B0CA" cx="15.75" cy="46.083" r="1.75"/><circle pid="4" _fill="#A2B0CA" cx="26.25" cy="36.75" r="1.75"/><circle pid="5" _fill="#A2B0CA" cx="26.25" cy="46.083" r="1.75"/><circle pid="6" _fill="#A2B0CA" cx="36.75" cy="36.75" r="1.75"/><circle pid="7" _fill="#A2B0CA" cx="36.75" cy="46.083" r="1.75"/><circle pid="8" _fill="#A2B0CA" cx="32.083" cy="41.417" r="1.75"/><circle pid="9" _fill="#A2B0CA" cx="21.583" cy="41.417" r="1.75"/></g>'
  }
})
