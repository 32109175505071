/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'azcloud': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 13.085C0 16.296 2.59 18.9 5.783 18.9h6.77l4.022-11.046c.142-.371.369-1.112.369-1.283 0-.77-.644-.87-1.394-.986l-.253-.04v-.457h4.03L24.316 18.9C27.465 18.846 30 16.263 30 13.085a5.815 5.815 0 00-4-5.534C25.683 3.327 22.174 0 17.893 0a8.111 8.111 0 00-6.703 3.545 4.847 4.847 0 00-1.61-.274c-2.418 0-4.425 1.768-4.813 4.088A5.808 5.808 0 000 13.085zM22.075 18.9h-8.19l1.356-3.777h5.477l1.357 3.777zm-1.834-4.362l-2.42-6.542-2.279 6.542h4.699z" _fill="#000"/>'
  }
})
