/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M6 8.202l-3.303 3.303c-.66.66-1.541.66-2.202 0-.66-.661-.66-1.542 0-2.202L3.798 6 .495 2.697c-.66-.66-.66-1.541 0-2.202.661-.66 1.542-.66 2.202 0L6 3.798 9.303.495c.66-.66 1.541-.66 2.202 0 .66.661.66 1.542 0 2.202L8.202 6l3.303 3.303c.66.66.66 1.541 0 2.202-.661.66-1.542.66-2.202 0L6 8.202z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
