/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'desktop': {
    width: 25,
    height: 18,
    viewBox: '0 0 25 18',
    data: '<path pid="0" d="M23.727 13.886h-1.5v-11.5c0-.827-.673-1.5-1.5-1.5h-17c-.827 0-1.5.673-1.5 1.5v11.5h-1.5a.5.5 0 00-.5.5v1c0 1.378 1.122 2.5 2.5 2.5h19c1.378 0 2.5-1.122 2.5-2.5v-1a.5.5 0 00-.5-.5zm-20.5-11.5a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v11.5h-5a.495.495 0 00-.352.146l-.854.854H9.435l-.854-.854a.504.504 0 00-.354-.146h-5v-11.5zm20 13c0 .827-.673 1.5-1.5 1.5h-19c-.827 0-1.5-.673-1.5-1.5v-.5H8.02l.853.854a.504.504 0 00.354.146h6a.5.5 0 00.353-.146l.854-.854h6.793v.5z"/>'
  }
})
