<template>
  <b-button
    :class="pillable"
    class="detailsButton"
    :variant="bgButton"
    :disabled="disabled"
    @click="doAction"
  >
    <az-icon
      v-if="icon"
      :name="icon"
      :scale="scale"
      :class="colorIconButton"
    ></az-icon>
    <span v-if="text" class="ml-2" :class="colorTextButton">
      {{ text }}
    </span>
    <az-icon class="ml-2" v-if="arrowRight" name="right" scale="1"></az-icon>
  </b-button>
</template>

<script>
import store from "@/store/store";

export default {
  name: "DetailsButton",
  components: {},
  props: {
    route: {},
    
    pillable: String,

    disabled: {
      type: Boolean,
      default: false,
    },

    bgButton: {
      type: String,
      default: "primary",
    },

    colorTextButton: {
      type: String,
      default: "text-light",
    },

    colorIconButton: {
      type: String,
      default: "light",
    },

    scale: {
      type: String,
      default: ".7",
    },

    icon: String,
    text: String,

    arrowRight: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /**
     * Navega a la ruta especificada
     */
    doAction(event) {
      if (this.route) {
        let path = { name: this.route.name, params: this.route.params };
        // Salvo la ruta de navegación en VUEX, limpiando registros si existía previamente
        switch (this.route.name) {
          case "user":
            store.dispatch("navModule/deleteUser");
            store.dispatch("navModule/saveUser", path);
            break;
          case "device":
            store.dispatch("navModule/deleteDevice");
            store.dispatch("navModule/saveDevice", path);
            break;
          case "installation":
            store.dispatch("navModule/deleteInstallation");
            store.dispatch("navModule/saveInstallation", path);
            break;
        }
        this.$router.push(path).catch(() => {});
      } else {
        // console.log(event);
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark {
  fill: var(--dark);
}

.light {
  fill: var(--light);
}

.detailsButton {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

  :disabled {
    box-shadow: none;
  }

  svg {
    position: relative;
    top: -0.15rem;
  }
}
</style> 