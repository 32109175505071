import i18n from '@/services/language.service';
import {capitalize} from '@/utils/utils';

/**
 *  MAPA DE TRADUCCIONES
 *  Parámetros de configuración
 */

const mapTranslations = {

  AZ_INSTALLATION_TYPES: {
    zone: () => i18n.t('system_config.system_type_options.zone'),
    zone_fc:() => i18n.t('system_config.system_type_options.zone_fc'),
    two_tubes:() => i18n.t('system_config.system_type_options.two_tubes'),
    four_tubes:() => i18n.t('system_config.system_type_options.four_tubes'),
    radiante:() => i18n.t('system_config.system_type_options.radiante'),
    radiante_v:() => i18n.t('system_config.system_type_options.radiante_v'),
    vaf:() => i18n.t('system_config.system_type_options.vaf'),
    two_tubes_two_wires: () => i18n.t('system_config.system_type_options.two_tubes_two_wires'),
  },

  INSTALLATION_TYPE_CCP: {
    aerothermal: i18n.t('system.ccp_options.aerothermal'),
    pipes2: i18n.t('system.ccp_options.pipes2'),
    pipes4: i18n.t('system.ccp_options.pipes4'),
    radianT: i18n.t('system.ccp_options.radianT'),
  },

  MLI_MANUFACTURERS: {
    unknw: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_0'),
    fujGen1: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_1'),
    fujGen2: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_2'),
    daikin1: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_3'),
    daikin2: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_4'),
    lg: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_5'),
    tango: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_6'),
    mitsuelec: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_7'),
    toshiba: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_8'),
    gree: () => i18n.t('zones.adjusts.adjust_zone.manufacturer_9'),
  },

  PRICE_RATES_PERIOD: {
    1: () => i18n.t('price.rates.high.title'),
    2: () => i18n.t('price.rates.mid.title'),
    3: () => i18n.t('price.rates.low.title'),
  },

  PROTECTION_DEW_NAMES: {
    very_low: () => i18n.t('system_config.rocio_hist_very_low'),
    low: () => i18n.t('system_config.rocio_hist_low'),
    medium: () => i18n.t('system_config.rocio_hist_medium'),
    high: () => i18n.t('system_config.rocio_hist_high'),
    very_high: () => i18n.t('system_config.rocio_hist_very_high'),
  },

  AIDOO_GROUPS: {
    WSMM1: () => capitalize(i18n.t('installations.group') + ' 1'),
    WSMM2: () => capitalize(i18n.t('installations.group') + ' 2'),
    WSMM3: () => capitalize(i18n.t('installations.group') + ' 3'),
    WSMM4: () => capitalize(i18n.t('installations.group') + ' 4'),
    WSMM5: () => capitalize(i18n.t('installations.group') + ' 5'),
    WSMM6: () => capitalize(i18n.t('installations.group') + ' 6'),
    WSMM7: () => capitalize(i18n.t('installations.group') + ' 7'),
    WSMM8: () => capitalize(i18n.t('installations.group') + ' 8'),
    WSMM9: () => capitalize(i18n.t('installations.group') + ' 9'),
    WSMM10: () => capitalize(i18n.t('installations.group')) + (' 10'),
    WSMM12: () => capitalize(i18n.t('installations.group')) + (' 12'),
    WSMM13: () => capitalize(i18n.t('installations.group')) + (' 13'),
    WSMM14: () => capitalize(i18n.t('installations.group')) + (' 14'),
    LAIDOO: () => ' L-Aidoo',
    APFANCOIL: () => 'Aidoo Pro Fancoil',
    LAIDOO_ESP_C3_MINI: () => 'L-Aidoo Esp C3 mini'
  },

}


export default mapTranslations;
