/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 31,
    height: 30,
    viewBox: '0 0 31 30',
    data: '<path pid="0" d="M19.363 29.724L31 .711c.146-.436-.29-.87-.654-.653L1.25 11.663c-.218.073-.364.363-.291.58l.727 2.176c0 .146.146.29.291.29l10.693 3.555 3.565 10.662.032.055.009.108a.114.114 0 00.031.055c.073.072.146.145.219.072l2.182.726c.29.145.582 0 .655-.218z" _fill="#000" fill-rule="nonzero"/>'
  }
})
