/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hazy': {
    width: 47,
    height: 38,
    viewBox: '0 0 47 38',
    data: '<path pid="0" d="M24.953 33.851a1.741 1.741 0 010 3.482H7.544a1.741 1.741 0 010-3.482h17.41zm13.541 0a1.741 1.741 0 010 3.482h-6.77a1.741 1.741 0 010-3.482h6.77zm4.836-6.77a1.741 1.741 0 010 3.482H2.708a1.741 1.741 0 010-3.482H43.33zm.967-6.77a1.741 1.741 0 010 3.482H1.741a1.741 1.741 0 010-3.482h42.556zm-.967-6.77a1.741 1.741 0 010 3.481H2.708a1.741 1.741 0 010-3.481H43.33zm-2.902-6.77a1.741 1.741 0 010 3.481H5.61a1.741 1.741 0 010-3.482h34.818zM36.56 0a1.741 1.741 0 010 3.482H22.052a1.741 1.741 0 010-3.482H36.56zM15.282 0a1.741 1.741 0 010 3.482H9.478a1.741 1.741 0 010-3.482h5.804z" _fill="#8999B7" fill-rule="nonzero"/>'
  }
})
