<template>
  <b-overlay :show="updatingWS" rounded="sm">
    <b-container fluid class="m-0 p-0">
      <GoBack />
      <transition name="fade-in-transition" appear>
        <b-jumbotron class="pb-0 pt-2 box">
          <b-row v-if="device">
            <b-col class="mb-3">
              <b-card
                border-variant="gray5"
                bg-variant="gray5"
                text-variant="white"
                align="center"
                :title="$t('airtools.device')"
                title-tag="h3"
              >
                <b-card-header header-bg-variant="brand-a20">
                  <b-card-title align="center">
                    <transition name="zoom-in-center-transition" appear>
                      <b-avatar size="6rem">
                        <span v-if="wsImage !== ''">
                          <img :src=wsImage width="150" height="100"/>
                        </span>
                        <span v-else>
                          <az-icon name="device" scale="2"></az-icon>
                        </span>
                      </b-avatar>
                    </transition>

                    <b-row class="h4 mt-1">
                      <b-col></b-col>
                      <b-col cols="" v-if="!wsType(device.ws_type).isAidoo">
                        <Badge
                          :pillable="'badge-pill'"
                          :bgBadge="wsType(device.ws_type).bgColor"
                          :textColor="wsType(device.ws_type).textColor"
                          :text="wsType(device.ws_type).text"
                        >
                        </Badge>
                      </b-col>
                      <b-col cols="" v-if="wsType(device.ws_type).isAidoo">
                        <Badge
                          :pillable="'badge-pill'"
                          :bgBadge="wsType(device.ws_type).bgColor"
                          :textColor="wsType(device.ws_type).textColor"
                          :text="aidooType(device.devices)"
                        >
                        </Badge>
                      </b-col>
                      <b-col></b-col>
                    </b-row>
                  </b-card-title>

                  <b-card-text>
                    <b-spinner v-show="isLoading"></b-spinner>
                    <b-container
                      class=""
                      v-show="!isLoading"
                      v-if="device.config !== undefined"
                    >
                      <b-row align-h="between">
                        <b-col class="text-left text-dark">{{
                          $t("mac")
                        }}</b-col>
                        <b-col class="text-right">{{ mac }}</b-col>
                      </b-row>
                      <b-row align-h="between">
                        <b-col class="text-left text-dark">{{
                          $t("pin")
                        }}</b-col>
                        <b-col class="text-right">{{
                          device.config.pin
                        }}</b-col>
                      </b-row>
                      <b-row align-h="between" v-if="device.config.ws_hw">
                        <b-col class="text-left text-dark">{{
                          $t("ws_hw")
                        }}</b-col>
                        <b-col class="text-right">{{
                          device.config.ws_hw.toUpperCase()
                        }}</b-col>
                      </b-row>
                      <b-row align-h="between">
                        <b-col class="text-left text-dark">{{
                          $t("system.firm_ws")
                        }}</b-col>
                        <b-col class="text-right">{{
                          device.config.ws_fw
                        }}
                        <span v-if="device.config.lmachine_fw">/{{
                          device.config.lmachine_fw
                        }}
                        </span>
                        </b-col>
                      </b-row>
                      <b-row align-h="between">
                        <b-col class="text-left text-dark">{{
                          $t("state")
                        }}</b-col>
                        <b-col
                          class="text-right"
                          v-if="device.status && !device.status.isConnected"
                        >
                          <Badge
                            :bgBadge="'danger'"
                            :textColor="'text-light'"
                            :pillable="'badge-pill'"
                            :text="$t('disconnected')"
                          >
                          </Badge>
                        </b-col>
                        <b-col
                          class="text-right"
                          v-if="device.status.isConnected"
                        >
                          <Badge
                            :bgBadge="'success-dark'"
                            :textColor="'text-light'"
                            :pillable="'badge-pill'"
                            :text="$t('connected')"
                          >
                          </Badge>
                        </b-col>
                        <b-col
                          class="text-right"
                          v-if="
                            !device.status.isConnected &&
                            device.status.disconnection_date
                          "
                        >
                          {{ device.status.disconnection_date | formatDate }}
                        </b-col>
                        <!--b-col
                          class="text-right"
                          v-if="
                            device.status.isConnected &&
                            device.status.connection_date
                          "
                        >
                          {{ device.status.connection_date | formatDate }}
                        </b-col-->
                      </b-row>
                      <b-row
                        align-h="between"
                        v-if="device.config.disable_first_ota"
                      >
                        <b-col class="text-left text-dark">{{
                          $t("disabledOta")
                        }}</b-col>
                        <b-col class="text-right">
                          <az-icon
                            name="error-check"
                            scale="1.5"
                            class="text-center text-danger"
                          ></az-icon>
                        </b-col>
                      </b-row>
                      <b-row align-h="between" v-if="device.config.stat_ssid">
                        <b-col class="text-left text-dark">{{
                          $t("wifi")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_ssid }}
                        </b-col>
                      </b-row>
                      <b-row align-h="between" v-if="device.config.ws_hw">
                        <b-col class="text-left text-dark">{{
                          $t("hardware")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ wsTypeHardware(device.config.ws_hw) }}
                        </b-col>
                      </b-row>
                      <b-row
                        align-h="between"
                        v-if="device.config.stat_channel"
                      >
                        <b-col class="text-left text-dark">{{
                          $t("channel")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_channel }}
                        </b-col>
                      </b-row>
                      <b-row align-h="between" v-if="device.config.stat_ap_mac">
                        <b-col class="text-left text-dark">{{
                          $t("ap_mac")
                        }}</b-col>
                        <b-col class="text-right">
                          {{ device.config.stat_ap_mac }}
                        </b-col>
                      </b-row>
                      <b-row
                        align-h="between"
                        v-if="device.status.stat_quality"
                      >
                        <b-col class="text-left text-dark">{{
                          $t("connection")
                        }}</b-col>
                        <b-col class="text-right">
                          <SignalIcon
                            :type="'quality'"
                            :signal="device.status.stat_quality"
                          >
                          </SignalIcon>
                        </b-col>
                      </b-row>
                      <b-row align-h="between" v-if="device.status.stat_rssi">
                        <b-col class="text-left text-dark">{{
                          $t("currentSignal")
                        }}</b-col>
                        <b-col class="text-right">
                          <SignalIcon
                            :type="'rssi'"
                            :signal="device.status.stat_rssi"
                          >
                          </SignalIcon>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-card-text>
                </b-card-header>
              </b-card>
            </b-col>
          </b-row>
        </b-jumbotron>
      </transition>

      <b-jumbotron class="pb-0 pt-0">
        <template header>
            <b-row>
              <b-col sm="4">
                <b-form-group
                :label="`${$t('publication')}:`"
                  label-for="input-publish"
                  label-cols-sm="4"
                  content-cols-sm="8"
                >
                  <b-form-select
                    v-model="publish"
                    :options="publishOptions"
                    @input="findDeviceFirmwares"
                    id="input-publish"
                    class="mb-3 shadow"
                    size="lg"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="text-center" v-if="showUpdateButton">
                <DetailsButton
                  @click="updateFirmwares"
                  :text="$t('buttons.update')"
                  :icon="'upgrade-icon'"
                  :scale="'.4'"
                  :arrowRight="false"
                  :colorTextButton="'text-dark'"
                >
                </DetailsButton>
              </b-col>
              <b-col class="h4 pt-2 text-right">
                <i class="bi bi-clipboard"></i>
                
                <az-icon
                  name="gear"
                  scale="1"
                  style="margin-top: -0.3rem"
                ></az-icon>
                {{ total }}
                <span v-show="total !== 1">
                  {{ $t("versions") }}
                </span>
                <span v-show="total === 1">
                  {{ $t("version") }}
                </span>
              </b-col>
            </b-row>
          </template>

          <EmptyCard
            class="mt-1"
            :condition="total === 0 && !isLoading"
          ></EmptyCard>

          <b-row v-if="lmachineTotal === 0">
            <b-col lg="12" sm="12">
              <b-table
              v-if="!isLoading && total > 0"
              id="table-firmwares"
              responsive
              selectable
              outlined
              hover
              head-variant="dark"
              @row-clicked="updateServerInfo"
              :tbody-tr-class="rowClass"
              :items="firmwares"
              :fields="computedFields"
              busy.sync="isLoading"
              >
              <!-- :no-select-on-click="!isExtraRole" -->

                <template #cell(version)="data">
                  <div class="font-weight-bold">
                    {{ data.item.version }}
                  </div>
                </template>
                
                <template #cell(ws_type)="data">
                  <div class="font-weight-bold">
                    <b-badge
                      variant="brand"
                      v-if="wsType(data.item.ws_type).isAirzone"
                      >{{ wsType(data.item.ws_type).text }}</b-badge
                    >

                    <div v-if="wsType(data.item.ws_type).isAidoo">
                      <b-badge
                        variant="light"
                        >{{ wsType(data.item.ws_type).text }}</b-badge
                      >
                    </div>
                    <div v-if="data.item.aidoo_group">
                      <b-badge
                        variant="warning">
                        {{ getGroup(data.item.aidoo_group) }}
                      </b-badge>
                    </div>

                    <b-badge
                      variant="success"
                      v-if="wsType(data.item.ws_type).isCentral"
                      >{{ wsType(data.item.ws_type).text }}</b-badge
                    >
                  </div>
                </template>

                <template #cell(beta)="data">
                  <div>
                    <b-badge variant="success" v-if="data.item.beta">{{
                      $t("Beta")
                    }}</b-badge>
                    <b-badge variant="brand" v-if="!data.item.beta">{{
                      $t("Release")
                    }}</b-badge>
                  </div>
                  <div style="font-size: 14px">
                    {{ data.item.date | formatDate }}
                  </div>
                </template>

                <template #cell(admin_text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.admin_text)">
                    {{ data.item.admin_text[locale] }}
                  </div>
                </template>

                <template #cell(text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.text)">
                    {{ data.item.text[locale] }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <!-- @row-clicked="updateServerInfo" -->
          <b-row v-if="lmachineTotal > 0">
            <b-col lg="6" sm="12">
              <b-table
              v-if="!isLoading && total > 0"
              id="table-firmwares"
              responsive
              selectable
              outlined
              hover
              head-variant="dark"
              select-mode="single"
              @row-selected="onFirmwareRowSelected"
              :tbody-tr-class="rowClass"
              :items="firmwares"
              :fields="computedFields"
              busy.sync="isLoading"
              >
              <!-- :no-select-on-click="!isExtraRole" -->

                <template #cell(version)="data">
                  <div class="font-weight-bold">
                    {{ data.item.version }}
                  </div>
                </template>
                
                <template #cell(ws_type)="data">
                  <div class="font-weight-bold">
                    <b-badge
                      variant="brand"
                      v-if="wsType(data.item.ws_type).isAirzone"
                      >{{ wsType(data.item.ws_type).text }}</b-badge
                    >

                    <div v-if="wsType(data.item.ws_type).isAidoo">
                      <b-badge
                        variant="light"
                        >{{ wsType(data.item.ws_type).text }}</b-badge
                      >
                    </div>
                  </div>
                </template>

                <template #cell(beta)="data">
                  <div>
                    <b-badge variant="success" v-if="data.item.beta">{{
                      $t("Beta")
                    }}</b-badge>
                    <b-badge variant="brand" v-if="!data.item.beta">{{
                      $t("Release")
                    }}</b-badge>
                  </div>
                  <div style="font-size: 14px">
                    {{ data.item.date | formatDate }}
                  </div>
                </template>

                <template #cell(admin_text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.admin_text)">
                    {{ data.item.admin_text[locale] }}
                  </div>
                </template>

                <template #cell(text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.text)">
                    {{ data.item.text[locale] }}
                  </div>
                </template>
              </b-table>
            </b-col>

            <!-- @row-clicked="updateLmachineInfo" -->
            <b-col lg="6" sm="12">
              <b-table
              v-if="!isLoading && lmachineTotal > 0"
              id="table-lmachine-firmwares"
              responsive
              selectable
              outlined
              hover
              head-variant="dark"
              select-mode="single"
              @row-selected="onLmachineRowSelected"
              :tbody-tr-class="rowClass"
              :items="lmachineFirmwares"
              :fields="computedFields"
              busy.sync="isLoading"
              >
              <!-- :no-select-on-click="!isExtraRole" -->

                <template #cell(version)="data">
                  <div class="font-weight-bold">
                    {{ data.item.version }}
                  </div>
                </template>
                
                <template #cell(ws_type)="data">
                  <div class="font-weight-bold">
                    <div v-if="data.item.aidoo_group">
                      <b-badge
                        variant="warning">
                        {{ getGroup(data.item.aidoo_group) }}
                      </b-badge>
                    </div>
                  </div>
                </template>

                <template #cell(beta)="data">
                  <div>
                    <b-badge variant="success" v-if="data.item.beta">{{
                      $t("Beta")
                    }}</b-badge>
                    <b-badge variant="brand" v-if="!data.item.beta">{{
                      $t("Release")
                    }}</b-badge>
                  </div>
                  <div style="font-size: 14px">
                    {{ data.item.date | formatDate }}
                  </div>
                </template>

                <template #cell(admin_text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.admin_text)">
                    {{ data.item.admin_text[locale] }}
                  </div>
                </template>

                <template #cell(text)="data">
                  <div class="ellipsis" v-if="hasText(data.item.text)">
                    {{ data.item.text[locale] }}
                  </div>
                </template>
              </b-table>

              <div class="pb-3">
                <Pagination
                  v-if="lmachineTotal > 10"
                  v-model="lmachineCurrentPage"
                  :totalRows="lmachineTotal"
                  :perPage="lmachinePerPage"
                  :ariaControls="'table-lmachine-firmwares'"
                  :align="'center'"
                >
                </Pagination>
              </div>
            </b-col>
          </b-row>

          <div class="row" v-if="isLoading">
            <div class="col-12 d-flex justify-content-center">
              <div class="text-center" style="width: 30rem; height: 35rem">
                <b-spinner
                  style="width: 3rem; height: 3rem; margin-top: 25%"
                  class=""
                  variant="brand"
                  label="Spinning"
                ></b-spinner>
              </div>
            </div>
          </div>
        </b-jumbotron>

    </b-container>

    <template #overlay>
      <div class="updating text-center">
        <p class="h2">{{ $t("messages.updatingWebserver") }}</p>
        <div class="mt-5 pt-5 pb-3">
          <div class="gear" :class="{ 'gear-pause': !animateProgress }">
            <az-icon name="upgrade-icon" scale="1"></az-icon>
          </div>
          <div
            class="customShadow"
            :class="{ 'customShadow-pause': !animateProgress }"
          ></div>
        </div>

        <p class="">{{ $t("messages.update_server") }}</p>

        <b-progress
          variant="primary"
          height="2rem"
          show-progress
          max="100"
          :animated="animateProgress"
          class="mt-3"
        >
          <b-progress-bar :value="progress">
            <strong> {{ progress }} % </strong>
          </b-progress-bar>
        </b-progress>
      </div>
    </template>

  </b-overlay>

</template>

<script>
// import store from "@/store/store";
import WebService from "@/services/web.service";
import GoBack from "@/components/GoBack";
import Badge from "@/components/Badge";
import SignalIcon from "@/components/Signal";
import EmptyCard from "@/components/EmptyCard.vue";
import Pagination from "@/components/Pagination.vue";
import DetailsButton from "@/components/DetailsButton";
import deviceType from "@/mixins/DeviceType.mixin";
import setStoreFilters from "@/mixins/SetStoreFilters.mixin";
import setFirstPage from "@/mixins/CheckFilter.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import FirmwareMixin from '@/mixins/Firmware.mixin'
import CONSTANTS from "@/constant";
import i18n from "@/services/language.service";
import mapTranslations from '@/utils/mapTranslations';


export default {
  name: "Firmware",
  props: {},
  mixins: [setStoreFilters, wsType, deviceType, rowClass, setFirstPage, showMsgOk, FirmwareMixin],
  components: {
    EmptyCard,
    Pagination,
    GoBack,
    Badge,
    SignalIcon,
    DetailsButton,
  },
  data() {
    return {
      mac: this.$route.params.mac,
      device: this.$route.params.device,
      constants: CONSTANTS,
      total: 0,
      perPage: 10,
      currentPage: 1,
      lmachineTotal: 0,
      lmachinePerPage: 10,
      lmachineCurrentPage: 1,
      paging: false,
      isLoading: false,
      firmwares: [],
      lmachineFirmwares: [],
      locale: i18n.locale,
      wsImage: '',
      fields: [
        {
          key: "version",
          label: i18n.t("version"),
        },
        {
          key: "ws_type",
          label: i18n.t("airtools.device"),
        },
        {
          key: "beta",
          label: i18n.t("publication"),
        },
        {
          key: "admin_text",
          label: i18n.t("changesText"),
        },
        {
          key: "text",
          label: i18n.t("versionText"),
        },
      ],
      transProps: {
        name: 'flip-list'
      },
      type: this.$route.params.device.ws_type,
      publish: false,
      publishOptions: [
        { value: false, text: 'Release' },
        { value: true, text: 'Beta' },
      ],
      group: this.$route.params?.device?.config?.wsmmgroup,
      newGroupOptions: [],
      groupOptions: [],
      errors: {},
      text: {
        es: '',
        en: '',
        fr: '',
        it: '',
        pt: '',
        de: '',
        pl: '',
        el: '',
        tr: '',
      },
      admin_text: {
        es: '',
        en: '',
        fr: '',
        it: '',
        pt: '',
        de: '',
        pl: '',
        el: '',
        tr: '',
      },
      updatingWS: false,
      timeoutUpgrading: null,
      progress: 0,
      animateProgress: true,
      minIntervalTime: CONSTANTS.TIMEOUT.MAX_TIME_UPDATING_WS / 4800,
      firmwareSelected: [],
      lmachineSelected: [],
    };
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;

      this.paging = true
        
      this.findDeviceFirmwares();
    },
  },

  computed: {

    computedFields() {
      if (this.type === CONSTANTS.WEBSERVERTYPE.AIRZONE) {
        return this.fields.filter(field => !field.isAidoo)
      } else {
        return this.fields
      }
    },

    showUpdateButton() {
      return this.firmwareSelected.length > 0 || this.lmachineSelected.length > 0
    },

  },

  methods: {

    onFirmwareRowSelected(item) {
      console.log(item)
      this.firmwareSelected = item
    },

    onLmachineRowSelected(item) {
      console.log(item)
      this.lmachineSelected = item
    },

    updateFirmwares() {

      if (this.lmachineSelected.length === 0) {
        this.updateServerInfo(this.firmwareSelected[0])
        console.log('Update firmware: ' )
      } else if (this.firmwareSelected === 0) {
        this.updateLmachineInfo(this.lmachineSelected[0])
        console.log('Update lmachine firmware: ' )
      } else {
        this.device.versionWS = {
          ws_fw: {
            versions: this.firmwareSelected
          },
          lmachine_fw: {
            versions: this.lmachineSelected
          }
        } 
        console.log('Update ambos firmwares: ', this.device.versionWS)
        this.checkVersionToUpdate()
        // console.log( )
      }
      // console.log(fwSelected)
    },

    /**
     * Obtiene las versiones paginadas del webserver
     */
    async findDeviceFirmwares() {
      try {
        this.isLoading = true;

        // Inicializo array de firmwares seleccionados
        this.firmwareSelected = []
        this.lmachineSelected = []

        // Si no está paginando, asigno siempre la primera página
        if (!this.paging && this.currentPage !== 0) {
          this.currentPage = 1
        }
        this.paging = false;
        const isAQSensorWifi = this.device?.config?.ws_hw === CONSTANTS.WSHARD.AIRQSENSOR_WIFI;

        const response = await WebService.getFirmwares(
          10,
          this.currentPage - 1,
          this.type,
          this.publish,
          this.group,
          isAQSensorWifi,
        );
        // console.log(response)
        if (response.lmachine_fw?.versions?.length > 0) {
          const lmachine_fw = response.lmachine_fw
          this.lmachineTotal = lmachine_fw.total;
          // Order by version
          lmachine_fw.versions.sort((a,b) => (a.version < b.version) ? 1 : ((b.version < a.version) ? -1 : 0))
          this.lmachineFirmwares = lmachine_fw.versions;
        } else {
          this.lmachineTotal = 0;
        }
        if (response.ws_fw?.versions?.length > 0) {
          const webserver_fw = response.ws_fw
          this.total = webserver_fw.total;
          // Order by version
          webserver_fw.versions.sort((a,b) => (a.version < b.version) ? 1 : ((b.version < a.version) ? -1 : 0))
          this.firmwares = webserver_fw.versions;
        }
        this.animateTableRows()
        this.isLoading = false;
      } catch(err) {
        console.log(err)
        this.isLoading = false;
      }
    },

    /**
     * Añade los grupos de Aidoo al array de opciones
     */
     getGroupOptions() {
      for (let key in mapTranslations.AIDOO_GROUPS) {
         this.newGroupOptions.push({ value: key, text: mapTranslations.AIDOO_GROUPS[key]()})
      }
      this.groupOptions = [...this.newGroupOptions]
      this.groupOptions.unshift({value: 0, text: i18n.t('all')})
    },

    getGroup(aidoo_group) {
      const finded = this.newGroupOptions.find( group => group.value === aidoo_group)
      if (finded) {
        return finded.text
      } else {
        return aidoo_group
      }
    },

    /**
     * Dispara el evento onblur en todos los dispositivos
     * @param {Object} e - Evento del input
     */
    hideKeyboard(e) {
      e.preventDefault();
      setTimeout(() => {
        document.getElementById("firmwareFilter").blur();
      }, 10);
    },

    hasText(text) {
      if (!text) return false
      return Object.keys(text).findIndex(k => text[k] !== '') !== -1
    },

    beforeListEnter(element) {
      element.sytle.opacity = 0
    },

    listEnter(element, done) {
      console.log(element)
      const delay = element.dataset.pk * 100
      setTimeout(() =>{
        element.style.transition = 'opacity 0.4s'
        element.style.opacity = 1
        element.style.animation = 'right-to-left'
        done()
      }, delay)
    },
  },

  async mounted() {
    if (!this.$route.params.device) {
      try {
        this.isLoading = true;
        const ws = await WebService.getDevice(this.mac);
        this.isLoading = false;
        console.log(ws)
        this.device = ws;
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    }
    // console.log('route:', this.$route.params)
    this.getWsImage()
    await this.findDeviceFirmwares();
    this.$nextTick(() => {
      this.animateTableRows()
    })
  },

};
</script>

<style lang="scss">
.az-input__search {
  display: flex;
}

label[for="input-newPin"] {
  text-transform: capitalize;
}

.form-group {
  input,
  select {
    box-shadow: 1px 1px 5px #000 !important;
  }
}

.table-secondary {
  opacity: 0
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}
</style>