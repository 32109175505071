/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bluetooth': {
    width: 27,
    height: 30,
    viewBox: '0 0 27 30',
    data: '<path pid="0" d="M16.8 15l3.45 3.45c.45-1.05.6-2.25.6-3.45 0-1.2-.3-2.4-.6-3.45L16.8 15zm7.95-7.95L22.95 9c.9 1.8 1.5 3.9 1.5 6s-.6 4.2-1.5 6l1.8 1.8c1.5-2.25 2.25-5.1 2.25-7.95 0-2.85-.75-5.55-2.25-7.8zm-5.7 1.5L10.5 0H9v11.4L2.1 4.5 0 6.6 8.4 15 0 23.4l2.1 2.1L9 18.6V30h1.5l8.55-8.55L12.6 15l6.45-6.45zM12 5.7l2.85 2.85L12 11.4V5.7zm2.85 15.75L12 24.3v-5.7l2.85 2.85z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
