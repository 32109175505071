/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'honeywell': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g _fill="#1D1D1B" fill-rule="evenodd"><path pid="0" d="M4.197 1.938a2.955 2.955 0 00-2.952 2.951v18.806a2.955 2.955 0 002.952 2.951h19.01a2.955 2.955 0 002.952-2.951V4.889a2.955 2.955 0 00-2.952-2.951H4.197zm19.01 25.745H4.197a3.993 3.993 0 01-3.99-3.988V4.889C.208 2.689 1.998.9 4.198.9h19.01c2.2 0 3.99 1.789 3.99 3.989v18.806a3.994 3.994 0 01-3.99 3.988z"/><path pid="1" d="M5.871 4.886c-.869 0-1.575.707-1.575 1.576v15.66c0 .87.706 1.576 1.575 1.576h15.66c.87 0 1.576-.707 1.576-1.576V6.462c0-.869-.707-1.576-1.576-1.576H5.871zm15.66 19.507H5.871a2.274 2.274 0 01-2.27-2.27V6.462A2.274 2.274 0 015.87 4.19h15.66a2.275 2.275 0 012.272 2.271v15.66a2.275 2.275 0 01-2.272 2.271z"/></g>'
  }
})
