/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'partly-cloudy-day 2': {
    width: 59,
    height: 40,
    viewBox: '0 0 59 40',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_partly-cloudy-day 2_a"><stop stop-color="#F5D2AE" offset="0%"/><stop stop-color="#F5CCAB" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_partly-cloudy-day 2_b"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(29.167)"><circle pid="0" _fill="url(#svgicon_partly-cloudy-day 2_a)" cx="14.583" cy="14.583" r="14.583"/><circle pid="1" _fill="url(#svgicon_partly-cloudy-day 2_b)" cx="14.583" cy="14.583" r="9.978"/></g><path pid="2" d="M25.667 39.667V28.549h6.116c.315-5.511 4.828-9.882 10.35-9.882 5.725 0 10.367 4.7 10.367 10.5 0 5.591-4.315 10.162-9.757 10.482v.018H25.666z" _fill="#7484A6"/><path pid="3" d="M9.6 39.667v-.018C4.247 39.336 0 34.853 0 29.37 0 23.68 4.567 19.07 10.2 19.07c.45 0 .891.029 1.325.086 1.998-6.372 7.902-10.99 14.875-10.99 8.616 0 15.6 7.051 15.6 15.75 0 8.574-6.786 15.548-15.232 15.745l-.368.005H9.6z" _fill="#A2B0CA"/></g>'
  }
})
