/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-small-off': {
    width: 41,
    height: 30,
    viewBox: '0 0 41 30',
    data: '<path pid="0" d="M11.108.032a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.032zM26.14 22.3a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.137.063 17.717 4.875 23.452 9.687 29.187 18.267 29.937 24 25.125a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zm-2.843-11.494c.703 0 1.326-.17 1.87-.508.544-.338.962-.818 1.254-1.44.292-.623.438-1.348.438-2.174V6.31c0-.823-.149-1.548-.447-2.177-.298-.628-.718-1.11-1.262-1.446-.544-.337-1.165-.505-1.865-.505s-1.32.168-1.865.505c-.543.336-.964.818-1.262 1.446-.298.629-.447 1.356-.447 2.182v.415c.004.807.156 1.521.456 2.142.3.62.722 1.098 1.268 1.432.545.335 1.166.502 1.862.502zm0-1.418c-.592 0-1.046-.237-1.36-.71-.316-.472-.473-1.137-.473-1.994v-.41c.003-.871.163-1.533.478-1.985.315-.451.763-.677 1.343-.677.584 0 1.034.228 1.349.686.315.457.473 1.126.473 2.006v.409c-.004.872-.162 1.536-.473 1.992-.311.455-.757.683-1.337.683zm6.548 1.302V7.26h3.32V5.867h-3.32V3.698h3.735v-1.4h-5.464v8.392h1.729zm6.468 0V7.26h3.32V5.867h-3.32V3.698h3.735v-1.4h-5.465v8.392h1.73z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
