/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-6': {
    width: 21,
    height: 26,
    viewBox: '0 0 21 26',
    data: '<path pid="0" d="M19.821 25.714H2.915C1.225 25.714 0 24.362 0 22.5V4.286a.536.536 0 011.071 0V22.5c0 1.067.57 2.143 1.844 2.143h16.37V6.964a.536.536 0 011.072 0V25.18c0 .295-.24.535-.536.535zm0-18.214h-7.5a.536.536 0 010-1.071h6.482c-.76-1.534-.76-3.824 0-5.358H3.45c-1.245 0-2.38 1.021-2.38 2.143v1.072c0 1.122 1.135 2.143 2.38 2.143h2.442a.536.536 0 010 1.071H3.45C1.613 7.5 0 5.998 0 4.286V3.214C0 1.502 1.613 0 3.451 0h16.37a.536.536 0 01.38.915c-1.193 1.192-1.193 4.479 0 5.671a.535.535 0 01-.38.914zM18.75 4.286H3.214a.536.536 0 010-1.072H18.75a.536.536 0 010 1.072zm-6.429 12.857a.54.54 0 01-.379-.157L9.107 14.15l-2.835 2.835a.535.535 0 01-.915-.378V3.75a.536.536 0 011.072 0v11.564l2.299-2.3a.536.536 0 01.757 0l2.3 2.3V3.75a.536.536 0 011.072 0v12.857a.536.536 0 01-.536.536z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
