/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vswing': {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29',
    data: '<path pid="0" d="M12.646 1.081a.64.64 0 010 .906l-9.909 9.91a4.27 4.27 0 01.04 5.073l9.842 9.843a.64.64 0 01-.905.906L1.125 17.129a3.841 3.841 0 010-5.432L11.741 1.081a.64.64 0 01.905 0zM23.403 0a.6.6 0 00-.316.086.076.076 0 00-.026.01l-.017.019-.02.016-.033.02-.021.023-.019.024a.597.597 0 00-.153.43v6.566a.6.6 0 001.2 0V2.259c2.39 3.187 3.6 7.26 3.6 12.137 0 4.88-1.209 8.957-3.6 12.145v-4.935a.6.6 0 00-1.2 0v6.566a.599.599 0 00.095.354l.058.076a.263.263 0 00.04.048l.053.035.017.019a.076.076 0 00.026.01.6.6 0 00.505.054l.028-.003 6.001-2.4a.6.6 0 10-.445-1.113l-3.887 1.555c2.107-3.006 3.272-6.695 3.477-11.01a29.188 29.188 0 00.02-2.31l-.02-.483c-.205-4.316-1.37-8.005-3.477-11.01l3.887 1.555a.6.6 0 10.445-1.113L23.62.036l-.028-.004a.58.58 0 00-.125-.029L23.403 0zM9.46 15.04a.64.64 0 010-1.28h8.535a.64.64 0 010 1.28H9.461zm-.563 2.682a.64.64 0 01.31-1.242l8.281 2.065a.64.64 0 01-.31 1.242l-8.281-2.065zm-1.195 2.466a.64.64 0 01.601-1.13l7.536 4.007a.64.64 0 01-.6 1.13l-7.537-4.007zm1.655-7.886a.64.64 0 01-.31-1.243l8.282-2.065a.64.64 0 01.31 1.243l-8.282 2.065zm-.762-2.633a.64.64 0 11-.602-1.13l7.537-4.007a.64.64 0 11.6 1.13L8.597 9.67z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
