/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'up-solid': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" d="M1.188 5.105a.674.674 0 010-.934L4.548.695a.62.62 0 01.904 0l3.36 3.476a.674.674 0 010 .934.574.574 0 01-.33.172.72.72 0 01-.134.01.72.72 0 01-.133-.01c-.118-.02-6.776.088-7.027-.172z" _fill="#FFF" fill-rule="nonzero" _stroke="#FFF" stroke-width=".2"/>'
  }
})
