/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comfort': {
    width: 34,
    height: 29,
    viewBox: '0 0 34 29',
    data: '<path pid="0" d="M15.418.628a2.184 2.184 0 013.068.002l14.932 14.771a1.635 1.635 0 01-1.145 2.794h-3.557v7.994a2.73 2.73 0 01-2.727 2.725h-3.735a.742.742 0 010-1.481h3.735c.687 0 1.244-.557 1.244-1.244v-8.734c0-.408.333-.74.743-.74h4.294a.148.148 0 00.105-.044.16.16 0 00.045-.11.154.154 0 00-.046-.108L17.446 1.681a.709.709 0 00-.988 0l-5.711 5.64a.74.74 0 01-1.261-.527V5.677l-2.804.02.02 5.33c0 .2-.08.387-.221.527l-4.956 4.9a.152.152 0 00.107.26h4.293c.409 0 .742.333.742.741v8.734c0 .687.558 1.244 1.244 1.244h3.735c.409 0 .742.332.742.74a.742.742 0 01-.742.741H7.91a2.73 2.73 0 01-2.727-2.725v-7.994H1.63a1.649 1.649 0 01-1.157-.483 1.636 1.636 0 01.009-2.31l4.738-4.685v-5.04c0-.806.656-1.46 1.462-1.46h2.824c.605 0 1.135.372 1.354.911zm1.532 7.997a2.274 2.274 0 012.272 2.27v12.82c.528.572.83 1.332.83 2.099 0 1.71-1.392 3.1-3.102 3.1a3.104 3.104 0 01-3.1-3.1c0-.767.301-1.527.828-2.098v-12.82a2.275 2.275 0 012.272-2.271zm0 1.229c-.575 0-1.043.467-1.043 1.042v13.077a.62.62 0 01-.2.455 1.849 1.849 0 00-.628 1.386 1.873 1.873 0 003.743 0 1.85 1.85 0 00-.626-1.385.617.617 0 01-.203-.456V10.896c0-.575-.467-1.042-1.043-1.042zm0 6.23c.34 0 .614.276.614.614v7.81c.501.236.83.743.83 1.305a1.444 1.444 0 01-2.888 0c0-.562.328-1.07.83-1.305v-7.81c0-.338.276-.615.614-.615zm0 9.516a.214.214 0 100 .428.214.214 0 000-.428z" _fill="#000" fill-rule="evenodd"/>'
  }
})
