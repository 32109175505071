/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tornado': {
    width: 58,
    height: 45,
    viewBox: '0 0 58 45',
    data: '<path pid="0" d="M55.708 35.583a2.237 2.237 0 11.001-4.473 2.237 2.237 0 010 4.473zm-.972-18.472a2.237 2.237 0 11.001-4.473 2.237 2.237 0 010 4.473zM39.181 42.39a2.237 2.237 0 110-4.473 2.237 2.237 0 010 4.473zM14.875 4.472A2.237 2.237 0 1114.876 0a2.237 2.237 0 01-.001 4.473zM7.097 26.833a2.237 2.237 0 11.001-4.473 2.237 2.237 0 010 4.473zm46.18-16.527H1.75a1.75 1.75 0 010-3.5h51.528a1.75 1.75 0 010 3.5zM7.584 13.61h39.861a1.75 1.75 0 010 3.5H7.584a1.75 1.75 0 010-3.5zm7.778 27.222h9.722a1.75 1.75 0 010 3.5h-9.722a1.75 1.75 0 010-3.5zm1.167-5.055c0-.966.784-1.75 1.75-1.75H32.86a1.75 1.75 0 010 3.5H18.278a1.75 1.75 0 01-1.75-1.75zm3.889-6.806c0-.966.783-1.75 1.75-1.75h25.277a1.75 1.75 0 010 3.5H22.167a1.75 1.75 0 01-1.75-1.75zm-3.89-6.805c0-.967.785-1.75 1.75-1.75h33.056a1.75 1.75 0 010 3.5H18.278a1.75 1.75 0 01-1.75-1.75z" _fill="#8999B7" fill-rule="nonzero"/>'
  }
})
