/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit': {
    width: 27,
    height: 30,
    viewBox: '0 0 27 30',
    data: '<path pid="0" d="M18.125 18.75a.625.625 0 00-.625.625v8.75c0 .345-.28.625-.625.625h-15a.625.625 0 01-.625-.625V1.875c0-.345.28-.625.625-.625h15c.345 0 .625.28.625.625v8.75a.625.625 0 001.25 0v-8.75A1.877 1.877 0 0016.875 0h-15A1.877 1.877 0 000 1.875v26.25C0 29.159.841 30 1.875 30h15a1.877 1.877 0 001.875-1.875v-8.75a.625.625 0 00-.625-.625zm7.911-4.22l-5-4.375a.626.626 0 00-1.036.47V12.5h-6.875a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625H20v1.875a.623.623 0 001.036.47l5-4.375a.623.623 0 000-.94zm-4.786 3.467v-1.122a.625.625 0 00-.625-.625H13.75v-2.5h6.875c.345 0 .625-.28.625-.625v-1.122L24.676 15l-3.426 2.997z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
