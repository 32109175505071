/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large': {
    width: 25,
    height: 23,
    viewBox: '0 0 25 23',
    data: '<path pid="0" d="M20.054 16.886a.5.5 0 000-1h-.01a.496.496 0 00-.495.5c0 .276.229.5.505.5zm-7.52.5a1 1 0 10-.001-2.001 1 1 0 000 2zm9.5-16.5h-19a2.503 2.503 0 00-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h6.986c-.072 1.983-.567 3-1.486 3a.5.5 0 000 1h8a.5.5 0 10-.001-1c-.92 0-1.414-1.017-1.486-3h6.987c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zm-11.705 21c.416-.665.638-1.68.685-3h3.039c.047 1.32.269 2.335.685 3h-4.41zm13.205-5.5c0 .827-.673 1.5-1.5 1.5h-19c-.827 0-1.5-.673-1.5-1.5v-1.5h22v1.5zm0-2.5h-22v-10.5c0-.827.673-1.5 1.5-1.5h19c.827 0 1.5.673 1.5 1.5v10.5z"/>'
  }
})
