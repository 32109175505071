/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nest': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g _fill="#1D1D1B" fill-rule="evenodd"><path pid="0" d="M14.075 1.938c-6.812 0-12.354 5.542-12.354 12.354 0 6.812 5.542 12.354 12.354 12.354 6.812 0 12.354-5.542 12.354-12.354 0-6.812-5.542-12.354-12.354-12.354m0 25.745C6.69 27.683.683 21.676.683 14.292S6.69.9 14.075.9s13.392 6.008 13.392 13.392c0 7.384-6.007 13.391-13.392 13.391"/><path pid="1" d="M14.075 5.359c-4.926 0-8.933 4.008-8.933 8.934 0 4.925 4.007 8.932 8.933 8.932 4.925 0 8.933-4.007 8.933-8.932 0-4.926-4.008-8.934-8.933-8.934m0 18.515c-5.283 0-9.582-4.298-9.582-9.581 0-5.284 4.3-9.582 9.582-9.582 5.284 0 9.582 4.298 9.582 9.582 0 5.283-4.298 9.58-9.582 9.58"/></g>'
  }
})
