<template>
  <b-container fluid class="m-0 p-0">
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-0">
        <template header>
          <!--b-row class="mt-3 background filterIputnBox">
            <b-col>
              <b-input-group>
                <b-input-group-prepend>
                  <div class="az-input__value">
                    <div class="az-input__body">
                      <b-form-input
                        v-model="firmwareFilter"
                        id="firmwareFilter"
                        class="searchFilter"
                        placeholder="MAC (12:AB:34:CD:45:FD)"
                        size="lg"
                        autocomplete="firmwareFilter"
                        autofocus
                        @keyup.enter="findFirmware()"
                        @input.native="macPattern($event, true)"
                      >
                      </b-form-input>
                      <div class="az-input__search">
                        <div
                          class="eyeIcon searchIcon"
                          :class="{
                            rightIcon: firmwareFilter === null || firmwareFilter === '',
                          }"
                          @click="findFirmware()"
                        >
                          <az-icon name="search" scale="1"></az-icon>
                        </div>
                        <div
                          class="clearIcon"
                          @click="firmwareFilter = ''"
                          v-if="firmwareFilter !== null && firmwareFilter !== ''"
                        >
                          <az-icon name="clear" scale="1"></az-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-input-group-prepend>
              </b-input-group>
            </b-col>
          </b-row-->

          <b-row>
            <b-col v-if="isExtraRole()" class="buttonsAddServer">
              <b-button variant="brand" v-b-toggle.add-firmware>
                <az-icon name="addDevice" scale=".7"></az-icon>
                <span class="ml-2">
                  {{ $t('buttons.add_firmware') }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </template>

        <template lead>
          <b-collapse id="add-firmware" class="mb-3">

            <b-card>
              <b-row>
                <b-col sm="11">
                  <b-form class="needs-validation" novalidate>
                    <b-form-group
                      label-cols-lg="2"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0"
                    >

                      <b-row>
                        <b-col sm="6">
                          <b-form-group
                            :label="`${$t('version')}:`"
                            label-for="input-newVersion"
                            label-cols-sm="4"
                            content-cols-sm="8"
                          >
                            <b-form-input
                              class="input__newVersion"
                              @focus="errors.version = null"
                              v-model="newVersion"
                              id="input-newVersion"
                              size="lg"
                              autocomplete="newVersion"
                              autofocus
                              :class="{ borderError: errors.newVersion }"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    
                      <b-row>
                        <b-col sm="2"></b-col>
                        <b-col sm="10">
                          <div class="error pt-2 pb-3" v-if="errors.version">
                            {{ $t("messages.errors.emptyInputError") }}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="2"></b-col>
                        <b-col sm="10">
                          <div class="error pt-2 pb-3" v-if="newVersion && errors.versionName"> <!-- errors.versionName -->
                            {{ $t("messages.errors.regexVersionError", { example: validVersionName }) }}
                          </div>
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col sm="6">
                          <b-form-group
                            :label="`${$t('airtools.device')}:`"
                            label-for="input-newType"
                            label-cols-sm="4"
                            content-cols-sm="8"
                          >
                            <b-form-select
                              v-model="newTypeModel"
                              :options="newTypeOptions"
                              @input="changeNewType"
                              id="input-newType"
                              class="mb-3 shadow input__newVersion"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="6" v-if="newType !== constants.WEBSERVERTYPE.AIRZONE && newType !== constants.WSHARD.AIRQSENSOR_WIFI">
                        
                          <b-form-group
                            :label="`${$t('installations.group')} :`"
                            label-for="input-newGroup"
                            label-cols-sm="3"
                            label-align-sm="right"
                          >
                            <b-form-select
                              v-model="newGroup"
                              :options="newGroupOptions"
                              id="input-newGroup"
                              class="mb-3 shadow"
                              size="lg"
                              value-field="value"
                              text-field="text"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="12">
                          <b-form-group
                            :label="`${$t('files')}:`"
                            label-for="input-loadedFiles"
                            label-cols-sm="2"
                            content-cols-sm="10">

                            <div class="file-drop-area mb-2" :class="{'is-active': isActive}">
                              <b-form-file
                                class="mb-2"
                                id="input-newFiles"
                                v-model="files"




                                :disabled="disableInputFile"
                                @input="inputChanged"
                                ref="file-input"
                                multiple
                                @dragenter="isActive = true"
                                @click="isActive = true"
                                @focus="isActive = true"
                                @dragend="isActive = false"
                                @drop="isActive = false"
                                @blur="isActive = false">
                              </b-form-file>
                              <span class="fake-btn">{{ $t('buttons.chooseFile') }}</span>
                              <span class="file-msg">{{$t('buttons.dragDropFiles')}}</span>
                            </div>
                            
                            <b-button id="remove-newFiles" class="btn btn-danger" v-if="loadedFiles.length > 0" style="margin-right: 1rem" @click="remove">{{ $t('buttons.remove') }}</b-button>
                            
                            <b-row class="mt-3" v-if="loadedFiles.length > 0">
                              <b-col>
                                <b v-if="loadedFiles.length >= 1">{{ loadedFiles[0].name }}</b>
                                <b v-if="loadedFiles.length === 2">{{ `, ${loadedFiles[1].name}` }}</b>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <div class="error my-1 pb-2" v-if="errors.files">
                                  {{ $t( errorFileMessage, filesName) }}
                                </div>
                              </b-col>
                            </b-row>

                          </b-form-group>
                        </b-col>
                      </b-row>

                      
                      
                      <b-row class="mb-4">
                        <b-col sm="2">
                          <label for="textarea-default">{{ $t('changesText') }}:</label>
                        </b-col>
                        <b-col>
                          <b-tabs v-model="tabIndexAdmin" style="background-color: #ccc" fill lazy>
                            <b-tab
                              @click="clickTab(1, true)"
                              id="admin_text_es"
                              :class="{ active: checkTab(1, true) }"
                              :title="$t('spanish')"
                              >
                              <vue-editor v-model="admin_text.es" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(2, true)"
                              id="admin_text_en"
                              :class="{ active: checkTab(2, true) }"
                              :title="$t('english')"
                              >
                              <vue-editor v-model="admin_text.en" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(3, true)"
                              id="admin_text_fr"
                              :class="{ active: checkTab(3, true) }"
                              :title="$t('french')"
                              >
                              <vue-editor v-model="admin_text.fr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(4, true)"
                              id="admin_text_it"
                              :class="{ active: checkTab(4, true) }"
                              :title="$t('italian')"
                              >
                              <vue-editor v-model="admin_text.it" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(5, true)"
                              id="admin_text_pt"
                              :class="{ active: checkTab(5, true) }"
                              :title="$t('portuguese')"
                              >
                              <vue-editor v-model="admin_text.pt" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(6, true)"
                              id="admin_text_de"
                              :class="{ active: checkTab(6, true) }"
                              :title="$t('german')"
                              >
                              <vue-editor v-model="admin_text.de" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(7, true)"
                              id="admin_text_pl"
                              :class="{ active: checkTab(7, true) }"
                              :title="$t('polish')"
                              >
                              <vue-editor v-model="admin_text.pl" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(8, true)"
                              id="admin_text_el"
                              :class="{ active: checkTab(8, true) }"
                              :title="$t('greek')"
                              >
                              <vue-editor v-model="admin_text.el" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(9, true)"
                              id="admin_text_tr"
                              :class="{ active: checkTab(9, true) }"
                              :title="$t('turkish')"
                              >
                              <vue-editor v-model="admin_text.tr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                      
                          </b-tabs>
                        </b-col>
                      </b-row>

                      <!--b-row>
                        <b-col sm="2"></b-col>
                        <b-col sm="10">
                          <div class="error my-2 pb-2" v-if="errors.admin_text">
                            {{ $t("messages.errors.emptyInputError") }}
                          </div>
                        </b-col>
                      </b-row-->

                      <b-row class="mb-4">
                        <b-col sm="2">
                          <label for="textarea-default">{{ $t('versionText') }}:</label>
                        </b-col>
                        <b-col>
                          <b-tabs v-model="tabIndex" style="background-color: #ccc" fill lazy>
                            <b-tab
                              @click="clickTab(1)"
                              id="text_es"
                              :class="{ active: checkTab(1) }"
                              :title="$t('spanish')"
                              >
                              <vue-editor v-model="text.es" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(2)"
                              id="text_en"
                              :class="{ active: checkTab(2) }"
                              :title="$t('english')"
                              >
                              <vue-editor v-model="text.en" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(3)"
                              id="text_fr"
                              :class="{ active: checkTab(3) }"
                              :title="$t('french')"
                              >
                              <vue-editor v-model="text.fr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                            
                            <b-tab
                              @click="clickTab(4)"
                              id="text_it"
                              :class="{ active: checkTab(4) }"
                              :title="$t('italian')"
                              >
                              <vue-editor v-model="text.it" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(5)"
                              id="text_pt"
                              :class="{ active: checkTab(5) }"
                              :title="$t('portuguese')"
                              >
                              <vue-editor v-model="text.pt" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(6)"
                              id="text_de"
                              :class="{ active: checkTab(6) }"
                              :title="$t('german')"
                              >
                              <vue-editor v-model="text.de" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(7)"
                              id="text_pl"
                              :class="{ active: checkTab(7) }"
                              :title="$t('polish')"
                              >
                              <vue-editor v-model="text.pl" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(8)"
                              id="text_el"
                              :class="{ active: checkTab(8) }"
                              :title="$t('greek')"
                              >
                              <vue-editor v-model="text.el" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>

                            <b-tab
                              @click="clickTab(9)"
                              id="text_tr"
                              :class="{ active: checkTab(9) }"
                              :title="$t('turkish')"
                              >
                              <vue-editor v-model="text.tr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                            </b-tab>
                      
                          </b-tabs>
                        </b-col>
                      </b-row>

                      <!--b-row>
                        <b-col sm="2"></b-col>
                        <b-col sm="10">
                          <div class="error my-2 pb-2" v-if="errors.text">
                            {{ $t("messages.errors.emptyInputError") }}
                          </div>
                        </b-col>
                      </b-row-->

                      <b-row class="mb-4">
                        <b-col sm="2"></b-col>
                        <b-col sm="10">
                          <b> {{ $t('messages.addFirmwareInfo') }} </b>
                        </b-col>
                      </b-row>

                      <b-form-group
                        label-for="sumbit-newFirmware"
                        label-cols-sm="5"
                        label-align-sm="right"
                      >
                        <b-button
                          id="sumbit-newFirmware"
                          class="btn btn-brand"
                          @click="saveFirmware"
                          >{{ $t("buttons.save") }}</b-button
                        >
                      </b-form-group>
                    </b-form-group>
                  </b-form>
                </b-col>

                <b-col sm="1">
                  <b-row>
                    <b-col sm="12">
                      <az-icon
                        name="close"
                        scale="1"
                        style="float: right"
                        v-b-toggle.add-firmware
                      ></az-icon>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-card>

          </b-collapse>
        </template>
        
        
        <b-row class="mt-3">
          <b-col lg="4" sm="12">
            <b-form-group
              :label="`${$t('airtools.device')}:`"
              label-for="input-type"
              label-cols-sm="4"
              content-cols-sm="8"
              class="mb-0"
            >
              <b-form-select
                v-model="typeModel"
                :options="typeOptions"
                @input="changeType"
                id="input-type"
                class="mb-3 shadow"
                size="lg"
                value-field="value"
                text-field="text"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" sm="12">
            <b-form-group
            :label="`${$t('publication')}:`"
              label-for="input-publish"
              label-cols-sm="4"
              content-cols-sm="8"
              class="mb-0"
            >
              <b-form-select
                v-model="publish"
                :options="publishOptions"
                @input="findFirmwares"
                id="input-publish"
                class="mb-3 shadow"
                size="lg"
                value-field="value"
                text-field="text"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" sm="12">
            <b-form-group
              v-if="type === constants.WEBSERVERTYPE.AIDOO"
              :label="`${$t('installations.group')}:`"
              label-for="input-group"
              label-cols-sm="3"
              content-cols-sm="8"
              class="mb-0"
            >
              <b-form-select
                v-model="group"
                :options="groupOptions"
                @input="findFirmwares"
                id="input-group"
                class="mb-3 shadow"
                size="lg"
                value-field="value"
                text-field="text"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="2" sm="12" class="h4 mt-1 pt-1 text-right">
            <i class="bi bi-clipboard"></i>
            
            <az-icon
              name="gear"
              scale="1"
              style="margin-top: -0.3rem"
            ></az-icon>
            {{ total }}
            <span v-show="total !== 1">
              {{ $t("versions") }}
            </span>
            <span v-show="total === 1">
              {{ $t("version") }}
            </span>
          </b-col>
        </b-row>

        <EmptyCard
          class="mt-1"
          :condition="total === 0 && !isLoading"
        ></EmptyCard>

        <b-row v-if="lmachineTotal === 0">
          <b-col lg="12" sm="12">
            <b-table
            v-if="!isLoading && total > 0"
            id="table-firmwares"
            responsive
            selectable
            outlined
            hover
            head-variant="dark"
            @row-clicked="infoFirmware"
            :no-select-on-click="!isExtraRole()"
            :tbody-tr-class="rowClass"
            :items="firmwares"
            :fields="computedFields"
              busy.sync="isLoading"
            >

              <template #cell(version)="data">
                <div class="font-weight-bold">
                  {{ data.item.version }}
                </div>
              </template>
              
              <template #cell(ws_type)="data">
                <div class="font-weight-bold">
                  <b-badge
                    variant="brand"
                    v-if="wsType(data.item.ws_type).isAirzone"
                    >{{ wsType(data.item.ws_type).text }}</b-badge
                  >

                  <div v-if="wsType(data.item.ws_type).isAidoo">
                    <b-badge
                      variant="light"
                      >{{ wsType(data.item.ws_type).text }}</b-badge
                    >
                  </div>
                  <div v-if="data.item.aidoo_group">
                    <b-badge
                      variant="warning">
                      {{ getGroup(data.item.aidoo_group) }}
                    </b-badge>
                  </div>

                  <b-badge
                    variant="success"
                    v-if="wsType(data.item.ws_type).isCentral"
                    >{{ wsType(data.item.ws_type).text }}</b-badge
                  >
                </div>
              </template>

              <template #cell(beta)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.beta">{{
                    $t("Beta")
                  }}</b-badge>
                  <b-badge variant="brand" v-if="!data.item.beta">{{
                    $t("Release")
                  }}</b-badge>
                </div>
                <div style="font-size: 14px">
                  {{ data.item.date | formatDate }}
                </div>
              </template>

              <template #cell(admin_text)="data">
                <div class="ellipsis" v-if="hasText(data.item.admin_text)" @click="!isExtraRole() && showTextModal(data.item.admin_text[locale], true)">
                  {{ data.item.admin_text[locale] }}
                </div>
              </template>

              <template #cell(text)="data">
                <div class="ellipsis" v-if="hasText(data.item.text)" @click="!isExtraRole() && showTextModal(data.item.text[locale], false)">
                  {{ data.item.text[locale] }}
                </div>
              </template>
            </b-table>
          </b-col>

          <div class="pb-3">
            <Pagination
              v-if="total > 10"
              v-model="currentPage"
              :totalRows="total"
              :perPage="perPage"
              :ariaControls="'table-firmwares'"
              :align="'center'"
            >
            </Pagination>
          </div>
        </b-row>

        <b-row v-if="lmachineTotal > 0">
          <b-col lg="6" sm="12">
            <b-table
            v-if="!isLoading && total > 0"
            id="table-firmwares"
            responsive
            selectable
            outlined
            hover
            head-variant="dark"
            @row-clicked="infoFirmware"
            :no-select-on-click="!isExtraRole()"
            :tbody-tr-class="rowClass"
            :items="firmwares"
            :fields="computedFields"
              busy.sync="isLoading"
            >

              <template #cell(version)="data">
                <div class="font-weight-bold">
                  {{ data.item.version }}
                </div>
              </template>
              
              <template #cell(ws_type)="data">
                <div class="font-weight-bold">
                  <div v-if="wsType(data.item.ws_type).isAidoo">
                    <b-badge
                      variant="light"
                      >{{ wsType(data.item.ws_type).text }}</b-badge
                    >
                  </div>
                </div>
              </template>

              <template #cell(beta)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.beta">{{
                    $t("Beta")
                  }}</b-badge>
                  <b-badge variant="brand" v-if="!data.item.beta">{{
                    $t("Release")
                  }}</b-badge>
                </div>
                <div style="font-size: 14px">
                  {{ data.item.date | formatDate }}
                </div>
              </template>

              <template #cell(admin_text)="data">
                <div class="ellipsis" v-if="hasText(data.item.admin_text)" @click="!isExtraRole() && showTextModal(data.item.admin_text[locale], true)">
                  {{ data.item.admin_text[locale] }}
                </div>
              </template>

              <template #cell(text)="data">
                <div class="ellipsis" v-if="hasText(data.item.text)" @click="!isExtraRole() && showTextModal(data.item.text[locale], false)">
                  {{ data.item.text[locale] }}
                </div>
              </template>
            </b-table>

            <div class="pb-3">
              <Pagination
                v-if="total > 10"
                v-model="currentPage"
                :totalRows="total"
                :perPage="perPage"
                :ariaControls="'table-firmwares'"
                :align="'center'"
              >
              </Pagination>
            </div>
          </b-col>

          <b-col lg="6" sm="12">
            <b-table
            v-if="!isLoading && lmachineTotal > 0"
            id="table-lmachine-firmwares"
            responsive
            selectable
            outlined
            hover
            head-variant="dark"
            @row-clicked="infoLmachineFirmware"
            :no-select-on-click="!isExtraRole()"
            :tbody-tr-class="rowClass"
            :items="lmachineFirmwares"
            :fields="computedFields"
              busy.sync="isLoading"
            >

              <template #cell(version)="data">
                <div class="font-weight-bold">
                  {{ data.item.version }}
                </div>
              </template>
              
              <template #cell(ws_type)="data">
                <div class="font-weight-bold">
                  <div v-if="data.item.aidoo_group">
                    <b-badge
                      variant="warning">
                      {{ getGroup(data.item.aidoo_group) }}
                    </b-badge>
                  </div>
                </div>
              </template>

              <template #cell(beta)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.beta">{{
                    $t("Beta")
                  }}</b-badge>
                  <b-badge variant="brand" v-if="!data.item.beta">{{
                    $t("Release")
                  }}</b-badge>
                </div>
                <div style="font-size: 14px">
                  {{ data.item.date | formatDate }}
                </div>
              </template>

              <template #cell(admin_text)="data">
                <div class="ellipsis" v-if="hasText(data.item.admin_text)" @click="!isExtraRole() && showTextModal(data.item.admin_text[locale], true)">
                  {{ data.item.admin_text[locale] }}
                </div>
              </template>

              <template #cell(text)="data">
                <div class="ellipsis" v-if="hasText(data.item.text)" @click="!isExtraRole() && showTextModal(data.item.text[locale], false)">
                  {{ data.item.text[locale] }}
                </div>
              </template>
            </b-table>

            <div class="pb-3">
              <Pagination
                v-if="lmachineTotal > 10"
                v-model="lmachineCurrentPage"
                :totalRows="lmachineTotal"
                :perPage="lmachinePerPage"
                :ariaControls="'table-lmachine-firmwares'"
                :align="'center'"
              >
              </Pagination>
            </div>
          </b-col>
        </b-row>

        <div class="row" v-if="isLoading">
          <div class="col-12 d-flex justify-content-center">
            <div class="text-center" style="width: 30rem; height: 35rem">
              <b-spinner
                style="width: 3rem; height: 3rem; margin-top: 25%"
                class=""
                variant="brand"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
        </div>
      </b-jumbotron>
    </transition>

    <b-modal
      v-model="showModalWarning"
      size="md"
      centered
      header-bg-variant="warning"
      body-text-variant="secondary"
      body-bg-variant="light"
      class="text-center"
      hide-footer>
        <template #modal-title>
          <span class="h3 font-weight-bold">{{ modalWarningTitle }}</span>
        </template>
        <div class="d-block text-center py-4">
          <h3>{{ modalWarningMsg }}</h3>
        </div>
        <div class="row">
          <div class="col-12 text-right d-flex justify-content-center py-2">
            <b-button class="mt-3 w-25" variant="primary" size="md" @click="hideModalWarning">{{ $t('buttons.accept') }}</b-button>
          </div>
        </div>

    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/store";
import WebService from "@/services/web.service";
import EmptyCard from "@/components/EmptyCard.vue";
import Pagination from "@/components/Pagination.vue";
import setStoreFilters from "@/mixins/SetStoreFilters.mixin";
import setFirstPage from "@/mixins/CheckFilter.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import FirmwareMixin from '@/mixins/Firmware.mixin'
import Role from '@/mixins/Role.mixin'
import CONSTANTS from "@/constant";
import i18n from "@/services/language.service";
import mapTranslations from '@/utils/mapTranslations';
import { VueEditor, Quill } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
// import htmlIcon from "@/assets/icons/svg/filetype-html.svg"

Quill.register("modules/htmlEditButton", htmlEditButton)


export default {
  name: "Firmware",
  props: {},
  mixins: [setStoreFilters, wsType, rowClass, setFirstPage, showMsgOk, FirmwareMixin, Role],
  components: {
    EmptyCard,
    Pagination,
    // DetailsButton,
    VueEditor
  },
  data() {
    return {
      constants: CONSTANTS,
      firmwareFilter: "",
      total: 0,
      perPage: 10,
      currentPage: 1,
      lmachineTotal: 0,
      lmachinePerPage: 10,
      lmachineCurrentPage: 1,
      paging: false,
      isLoading: false,
      firmwares: [],
      lmachineFirmwares: [],
      locale: i18n.locale,
      fields: [
        {
          key: "version",
          label: i18n.t("version"),
        },
        {
          key: "ws_type",
          label: i18n.t("airtools.device"),
        },
        {
          key: "beta",
          label: i18n.t("publication"),
        },
        {
          key: "admin_text",
          label: i18n.t("changesText"),
        },
        {
          key: "text",
          label: i18n.t("versionText"),
        },
      ],
      type: CONSTANTS.WEBSERVERTYPE.AIRZONE,
      typeModel: 'AIRZONE',
      typeOptions: [
        { value: 'AIRZONE', text: CONSTANTS.TAG.WS_AZ },
        { value: 'AIDOO', text: CONSTANTS.TAG.WS_AIDOO },
        { value: CONSTANTS.WSHARD.AIRQSENSOR_WIFI, text: CONSTANTS.TAG.WS_AQSENSOR_WIFI },
      ],
      publish: false,
      publishOptions: [
        { value: false, text: 'Release' },
        { value: true, text: 'Beta' },
      ],
      group: 0,
      groupOptions: [],
      newVersion: '',
      newType: CONSTANTS.WEBSERVERTYPE.AIRZONE,
      newTypeModel: 'AIRZONE',
      newGroup: "WSMM1",
      newTypeOptions: [
        { value: 'AIRZONE', text: CONSTANTS.TAG.WS_AZ },
        { value: 'AIDOO', text: CONSTANTS.TAG.WS_AIDOO },
        { value: CONSTANTS.WSHARD.AIRQSENSOR_WIFI, text: CONSTANTS.TAG.WS_AQSENSOR_WIFI },
      ],
      newGroupOptions: [],
      errors: {},
      files: [],
      loadedFiles: [],
      tabIndexAdmin: 0,
      tabIndex: 0,
      tab: 1,
      tabAdmin: 1,
      text: {
        es: '',
        en: '',
        fr: '',
        it: '',
        pt: '',
        de: '',
        pl: '',
        el: '',
        tr: '',
      },
      admin_text: {
        es: '',
        en: '',
        fr: '',
        it: '',
        pt: '',
        de: '',
        pl: '',
        el: '',
        tr: '',
      },
      editorSettings: {
        modules: {
          htmlEditButton: {
            debug: false, // logging, default:false
            msg: "HTML", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: i18n.t('buttons.accept'), // Text to display in the OK button, default: Ok,
            cancelText: i18n.t('buttons.cancel'), // Text to display in the cancel button, default: Cancel
            buttonHTML: "<>",//"&lt;&gt;", // Text to display in the toolbar button, default: <>
            buttonTitle: "HTML", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {} // The default mod
          },
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
      ],
      saveEmptyTexts: false,
      withoutAdminTexts: false,
      withoutTexts: false,
      showModalWarning: false,
      modalWarningTitle: '',
      modalWarningMsg: '',
      isActive: false,
    };
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;

      this.paging = true
        
      this.findFirmwares();
    },
  },

  computed: {

    computedFields() {
      if (this.type === CONSTANTS.WEBSERVERTYPE.AIRZONE) {
        return this.fields.filter(field => !field.isAidoo)
      } else {
        return this.fields
      }
    },

    filesName() {
      return {
        zip: CONSTANTS.FIRMWAREFILES[this.newType].ota,
        sh: CONSTANTS.FIRMWAREFILES[this.newType].update,
        bin: CONSTANTS.FIRMWAREFILES[this.newType].firmware,
      }
    },

    validVersionName() {
      return CONSTANTS.VALID_VERSIONS_NAME[this.newTypeModel];
    },

    errorFileMessage() {
      if(this.newTypeModel === CONSTANTS.WSHARD.AIRQSENSOR_WIFI || this.newGroup === CONSTANTS.AIDOO_GROUP.AIDOO_C3_MINI){
        return "messages.errors.extensionSingleError"
      } else {
       return "messages.errors.extensionError" 
      }
    },

    disableInputFile() {
      if(this.newTypeModel === CONSTANTS.WSHARD.AIRQSENSOR_WIFI || this.newGroup === CONSTANTS.AIDOO_GROUP.AIDOO_C3_MINI) {
        return this.loadedFiles.length === 1
      } else {
        return this.loadedFiles.length === 2
      }
    },

  },

  methods: {
    /**
     * Añade los grupos de Aidoo al array de opciones
     */
    getGroupOptions() {
      for (let key in mapTranslations.AIDOO_GROUPS) {
         this.newGroupOptions.push({ value: key, text: mapTranslations.AIDOO_GROUPS[key]()})
      }
      this.groupOptions = [...this.newGroupOptions]
      // this.groupOptions.unshift({value: 0, text: i18n.t('all')})
    },

    /**
     * Cambia el filtro de firmware para desacoplar this.type al tener dos valores iguales 'ws_az'
     */
    changeType(type = CONSTANTS.WEBSERVERTYPE.AIRZONE) {
      this.type = type === CONSTANTS.WSHARD.AIRQSENSOR_WIFI? 'ws_az' : CONSTANTS.WEBSERVERTYPE[type];

      this.findFirmwares();
    },

    changeNewType(type = CONSTANTS.WEBSERVERTYPE.AIRZONE) {
      this.newGroup= "WSMM1"
      this.newType = type === CONSTANTS.WSHARD.AIRQSENSOR_WIFI? 'ws_az' : CONSTANTS.WEBSERVERTYPE[type];
    },

    /**
     * Obtiene las versiones paginadas
     */
    async findFirmwares() {
      try {
        this.isLoading = true;

        // Si no está paginando, asigno siempre la primera página
        if (!this.paging && this.currentPage !== 0) {
          this.currentPage = 1
        }
        this.paging = false

        // Aseguro que no envía grupo para webservers airzone
        let aidoo_group = 0

        if (this.type === CONSTANTS.WEBSERVERTYPE.AIDOO) {
          if (!this.group) {
            console.log('Asigna grupo 1 de Aidoo por defecto')
            this.group = 'WSMM1'
          }
          aidoo_group = this.group
        } 

        const isAQSensorWifi = this.typeModel === CONSTANTS.WSHARD.AIRQSENSOR_WIFI;

        const response = await WebService.getFirmwares(
          10,
          this.currentPage - 1,
          this.type,
          this.publish,
          aidoo_group,
          isAQSensorWifi
        );
        // this.$root.$emit("bv::toggle::collapse", "add-firmware");
        if (response.lmachine_fw?.versions?.length > 0) {
          const lmachine_fw = response.lmachine_fw
          this.lmachineTotal = lmachine_fw.total;
          // Order by version
          lmachine_fw.versions.sort((a,b) => (a.version < b.version) ? 1 : ((b.version < a.version) ? -1 : 0))
          this.lmachineFirmwares = lmachine_fw.versions;
        } else {
          this.lmachineTotal = 0;
        }
        if (response.ws_fw?.versions?.length > 0) {
          const webserver_fw = response.ws_fw
          this.total = webserver_fw.total;
          // Order by version
          webserver_fw.versions.sort((a,b) => (a.version < b.version) ? 1 : ((b.version < a.version) ? -1 : 0))
          this.firmwares = webserver_fw.versions;
        }
        this.animateTableRows()
        this.isLoading = false;
      } catch(err) {
        console.log(err)
        this.isLoading = false;
      }
    },

    /**
     * Enlaza con la vista del dispositivo
     * @param {Object} item - Objecto con los datos del dispositivo
     */
    infoFirmware(item) {
      if (this.isExtraRole() || this.isTextWriteRole()) {
        const path = { name: "firmware", params: { id: item._id } };
        // Salvo la ruta de navegación del firmware en VUEX
        store.dispatch("navModule/saveFirmware", path);
        // Borra el filtro de busqueda si se escribe en el input sin buscar y se clica en un firmware dentro de la página
        store.dispatch("navModule/savePageAndFilter", {
          filter: this.firmwareFilter,
          page: this.currentPage,
        });
        this.$router.push(path);
      }
    },

    /**
     * Enlaza con la vista del dispositivo
     * @param {Object} item - Objecto con los datos del dispositivo
     */
     infoLmachineFirmware(item) {
      if (this.isExtraRole() || this.isTextWriteRole()) {
        const path = { name: "firmware", params: { id: item._id } };
        // Salvo la ruta de navegación del firmware en VUEX
        store.dispatch("navModule/saveFirmware", path);
        // Borra el filtro de busqueda si se escribe en el input sin buscar y se clica en un firmware dentro de la página
        store.dispatch("navModule/savePageAndFilter", {
          filter: this.firmwareFilter,
          page: this.lmachineCurrentPage,
        });
        this.$router.push(path);
      }
    },

    showTextModal(message, isAdmin) {
      // console.log('showTextModal')
      const h = this.$createElement;
      const messageVNode = h("div", { 
        domProps: { innerHTML: message },
      });
      this.$bvModal
        .msgBoxOk([messageVNode], {
          title: isAdmin ? i18n.t('changesText') : i18n.t('versionText'),
          okVariant: 'primary',
          okTitle: i18n.t('buttons.accept'),
          headerBgVariant: "primary",
          headerClass: "justify-center",
          footerClass: "justify-center border-top-0",
          hideHeaderClose: true,
          centered: true,
        }
      )
      .then(() => {
        // console.log('Close modal')
      })
    },

    getGroup(aidoo_group) {
      const finded = this.newGroupOptions.find( group => group.value === aidoo_group)
      if (finded) {
        return finded.text
      } else {
        return aidoo_group
      }
    },

    /**
     * Dispara el evento onblur en todos los dispositivos
     * @param {Object} e - Evento del input
     */
    hideKeyboard(e) {
      e.preventDefault();
      setTimeout(() => {
        document.getElementById("firmwareFilter").blur();
      }, 10);
    },

    /**
     * Valido extensión de archivos y número de versión
     */
    validate() {
      const errors = {};

      if (this.newVersion === '') {
        errors.version = true
        this.scrollTo('input-newVersion')
        return errors
      } else {
        const validRegexByType = {
          AIRZONE: /^\w+\.\w+$/,
          AIDOO: /^\w+\.\w+$/,
          airqsensorWifi: /^\w+\.\w+\.\w+$/,
        }

        if(!validRegexByType[this.newTypeModel].test(this.newVersion)) {
          errors.versionName = true;
          this.scrollTo('input-newVersion');
          return errors;
        }
      }

      /**
       * Validación de archivo en grupo LAIDO_ESP_C3_MINI
       */
      if(mapTranslations.AIDOO_GROUPS[this.newGroup]() === mapTranslations.AIDOO_GROUPS.LAIDOO_ESP_C3_MINI()) {
        if(this.loadedFiles.length !== 1){
          errors.files = true
          this.scrollTo('input-newFiles')
          return errors
        } else if(this.loadedFiles.length === 1) {
          if(this.loadedFiles[0].name !== this.filesName.zip){
            errors.files = true
            this.scrollTo('remove-newFiles')
            return errors
          }
        }
        return errors
      }

       if(this.newTypeModel === CONSTANTS.WSHARD.AIRQSENSOR_WIFI) {
         if(this.loadedFiles.length < 1) {
           errors.files = true
           this.scrollTo('input-newFiles')
           return errors
          } else if (this.loadedFiles.length === 1) {
            if (this.loadedFiles[0].name !== this.filesName.bin) {
              errors.files = true
              this.scrollTo('remove-newFiles')
              return errors
            }
            
          }



        return errors;
      }

      if (this.loadedFiles.length < 2) {
        errors.files = true
        this.scrollTo('input-newFiles')
        return errors
      } else if (this.loadedFiles.length === 2){
        this.loadedFiles.forEach(file => {
          if (file.name !== this.filesName.zip && file.name !== this.filesName.sh) {
            errors.files = true
            this.scrollTo('remove-newFiles')
            return errors
          }
        })
      }

      return errors;
    },

    /**
     * Muestra aviso de textos vacíos
     */
    async showEmptyTextsPopup() {
      // Creo promesa para que devuelva valores antes de destruir el modal
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(i18n.t('messages.saveWithoutTexts', {text: this.withoutAdminTexts ? i18n.t('changesText') : i18n.t('versionText') }), {
            title: i18n.t('buttons.save'),
            okVariant: "primary",
            okTitle: i18n.t("buttons.save"),
            cancelTitle: i18n.t("buttons.cancel"),
            headerBgVariant: "primary",
            headerTextVariant: "light",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          })
          .then((accept) => {
            console.log('guardar y no mostrar el aviso', accept)
            if (accept) {
              this.saveEmptyTexts = true
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch((cancel) => {
            console.log(cancel);
            reject(false)
          });
        })
    },

    hasText(text) {
      if (!text) return false
      return Object.keys(text).findIndex(k => text[k] !== '') !== -1
    },

    /**
     * Comprueba si hay textos vacíos
     */
    async checkEmptyTexts() {
      this.withoutAdminTexts = false
      this.withoutTexts = false

      if (!this.saveEmptyTexts){
        const indexAdmin = Object.keys(this.admin_text).findIndex(k => this.admin_text[k] === '')
        if (indexAdmin !== -1) {
          // errors.admin_text = true
          this.withoutAdminTexts = true
          this.tabIndexAdmin = indexAdmin
          this.clickTab(indexAdmin - 1, true)
          return await this.showEmptyTextsPopup()
        }
      }
      
      if (!this.saveEmptyTexts) {
        const index = Object.keys(this.text).findIndex(k => this.text[k] === '')
        if (index !== -1) {
          // errors.text = true
          this.withoutTexts = true
          this.tabIndex = index
          this.clickTab(index - 1, false)
          return await this.showEmptyTextsPopup()
        }
      }
      return true
    },

    /**
     * Valida y guarda los datos de la version
     */
    async saveFirmware() {
      try {
        //
        // Inicializamos valores
        //
        this.errors = {};

        //
        // Si hay una petición en curso no hago nada
        //
        if (this.isLoading) return;

        //
        // Valido extensiones de archivos del formulario
        //
        this.errors = this.validate();
        if (Object.keys(this.errors).length !== 0) return;

        //
        // Aviso si hay textos vacíos
        //
        const respEmptyTexts = await this.checkEmptyTexts()
        if (!respEmptyTexts) return

        //
        // Preparo datos y realizo la petición
        //
        this.isLoading = true;

        const files = []
        this.loadedFiles.forEach( file => {
          files.push({ name: file.name, type: file.type })
        })

        const data = {
          version: this.newVersion,
          ws_type: this.newType,
          enduser_visible: false,
          files,
          text: this.text,
          admin_text: this.admin_text,
        };
        
        if (!this.hasText(this.admin_text)) delete data.admin_text  
        if (!this.hasText(this.text)) delete data.text

        if (this.newTypeModel === CONSTANTS.TAG.WS_AZ) {
          data.arch = 'imx6ull';
        } else if (this.newTypeModel === CONSTANTS.WSHARD.AIRQSENSOR_WIFI) {
          data.arch = 'esp32_c3_mini';
          data.group = 'airqsensorWifi';
        } else if (this.newType === CONSTANTS.WEBSERVERTYPE.AIDOO)  {
          data.aidoo_group = this.newGroup;
        }

        const response = await WebService.publishVersion(data);
        // Subo archivos de firmware al S3
        const errors = await this.uploadFirmwareFiles(response, this.loadedFiles)
        
        if (!errors) {
          this.showMsgOk(
            i18n.t("publishBeta"),
            i18n.t("messages.firmwareMsg", { version: this.newVersion })
          );
          this.$root.$emit("bv::toggle::collapse", "add-firmware");
          this.animateTableRows()
        }

        this.isLoading = false;
      } catch(error) {
        console.log(error)
        if (error.status === 400) {
          this.modalWarningTitle = i18n.t('messages.errors.versionExistsTitle')
          this.modalWarningMsg = i18n.t('messages.errors.versionExistsMsg', { version: this.newVersion })
          this.showModalWarning = true
        } else {
          this.$bvModal.show("bv-modal-error");
        }
        this.isLoading = false;
      }
    },

    /**
     * Oculta modal y pon el foco en el campo versión
     */
    hideModalWarning() {
      this.showModalWarning = false;
      setTimeout(() => {
        this.scrollTo('input-newVersion')
      }, 200)
    },

  },

  created() {
    this.getGroupOptions()
  },

  async mounted() {
    // Resetea el store para página y filtro
    store.dispatch("navModule/deletePageAndFilter");
    await this.findFirmwares();
    // Estilo de icono HTML en las dos barras de herramientas
    this.$nextTick(() => {
      this.animateTableRows()
      this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[0])
      this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[1])
      this.editorStyle(document.getElementsByClassName('ql-editor')[0])
      this.editorStyle(document.getElementsByClassName('ql-editor')[1])
    })
  }
};
</script>

<style lang="scss">
.az-input__search {
  display: flex;
}

.marginTop {
  margin-top: 3.5rem !important;
}

label[for="input-newPin"] {
  text-transform: capitalize;
}

.buttonsAddServer {
  float: left;
  padding-top: 1.3rem;
}

.buttonsAddServer .az-icon {
  position: relative;
  top: -0.1rem;
  fill: var(--light);
}

.form-group {
  input,
  select {
    box-shadow: 1px 1px 5px #000 !important;
  }
}

.nav-tabs {
  background-color: var(--gray7) !important;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 3rem;
  border: 1px dashed var(--gray6);
  border-radius: 5px;
  transition: 0.2s;
  &.is-active {
    background-color: var(--gray7);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: var(--gray7);
  border: 1px solid var(--gray5);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-input {
  height: 100%;
  z-index: 2 !important;
}

.custom-file {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.input__newVersion {
  position: relative;
  left: 0.5rem;
}

label {
  margin-top: 0.2rem
}
</style>