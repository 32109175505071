/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-9': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.94 15.359l-5-10.625a.625.625 0 00-.3-.3L15.263.059a.624.624 0 00-.53 0L5.36 4.434a.625.625 0 00-.3.3l-5 10.625a.622.622 0 00.565.891H2.5v13.125c0 .345.28.625.625.625h23.75c.345 0 .625-.28.625-.625V16.25h1.875a.624.624 0 00.565-.891zm-16.133 9.016l-6.308 3.881v-7.762l6.308 3.881zM9.083 20h11.832L15 23.641l-5.916-3.64zm0 8.75l5.916-3.641 5.916 3.641H9.083zm13.416-.494l-6.307-3.88 6.307-3.882v7.762zM26.874 15a.625.625 0 00-.625.625V28.75h-2.5v-9.375c0-.015-.007-.029-.009-.044-.002-.042-.013-.083-.025-.125a.59.59 0 00-.042-.113c-.008-.014-.008-.03-.016-.044-.013-.021-.034-.033-.048-.051a.575.575 0 00-.296-.208.632.632 0 00-.118-.024c-.025-.005-.046-.016-.07-.016H6.874c-.026 0-.047.011-.072.015a.537.537 0 00-.231.075.59.59 0 00-.183.159c-.015.019-.035.03-.047.051-.009.014-.009.03-.016.044a.603.603 0 00-.043.115.554.554 0 00-.025.125c-.001.012-.009.026-.009.041v9.375h-2.5V15.625A.625.625 0 003.124 15H1.61l4.484-9.528L15 1.315l8.906 4.155L28.39 15h-1.515zm-8.75-8.75h-6.25a.625.625 0 00-.625.625v7.5c0 .345.28.625.625.625h6.25c.345 0 .625-.28.625-.625v-7.5a.625.625 0 00-.625-.625zm-.625 7.5h-5V7.5h5v6.25z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
