/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'success-modal': {
    width: 31,
    height: 30,
    viewBox: '0 0 31 30',
    data: '<path pid="0" d="M15.5 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15zm0 1.154C7.853 1.154 1.654 7.353 1.654 15S7.853 28.846 15.5 28.846 29.346 22.647 29.346 15 23.147 1.154 15.5 1.154zm5.903 8.992a1.154 1.154 0 011.683 1.576l-.052.055-8.846 8.847a1.154 1.154 0 01-1.576.051l-.056-.051-4.59-4.591a1.154 1.154 0 011.576-1.684l.055.052 3.775 3.775 8.03-8.03z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
