/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speed-auto': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M14.288 33.708h-1.5c-1.5 0-2.8-1.2-2.8-2.7v-15.6c0-1.6 1.1-2.8 2.8-2.8h1.5c1.5 0 2.8 1.2 2.8 2.8v15.5c-.1 1.5-1.3 2.8-2.8 2.8zm-1.5-19.5c-.8 0-1.2.5-1.2 1.2v15.5c0 .7.6 1.2 1.2 1.2h1.5c.7 0 1.2-.6 1.2-1.2v-15.5c0-.7-.6-1.2-1.2-1.2h-1.5zM5.288 15.708h-1.5c-1.6 0-2.8 1.1-2.8 2.8v12.4c0 1.5 1.2 2.8 2.8 2.8h1.5c1.5 0 2.8-1.2 2.8-2.8v-12.5c-.1-1.5-1.3-2.7-2.8-2.7zM27.188.908c-3.6 0-6.5 2.9-6.5 6.5 0 .5.1 1 .2 1.4-1.1.3-1.8 1.3-1.8 2.6v19.5c0 1.5 1.2 2.8 2.8 2.8h1.5c1.5 0 2.8-1.2 2.8-2.8v-17.2c.4.1.7.1 1.1.1 3.6 0 6.5-2.9 6.5-6.4-.2-3.6-3.1-6.5-6.6-6.5zm-2.6 30c0 .7-.6 1.2-1.2 1.2h-1.6c-.7 0-1.2-.6-1.2-1.2v-19.5c0-.6.3-1 .8-1.2.7 1.4 1.8 2.4 3.2 3.1v17.6zm2.6-18.6c-2.7 0-5-2.2-5-4.9s2.2-5 5-5 5 2.2 5 5-2.3 4.9-5 4.9z"/><path pid="1" d="M27.788 3.908l2.6 6.9h-1.5l-.5-1.3h-2.4l-.5 1.3h-1.5l2.5-6.9h1.3zm-.7 1.8l-.9 2.6h1.8l-.9-2.6z"/></g>'
  }
})
