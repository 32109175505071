/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    data: '<path pid="0" d="M17.5 12a1.5 1.5 0 010 3h-11a1.5 1.5 0 010-3h11zm-16 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm16-6a1.5 1.5 0 010 3h-11a1.5 1.5 0 010-3h11zm-16 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm16-6a1.5 1.5 0 010 3h-11a1.5 1.5 0 010-3h11zm-16 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
