/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-small-90': {
    width: 33,
    height: 30,
    viewBox: '0 0 33 30',
    data: '<path pid="0" d="M11.108.032a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.032zM26.14 22.3a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.137.063 17.717 4.875 23.452 9.687 29.187 18.267 29.937 24 25.125a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zM20.963 10.77l.41-.006c1.275-.053 2.26-.48 2.956-1.28.696-.799 1.044-1.898 1.044-3.297v-.616c-.004-.669-.125-1.263-.363-1.781-.239-.52-.573-.917-1.003-1.194a2.67 2.67 0 00-1.476-.415 2.79 2.79 0 00-1.453.384c-.43.255-.765.613-1.006 1.072-.24.459-.36.963-.36 1.513 0 .865.223 1.551.669 2.06.446.51 1.04.764 1.781.764.58 0 1.088-.213 1.522-.64-.12 1.296-.903 1.976-2.352 2.041l-.369.006v1.39zm1.608-4.07c-.38 0-.675-.147-.884-.44-.21-.294-.315-.67-.315-1.127 0-.462.108-.845.323-1.15.215-.306.49-.459.824-.459.362 0 .65.156.865.467.215.312.323.75.323 1.315v.685c-.1.196-.247.364-.441.502a1.165 1.165 0 01-.695.207zm6.808 4.105c.926 0 1.63-.308 2.11-.923.48-.614.72-1.502.72-2.663V5.675c-.011-1.126-.26-1.99-.743-2.59-.485-.602-1.184-.903-2.099-.903-.914 0-1.614.304-2.1.911-.487.607-.73 1.497-.73 2.669v1.545c.012 1.126.26 1.99.744 2.594.484.603 1.183.905 2.098.905zm0-1.343c-.411 0-.71-.167-.896-.499-.187-.332-.28-.84-.28-1.525V5.4c.012-.642.11-1.114.297-1.415.186-.302.476-.453.867-.453.404 0 .7.159.891.476.19.317.285.823.285 1.519V7.52c-.007.657-.101 1.145-.282 1.464-.18.32-.475.479-.882.479z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
