/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-small-off': {
    width: 33,
    height: 30,
    viewBox: '0 0 33 30',
    data: '<path pid="0" d="M29.126 0C17.31 0 9.396 2.186 4.746 6.037 1.367 8.834 0 12.231 0 15.673l.008.44c.087 2.33.862 4.393 2.244 6.028l.168.192.07-.125C.92 25.048 0 27.627 0 29.126a.875.875 0 001.749 0l.006-.185c.052-.773.41-1.971 1.01-3.374l.32-.717c.114-.245.234-.493.36-.746l.284-.556-.193-.15c1.607 1.301 3.676 2.139 6.057 2.44l.227.03.001-1.59-.173-.2-.438-.07c-1.73-.309-3.238-.957-4.44-1.898l-.164-.135-.087.148c3.392-5.796 8.38-10.903 14.226-13.735a.876.876 0 00.405-1.168.877.877 0 00-1.167-.406l-.567.283c-5.463 2.811-10.354 7.711-13.84 13.271l-.203.33.176.223c-1.176-1.422-1.8-3.217-1.8-5.248 0-2.947 1.191-5.862 4.14-8.305l.285-.23C10.116 4.035 16.6 2.131 26.094 1.8l.985-.028-.446.519c-2.322 2.776-3.974 5.892-4.94 9.869l-.36 1.569c-.478 2.036-.972 3.62-1.605 5l-.131.287 1.805-.001.186-.124c.102-.241.2-.488.294-.74l.208-.578c.066-.19.13-.379.19-.569l.177-.58c.057-.197.113-.4.17-.61l.169-.658.174-.727.185-.813.099-.445c1.096-4.983 3.196-8.381 6.49-11.678A.874.874 0 0029.126 0zM15.822 29.694c.722 0 1.361-.173 1.919-.52.558-.347.986-.84 1.286-1.478.3-.638.449-1.381.449-2.229v-.384c0-.843-.153-1.587-.458-2.232-.306-.644-.737-1.139-1.295-1.484-.558-.344-1.195-.517-1.912-.517-.718 0-1.355.173-1.913.517-.558.345-.99.84-1.295 1.484-.305.645-.458 1.39-.458 2.238v.426c.004.827.16 1.56.467 2.196.308.636.741 1.126 1.3 1.469.56.343 1.197.514 1.91.514zm0-1.454c-.607 0-1.072-.242-1.395-.727-.323-.485-.485-1.167-.485-2.046v-.42c.004-.894.168-1.573.491-2.036.323-.463.782-.695 1.378-.695.599 0 1.06.235 1.383.704.323.469.485 1.155.485 2.057v.42c-.004.895-.166 1.575-.485 2.042-.32.468-.776.701-1.372.701zm6.716 1.336V26.06h3.406v-1.431h-3.406v-2.223h3.831v-1.437h-5.604v8.608h1.773zm6.634 0V26.06h3.405v-1.431h-3.405v-2.223h3.83v-1.437h-5.604v8.608h1.774z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
