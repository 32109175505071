/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ethernet': {
    width: 34,
    height: 29,
    viewBox: '0 0 34 29',
    data: '<path pid="0" d="M.705 14.527c.194-.403.524-.516.943-.508 4.806.008 9.612.008 14.418.008h.395V10.61h-3.903c-.613 0-.879-.242-.879-.839-.008-2.653-.008-5.298 0-7.95 0-.573.258-.831.83-.831 3.113-.008 6.226-.008 9.338 0 .589 0 .839.266.839.855.008 2.636.008 5.281 0 7.918 0 .605-.266.847-.871.847h-3.91v3.418h14.763c.178 0 .371.009.54.065a.62.62 0 01.444.637.642.642 0 01-.452.637 1.542 1.542 0 01-.475.064h-6.58v3.42h3.814c.734 0 .96.225.96.95v5.96c0 .612.007 1.225 0 1.838-.009.629-.259.879-.88.879-3.08.008-6.16 0-9.24 0-.637 0-.887-.258-.887-.903v-7.822c0-.66.25-.895.92-.895h3.87v-3.394H9.663v3.394h3.935c.565 0 .839.25.839.815.008 2.66.008 5.322 0 7.982 0 .565-.266.823-.839.823-3.112.008-6.225.008-9.337 0-.572 0-.83-.258-.83-.83-.009-2.653-.009-5.298 0-7.951 0-.605.266-.839.878-.839h3.903V15.44h-.363c-2.072 0-4.144-.009-6.209.008-.427 0-.758-.113-.943-.508.008-.154.008-.283.008-.412zM21.291 2.384h-8.209v6.814h8.209V2.384zM4.84 20.252v6.814h8.201v-6.814h-8.2zm16.482 6.814h8.2v-6.814h-8.2v6.814z" _fill="#000" fill-rule="nonzero"/>'
  }
})
