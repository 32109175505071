import StorageService from "@/services/storage.service";

const Role = {

  methods: {
    isExtraRole() {
      const extraRole = StorageService.getItem('extra_roles');
      return extraRole.includes('fwversions_write');
    },

    isTextWriteRole() {
      const extraRole = StorageService.getItem('extra_roles');
      return extraRole.includes('fwversions_text_write');
    },

    isUpdateRole() {
      const extraRole = StorageService.getItem('extra_roles');
      return extraRole.includes('fwversions_update_beta');
    },
    
    isAdminVendor() {
      const extraRole = StorageService.getItem('extra_roles');
      return extraRole.includes('admin_vendor');
    },
  },

}

export default Role;