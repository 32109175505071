/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'etape-air': {
    width: 30,
    height: 23,
    viewBox: '0 0 30 23',
    data: '<path pid="0" d="M20.583 14.374a3.843 3.843 0 010 7.676 3.843 3.843 0 01-3.842-3.843.655.655 0 01.655-.645.645.645 0 01.635.645 2.552 2.552 0 102.552-2.542H6.2a.645.645 0 010-1.29zm4.782-11.968a4.635 4.635 0 010 9.27H.645a.645.645 0 010-1.291h24.72a3.344 3.344 0 10-3.334-3.344.655.655 0 01-1.3 0 4.645 4.645 0 014.634-4.635zM13.131.296a3.843 3.843 0 111.478 7.39H5.065a.655.655 0 01-.645-.645c0-.357.289-.646.645-.646h9.544a2.552 2.552 0 10-2.552-2.542.645.645 0 11-1.291 0A3.843 3.843 0 0113.131.295z" _fill="#7B8794" fill-rule="nonzero"/>'
  }
})
