/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal3': {
    width: 19,
    height: 14,
    viewBox: '0 0 19 14',
    data: '<path pid="0" d="M9.2 13.285a1.783 1.783 0 110-3.567 1.783 1.783 0 010 3.567zm5.735-6.173A1.147 1.147 0 0113.4 8.817a6.25 6.25 0 00-4.2-1.612 6.25 6.25 0 00-4.193 1.604 1.147 1.147 0 11-1.532-1.707A8.544 8.544 0 019.2 4.912c2.127 0 4.158.78 5.735 2.2zm2.962-1.36a1.147 1.147 0 01-1.62.064 10.69 10.69 0 00-7.29-2.855c-2.62 0-5.12.943-7.083 2.669A1.147 1.147 0 01.39 3.907 12.98 12.98 0 018.987.667c3.295 0 6.434 1.232 8.847 3.465.465.43.493 1.156.063 1.62z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
