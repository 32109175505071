/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save': {
    width: 18,
    height: 13,
    viewBox: '0 0 18 13',
    data: '<path pid="0" d="M14.906.53a1.813 1.813 0 012.563 2.564l-9.37 9.37a1.81 1.81 0 01-2.568.005l-5-5a1.813 1.813 0 012.563-2.563l3.719 3.718L14.906.531z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
