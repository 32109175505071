/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speed-3': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M4.222 8h-1.5c-1.6 0-2.7 1.1-2.7 2.7v12.5c0 1.5 1.2 2.8 2.8 2.8h1.5c1.5 0 2.8-1.2 2.8-2.8V10.7c-.1-1.5-1.4-2.7-2.9-2.7zM13.222 5h-1.5c-1.7 0-2.8 1.1-2.8 2.7v15.5c0 1.6 1.3 2.8 2.8 2.8h1.5c1.5 0 2.8-1.3 2.8-2.8V7.7c0-1.5-1.3-2.7-2.8-2.7zM22.222.9h-1.5c-1.7 0-2.8 1.2-2.8 2.8v19.5c0 1.6 1.3 2.8 2.8 2.8h1.5c1.5 0 2.8-1.3 2.8-2.8V3.7c0-1.6-1.3-2.8-2.8-2.8z"/></g>'
  }
})
