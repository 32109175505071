/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order-blocks': {
    width: 31,
    height: 26,
    viewBox: '0 0 31 26',
    data: '<path pid="0" d="M11.133 18.571c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 25.133v-5.695c0-.479.388-.867.867-.867h10.266zM26 21.013v3.415c0 .868-.667 1.572-1.489 1.572h-2.397v-1.238h2.397c.175 0 .316-.15.316-.334v-3.415H26zm-10.737 3.649c.05.054.115.088.186.097l3.96.003L19.405 26l-4.03-.007a1.485 1.485 0 01-.949-.463zm-4.39-4.964H1.127v5.175h9.746v-5.175zm5.814-1.128v1.238H15.49a.325.325 0 00-.316.334v2.15H14v-2.15c0-.868.667-1.572 1.489-1.572h1.198zm13.446-6.37c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H19.867a.867.867 0 01-.867-.867v-5.695c0-.479.388-.867.867-.867h10.266zm-.26 1.126h-9.746v5.175h9.746v-5.175zm-18.74-4.04c.479 0 .867.388.867.866v5.696a.867.867 0 01-.867.866H.867A.867.867 0 010 15.848v-5.696c0-.478.388-.866.867-.866h10.266zm-.26 1.126H1.127v5.175h9.746v-5.175zM11.133 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H.867A.867.867 0 010 6.562V.867C0 .388.388 0 .867 0h10.266zm14 0c.479 0 .867.388.867.867v5.695a.867.867 0 01-.867.867H14.867A.867.867 0 0114 6.562V.867c0-.479.388-.867.867-.867h10.266zm-14.26 1.127H1.127v5.175h9.746V1.127zm14 0h-9.746v5.175h9.746V1.127z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
