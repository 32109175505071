/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 23,
    height: 30,
    viewBox: '0 0 23 30',
    data: '<path pid="0" d="M11.5 0c4.451 0 8.059 3.683 8.059 8.21v4.232c1.774.005 3.217 1.297 3.217 3.175v11.111c0 1.804-1.443 3.272-3.217 3.272H3.428C1.654 30 .21 28.532.21 26.728v-11.11c0-1.962 1.443-3.172 3.217-3.172V8.21C3.428 3.683 7.048 0 11.5 0zm1 17h-2a1 1 0 00-1 1v7a1 1 0 001 1h2a1 1 0 001-1v-7a1 1 0 00-1-1zm-1-12.692C9.57 4.308 8 5.841 8 7.724v4.74l7-.001V7.724c0-1.883-1.57-3.416-3.5-3.416z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
