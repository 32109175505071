/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal1': {
    width: 4,
    height: 5,
    viewBox: '0 0 4 5',
    data: '<path pid="0" d="M1.783 4.285a1.783 1.783 0 110-3.567 1.783 1.783 0 010 3.567z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
