/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'schedules': {
    width: 26,
    height: 30,
    viewBox: '0 0 26 30',
    data: '<path pid="0" d="M13.04 4.27C6.03 4.27.35 9.952.35 16.96c0 7.009 5.681 12.69 12.69 12.69 7.008 0 12.69-5.681 12.69-12.69 0-7.008-5.682-12.69-12.69-12.69zm0 1.652c6.096 0 11.038 4.942 11.038 11.038S19.136 27.998 13.04 27.998 2.002 23.056 2.002 16.96 6.944 5.922 13.04 5.922zm-.3 2.268a.826.826 0 00-.819.723l-.006.104v7.909c0 .28.14.536.368.687l.102.059.098.069 4.42 4.446a.826.826 0 001.241-1.084l-.074-.085-4.503-4.503V9.017a.826.826 0 00-.826-.827zM10.204.807A.826.826 0 009.198.394l-.104.043-6.327 3.164a.826.826 0 00.635 1.521l.104-.043 6.327-3.164a.826.826 0 00.37-1.108zm5.957-.37a.826.826 0 00-.835 1.421l.097.057 6.327 3.164a.826.826 0 00.836-1.421l-.097-.057L16.16.437z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
