/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-5': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.56 5.027l-16.25-5a.632.632 0 00-.556.095.622.622 0 00-.254.502v7.517L.411 12.537a.625.625 0 00-.411.587v16.251c0 .345.28.625.625.625h28.75c.346 0 .626-.28.626-.625V5.625a.624.624 0 00-.441-.598zM13.75 28.75H1.25V13.562l12.088-4.395h.001l.411-.15V28.75zm15 0H15V8.124a.631.631 0 00-.266-.512.623.623 0 00-.572-.075l-.412.149V1.47l15 4.616V28.75zm-23.125-15h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.626zm-.625 5H3.75V15H5v3.75zm.625 2.5h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625zm-.625 5H3.75V22.5H5v3.75zM23.126 12.5h2.5c.345 0 .625-.28.625-.626v-5a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5H25v3.75h-1.25V7.5zm-6.875 5h2.5c.345 0 .625-.28.625-.626v-5a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5h1.25v3.75H17.5V7.5zM23.126 20h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.626h-2.5a.625.625 0 00-.625.626v5c0 .345.28.625.625.625zm.625-5H25v3.75h-1.25V15zm-6.875 5h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.626h-2.5a.625.625 0 00-.625.626v5c0 .345.28.625.625.625zm.625-5h1.25v3.75H17.5V15zm5.625 12.5h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5H25v3.75h-1.25V22.5zm-6.875 5h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm.625-5h1.25v3.75H17.5V22.5zm-5.626-8.75h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.626zm-.625 5H10V15h1.25v3.75zm.625 2.5h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625zm-.625 5H10V22.5h1.25v3.75z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
