/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-16': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M26.875 26.25h-.625v-.625a.625.625 0 00-.625-.625H23.75v-6.25h.625a1.877 1.877 0 001.875-1.875A1.877 1.877 0 0024.375 15h-.656a9.383 9.383 0 00-7.528-8.57A1.874 1.874 0 0015 5.115V3.75h3.125a.625.625 0 00.442-1.067l-.807-.808.807-.808A.625.625 0 0018.125 0h-3.75a.625.625 0 00-.625.625v4.49c-.589.21-1.04.701-1.191 1.315A9.383 9.383 0 005.03 15h-.656A1.877 1.877 0 002.5 16.875c0 1.034.841 1.875 1.875 1.875H5V25H3.125a.625.625 0 00-.625.625v.625h-.625A1.877 1.877 0 000 28.125v1.25c0 .345.28.625.625.625h27.5c.345 0 .625-.28.625-.625v-1.25a1.877 1.877 0 00-1.875-1.875zM15 1.251h1.616l-.182.183a.625.625 0 000 .883l.182.183H15V1.251zM14.375 7.5c4.27 0 7.781 3.31 8.101 7.5H6.274c.32-4.19 3.831-7.5 8.101-7.5zm-10 10a.626.626 0 010-1.25h20a.626.626 0 010 1.25h-20zM22.5 18.75V25h-1.25v-6.25h1.25zm-2.5 0V25h-1.25v-6.25H20zm-2.5 0V25h-1.25v-6.25h1.25zm-2.5 0V25h-1.25v-6.25H15zm-2.5 0V25h-1.25v-6.25h1.25zm-2.5 0V25H8.75v-6.25H10zm-2.5 0V25H6.25v-6.25H7.5zm20 10H1.25v-.625c0-.344.28-.625.625-.625h1.25c.345 0 .625-.28.625-.625v-.625H25v.625c0 .345.28.625.625.625h1.25c.345 0 .625.281.625.625v.625z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
