/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'update': {
    width: 16,
    height: 16,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M7.6 22c-.1-.8-.2-1.5-.2-2.3 0-6.6 5.2-12 11.7-12.4l-2.7 2.6c-.2.2-.2.6 0 .9.1.1.3.2.4.2.2 0 .3-.1.4-.2l3.7-3.6c.1-.1.2-.2.3-.4v-.1-.1-.1-.1c-.1-.1-.1-.3-.3-.3l-5.1-2.5c-.3-.2-.7 0-.8.3-.2.3 0 .7.3.8l2.9 1.4C11.4 7 6.1 12.7 6.1 19.7c0 .8.1 1.6.2 2.5.1.3.3.5.6.5H7c.4 0 .6-.3.6-.7zm28.8 2.1c-.2-.3-.6-.4-.9-.2l-2.8 1.7c.8-1.8 1.2-3.7 1.2-5.6 0-6-3.9-11.3-9.7-13.3-.3-.1-.7.1-.8.4-.1.3.1.7.4.8 5.3 1.8 8.8 6.7 8.8 12.1 0 1.9-.4 3.7-1.2 5.4l-.7-3.6c-.1-.3-.4-.6-.8-.5-.4.1-.6.4-.5.8l1.1 5.3c0 .1.1.3.2.4l.1.1h.2c.1 0 .2.1.3.1.1 0 .2 0 .3-.1l4.8-2.8c.1-.3.2-.7 0-1zm-8.1 5.7c-2.3 1.9-5.3 2.9-8.4 2.9-4.3 0-8.2-2-10.6-5.3l4 1c.3.1.7-.1.8-.4.1-.3-.1-.7-.5-.7l-5.5-1.4c-.1 0-.2-.1-.4 0h-.1-.1-.1-.1l-.1.1-.1.1c-.1.1-.1.2-.2.3l-.2 5.1c0 .3.3.6.6.6s.6-.3.6-.6l.1-3.1c2.6 3.5 6.9 5.5 11.5 5.5 3.4 0 6.6-1.1 9.2-3.2.3-.2.3-.6.1-.9.2-.2-.2-.2-.5 0z"/>'
  }
})
