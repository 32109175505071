/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hauto': {
    width: 28,
    height: 26,
    viewBox: '0 0 28 26',
    data: '<path pid="0" d="M26.813 12.745a.636.636 0 01-.905 0L16 2.76a4.24 4.24 0 01-5.07.041l-9.839 9.919a.636.636 0 01-.905 0 .649.649 0 010-.913L10.772 1.134a3.817 3.817 0 015.43 0l10.61 10.699c.25.252.25.66 0 .912zM12.86 9.535c0-.356.287-.645.64-.645.353 0 .64.289.64.645v8.602a.643.643 0 01-.64.646.643.643 0 01-.64-.646V9.535zm-2.68-.567a.64.64 0 111.241.312l-2.064 8.347a.64.64 0 11-1.242-.312l2.064-8.347zM7.713 7.764a.637.637 0 01.866-.267c.312.167.43.558.264.873L4.84 15.965a.637.637 0 01-.866.267.648.648 0 01-.264-.873l4.005-7.595zm7.883 1.667a.64.64 0 111.242-.312l2.064 8.347a.64.64 0 11-1.242.312l-2.064-8.347zm3.761-1.374l2.729 5.173a7.939 7.939 0 00-1.233.412l-2.626-4.979a.648.648 0 01.265-.872.637.637 0 01.865.266zm5.91 15.18h-3.144l-.658 1.892H20L23.066 17h1.266l3.071 8.129h-1.471l-.664-1.893zm-2.748-1.14h2.352l-1.176-3.372-1.176 3.372z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
