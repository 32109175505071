/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details-old': {
    width: 27,
    height: 26,
    viewBox: '0 0 27 26',
    data: '<path pid="0" d="M6.519 19.142a.69.69 0 01.494.209.732.732 0 01.2.398l.01.119v4.906c0 .201-.08.384-.21.517a.695.695 0 01-.381.2l-.113.009H1.704a.69.69 0 01-.495-.21.732.732 0 01-.2-.398L1 24.774v-4.906c0-.202.08-.385.21-.517a.695.695 0 01.38-.2l.114-.01h4.815zm.5.226H1.204v5.906h5.815v-5.906zm18.277-.226a.69.69 0 01.495.209.732.732 0 01.2.398l.009.119v4.906c0 .201-.08.384-.21.517a.695.695 0 01-.38.2l-.114.009H10.852a.69.69 0 01-.494-.21.732.732 0 01-.2-.398l-.01-.118v-4.906c0-.202.08-.385.21-.517a.695.695 0 01.38-.2l.114-.01h14.444zm.5.226H10.352v5.906h15.444v-5.906zM6.52 9.82a.69.69 0 01.494.21.732.732 0 01.2.398l.01.118v4.906c0 .202-.08.384-.21.517a.695.695 0 01-.381.2l-.113.01H1.704a.69.69 0 01-.495-.21.732.732 0 01-.2-.399L1 15.453v-4.906c0-.202.08-.384.21-.517a.695.695 0 01.38-.2l.114-.01h4.815zm.5.226H1.204v5.906h5.815v-5.906zm18.277-.226a.69.69 0 01.495.21.732.732 0 01.2.398l.009.118v4.906c0 .202-.08.384-.21.517a.695.695 0 01-.38.2l-.114.01H10.852a.69.69 0 01-.494-.21.732.732 0 01-.2-.399l-.01-.118v-4.906c0-.202.08-.384.21-.517a.695.695 0 01.38-.2l.114-.01h14.444zm.5.226H10.352v5.906h15.444v-5.906zM6.52.5a.69.69 0 01.494.21.732.732 0 01.2.398l.01.118v4.906c0 .202-.08.385-.21.517a.695.695 0 01-.381.2l-.113.01H1.704a.69.69 0 01-.495-.21.732.732 0 01-.2-.398L1 6.132V1.226c0-.201.08-.384.21-.517a.695.695 0 01.38-.2L1.704.5h4.815zm.5.226H1.204v5.906h5.815V.726zM25.296.5a.69.69 0 01.495.21.732.732 0 01.2.398l.009.118v4.906c0 .202-.08.385-.21.517a.695.695 0 01-.38.2l-.114.01H10.852a.69.69 0 01-.494-.21.732.732 0 01-.2-.398l-.01-.119V1.226c0-.201.08-.384.21-.517a.695.695 0 01.38-.2L10.852.5h14.444zm.5.226H10.352v5.906h15.444V.726z" _stroke="#202832" _fill="none" fill-rule="evenodd"/>'
  }
})
