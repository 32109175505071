/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto-bold-lock': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M25.704 18c1.854 0 3.358 1.535 3.358 3.42v1.764c.739.002 1.34.54 1.34 1.323v4.63c0 .751-.601 1.363-1.34 1.363H22.34c-.739 0-1.34-.612-1.34-1.363v-4.63c0-.817.601-1.321 1.34-1.321V21.42c0-1.886 1.51-3.421 3.364-3.421zM2.523 21.701l5.331 1.451a.776.776 0 01-.408 1.496l-3.487-.95a12.28 12.28 0 0013.042 4.653v1.596a13.825 13.825 0 01-3.103.353 13.829 13.829 0 01-10.994-5.42l-.106 2.8a.776.776 0 01-.774.745h-.03a.775.775 0 01-.745-.803l.195-5.23a.755.755 0 01.176-.437.727.727 0 01.068-.08l.006-.008a.275.275 0 01.063-.068c.045-.027.049-.03.067-.035.017-.016.019-.017.061-.044a.396.396 0 01.107-.034l.006-.001.02-.004.02-.006.006-.002c.022-.007.022-.007.048-.012a.746.746 0 01.431.04zm23.18 3.382a.833.833 0 00-.833.834V28a.833.833 0 001.667 0v-2.083a.833.833 0 00-.833-.834zm0-5.288c-.803 0-1.458.639-1.458 1.423v1.975h2.917v-1.975c0-.784-.654-1.423-1.458-1.423zM15.458 9.837l.146.117c.09.393.184.685.448 1.368l2.933 7.853c.25.672.489 1.257.648 1.587l.103.215h-2.878l-.148-.124a9.394 9.394 0 00-.34-1.39l-.418-1.192h-3.814l-.418 1.193c-.195.548-.281.895-.353 1.385l-.148.128h-2.89l.118-.22c.208-.392.392-.825.671-1.582l2.906-7.852c.238-.66.343-1.002.421-1.367l.147-.119h2.866zM9.869.08l5 2.5a.739.739 0 01.358.415.408.408 0 01.029.068l.006.01c.012.023.012.023.028.077.003.04.003.04 0 .066l-.004.023.002.007.002.012a.301.301 0 01-.013.187l-.002.006-.01.037-.007.027a.724.724 0 01-.297.417l-3.64 3.64a.773.773 0 01-1.095 0 .776.776 0 010-1.096l2.344-2.343c-6.22.669-11.02 5.941-11.02 12.266 0 .73.07 1.468.215 2.253a.775.775 0 11-1.524.282A13.88 13.88 0 010 16.399C0 9.57 4.987 3.791 11.636 2.697l-2.459-1.23A.776.776 0 018.83.427.777.777 0 019.87.08zm4.168 12.286a8.223 8.223 0 01-.05.149l-1.188 3.576h2.49l-1.2-3.576-.052-.149zm4.496-9.059A13.925 13.925 0 0127.727 15h-1.559a12.376 12.376 0 00-8.153-10.233.777.777 0 01-.472-.989.777.777 0 01.99-.47z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
