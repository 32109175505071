/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-bold': {
    width: 31,
    height: 32,
    viewBox: '0 0 31 32',
    data: '<path pid="0" d="M29.825 31H1.075a.625.625 0 01-.625-.625 7.016 7.016 0 015.321-6.819l4.789-1.197.435-1.741a8.672 8.672 0 01-2.299-4.653 1.881 1.881 0 01-1.504-1.609l-.312-2.5a1.876 1.876 0 011.296-2.019c-.09-.762-.226-2.026-.226-2.587 0-2.413.725-4.886 6.05-4.998C15.865 1 17.868 1 18.737 1c2.205 0 3.798.726 4.735 2.158 1.22 1.862.636 3.146-.067 3.895l-.468.468-.115 2.354a1.87 1.87 0 011.196 1.983l-.312 2.5a1.877 1.877 0 01-1.355 1.572 8.615 8.615 0 01-2.419 4.799l.408 1.63 4.788 1.197a7.015 7.015 0 015.322 6.817c0 .344-.28.627-.625.627zM1.732 29.75h27.434a5.768 5.768 0 00-4.341-4.981l-5.153-1.288a.627.627 0 01-.454-.455l-.587-2.348a.623.623 0 01.189-.617c1.326-1.188 2.201-2.958 2.342-4.736a.625.625 0 01.624-.575c.375 0 .642-.235.681-.548l.313-2.5a.608.608 0 00-.149-.485.615.615 0 00-.465-.213.629.629 0 01-.617-.655l.15-3.129a.624.624 0 01.183-.411l.625-.625c.391-.417.749-1.073-.081-2.342-.691-1.056-1.931-1.592-3.689-1.592-1.281 0-2.779.135-4.174 1.134a.632.632 0 01-.357.116C9.582 3.546 9.2 5.3 9.2 7.25c0 .611.21 2.406.29 3.048a.625.625 0 01-.62.702H8.74a.616.616 0 00-.469.211.617.617 0 00-.151.491l.312 2.5a.625.625 0 00.62.548h.204c.326 0 .598.25.624.575a7.328 7.328 0 002.235 4.642.623.623 0 01.179.608l-.613 2.451a.63.63 0 01-.456.455L6.075 24.77a5.77 5.77 0 00-4.343 4.981z" _fill="#202832" fill-rule="nonzero" _stroke="#202832" stroke-width=".3"/>'
  }
})
