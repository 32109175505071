/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear-day': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M27.8 46a1.8 1.8 0 011.8 1.8v6a1.8 1.8 0 01-3.6 0v-6a1.8 1.8 0 011.8-1.8zm-12.756-5.446a1.8 1.8 0 01-.002 2.548l-4.003 3.972a1.8 1.8 0 11-2.542-2.549l4.002-3.971a1.8 1.8 0 012.545 0zm27.936-.11l.12.108 4.006 3.975a1.8 1.8 0 01-2.544 2.549L40.557 43.1a1.799 1.799 0 012.423-2.657zM27.8 12c8.726 0 15.8 7.074 15.8 15.8s-7.074 15.8-15.8 15.8S12 36.526 12 27.8 19.074 12 27.8 12zm26 14a1.8 1.8 0 010 3.6h-6a1.8 1.8 0 010-3.6h6zm-46 0a1.8 1.8 0 010 3.6h-6a1.8 1.8 0 010-3.6h6zM47.106 8.523a1.8 1.8 0 01-.003 2.549L43.1 15.045a1.8 1.8 0 01-2.54-2.55l4.001-3.972a1.8 1.8 0 012.546 0zm-36.068-.002l4.005 3.976a1.799 1.799 0 11-2.544 2.546L8.494 11.07a1.8 1.8 0 012.544-2.549zM27.8 0a1.8 1.8 0 011.8 1.8v6a1.8 1.8 0 01-3.6 0v-6A1.8 1.8 0 0127.8 0z" _fill="#F1D500" fill-rule="nonzero"/>'
  }
})
