/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'update-old': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M1.467 18.271a12.129 12.129 0 01-.217-2.248c0-6.487 5.111-11.808 11.57-12.28l-2.638 2.6a.61.61 0 000 .871.632.632 0 00.884 0l3.64-3.59c.126-.08.216-.2.259-.347l.006-.026c.009-.038.026-.072.029-.112.001-.03-.01-.055-.014-.085-.001-.026.01-.05.006-.074-.005-.022-.02-.04-.028-.063a.297.297 0 00-.02-.045.605.605 0 00-.292-.343l-5-2.465a.631.631 0 00-.837.276.613.613 0 00.28.827l2.885 1.422C5.234 3.451 0 9.14 0 16.023c0 .806.079 1.615.239 2.473a.622.622 0 00.728.494.617.617 0 00.5-.719zm28.441 2.005a.661.661 0 00-.891-.22l-2.804 1.633a13.556 13.556 0 001.185-5.524c0-5.885-3.83-11.162-9.53-13.13a.657.657 0 00-.831.386.631.631 0 00.396.809c5.182 1.789 8.666 6.584 8.666 11.935 0 1.854-.424 3.67-1.225 5.339l-.737-3.563a.65.65 0 00-.765-.496.634.634 0 00-.51.746l1.072 5.182a.608.608 0 00.155.389l.005.005c.04.043.074.087.126.119.017.01.038.006.055.015.022.013.035.034.059.045a.654.654 0 00.262.054c.116 0 .23-.03.334-.089l4.752-2.766a.626.626 0 00.226-.87zm-7.955 5.65c-2.301 1.854-5.23 2.877-8.247 2.877-4.24 0-8.12-1.974-10.472-5.226l3.954 1.015c.344.092.688-.1.78-.42.091-.32-.108-.648-.446-.735l-5.419-1.39a.67.67 0 00-.37-.036c-.017.003-.033.01-.05.013-.04.011-.082.014-.12.031-.02.01-.033.028-.05.039-.018.01-.039.01-.055.023-.024.016-.036.041-.056.06a.601.601 0 00-.053.06.578.578 0 00-.15.34L1 27.583c-.012.33.26.608.612.62h.024c.34 0 .62-.254.635-.576l.123-3.087C5 27.946 9.167 30 13.705 30c3.32 0 6.542-1.123 9.072-3.164a.574.574 0 00.07-.844.662.662 0 00-.894-.066z" _fill="#000" fill-rule="nonzero"/>'
  }
})
