<template>
  <b-overlay :show="isLoading" rounded="lg" id="overlay-login">
    <div class="mt-0 col-xs-1 text-center" id="container-login">
      <img class="" alt="Airzone logo" src="@/assets/airzoneWhite.svg" />

      <div class="pb-3">
        <h1>{{ $t("login.admin") }}</h1>
      </div>

      <b-container>
        <b-row>
          <b-col class="col-lg-4 col-md-6 col-sm-8 mx-auto p-5">
            <b-form @submit.prevent="loginUser" novalidate>
              <b-form-group class="mb-1">
                <b-form-input
                  type="email"
                  autocomplete="email"
                  autofocus
                  :class="{ borderError: errors.email || userNotExist }"
                  v-model.trim="email"
                  id="InputEmail"
                  :placeholder="$t('login.email')"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="mb-0">
                <div class="az-input__value">
                  <div class="az-input__body">
                    <b-form-input
                      :type="showPassword"
                      autocomplete="password"
                      autofocus
                      :class="{ borderError: errors.password || userNotExist }"
                      v-model.trim="password"
                      id="InputPassword"
                      :placeholder="$t('login.password')"
                    >
                    </b-form-input>
                    <div class="az-input__password">
                      <div
                        class="eyeIcon"
                        @click="showHidePassword"
                        v-show="showIconPassword"
                      >
                        <az-icon name="password" scale="1"></az-icon>
                      </div>
                      <div
                        class="eyeIcon"
                        @click="showHidePassword"
                        v-show="!showIconPassword"
                      >
                        <az-icon name="password-hide" scale="1"></az-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="errors-container">
                <div class="error mt-1" v-if="errors.email">
                  {{ $t("messages.errors.email") }}
                </div>
                <div class="error mt-1" v-if="errors.password">
                  {{ $t("messages.errors.passwordLength") }}
                </div>
                <div class="error mt-1" v-if="userNotExist">
                  {{ $t("messages.errors.userNotExist") }}
                </div>
              </b-form-group>

              <b-button
                :disabled="!email || !password"
                class="button-send text-center font-weight-bold"
                type="submit"
              >
                {{ $t("login.send") }}
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <template #overlay>
      <div class="text-center">
        <b-spinner
          variant="primary"
          class="spinner-grow"
          label="Large Spinning"
          type="grow"
        ></b-spinner>
        <p class="h2 mt-3">{{ $t("messages.loading") }}</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import AuthService from "@/services/auth.service";
import StorageService from "@/services/storage.service";
import CryptoService from "@/services/crypto.service";
import CONSTANTS from "@/constant";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errors: {},
      userNotExist: false,
      isLoading: false,
      showPassword: "password",
      showIconPassword: false,
    };
  },
  methods: {
    /**
     * Muestra el modal de error
     */
    showModalError() {
      this.$bvModal.show("bv-modal-error");
    },

    /**
     * Obtiene el email y la contraseña del usuario en el local storage, si se había logueado previamente
     */
    getStoreData() {
      const userEmail = StorageService.getItem("userEmail");
      const userPassword = StorageService.getItem("userPassword");

      this.email = userEmail || "";
      this.password = userPassword ? CryptoService.decrypt(userPassword) : "";
    },

    /**
     * Muestro u oculto contraseña
     */
    showHidePassword() {
      console.log("this.showPassword: ", this.showPassword);
      this.showIconPassword = !this.showIconPassword;
      if (this.showPassword === "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
    },

    /**
     * Valido expresión regular del email
     *
     * @param {String} email - Email a validar
     */
    isEmail(email) {
      var re = CONSTANTS.REGEX.EMAIL;
      return re.test(email);
    },

    /**
     * Valido longitud de la contraseña
     *
     * @param {String} password - Contraseña a validar
     */
    isPassword(password) {
      return password && password.length >= 8;
    },

    /**
     * Valido email y contraseña
     */
    validate() {
      const errors = {};

      if (!this.isEmail(this.email)) {
        errors.email = true;
      }

      if (!this.isPassword(this.password)) {
        errors.password = true;
      }

      return errors;
    },

    /**
     * Intento loguear al usuario
     */
    async loginUser() {
      try {
        //
        // Inicializamos valores
        //
        this.userNotExist = false;
        this.errors = {};
        //
        // Si hay una petición en curso no hago nada
        //
        if (this.isLoading) return;

        //
        // Valido formulario
        //
        this.errors = this.validate();
        if (Object.keys(this.errors).length !== 0) return;

        //
        // Realizo la petición
        //
        this.isLoading = true;
        let user = await AuthService.login(this.email, this.password);

        if (user) {
          //
          // Almaceno los datos de acceso localmente para poder recordar al usuario
          //
          StorageService.setItem("userEmail", this.email);
          StorageService.setItem(
            "userPassword",
            CryptoService.encrypt(this.password)
          );
          //
          // Redirijo al usuario a la ruta por defecto,
          // o a la ruta privada a la que había intentado acceder
          //
          this.$router.push({ name: "servers" }).catch(() => {});
        } else {
          this.userNotExist = true;
          this.isLoading = false;

          // this.$refs["modal-error"].show();
          // this._vm.$bvModal.show('bv-modal-error')
        }
      } catch (error) {
        console.log(error);
        //
        // El usuario no existe en la base de datos
        //
        if (error._id === "userNotExist") {
          // console.log('userNotExist');
          this.userNotExist = true;
          this.isLoading = false;
          return;
        }

        //
        // Error por defecto
        //
        console.log("Error por defecto");
        this.showModalError();
        this.isLoading = false;
      }
    },
  },
  async created() {
    this.getStoreData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container-login {
  padding-top: 10rem;
}

#overlay-login {
  height: 100%;
}

.button-send {
  background-image: radial-gradient(
    circle at 0 0,
    var(--brand-light),
    var(--brand)
  );
  width: 100%;
  margin-top: 1.5rem !important;
}

.button-send:hover {
  background-image: radial-gradient(
    circle at 0 0,
    var(--brand),
    var(--brand-dark)
  );
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
}
</style>
