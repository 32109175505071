/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-14': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M26.875 26.25h-.625v-.625c0-.814-.524-1.501-1.25-1.76v-8.98a1.872 1.872 0 001.25-1.76V12.5h.625c.345 0 .625-.28.625-.625V10h.625a.625.625 0 00.335-1.153L14.71.097a.626.626 0 00-.671 0L.289 8.848A.626.626 0 00.625 10h.625v1.875c0 .345.28.625.625.625H2.5v.625c0 .814.524 1.501 1.25 1.76v8.98a1.872 1.872 0 00-1.25 1.76v.625h-.625A1.877 1.877 0 000 28.125v1.25c0 .345.28.625.625.625h27.5c.345 0 .625-.28.625-.625v-1.25a1.877 1.877 0 00-1.875-1.875zM25 13.125c0 .345-.28.625-.625.625h-2.5a.625.625 0 01-.625-.625V12.5H25v.625zm-8.75 10.74v-8.98a1.872 1.872 0 001.25-1.76V12.5H20v.625c0 .814.524 1.501 1.25 1.76v8.98a1.872 1.872 0 00-1.25 1.76v.625h-2.5v-.625c0-.814-.524-1.501-1.25-1.76zm-8.75 0v-8.98a1.872 1.872 0 001.25-1.76V12.5h2.5v.625c0 .814.524 1.501 1.25 1.76v8.98a1.872 1.872 0 00-1.25 1.76v.625h-2.5v-.625c0-.814-.524-1.501-1.25-1.76zM4.375 13.75a.625.625 0 01-.625-.625V12.5H7.5v.625c0 .345-.28.625-.625.625h-2.5zM6.25 15v8.75H5V15h1.25zm6.25 10.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625v.625H12.5v-.625zm1.25-1.875V15H15v8.75h-1.25zm1.875-10h-2.5a.625.625 0 01-.625-.625V12.5h3.75v.625c0 .345-.28.625-.625.625zm5.625 11.875c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625v.625h-3.75v-.625zm1.25-1.875V15h1.25v8.75H22.5zM14.375 1.366L25.979 8.75H2.77l11.604-7.384zM2.5 11.25V10h23.75v1.25H2.5zm1.25 14.375c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625v.625H3.75v-.625zM27.5 28.75H1.25v-.625c0-.345.28-.625.625-.625h25c.345 0 .625.28.625.625v.625zM18.471 6.355l-3.75-2.5a.625.625 0 00-.694 0l-3.75 2.5a.625.625 0 00.348 1.145h7.5a.624.624 0 00.346-1.145zM12.69 6.25l1.685-1.124L16.06 6.25h-3.371z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
