/**
 * Guarda el nombre de la anterior navegación
 *
 * @param {Object} userData - User Data
 */
 const savePrev = ({ commit }, prevData) => {
  commit('ADD_PREV', prevData);
};

/**
 * Asigna el array sin el último elemento, ya sacado
 *
 * @param {Object} userData - User Data
 */
 const setPrev = ({ commit }, prevData) => {
  commit('POP_PREV', prevData);
}

/**
 * Elimina el nombre de la anterior navegación del store
 */
const deletePrev = ({ commit }) => {
  commit('ADD_PREV', []);
};


/**
 * Guarda la página y el filtro de búsqueda
 *
 * @param {Object} userData - User Data
 */
const savePageAndFilter = ({ commit }, pageAndFilterData) => {
  commit('ADD_PAGEANDFILTER', pageAndFilterData);
};

/**
 * Elimina la página y el filtro de búsqueda del store
 */
const deletePageAndFilter = ({ commit }) => {
  commit('ADD_PAGEANDFILTER', {});
};

/**
 * Guarda los datos del usuario en el store
 *
 * @param {Object} userData - User Data
 */
const saveUser = ({ commit }, userData) => {
  commit('ADD_USER', userData);
};

/**
 * Elimina los datos del usuario del store
 */
const deleteUser = ({ commit }) => {
  commit('ADD_USER', {});
};

/**
 * Guarda los datos del dispositivo en el store
 *
 * @param {Object} deviceData - Device Data
 */
 const saveDevice = ({ commit }, deviceData) => {
  commit('ADD_DEVICE', deviceData);
};

/**
 * Elimina los datos del dispositivo del store
 */
const deleteDevice = ({ commit }) => {
  commit('ADD_DEVICE', {});
};

/**
 * Guarda los datos de la instalación en el store
 *
 * @param {Object} installationData - Installation Data
 */
 const saveInstallation = ({ commit }, installationData) => {
  commit('ADD_INSTALLATION', installationData);
};

/**
 * Elimina los datos de la instalación del store
 */
const deleteInstallation = ({ commit }) => {
  commit('ADD_INSTALLATION', {});
};

/**
 * Guarda los datos del firmware en el store
 *
 * @param {Object} firmwareData - Datos del firmware
 */
 const saveFirmware = ({ commit }, firmwareData) => {
  commit('ADD_FIRMWARE', firmwareData);
};

/**
 * Elimina los datos del firmware del store
 */
const deleteFirmware = ({ commit }) => {
  commit('ADD_FIRMWARE', {});
};

export default {
  savePrev,
  setPrev,
  deletePrev,
  savePageAndFilter,
  deletePageAndFilter,
  saveUser,
  deleteUser,
  saveDevice,
  deleteDevice,
  saveInstallation,
  deleteInstallation,
  saveFirmware,
  deleteFirmware,
};
