/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'temp': {
    width: 10,
    height: 31,
    viewBox: '0 0 10 31',
    data: '<path pid="0" d="M5 .417a3.129 3.129 0 013.126 3.125V23a4.37 4.37 0 011.25 3.042A4.38 4.38 0 015 30.417a4.38 4.38 0 01-4.375-4.375A4.37 4.37 0 011.876 23V3.542A3.129 3.129 0 015 .417zm0 1.25a1.877 1.877 0 00-1.874 1.875v19.723a.629.629 0 01-.207.465 3.085 3.085 0 00-1.043 2.312A3.129 3.129 0 005 29.167a3.129 3.129 0 003.125-3.125c0-.885-.371-1.706-1.044-2.312a.627.627 0 01-.206-.465V3.542A1.877 1.877 0 005 1.667zm0 10c.346 0 .626.28.626.625v11.983a1.878 1.878 0 011.25 1.767A1.877 1.877 0 015 27.917a1.877 1.877 0 01-1.875-1.875c0-.814.522-1.51 1.25-1.768V12.292c0-.345.28-.625.625-.625zm0 13.75a.626.626 0 000 1.25.626.626 0 000-1.25z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
