/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order': {
    width: 10,
    height: 15,
    viewBox: '0 0 10 15',
    data: '<path pid="0" d="M3.698 2.022c0 .84-.716 1.521-1.599 1.521S.5 2.863.5 2.022C.5 1.182 1.216.5 2.099.5s1.599.681 1.599 1.522zm0 5.478c0 .84-.716 1.522-1.599 1.522S.5 8.34.5 7.5s.716-1.522 1.599-1.522S3.698 6.66 3.698 7.5zm0 5.478c0 .84-.716 1.522-1.599 1.522S.5 13.819.5 12.978c0-.84.716-1.521 1.599-1.521s1.599.68 1.599 1.521zM9.5 2.022c0 .84-.716 1.521-1.599 1.521s-1.599-.68-1.599-1.521c0-.84.716-1.522 1.599-1.522S9.5 1.181 9.5 2.022zm0 5.478c0 .84-.716 1.522-1.599 1.522S6.302 8.34 6.302 7.5s.716-1.522 1.599-1.522S9.5 6.66 9.5 7.5zm0 5.478c0 .84-.716 1.522-1.599 1.522s-1.599-.681-1.599-1.522c0-.84.716-1.521 1.599-1.521s1.599.68 1.599 1.521z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
