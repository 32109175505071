/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'addDevice': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 0C6.73 0 0 6.73 0 15s6.73 15 15 15 15-6.73 15-15S23.27 0 15 0zm0 28.696C7.448 28.696 1.304 22.552 1.304 15S7.448 1.304 15 1.304 28.696 7.448 28.696 15 22.552 28.696 15 28.696zM20.875 17h-12c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h12c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5zm-6 6c-.9 0-1.5-.6-1.5-1.5v-12c0-.9.6-1.5 1.5-1.5s1.5.6 1.5 1.5v12c0 .9-.6 1.5-1.5 1.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
