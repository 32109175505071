/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speedbar-3': {
    width: 34,
    height: 33,
    viewBox: '0 0 34 33',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M14.244 32.8h-1.5c-1.5 0-2.8-1.2-2.8-2.7V14.5c0-1.6 1.1-2.8 2.8-2.8h1.5c1.5 0 2.8 1.2 2.8 2.8V30c-.1 1.5-1.3 2.8-2.8 2.8zm-1.5-19.5c-.8 0-1.2.5-1.2 1.2V30c0 .7.6 1.2 1.2 1.2h1.5c.7 0 1.2-.6 1.2-1.2V14.5c0-.7-.6-1.2-1.2-1.2h-1.5zM5.193 32.783h-1.5c-1.5 0-2.8-1-2.8-2.3v-13c0-1.3 1.1-2.3 2.8-2.3h1.5c1.5 0 2.8 1 2.8 2.3v12.9c-.1 1.3-1.3 2.4-2.8 2.4zm-1.5-16.3c-.8 0-1.2.4-1.2 1v12.9c0 .6.6 1 1.2 1h1.5c.7 0 1.2-.5 1.2-1v-12.9c0-.6-.6-1-1.2-1h-1.5zM27.193 0c-3.6 0-6.5 2.9-6.5 6.5 0 .5.1 1 .2 1.4-1.1.3-1.8 1.3-1.8 2.6V30c0 1.5 1.2 2.8 2.8 2.8h1.5c1.5 0 2.8-1.2 2.8-2.8V12.8c.4.1.7.1 1.1.1 3.6 0 6.5-2.9 6.5-6.4-.2-3.6-3.1-6.5-6.6-6.5zm-2.6 30c0 .7-.6 1.2-1.2 1.2h-1.6c-.7 0-1.2-.6-1.2-1.2V10.5c0-.6.3-1 .8-1.2.7 1.4 1.8 2.4 3.2 3.1V30zm2.6-18.6c-2.7 0-5-2.2-5-4.9s2.2-5 5-5 5 2.2 5 5-2.3 4.9-5 4.9z"/><path pid="1" d="M26.193 5.8h.7c.4 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.2-.7-.2-.3 0-.5.1-.7.2-.2.1-.3.3-.3.6h-1.4c0-.4.1-.7.3-1 .2-.3.5-.5.8-.7.4-.2.8-.2 1.2-.2.8 0 1.3.2 1.8.5.4.4.6.9.6 1.5 0 .3-.1.6-.3.9-.2.3-.5.5-.8.6.4.1.7.4.9.6.2.3.3.6.3 1 0 .6-.2 1.1-.7 1.5-.5.4-1.1.6-1.8.6s-1.3-.2-1.7-.6c-.5-.4-.7-.9-.7-1.5h1.4c0 .3.1.5.3.7.3.3.5.4.8.4.3 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.7-.4-1.1-1.2-1.1h-.7V5.8z"/></g>'
  }
})
