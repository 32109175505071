/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-15': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M28.736 16.744l-1.875-8.75a.626.626 0 00-1.222 0l-1.875 8.75a.669.669 0 00-.014.131v12.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-12.5a.669.669 0 00-.014-.131zm-2.486-5.637l1.103 5.143h-2.204l1.101-5.143zM27.5 28.75H25V17.5h2.5v11.25zM3.111 7.994a.626.626 0 00-1.222 0l-1.875 8.75a.669.669 0 00-.014.131v12.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-12.5a.669.669 0 00-.014-.131l-1.875-8.75zM2.5 11.107l1.103 5.143H1.397L2.5 11.107zM3.75 28.75h-2.5V17.5h2.5v11.25zm6.875-20a.625.625 0 00-.625.625v2.5a.625.625 0 001.25 0v-2.5a.625.625 0 00-.625-.625zm7.5 0a.625.625 0 00-.625.625v2.5a.625.625 0 001.25 0v-2.5a.625.625 0 00-.625-.625zm4.356-.474l1.25-5a.611.611 0 00.019-.151v-2.5A.625.625 0 0023.125 0h-2.5A.625.625 0 0020 .625V2.5h-1.25V.625A.625.625 0 0018.125 0h-2.5A.625.625 0 0015 .625V2.5h-1.25V.625A.625.625 0 0013.125 0h-2.5A.625.625 0 0010 .625V2.5H8.75V.625A.625.625 0 008.125 0h-2.5A.625.625 0 005 .625v2.5c0 .051.006.101.019.151l1.25 5c.07.279.32.474.606.474h.587l-1.21 20.587a.625.625 0 00.624.663h3.749c.345 0 .625-.28.625-.625v-5a3.13 3.13 0 013.125-3.125 3.13 3.13 0 013.125 3.125v5c0 .345.28.625.625.625h3.75a.629.629 0 00.455-.195.632.632 0 00.169-.466L21.288 8.75h.587a.625.625 0 00.606-.474zM20.625 7.5a.625.625 0 00-.624.662l1.211 20.589H18.75v-4.375A4.38 4.38 0 0014.375 20 4.38 4.38 0 0010 24.375v4.375H7.538l1.21-20.587a.625.625 0 00-.624-.663h-.76L6.25 3.048V1.25H7.5v1.875c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625V1.25h1.25v1.875c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625V1.25h1.25v1.875c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625V1.25h1.25v1.796L21.386 7.5h-.761zm-6.25 1.251a.625.625 0 00-.625.625v2.5a.625.625 0 001.25 0v-2.5a.625.625 0 00-.625-.625z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
