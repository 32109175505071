/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 30,
    height: 20,
    viewBox: '0 0 30 20',
    data: '<path pid="0" d="M26.875 0A3.13 3.13 0 0130 3.125v13.75A3.13 3.13 0 0126.875 20H3.125A3.13 3.13 0 010 16.875V3.125A3.13 3.13 0 013.125 0h23.75zm0 1.25H3.125A1.877 1.877 0 001.25 3.125v13.75c0 1.034.841 1.875 1.875 1.875h23.75a1.877 1.877 0 001.875-1.875V3.125a1.877 1.877 0 00-1.875-1.875zm-15.77 8.974a.626.626 0 01-.08.881l-7.5 6.25a.623.623 0 01-.881-.08.626.626 0 01.08-.881l7.5-6.25a.628.628 0 01.881.08zm8.671-.08l7.5 6.25a.626.626 0 01-.801.961l-7.5-6.25a.626.626 0 01.801-.961zM3.477 2.61L13.73 9.624a2.238 2.238 0 002.541 0L26.521 2.61a.625.625 0 01.706 1.031l-10.25 7.014a3.49 3.49 0 01-1.977.616 3.49 3.49 0 01-1.976-.616L2.773 3.641a.626.626 0 01-.164-.868.623.623 0 01.868-.163z" _fill="#000" fill-rule="nonzero"/>'
  }
})
