/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-23': {
    width: 29,
    height: 28,
    viewBox: '0 0 29 28',
    data: '<path pid="0" d="M8.97.175c1.194.546 1.224 2.08 1.266 4.022.034 1.56.085 3.917.976 4.33A5.683 5.683 0 0114 7.878c.66-.746 3.125-3.474 4.923-4.714 1.109-.763 4.058-2.008 7.549 2.899 1.811 2.546 3.637 8.295 1.474 9.785-1.075.741-2.444.005-4.172-.929-1.392-.753-3.494-1.888-4.297-1.335a5.526 5.526 0 01-.821 2.703c.327.942 1.492 4.4 1.684 6.551.117 1.326-.262 4.464-6.32 4.983-2.73.236-7.587-.726-8.974-2.676a1.935 1.935 0 01-.372-.958c-.116-1.29 1.217-2.084 2.905-3.09 1.354-.81 3.402-2.035 3.32-2.995a5.551 5.551 0 01-1.971-2.053c-.99-.19-4.617-.928-6.607-1.842-.674-.31-1.208-.741-1.593-1.283-.423-.594-.665-1.316-.717-2.15-.08-1.265.287-2.763 1.086-4.45.71-1.5 2.004-3.172 3.465-4.47C5.812.738 7.592-.457 8.969.174zm8.754 17.232l-.08.077a5.68 5.68 0 01-5.551 1.158c-.163.705-.709 1.32-1.539 1.972-.608.478-1.185.847-2.33 1.531-1.891 1.128-2.345 1.544-2.31 1.936.01.112.055.227.152.363.934 1.313 5.06 2.399 7.845 2.158 4.176-.358 5.355-1.787 5.189-3.656-.124-1.391-.69-3.48-1.376-5.539zm-6.382-7.545c-1.983 1.366-2.468 4.072-1.08 6.024 1.389 1.951 4.137 2.428 6.12 1.062 1.983-1.367 2.468-4.072 1.08-6.024-1.389-1.951-4.138-2.429-6.12-1.062zm4.103 2.452c.611.859.398 2.048-.475 2.65a1.955 1.955 0 01-2.691-.468 1.886 1.886 0 01.474-2.65 1.955 1.955 0 012.692.468zm4.192-8.145c-1.162.8-2.724 2.335-4.186 3.943a5.58 5.58 0 013.006 2.126c.454.638.751 1.339.9 2.058.701-.213 1.514-.055 2.502.327.725.28 1.341.59 2.513 1.223 1.934 1.045 2.534 1.225 2.86 1 1.1-.758.109-5.425-1.78-8.08-2.406-3.382-4.253-3.673-5.815-2.597zM5.396 2.764c-1.339 1.19-2.532 2.73-3.171 4.078-.72 1.519-1.037 2.821-.972 3.858.039.614.207 1.119.494 1.522.258.362.622.655 1.1.876 1.287.59 3.423 1.157 5.562 1.599a5.481 5.481 0 011.727-5.42c-.661-.594-.922-1.595-1.052-3.04a20.199 20.199 0 01-.063-.988c-.012-.3-.018-.535-.029-1.025-.048-2.183-.186-2.773-.549-2.938-.549-.252-1.716.29-3.047 1.478z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
