

const AccessTypeColor = {

  methods: {
    /**
      * Devuelve el color de fondo para el componente de bootstrap 'bagde', según el tipo de acceso
      * 
      * @param {String} type - Tipo de acceso
      * @return {String} - Estilo del color de fondo para badge según el tipo de acceso
      */
    accessTypeColor(type) {
      switch (type) {
        case 'admin':
          return 'success-dark'
        case 'advanced':
          return 'warning-dark'
        case 'basic':
          return 'danger-dark'
      }
    }
  }

}

export default AccessTypeColor;