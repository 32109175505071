/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-small-60': {
    width: 33,
    height: 30,
    viewBox: '0 0 33 30',
    data: '<path pid="0" d="M11.108.032a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.032zM26.14 22.3a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.137.063 17.717 4.875 23.452 9.687 29.187 18.267 29.937 24 25.125a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zM22.75 10.806c.546 0 1.035-.123 1.467-.37.432-.245.767-.59 1.003-1.031.236-.442.355-.936.355-1.481 0-.854-.216-1.541-.646-2.064-.43-.523-1.01-.784-1.74-.784-.681 0-1.244.227-1.69.68.092-.676.368-1.205.827-1.585.46-.38 1.066-.576 1.819-.588h.161V2.211h-.276c-.85.004-1.596.197-2.24.58a3.783 3.783 0 00-1.481 1.62c-.344.697-.516 1.5-.516 2.412v.599c0 1.026.272 1.847.816 2.461.543.615 1.257.923 2.141.923zm-.035-1.343c-.395 0-.703-.157-.922-.47-.219-.313-.328-.749-.328-1.306V7.18a1.33 1.33 0 011.216-.767c.376 0 .676.14.9.421.222.28.333.65.333 1.107 0 .45-.11.815-.331 1.098a1.05 1.05 0 01-.868.424zm6.664 1.343c.926 0 1.63-.308 2.11-.923.48-.614.72-1.502.72-2.663V5.675c-.011-1.126-.26-1.99-.743-2.59-.485-.602-1.184-.903-2.099-.903-.914 0-1.614.304-2.1.911-.487.607-.73 1.497-.73 2.669v1.545c.012 1.126.26 1.99.744 2.594.484.603 1.183.905 2.098.905zm0-1.343c-.411 0-.71-.167-.896-.499-.187-.332-.28-.84-.28-1.525V5.4c.012-.642.11-1.114.297-1.415.186-.302.476-.453.867-.453.404 0 .7.159.891.476.19.317.285.823.285 1.519V7.52c-.007.657-.101 1.145-.282 1.464-.18.32-.475.479-.882.479z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
