/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-off': {
    width: 42,
    height: 35,
    viewBox: '0 0 42 35',
    data: '<path pid="0" d="M29.174.792c-11.815 0-19.73 2.186-24.38 6.037C1.417 9.626.048 13.023.048 16.464l.008.44c.087 2.33.862 4.393 2.244 6.029l.169.192.069-.125c-1.57 2.84-2.49 5.418-2.49 6.917a.875.875 0 001.75 0l.006-.184c.051-.773.409-1.971 1.01-3.374l.32-.718c.114-.244.233-.493.36-.745l.283-.556-.192-.15C5.19 25.491 7.26 26.33 9.64 26.63l.227.029.001-1.59-.173-.199-.437-.07c-1.731-.309-3.239-.957-4.44-1.898l-.164-.135-.088.148C7.96 17.12 12.947 12.012 18.793 9.18a.876.876 0 00.406-1.168.877.877 0 00-1.168-.407l-.567.284c-5.462 2.81-10.354 7.711-13.84 13.27l-.203.33.176.224c-1.175-1.422-1.8-3.217-1.8-5.249 0-2.946 1.191-5.862 4.14-8.304l.285-.23c3.942-3.104 10.427-5.007 19.92-5.338l.986-.029-.446.52c-2.322 2.775-3.975 5.892-4.941 9.869l-.36 1.569c-.477 2.035-.971 3.62-1.605 5l-.13.286h1.804l.186-.124c.102-.242.2-.488.294-.74l.208-.578c.067-.19.13-.379.19-.57l.177-.58c.057-.196.114-.398.17-.609l.169-.658.174-.727.186-.813.098-.445C24.398 8.98 26.5 5.58 29.792 2.285a.874.874 0 00-.619-1.493zM16.885 34.264c1.053 0 1.986-.253 2.8-.76.814-.506 1.44-1.225 1.877-2.157.437-.931.656-2.016.656-3.253v-.56c0-1.231-.223-2.317-.67-3.258-.445-.94-1.075-1.662-1.889-2.165-.814-.504-1.744-.755-2.791-.755s-1.978.251-2.791.755c-.814.503-1.444 1.225-1.89 2.165-.446.94-.669 2.03-.669 3.266v.622c.006 1.208.233 2.276.682 3.205.448.929 1.081 1.644 1.898 2.144.817.5 1.746.75 2.787.75zm0-2.123c-.886 0-1.565-.354-2.036-1.061-.472-.708-.708-1.703-.708-2.986v-.612c.006-1.306.245-2.297.716-2.973.472-.676 1.142-1.014 2.01-1.014.875 0 1.548.343 2.02 1.027.472.685.707 1.686.707 3.003v.613c-.005 1.305-.241 2.3-.707 2.98-.466.682-1.133 1.023-2.002 1.023zm9.802 1.95v-5.134h4.97V26.87h-4.97v-3.244h5.591v-2.097h-8.18v12.563h2.59zm9.681 0v-5.134h4.97V26.87h-4.97v-3.244h5.592v-2.097h-8.18v12.563h2.588z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
