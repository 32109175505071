import ApiService from '@/services/api.service';
import StorageService from '@/services/storage.service';
import { setLanguage } from '@/services/language.service';

 /**
  * @type {Promise<string>}
  */
let refreshPromise = null;
let refreshPending = false;

const AuthService = {
  /**
   * Loguea al usuario, obtiene sus datos y almacena localmente sus credenciales,
   *
   * @param {String} email - Email del usuario
   * @param {String} password - Contraseña del usuario
   * @return {Promise<import('Auth/models/User').default>} - Datos del usuario parseados y validados
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - Si el usuariono existe o si la contraseña es errónea
   * @throws {userNotConfirmed} - Si el usuario todavía no ha validado su cuenta
   * @throws {sanitazeUserDataError} - Error al validar los datos del usuario
   * @throws {invalidUserData} - No se han recibido los datos mínimos necesarios
   * @throws {default} - Ocurrió otro error
   */
  async login( email, password ) {
    try {
      let response;
      response = await ApiService.post('auth/login', { email, password });
      // console.log('response: ', response);

      if (response.data.role && response.data.role === 'admin') {
        //
        // Actualizo las cabeceras de axios con el bearer token
        // y almaceno los token en el local storage para futuras visitas
        //
        if (!response.data.token) return false;
        ApiService.setHeader(response.data.token);
        StorageService.setItem('email', response.data.email);
        StorageService.setItem('access_token', response.data.token);
        StorageService.setItem('refresh_token', response.data.refreshToken);
        StorageService.setItem('extra_roles', response.data.extra_roles);
        setLanguage(response.data.config.lang);
        const user = { email, token: response.data.token }
        // console.log('AuthService/login');

        return user;
      } else if (!response.data.role || response.data.role !== 'admin') {
        return false
      } else {
        const error = {
          name: 'userNotExist'
        }
        return error;
      }
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 400) {
        // console.log('error.response: ', error.response)
        throw error.response.data;
      }
      throw error;
    //   throw new AuthError(error);
    }
  },

  /**
   * Comprueba si un usuarios está logueado, obtiene sus datos y almacena sus credenciales
   *
   * @return {User} - Datos del usuario parseados y validados
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - Si el usuariono existe o si la contraseña es errónea
   * @throws {userNotConfirmed} - Si el usuario todavía no ha validado su cuenta
   * @throws {sanitazeUserDataError} - Error al validar los datos del usuario
   * @throws {invalidUserData} - No se han recibido los datos mínimos necesarios
   * @throws {default} - Ocurrió otro error
   */
   async isLogin(token) {
    try {
      ApiService.setHeader(token);
      // console.log('isLogin token: ', token);
      const response = await ApiService.get('user');

    //   setLanguage(user.lang);
      StorageService.setItem('extra_roles', response?.data?.extra_roles);
      // console.log('AuthService/isLogin', response);
      return response;
    } catch (error) {
      console.log(error);
      this.clearUser();
      throw error;
    }
  },

  /**
   * Obtiene un nuevo token mediante el refreshToken
   *
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {tokenNotFound} - No se encuentra el refreshToken en la Base de Datos
   * @throws {default} - Ocurrió algún error
   */
  refreshToken() {

    if( refreshPending === false) {
      refreshPending = true;
      // console.log('Creando pending');
      refreshPromise = new Promise( (resolve, reject ) => {
        try {
          // console.log('Resolviendo pending');
          const localRefreshToken = StorageService.getItem('refresh_token');
          if(!localRefreshToken) {
            refreshPending = false;
            return reject();
          }

          ApiService.get(`auth/refreshToken/${localRefreshToken}`)
          .then( response => {

              ApiService.setHeader(response.data.token);
              StorageService.setItem('access_token', response.data.token);
              StorageService.setItem('refresh_token', response.data.refreshToken);
    
            //   log.success('AuthService/refreshToken');
    
              refreshPending = false;
              resolve (response.data.token);
          })
          .catch(err => {

            console.log(err);
            reject(err);
          })

        } catch (error) {
          refreshPending = false;
          console.log('RefreshToken', error);
          return reject(error);
        //   return reject(new AuthError(error));
        }

      })
    } else {
      console.log("Pending creado");
    }

    return refreshPromise;
  },

  /**
   * Envía un email al usuario para que pueda validar su cuenta
   * si el email se envía correctamente devuelve true.
   *
   * @param {String} email - Email del usuario
   * @return {Promise<Boolean>} - Resultado de la petición
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - El usuario no existe
   * @throws {userConfirmed} - El usuario ya había confirmado su cuenta
   * @throws {default} - Ocurrió algún error
   */
  async sendConfirmEmail(email) {
    try {
      await ApiService.get(`auth/resendConfirmationEmail/${email}`);
      // console.log('AuthService/sendConfirmEmail', email);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
      // throw new AuthError(error);
    }
  },

  /**
   * Confirma el registro de usuario para validar su cuenta
   * si se confirma correctamente devuelve true.
   *
   * @param {String} userID - Identificador de usuario
   * @return {Promise<Boolean>} - Resultado de la petición
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {userNotExist} - El usuario no existe
   * @throws {userConfirmed} - El usuario ya había confirmado su cuenta
   * @throws {default} - Ocurrió algún error
   */
  async sendManualConfirmationEmail(userID) {
    try {
      await ApiService.post(`admin/user/${userID}/confirm`);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
      // throw new AuthError(error);
    }
  },

  /**
   * Limpia los datos del usuario locales
   *
   * @return {Boolean} - Resultado de eliminar al usuario
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {default} - Ocurrió algún error
   */
  clearUser() {
    ApiService.removeHeader();
    StorageService.removeItem('access_token');
    StorageService.removeItem('refresh_token');
    StorageService.removeItem('extra_roles');

    // console.log('AuthService/clearUser');

    return this;
  },

  /**
   * Cierra sesión del usuario actual y limpia sus datos del navegador
   *
   * @return {Promise<Boolean>} - Indica el resultado cerrar la sesión del usuario
   * @throws {default} - Ocurrió algún error
   */
   async logout() {
    try {
      await ApiService.get('user/logout');

      this.clearUser();

      // console.log('AuthService/logout');

      return true;
    } catch (error) {
        console.log('Logout: ', error);
    //   throw new AuthError(error);
    }
  },
};

export default AuthService;