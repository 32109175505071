/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat_air-bold': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M15.15 26.087c.443 0 .802.36.802.802v2.609a.802.802 0 01-1.604 0v-2.609c0-.443.36-.802.802-.802zm6.563-1.172l1.304 2.26a.802.802 0 11-1.39.801l-1.303-2.259a.802.802 0 011.389-.802zm-12.031-.294a.802.802 0 01.293 1.096l-1.304 2.26a.8.8 0 01-1.096.293.802.802 0 01-.294-1.096l1.305-2.259a.8.8 0 011.096-.294zM23.5 0a6.5 6.5 0 011.316 12.867c.175.732.267 1.497.267 2.283 0 5.478-4.455 9.933-9.933 9.933s-9.933-4.455-9.933-9.933 4.455-9.933 9.933-9.933c.665 0 1.314.066 1.943.191A6.499 6.499 0 0123.5 0zm-8.35 6.822c-4.592 0-8.328 3.736-8.328 8.328 0 4.592 3.736 8.328 8.328 8.328 4.592 0 8.328-3.736 8.328-8.328a8.31 8.31 0 00-.283-2.159 6.498 6.498 0 01-6.173-5.957 8.288 8.288 0 00-1.872-.212zm10.567 13.503l2.26 1.304a.802.802 0 01-.803 1.39l-2.259-1.305a.802.802 0 01.802-1.39zm-20.038.293a.802.802 0 01-.294 1.096l-2.259 1.304a.801.801 0 11-.804-1.39l2.26-1.303a.803.803 0 011.097.293zm-2.268-6.27a.802.802 0 010 1.604H.802a.802.802 0 010-1.604h2.609zm26.087 0a.802.802 0 010 1.604h-2.609a.802.802 0 010-1.604h2.609zM23.5 1.4a5.1 5.1 0 100 10.2 5.1 5.1 0 000-10.2zM3.126 7.281l2.259 1.305a.802.802 0 01-.802 1.39L2.323 8.67a.801.801 0 11.802-1.39zM24.16 2.5l2.62 7h-1.533l-.486-1.442h-2.529l-.48 1.442h-1.534l2.606-7h1.336zm-.668 1.77l-.87 2.62h1.75l-.88-2.62zM8.67 2.323l1.304 2.259a.803.803 0 01-1.39.802L7.28 3.125a.804.804 0 011.39-.801zM15.15 0c.443 0 .802.36.802.802v2.609a.802.802 0 01-1.604 0V.802c0-.443.36-.802.802-.802z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
