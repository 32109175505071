
const AnimateCardGroup = {

  methods: {
    /**
     * Anima la aparición de los elementos con clase "card-group" en la iteración
     */
    animateCardGroup() {
      setTimeout(() => {
        const elements = document.getElementsByClassName("card-group");
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.opacity = 1;
        }
      }, 0);
    },
  }

}

export default AnimateCardGroup;