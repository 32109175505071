/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-30': {
    width: 36,
    height: 31,
    viewBox: '0 0 36 31',
    data: '<path pid="0" d="M11.108.732a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.732zM26.14 23a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.837.063 18.417 4.875 24.152 9.687 29.887 18.267 30.637 24 25.825a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zm-5.444-9.397c1.366 0 2.464-.343 3.293-1.029.83-.686 1.244-1.597 1.244-2.734 0-.704-.177-1.313-.532-1.83-.354-.515-.892-.902-1.613-1.16a3.485 3.485 0 001.407-1.134 2.694 2.694 0 00.536-1.618c0-1.137-.384-2.03-1.152-2.677-.768-.648-1.829-.972-3.183-.972-.78 0-1.493.15-2.14.449-.648.299-1.152.713-1.513 1.244-.36.53-.54 1.13-.54 1.798h2.54c0-.428.165-.775.493-1.042.328-.267.735-.4 1.222-.4.54 0 .963.144 1.27.43.308.288.462.69.462 1.205 0 .534-.155.962-.466 1.284-.31.322-.788.484-1.433.484h-1.354v1.987h1.345c1.407 0 2.11.639 2.11 1.916 0 .528-.171.951-.514 1.27-.343.32-.816.48-1.42.48-.528 0-.972-.152-1.332-.457a1.472 1.472 0 01-.54-1.178h-2.542c0 1.108.406 1.999 1.218 2.673.812.674 1.857 1.01 3.134 1.01zm10.586 0c1.413 0 2.486-.47 3.218-1.407.733-.938 1.1-2.292 1.1-4.062V5.778c-.018-1.718-.396-3.035-1.135-3.953C33.728.908 32.661.45 31.266.45c-1.395 0-2.464.463-3.205 1.39-.742.926-1.112 2.283-1.112 4.07v2.357c.017 1.717.395 3.036 1.134 3.956.739.92 1.805 1.38 3.2 1.38zm0-2.049c-.627 0-1.083-.253-1.367-.76-.284-.507-.426-1.283-.426-2.326V5.355c.017-.978.168-1.698.453-2.158.284-.46.725-.69 1.323-.69.615 0 1.068.242 1.358.725.29.484.435 1.256.435 2.317V8.59c-.011 1.002-.155 1.747-.43 2.233-.276.487-.724.73-1.346.73z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
