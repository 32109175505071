/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'slider': {
    width: 32,
    height: 27,
    viewBox: '0 0 32 27',
    data: '<path pid="0" d="M22.16 0c1.099 0 1.99.89 1.99 1.99v22.365a1.99 1.99 0 01-1.99 1.99H9.674a1.99 1.99 0 01-1.99-1.99V1.99c0-1.1.89-1.99 1.99-1.99H22.16zm0 1.293H9.674a.697.697 0 00-.69.602l-.007.095v22.365c0 .353.262.644.602.69l.095.007H22.16a.697.697 0 00.69-.602l.006-.095V1.99a.697.697 0 00-.601-.69l-.095-.007zm8.199 2.686c.858 0 1.563.66 1.635 1.5l.006.142v15.573c0 .86-.66 1.564-1.5 1.636l-.141.006h-4.494v-1.293h4.494c.168 0 .308-.12.34-.278l.008-.07V5.62a.348.348 0 00-.278-.341l-.07-.008h-4.494V3.98h4.494zM6.135 3.744v1.293H1.641l-.07.008a.348.348 0 00-.278.34V20.96l.007.07a.348.348 0 00.341.279h4.494V22.6H1.641l-.141-.006A1.642 1.642 0 010 20.959V5.386l.006-.142c.072-.84.777-1.5 1.635-1.5h4.494z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
