/* eslint-disable no-undef */
import axios from 'axios';
// import AppError from 'Core/services/errors.service';

const downloadConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'blob',
}
// const axios = axios.create({});

const S3Service = {
  download(resource, data) {
    if (data) return axios.get(resource, data, downloadConfig);
    return axios.get(resource, downloadConfig);
  },

  get(resource, data) {
    if (data) return axios.get(resource, data);
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data, {headers: {'Content-Type': data.type}});
  },

  put(resource, data) {
    return axios.put(resource, data, {headers: {'Content-Type': data.type}});
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource, data) {
    if (data) return axios.delete(resource, { data });
    return axios.delete(resource, data);
  },
}

export default S3Service;
