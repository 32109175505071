<template>
  <b-container fluid class="m-0 p-0" id="container-app">
    <router-view />

    <ModalError />
  </b-container>
</template>

<script>
import ModalError from "@/components/ModalError";

export default {
  name: "App",
  components: {
    ModalError,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap-vue/index";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>

<style>
html,
body,
#container-app {
  height: 100%;
  min-height: 100vh;
}

body {
  background-color: var(--gray8);
}

.az-icon.text-success {
  fill: var(--success);
}

.az-icon.text-success-dark {
  fill: var(--success-dark);
}

.iconStatus.text-success-dark,
.iconStatus.text-danger {
  background-color: var(--gray4);
}

.az-icon.text-warning {
  fill: var(--warning);
}

.az-icon.text-danger {
  fill: var(--danger);
}
</style>
