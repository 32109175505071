/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-11': {
    width: 27,
    height: 23,
    viewBox: '0 0 27 23',
    data: '<path pid="0" d="M25.784 22.92a.573.573 0 01-.573-.574v-4.01H1.146v4.01a.573.573 0 01-1.146 0v-4.584c0-.316.257-.573.573-.573h25.211c.316 0 .573.257.573.573v4.584a.573.573 0 01-.573.573zm0-1.147H.573a.573.573 0 010-1.146h25.211a.573.573 0 010 1.146zm0-3.438a.575.575 0 01-.524-.34L20.677 7.681a.573.573 0 111.047-.465l3.487 7.847v-1.884a.573.573 0 011.146 0v4.583a.573.573 0 01-.573.573zm-25.211 0A.573.573 0 010 17.762V13.18a.573.573 0 011.146 0v1.884l3.487-7.847a.573.573 0 01.524-.34h1.719a.573.573 0 010 1.146H5.529l-4.432 9.973a.575.575 0 01-.524.34zM21.2 8.022h-1.719a.573.573 0 010-1.146h1.146V2.865c0-.948-.77-1.719-1.719-1.719H7.448c-.947 0-1.718.771-1.718 1.719v4.584a.573.573 0 01-1.146 0V2.865A2.869 2.869 0 017.449 0h11.46a2.869 2.869 0 012.864 2.865v4.584a.573.573 0 01-.573.573zm-10.313 2.292H8.174A1.72 1.72 0 016.47 8.382l.286-2.292a1.72 1.72 0 011.706-1.506h2.426c.947 0 1.719.771 1.719 1.719v2.292c0 .947-.772 1.719-1.72 1.719zM8.46 5.73a.573.573 0 00-.569.502l-.286 2.292a.566.566 0 00.138.45c.11.125.263.194.43.194h2.713a.573.573 0 00.573-.573V6.303a.573.573 0 00-.573-.573H8.46zm9.722 4.584H15.47a1.721 1.721 0 01-1.718-1.72V6.304c0-.948.77-1.72 1.718-1.72h2.426a1.72 1.72 0 011.706 1.507l.286 2.292a1.72 1.72 0 01-1.705 1.932zM15.47 5.73a.573.573 0 00-.572.573v2.292c0 .316.256.573.572.573h2.713c.167 0 .32-.07.43-.194a.567.567 0 00.138-.45l-.286-2.292a.573.573 0 00-.569-.502H15.47zm-1.145 2.292h-2.292a.573.573 0 010-1.146h2.292a.573.573 0 010 1.146z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
