/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Info-modal': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 0c8.272 0 15 6.728 15 15s-6.728 15-15 15S0 23.272 0 15 6.728 0 15 0zm0 1.154C7.366 1.154 1.154 7.366 1.154 15S7.366 28.846 15 28.846 28.846 22.634 28.846 15 22.634 1.154 15 1.154zm2.609 11.89c.34 0 .62.262.65.595l.002.057v7.174h.652c1.079 0 1.957.877 1.957 1.956a1.96 1.96 0 01-1.874 1.955l-.083.002h-6.522a1.959 1.959 0 01-1.956-1.957 1.96 1.96 0 011.874-1.955l.082-.001h.652v-3.913h-.652A1.959 1.959 0 0110.435 15a1.96 1.96 0 011.874-1.955l.082-.002h5.218zm-.502 1.153H12.39a.805.805 0 00-.802.803c0 .422.33.771.745.8l.057.003h1.806v6.22h-1.806a.805.805 0 00-.802.803c0 .422.33.771.745.8l.057.003h6.522a.805.805 0 00.803-.803.805.805 0 00-.746-.8l-.057-.003h-1.806v-7.826zM15 5.217a3.265 3.265 0 013.26 3.261A3.265 3.265 0 0115 11.74a3.265 3.265 0 01-3.26-3.26A3.265 3.265 0 0115 5.216zm0 1.154c-1.16 0-2.107.947-2.107 2.107 0 1.16.947 2.107 2.107 2.107 1.16 0 2.107-.947 2.107-2.107 0-1.16-.947-2.107-2.107-2.107z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
