/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'powerfull-on': {
    width: 28,
    height: 30,
    viewBox: '0 0 28 30',
    data: '<path pid="0" d="M3.236 4.79l2.555 2.6c-3.12 3.915-2.868 9.633.755 13.256l.1.098-2.541 2.587-.123-.121c-5.048-5.048-5.297-13.078-.746-18.42zm21.13.506c4.026 5.21 3.75 12.671-.826 17.573l-2.544-2.59c3.137-3.468 3.403-8.611.797-12.363l2.574-2.62zm-12.25 6.414a2.72 2.72 0 013.485-1.625c1.411.513 7.112 4.761 6.88 5.398-.231.636-7.329.226-8.74-.288a2.72 2.72 0 01-1.625-3.485zm11.046-7.775L20.621 6.52l-.182-.176c-3.741-3.535-9.548-3.623-13.393-.266L4.5 3.49c5.322-4.797 13.52-4.649 18.662.445zm-10.48 22.9c0 .635-.111 1.191-.336 1.67a2.533 2.533 0 01-.962 1.106c-.418.26-.897.389-1.437.389a2.68 2.68 0 01-1.43-.385 2.558 2.558 0 01-.973-1.1c-.23-.476-.346-1.024-.35-1.644v-.319c0-.634.115-1.192.344-1.675.228-.482.551-.853.969-1.11a2.666 2.666 0 011.432-.388c.537 0 1.014.13 1.431.387.418.258.741.629.97 1.111.228.483.343 1.04.343 1.67v.289zm-1.345-.292c0-.675-.12-1.189-.363-1.54-.242-.35-.587-.526-1.035-.526-.446 0-.79.173-1.032.52-.242.346-.364.855-.367 1.524v.315c0 .658.121 1.168.363 1.53.242.364.59.545 1.044.545.446 0 .788-.175 1.027-.524.24-.35.36-.86.363-1.53v-.314zm7.55 3.368H17.56l-2.585-4.24v4.24h-1.328v-6.444h1.328l2.59 4.25v-4.25h1.323v6.444z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
