/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-info': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 0c8.272 0 15 6.728 15 15s-6.728 15-15 15S0 23.272 0 15 6.728 0 15 0zm2.107 14.197H12.39a.805.805 0 00-.802.803c0 .422.33.771.745.8l.057.003h1.806v6.22h-1.806a.805.805 0 00-.802.803c0 .422.33.771.745.8l.057.003h6.522a.805.805 0 00.803-.803.805.805 0 00-.746-.8l-.057-.003h-1.806v-7.826zM15 6.371c-1.16 0-2.107.947-2.107 2.107 0 1.16.947 2.107 2.107 2.107 1.16 0 2.107-.947 2.107-2.107 0-1.16-.947-2.107-2.107-2.107z" _fill="#000" fill-rule="nonzero"/>'
  }
})
