/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto-bold': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M1.996 20.405a.774.774 0 01-.905-.621 13.88 13.88 0 01-.241-2.535c0-6.83 4.987-12.608 11.636-13.702l-2.459-1.23a.776.776 0 01-.347-1.04A.777.777 0 0110.72.93l5 2.5a.739.739 0 01.358.415.408.408 0 01.029.068l.006.01A.27.27 0 0116.14 4c.003.04.003.04 0 .066l-.004.023c-.001.011-.001.011-.002-.01l.004.017.002.012a.301.301 0 01-.013.187l-.002.006-.01.037-.007.027a.724.724 0 01-.297.417l-3.64 3.64a.773.773 0 01-1.095 0 .776.776 0 010-1.096l2.344-2.343C7.2 5.652 2.4 10.924 2.4 17.249c0 .73.07 1.468.215 2.253.079.42-.2.824-.62.903zm27.98.555a.776.776 0 11.795 1.33L26.2 25.022a.793.793 0 01-.396.107.764.764 0 01-.313-.066.228.228 0 01-.064-.043c-.012-.003-.025-.008-.063-.026a.551.551 0 01-.135-.122l-.01-.013-.003-.002c-.01-.01-.01-.01-.012-.014a.728.728 0 01-.183-.453l-1.031-5.111a.775.775 0 111.52-.306l.616 3.053c.641-1.516.973-3.135.973-4.776 0-5.213-3.309-9.887-8.233-11.631a.777.777 0 01-.472-.99.777.777 0 01.99-.47c5.541 1.96 9.265 7.222 9.265 13.091 0 1.74-.333 3.463-.977 5.088l2.306-1.377zM4.81 24.548a12.28 12.28 0 009.94 5.052c2.932 0 5.779-1.054 8.016-2.968a.775.775 0 011.006 1.178 13.887 13.887 0 01-9.023 3.34 13.829 13.829 0 01-10.994-5.42l-.106 2.8a.776.776 0 01-.774.745h-.03a.775.775 0 01-.745-.803l.195-5.23a.755.755 0 01.176-.436.727.727 0 01.074-.089.275.275 0 01.063-.068c.045-.026.049-.03.067-.035.017-.016.019-.017.061-.044a.396.396 0 01.107-.034l.006-.001.04-.01.006-.002c.022-.007.022-.007.048-.012a.746.746 0 01.431.04l5.331 1.451a.776.776 0 01-.408 1.496l-3.487-.95zm7.76-4.234c-.195.548-.281.895-.353 1.385l-.148.128h-2.89l.118-.22c.208-.392.392-.825.671-1.582l2.906-7.852c.238-.66.343-1.001.421-1.367l.147-.119h2.866l.146.117c.09.393.184.685.448 1.368l2.933 7.853c.25.672.489 1.257.648 1.588l.103.214h-2.878l-.148-.124a9.394 9.394 0 00-.34-1.39l-.418-1.192h-3.814l-.418 1.193zm2.37-6.95l-.052-.148a8.22 8.22 0 01-.05.149l-1.188 3.576h2.49l-1.2-3.576z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
