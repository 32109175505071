/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<path pid="0" d="M27.776 22.316l5.09-5.09a1.35 1.35 0 011.908 1.909l-5.09 5.089 1.59 1.59a4.048 4.048 0 005.726 0L47.814 15a4.048 4.048 0 000-5.725l-5.089-5.09a4.048 4.048 0 00-5.725 0L26.186 15a4.048 4.048 0 000 5.725l1.59 1.59zM27.921 26L26 27.92l1.6 1.602a6.791 6.791 0 010 9.604L16.717 50.01a6.791 6.791 0 01-9.605 0L1.99 44.889a6.791 6.791 0 010-9.605L12.874 24.4a6.791 6.791 0 019.604 0L24.08 26 26 24.08l-1.6-1.602a6.791 6.791 0 010-9.604L35.283 1.99a6.791 6.791 0 019.605 0l5.122 5.122a6.791 6.791 0 010 9.605L39.126 27.6a6.791 6.791 0 01-9.604 0L27.92 26zm-3.697 3.684l-4.473 4.474a1.35 1.35 0 01-1.909-1.909l4.474-4.473-1.59-1.59a4.048 4.048 0 00-5.726 0L4.186 37a4.048 4.048 0 000 5.725l5.089 5.09a4.048 4.048 0 005.725 0L25.814 37a4.048 4.048 0 000-5.725l-1.59-1.59z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
