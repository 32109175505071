/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-22': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M1.415 17.868a12.024 12.024 0 01-.21-2.2c0-6.343 4.929-11.546 11.156-12.007L9.818 6.203a.603.603 0 00.852.852l3.51-3.51a.591.591 0 00.25-.34c.002-.009.003-.017.006-.025.008-.038.025-.072.027-.11.001-.03-.01-.054-.013-.083-.001-.026.01-.049.006-.073-.005-.021-.02-.038-.028-.061-.004-.016-.012-.03-.019-.045a.59.59 0 00-.28-.335L9.306.063a.604.604 0 00-.538 1.079l2.782 1.39C5.046 3.375 0 8.938 0 15.67c0 .788.076 1.579.23 2.418a.602.602 0 101.185-.22zm27.428 1.711a.604.604 0 00-.826-.208l-2.6 1.552a13.177 13.177 0 001.099-5.254c0-5.598-3.552-10.617-8.838-12.488a.605.605 0 00-.77.366.604.604 0 00.367.77c4.806 1.701 8.036 6.263 8.036 11.352 0 1.763-.393 3.49-1.136 5.078l-.683-3.388a.602.602 0 10-1.182.237l.994 4.93a.587.587 0 00.143.37l.005.004c.036.041.069.083.117.114.016.01.035.006.05.014.021.012.033.033.055.042a.593.593 0 00.243.052.62.62 0 00.31-.084l4.407-2.632a.603.603 0 00.21-.827zm-7.762 5.248a12.048 12.048 0 01-7.823 2.896 11.992 11.992 0 01-9.935-5.262l3.75 1.023a.604.604 0 00.317-1.163l-5.14-1.4a.6.6 0 00-.35-.036c-.017.002-.032.01-.049.013-.037.01-.077.013-.113.031-.02.01-.031.028-.048.039-.017.01-.036.01-.052.023-.023.017-.034.042-.053.061a.594.594 0 00-.05.06.6.6 0 00-.143.342l-.188 5.042a.602.602 0 00.58.624h.023c.323 0 .59-.255.602-.58l.117-3.108a13.194 13.194 0 0010.73 5.497c3.15 0 6.207-1.131 8.608-3.186a.602.602 0 10-.783-.916zm-2.425-4.888H16.11a9.196 9.196 0 00-.334-1.363l-.437-1.247h-3.883l-.437 1.247a6.42 6.42 0 00-.347 1.363H8.127c.206-.386.386-.81.656-1.543l2.802-7.573c.231-.643.334-.977.411-1.337h2.764c.077.334.155.604.437 1.337l2.829 7.573a21 21 0 00.63 1.543zm-3.857-4.423l-1.221-3.638c-.039-.116-.09-.245-.193-.592-.077.27-.09.334-.18.592l-1.209 3.638H14.8z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
