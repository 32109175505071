/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-small-off': {
    width: 44,
    height: 30,
    viewBox: '0 0 44 30',
    data: '<path pid="0" d="M12.961 3.897c7.158 0 12.961 5.802 12.961 12.96 0 .448-.023.89-.067 1.327h-2.353c.054-.435.082-.877.082-1.326 0-5.867-4.756-10.624-10.623-10.624-5.867 0-10.623 4.757-10.623 10.624 0 5.867 4.756 10.623 10.623 10.623 2.458 0 4.722-.835 6.522-2.238v2.818a12.901 12.901 0 01-6.522 1.758C5.803 29.819 0 24.016 0 16.858 0 9.699 5.803 3.897 12.961 3.897zm-.297 3.896c.645 0 1.169.523 1.169 1.169v7.309l4.374 4.374a1.169 1.169 0 01-1.653 1.653l-4.394-4.42a1.169 1.169 0 01-.665-1.055V8.962c0-.646.523-1.169 1.169-1.169zM10.452.646c.289.578.055 1.28-.523 1.569L3.64 5.359a1.169 1.169 0 01-1.045-2.09L8.884.123a1.169 1.169 0 011.568.522zm14.897 19.866c.77 0 1.453.185 2.051.555.599.37 1.062.9 1.39 1.592.327.691.49 1.49.49 2.394v.413l-.007.335c-.035.771-.193 1.456-.474 2.055-.321.685-.781 1.214-1.38 1.586-.598.372-1.284.558-2.058.558-.765 0-1.448-.184-2.048-.552-.6-.368-1.065-.893-1.395-1.576-.33-.683-.497-1.468-.501-2.356v-.456l.006-.3c.032-.786.194-1.486.485-2.1.328-.692.79-1.223 1.39-1.593.597-.37 1.281-.555 2.05-.555zm11.327.127v1.541h-4.11v2.385h3.653V26.1h-3.653v3.773h-1.903V20.64h6.013zm7.115 0v1.541h-4.11v2.385h3.654V26.1h-3.653v3.773h-1.903V20.64h6.012zM25.35 22.085c-.639 0-1.131.249-1.478.745-.347.497-.522 1.225-.526 2.185v.45l.005.277c.031.81.203 1.45.515 1.918.346.52.845.78 1.496.78.639 0 1.13-.25 1.472-.752.342-.5.516-1.231.52-2.19v-.451l-.004-.258c-.03-.842-.201-1.492-.516-1.95-.347-.502-.842-.754-1.484-.754zM16.219.124l6.288 3.144a1.169 1.169 0 11-1.045 2.091l-6.29-3.144A1.169 1.169 0 1116.219.124z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
