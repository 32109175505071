/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fan0': {
    width: 32,
    height: 30,
    viewBox: '0 0 32 30',
    data: '<path pid="0" d="M9.493.189c1.265.587 1.297 2.24 1.342 4.331.035 1.68.089 4.219 1.032 4.662a5.93 5.93 0 012.951-.697c.7-.803 3.308-3.741 5.211-5.076 1.173-.823 4.296-2.163 7.99 3.121 1.917 2.742 3.85 8.933 1.56 10.539-1.137.798-2.586.005-4.416-1-1.473-.812-3.698-2.034-4.548-1.439a6.026 6.026 0 01-.869 2.911c.347 1.014 1.58 4.738 1.783 7.055.124 1.428-.278 4.807-6.69 5.366-2.889.255-8.03-.782-9.498-2.881a2.105 2.105 0 01-.394-1.033c-.123-1.388 1.288-2.244 3.074-3.328 1.434-.871 3.602-2.19 3.515-3.224a5.917 5.917 0 01-2.087-2.211c-1.046-.206-4.886-1-6.992-1.984-.713-.334-1.279-.798-1.687-1.382-.447-.64-.703-1.417-.759-2.315-.084-1.363.304-2.975 1.15-4.793.752-1.615 2.121-3.416 3.667-4.814C6.152.796 8.037-.49 9.493.19zm18.59 21.099l3.07 8.129h-1.47l-.664-1.893h-3.144l-.658 1.893H23.75l3.066-8.13h1.265zm-9.323-2.54l-.113.106a5.925 5.925 0 01-5.847 1.222c-.172.759-.75 1.42-1.63 2.124-.642.514-1.253.911-2.466 1.649-2.001 1.214-2.482 1.662-2.444 2.085.01.12.058.244.16.39.989 1.415 5.357 2.583 8.304 2.324 4.42-.386 5.668-1.924 5.492-3.937-.13-1.498-.73-3.748-1.456-5.964zm-6.756-8.127a4.67 4.67 0 00-1.142 6.487 4.655 4.655 0 006.478 1.144 4.67 4.67 0 001.142-6.488 4.655 4.655 0 00-6.478-1.143zm4.344 2.64a2.046 2.046 0 11-3.351 2.35 2.046 2.046 0 113.351-2.35zm4.437-8.771c-1.23.862-2.884 2.515-4.431 4.246a5.909 5.909 0 013.182 2.29c.48.687.795 1.441.952 2.216.743-.23 1.603-.06 2.649.352.767.3 1.42.634 2.66 1.317 2.046 1.125 2.681 1.32 3.027 1.077 1.164-.816.115-5.842-1.884-8.7-2.547-3.644-4.502-3.957-6.155-2.798zM5.71 2.976c-1.417 1.282-2.68 2.94-3.356 4.392-.761 1.636-1.098 3.039-1.029 4.155.041.661.22 1.205.523 1.64.273.39.658.705 1.165.943 1.361.636 3.622 1.246 5.886 1.721a5.967 5.967 0 011.828-5.836c-.7-.641-.975-1.718-1.113-3.274-.03-.33-.05-.68-.066-1.064-.013-.324-.02-.577-.031-1.104-.051-2.35-.197-2.986-.581-3.165-.581-.27-1.816.313-3.226 1.592zm20.56 23.41h2.352l-1.176-3.373-1.177 3.372z" _fill="#4C5153" fill-rule="evenodd"/>'
  }
})
