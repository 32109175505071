<template>
  <b-container fluid class="m-0 p-0">
    <GoBack />
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-2 box">
        <b-row>
          <b-col>
            <b-card
              border-variant="gray5"
              bg-variant="gray5"
              text-variant="white"
              align="center"
              title="Airzone cloud"
              title-tag="h3"
            >
              <b-card-header v-if="version" header-bg-variant="brand-a20">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem" style>
                      <az-icon name="azcloud" scale="2"></az-icon>
                    </b-avatar>
                  </transition>

                  <b-row class="h4 mt-1">
                    <b-col></b-col>
                    <b-col>
                      <Badge
                        :pillable="'badge-pill'"
                        :text="version.version"
                      >
                      </Badge>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                  
                  <b-row class="h4 mt-1" v-if="version.isLatestVersion">
                    <b-col></b-col>
                    <b-col>
                      <Badge
                        bgBadge="brand"
                        textColor="light"
                        :pillable="'badge-pill'"
                        :text="$t('publishRelease')"
                      >
                      </Badge>
                    </b-col>
                    <b-col></b-col>
                  </b-row>

                  <b-row v-if="2">
                    <b-col></b-col>
                    <b-col style="font-size: initial">{{ version.date | formatDate }}</b-col>
                    <b-col></b-col>
                  </b-row>

                </b-card-title>

                <b-card-text>
                  <b-spinner v-show="isLoading"></b-spinner>
                  <b-container
                    class=""
                    v-show="!isLoading"
                  >
                    <b-row>
                      <b-col>
                        <b-form class="needs-validation" novalidate>
                          <b-form-group
                            class="mb-0"
                          >

                            <b-row class="mb-4" v-if="!version.isLatestVersion">
                              <b-col sm="6" class="text-left">
                                <b-form-checkbox switch v-model="version.force" size="lg">
                                  {{ $t('forcedUpdate') }}
                                </b-form-checkbox>
                              </b-col>

                              <transition name="fromRight" appear)>
                                <b-col sm="6" class="text-left" v-if="version.force">
                          
                                  <b-form-group
                                    label-for="input-invalidateLower"
                                    label-cols-sm="4"
                                    label-align-sm="right"
                                  >
                                    <b-form-select
                                      v-model="invalidateLower"
                                      :options="invalidateLowerOptions"
                                      id="input-invalidateLower"
                                      class="mb-3 shadow"
                                      size="lg"
                                      value-field="value"
                                      text-field="text"
                                    >
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </transition>
                            </b-row>                         

                            <b-row class="mb-4" v-if="version.isLatestVersion">
                              <b-col sm="2" class="text-left">
                                <label for="textarea-default">{{ $t('versionText') }}:</label>
                              </b-col>
                              <b-col class="text-gray1" v-if="version.notes">
                                <b-tabs v-model="tabIndexAdmin" style="background-color: #ccc" fill lazy>
                                  <b-tab
                                    @click="clickTab(1, true)"
                                    id="text_es"
                                    :class="{ active: checkTab(1, true) }"
                                    :title="$t('spanish')"
                                    >
                                    <vue-editor v-model="version.notes.es" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(2, true)"
                                    id="text_en"
                                    :class="{ active: checkTab(2, true) }"
                                    :title="$t('english')"
                                    >
                                    <vue-editor v-model="version.notes.en" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(3, true)"
                                    id="text_fr"
                                    :class="{ active: checkTab(3, true) }"
                                    :title="$t('french')"
                                    >
                                    <vue-editor v-model="version.notes.fr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(4, true)"
                                    id="text_it"
                                    :class="{ active: checkTab(4, true) }"
                                    :title="$t('italian')"
                                    >
                                    <vue-editor v-model="version.notes.it" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(5, true)"
                                    id="text_pt"
                                    :class="{ active: checkTab(5, true) }"
                                    :title="$t('portuguese')"
                                    >
                                    <vue-editor v-model="version.notes.pt" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(6, true)"
                                    id="text_de"
                                    :class="{ active: checkTab(6, true) }"
                                    :title="$t('german')"
                                    >
                                    <vue-editor v-model="version.notes.de" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(7, true)"
                                    id="text_pl"
                                    :class="{ active: checkTab(7, true) }"
                                    :title="$t('polish')"
                                    >
                                    <vue-editor v-model="version.notes.pl" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(8, true)"
                                    id="text_el"
                                    :class="{ active: checkTab(8, true) }"
                                    :title="$t('greek')"
                                    >
                                    <vue-editor v-model="version.notes.el" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(9, true)"
                                    id="text_tr"
                                    :class="{ active: checkTab(9, true) }"
                                    :title="$t('turkish')"
                                    >
                                    <vue-editor v-model="version.notes.tr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                            
                                </b-tabs>
                              </b-col>
                            </b-row>

                            <!--b-row>
                              <b-col sm="2"></b-col>
                              <b-col sm="10">
                                <div class="error my-2 pb-2" v-if="errors.notes">
                                  {{ $t("messages.errors.emptyInputError") }}
                                </div>
                              </b-col>
                            </b-row-->

                            <b-form-group
                              label-for="sumbit-newFirmware"
                              label-align-sm="right"
                            >
                              <b-button
                                id="sumbit-newFirmware"
                                class="btn btn-brand"
                                @click="saveVersion"
                                >{{ $t("buttons.save") }}</b-button
                              >
                            </b-form-group>
                          </b-form-group>
                        </b-form>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-text>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
      </b-jumbotron>
    </transition>
  </b-container>

</template>

<script>
import WebService from "@/services/web.service";
import GoBack from "@/components/GoBack";
import Badge from "@/components/Badge";
import deviceType from "@/mixins/DeviceType.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import FirmwareMixin from '@/mixins/Firmware.mixin'
import i18n from "@/services/language.service";
import { VueEditor, Quill } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";

Quill.register("modules/htmlEditButton", htmlEditButton)


export default {
  name: "Firmware",
  props: {},
  mixins: [rowClass, deviceType, wsType, showMsgOk, FirmwareMixin],
  components: {
    GoBack,
    Badge,
    VueEditor,
  },

  data() {
    return {
      versionId: this.$route.params.version,
      platform: this.$route.params.platform,
      isLoading: true,
      version: {
        notes: {
          es: '',
          en: '',
          fr: '',
          it: '',
          pt: '',
          de: '',
          pl: '',
          el: '',
          tr: '',
        },
      },
      invalidateLower: false,
      invalidateLowerOptions: [
        {
          value: false,
          text: i18n.t('thisVersion'),
        },
        {
          value: true,
          text: i18n.t('thisAndPrevious')
        },
      ],
      errors: {},
      tabIndexAdmin: 0,
      tabAdmin: 1,
      editorSettings: {
        modules: {
          htmlEditButton: {
            debug: false, // logging, default:false
            msg: "HTML", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: i18n.t('buttons.accept'), // Text to display in the OK button, default: Ok,
            cancelText: i18n.t('buttons.cancel'), // Text to display in the cancel button, default: Cancel
            buttonHTML: "<>",//"&lt;&gt;", // Text to display in the toolbar button, default: <>
            buttonTitle: "HTML", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {} // The default mod
          },
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
      ],
      saveEmptyTexts: false,
      withoutTexts: false,
      isActive: false,
    };
  },

  methods: {
    initializeTexts() {
      if (!this.version?.notes) this.version.notes = {}
      if (this.version?.notes['es'] === undefined) this.version.notes.es = ''
      if (this.version?.notes['en'] === undefined) this.version.notes.en = ''
      if (this.version?.notes['fr'] === undefined) this.version.notes.fr = ''
      if (this.version?.notes['it'] === undefined) this.version.notes.it = ''
      if (this.version?.notes['pt'] === undefined) this.version.notes.pt = ''
      if (this.version?.notes['de'] === undefined) this.version.notes.de = ''
    },

    async getVersion(platform, version) {
      try {
        this.isLoading = true
        const response = await WebService.getVersions(platform, version);
        // console.log(response)
        this.isLoading = false
        return response
      } catch(err) {
        this.$bvModal.show("bv-modal-error");
        this.isLoading = false
        this.setTextEditorSytle()
      }
    },

    /**
     * Valido extensión de archivos
     */
     validate() {
      const errors = {};

      return errors;
    },

    /**
     * Muestra aviso de textos vacíos
     */
    async showEmptyTextsPopup() {
      // Creo promesa para que devuelva valores antes de destruir el modal
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(i18n.t('messages.saveWithoutTexts', {text: i18n.t('versionText') }), {
            title: i18n.t('buttons.save'),
            okVariant: "primary",
            okTitle: i18n.t("buttons.save"),
            cancelTitle: i18n.t("buttons.cancel"),
            headerBgVariant: "primary",
            headerTextVariant: "light",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          })
          .then((accept) => {
            if (accept) {
              this.saveEmptyTexts = true
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch((error) => {
            console.log(error);
            reject(false)
          });
        })
    },

    /**
     * Comprueba si hay textos vacíos
     */
     async checkEmptyTexts() {
      this.withoutTexts = false
      
      if (!this.saveEmptyTexts) {
        const index = Object.keys(this.version?.notes).findIndex(k => this.version.notes[k] === '')
        if (index !== -1) {
          // errors.notes = true
          this.withoutTexts = true
          this.tabIndexAdmin = index
          this.clickTab(index - 1, false)
          return await this.showEmptyTextsPopup()
        }
      }
      return true
    },

    async showForcedUpdatedConfirmation() {
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(this.invalidateLower ? i18n.t('messages.forcedUpdatedLowerConfirm', {version: this.versionId}) : i18n.t('messages.forcedUpdatedConfirm', {version: this.versionId}), {
            title: i18n.t('forcedUpdate'),
            // okVariant: okBg,
            okTitle: i18n.t('buttons.accept'),
            cancelTitle: i18n.t('buttons.cancel'),
            headerBgVariant: "warning",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          })
          .then((accept) => {
            if (accept) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch((error) => {
            console.log(error);
            reject(false)
          });
        })
    },

    /**
     * Valida y guarda los datos de la version
     */
    async saveVersion() {
      try {
        //
        // Inicializamos valores
        //
        this.errors = {};

        //
        // Si hay una petición en curso no hago nada
        //
        if (this.isLoading) return;

        //
        // Aviso si hay textos vacíos en última versión
        //
        if (this.version.isLatestVersion) {
          const respEmptyTexts = await this.checkEmptyTexts()
          if (!respEmptyTexts) return
        }

        //
        // Preparo datos y realizo la petición
        //
        this.isLoading = true;
        const data = {...this.version}
        
        delete data.date
        delete data.isLatestVersion
        let response

        // Si es última versión, borro el forzado de actualización 
        if (!this.version.isLatestVersion) {
          let confirm = false
          delete data.notes
          data.version = this.version.version
          // Si fuerza actualización, envío si afecta a versiones anteriores
          // Sino envío el forzado a false y quito el campo de versiones anteriores
          if (this.version.force) {
            data.invalidateLower = this.invalidateLower
            data.force = this.version.force
            confirm = await this.showForcedUpdatedConfirmation()
            } else {
              delete data.invalidateLower
              confirm = true
            }
            if (confirm) {
              response = await WebService.forceUpdateAppVersion(data);
            }
        } else {
          delete data.force
          response = await WebService.editAppVersion(data);
        }

        if (response?.status === 204) {
          this.showMsgOk(
            i18n.t("publishRelease"),
            i18n.t("messages.firmwareMsg", { version: this.version.version })
          );
        }
        this.isLoading = false;
        this.setTextEditorSytle()
      } catch(error) {
        console.log(error)
        this.$bvModal.show("bv-modal-error");
        this.isLoading = false;
        this.setTextEditorSytle()
      }
    },

    setTextEditorSytle() {
      this.$nextTick(() => {
        this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[0])
        this.editorStyle(document.getElementsByClassName('ql-editor')[0])
      })
    },
    
  },
    
  async mounted() {
    if (!this.$route.params.versions) {
      const response = await this.getVersion(this.platform)
      this.versions = response.versions.map(item => item.version)
    } else {
      this.versions = this.$route.params.versions.map(item => item.version)
    }

    const resp =await this.getVersion(this.platform, this.versionId)
    this.version = resp.versions[0];
    this.initializeTexts()

    // Estilo del editor de texto
    this.setTextEditorSytle()
  },
};
</script>

<style lang="scss" scoped>
.b-avatar-custom {
  svg {
    fill: var(--brand-dark)
  }
}

#components.showComponents,
#installations.showInstallations {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-out;
  height: 100%;
  opacity: 1;
}
</style> 