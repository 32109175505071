/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'less': {
    width: 15,
    height: 3,
    viewBox: '0 0 15 3',
    data: '<path pid="0" d="M13.5 3h-12C.6 3 0 2.4 0 1.5S.6 0 1.5 0h12c.9 0 1.5.6 1.5 1.5S14.4 3 13.5 3z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
