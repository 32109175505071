/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speed-2': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M22.478 26h-1.5c-1.5 0-2.8-1.2-2.8-2.8V3.7c0-1.6 1.1-2.8 2.8-2.8h1.5c1.5 0 2.8 1.2 2.8 2.8v19.5c0 1.5-1.3 2.8-2.8 2.8zm-1.5-23.5c-.8 0-1.2.5-1.2 1.2v19.5c0 .7.6 1.2 1.2 1.2h1.5c.7 0 1.2-.6 1.2-1.2V3.7c0-.7-.6-1.2-1.2-1.2h-1.5zM4.478 8h-1.5c-1.6 0-2.7 1.1-2.7 2.7v12.5c0 1.5 1.2 2.8 2.8 2.8h1.5c1.5 0 2.8-1.2 2.8-2.8V10.7c-.1-1.5-1.4-2.7-2.9-2.7zM13.478 5h-1.5c-1.7 0-2.8 1.1-2.8 2.7v15.5c0 1.6 1.3 2.8 2.8 2.8h1.5c1.5 0 2.8-1.3 2.8-2.8V7.7c0-1.5-1.3-2.7-2.8-2.7z"/></g>'
  }
})
