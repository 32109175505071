/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'iaq': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" d="M12.49 10c1.56 0 3.161.635 4.805 1.888a1.083 1.083 0 01-1.313 1.724c-1.272-.97-2.437-1.445-3.491-1.445-.146 0-.293.025-.479.094l-.17.07-.193.095-.222.121-.124.072-.276.167-.317.2-.566.369-.952.63c-.16.104-.312.2-.457.29l-.415.247-.382.211a7.168 7.168 0 01-1.372.565 4.731 4.731 0 01-1.335.202c-2.024 0-3.694-.676-4.94-2.01a1.083 1.083 0 011.584-1.48c.828.888 1.924 1.323 3.356 1.323.222 0 .462-.038.726-.115.51-.149 1.025-.402 1.785-.878l.235-.15 1.479-.975.471-.3.413-.25c.904-.524 1.415-.665 2.15-.665zm0-5c1.56 0 3.161.635 4.805 1.888a1.083 1.083 0 01-1.313 1.724c-1.272-.97-2.437-1.445-3.491-1.445-.146 0-.293.025-.479.094l-.17.07-.193.095-.222.121-.124.072-.276.167-.317.2-.566.369-.952.63c-.16.104-.312.2-.457.29l-.415.247-.382.211a7.168 7.168 0 01-1.372.565 4.731 4.731 0 01-1.335.202c-2.024 0-3.694-.676-4.94-2.01a1.083 1.083 0 011.584-1.48c.828.888 1.924 1.323 3.356 1.323.222 0 .462-.038.726-.115.51-.149 1.025-.402 1.785-.878l.235-.15 1.479-.975.471-.3.413-.25C11.244 5.142 11.755 5 12.49 5zm0-5c1.56 0 3.161.635 4.805 1.888a1.083 1.083 0 01-1.313 1.724c-1.272-.97-2.437-1.445-3.491-1.445-.146 0-.293.025-.479.094l-.17.07-.193.095-.222.121-.124.072-.276.167-.317.2-.566.369-.952.63c-.16.104-.312.2-.457.29l-.415.247-.382.211a7.168 7.168 0 01-1.372.565A4.731 4.731 0 015.23 5.5c-2.024 0-3.694-.676-4.94-2.01a1.083 1.083 0 011.584-1.48c.828.888 1.924 1.323 3.356 1.323.222 0 .462-.038.726-.115.51-.149 1.025-.402 1.785-.878l.235-.15 1.479-.975.471-.3.413-.25C11.244.142 11.755 0 12.49 0z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
