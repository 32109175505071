/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-11': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.938 15.353c-.003-.005-.002-.011-.004-.016l-2.5-5a.626.626 0 00-.559-.345H21.25v-1.25h.625a.624.624 0 00.46-1.048L15.46.194a.644.644 0 00-.921 0l-6.875 7.5a.624.624 0 00.461 1.048h.625v1.25H3.125a.626.626 0 00-.559.346l-2.5 5c-.002.005-.001.01-.004.015a.616.616 0 00-.062.264v11.25c0 .345.28.625.625.625H8.75v1.25h-.625a.625.625 0 000 1.25h13.75a.625.625 0 000-1.25h-.625v-1.25h8.125c.345 0 .625-.28.625-.625v-11.25a.625.625 0 00-.063-.264zM8.75 26.242h-7.5v-10h7.5v10zm0-11.25H1.636l1.875-3.75H8.75v3.75zm7.5 13.75h-2.5v-5h2.5v5zm3.75 0h-2.5v-5.625a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v5.625H10v-20h10v20zM9.546 7.492l5.455-5.95 5.454 5.95H9.546zm11.704 3.75h5.239l1.875 3.75H21.25v-3.75zm7.5 15h-7.5v-10h7.5v10zm-25.625-2.5h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h2.5v2.5h-2.5v-2.5zm19.375 3.75h3.75c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h2.5v2.5h-2.5v-2.5zm-8.567-5.183a.628.628 0 00.884 0 .625.625 0 000-.884l-.442-.442v-.991a.625.625 0 00-1.25 0v1.25c0 .166.065.325.183.442l.625.625zM15 17.492a3.755 3.755 0 003.75-3.75A3.755 3.755 0 0015 9.992a3.755 3.755 0 00-3.75 3.75 3.755 3.755 0 003.75 3.75zm0-6.25c1.379 0 2.5 1.12 2.5 2.5 0 1.378-1.121 2.5-2.5 2.5a2.503 2.503 0 01-2.5-2.5c0-1.38 1.121-2.5 2.5-2.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
