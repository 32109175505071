/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leave': {
    width: 25,
    height: 30,
    viewBox: '0 0 25 30',
    data: '<path pid="0" d="M16.275 10.097c.354 0 .67.202.825.524l.051.126.033.147.009.12-.001 17.15h6.259c.26 0 .5.11.673.3l.081.1.068.115.054.136.033.146.009.121c0 .354-.203.67-.525.826l-.126.05-.146.033-.121.009h-6.645a1.45 1.45 0 01-1.369-.98l-.047-.163-.026-.167-.007-.14V11.015c0-.506.41-.917.918-.917zm-9.802 0c1.305 0 2.455.884 2.887 2.178l.068.233.052.237.034.229.705 6.173a2.14 2.14 0 01-.52 1.665 1.932 1.932 0 01-.704.505l-.21.074-.115.025-.565 7.679a.853.853 0 01-.224.511l-.106.1-.125.082-.13.058-.146.037-.133.01H2.99a.86.86 0 01-.731-.41l-.063-.116-.048-.142-.021-.132-.567-7.677-.129-.029a1.858 1.858 0 01-.267-.102l-.13-.066-.186-.12-.168-.138-.147-.149a2.099 2.099 0 01-.491-.996l-.033-.23L0 19.357l.013-.21.705-6.173c.156-1.377 1.11-2.491 2.369-2.797l.225-.045.23-.027.217-.008h2.714zm-.001 1.73H3.758c-.508 0-.975.34-1.195.856l-.059.16-.041.157-.027.17-.705 6.172c-.014.123.02.24.09.317l.052.05.02.014.07.01h.403a.86.86 0 01.731.41l.063.116.047.143.022.132.564 7.63h2.643L7 20.533a.86.86 0 01.464-.698l.12-.054.146-.037.133-.011h.404c.05 0 .097-.024.14-.072a.398.398 0 00.065-.103l.017-.05.012-.064-.003-.102-.705-6.173c-.088-.77-.662-1.343-1.322-1.343zM22.534.268a.918.918 0 011.298 0c.24.24.323.585.226.913l-.053.14-.086.143-.087.102-10.628 10.628a.918.918 0 01-1.298 0 .913.913 0 01-.226-.913l.053-.14.086-.143.087-.102L22.534.269zM5.115 3.719c1.65 0 2.99 1.342 2.99 2.991 0 1.65-1.34 2.99-2.99 2.99a2.993 2.993 0 01-2.99-2.99c0-1.649 1.341-2.99 2.99-2.99zm0 1.73c-.694 0-1.26.566-1.26 1.261s.566 1.261 1.26 1.261c.695 0 1.261-.566 1.261-1.26 0-.696-.566-1.262-1.26-1.262z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
