/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elipsis': {
    width: 25,
    height: 5,
    viewBox: '0 0 25 5',
    data: '<path pid="0" d="M22 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-9.75 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zM2.5 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z" _fill="#000" fill-rule="evenodd"/>'
  }
})
