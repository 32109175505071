/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.126 0a.874.874 0 01.619 1.493C26.582 4.658 24.52 7.916 23.39 12.58l-.42 1.85-.174.726-.17.658-.084.31-.173.593c-.088.29-.18.571-.279.856l-.208.578c-1.857 4.963-5.015 7.813-10.245 7.813-3.067 0-5.746-.807-7.77-2.309l-.138-.107-.09.175c-1.057 2.047-1.728 3.893-1.864 5.016l-.02.202-.006.185a.875.875 0 01-1.749 0c0-1.424.83-3.822 2.259-6.494l.161-.299-.168-.192C.956 20.608.194 18.7.032 16.546l-.024-.433-.008-.44c0-3.442 1.368-6.839 4.745-9.636C9.395 2.187 17.311 0 29.125 0zm-2.047 1.772l-.25.006C16.7 2.04 9.9 4.048 5.89 7.368c-2.949 2.443-4.14 5.358-4.14 8.305 0 1.895.544 3.586 1.572 4.959l.052.065.203-.329c3.486-5.56 8.377-10.46 13.84-13.271l.567-.283a.877.877 0 011.167.406.876.876 0 01-.405 1.168c-5.65 2.738-10.5 7.601-13.882 13.158l-.255.43.169.14c1.637 1.28 3.835 2.01 6.379 2.092l.48.007c5.384 0 7.918-3.234 9.59-10.037l.107-.449.213-.934c.94-4.283 2.643-7.582 5.087-10.504l.446-.52z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
