/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hswing': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M27.719 12.646a.64.64 0 01-.906 0l-9.91-9.909a4.27 4.27 0 01-5.073.04L1.987 12.62a.64.64 0 01-.906-.905l10.59-10.589c1.5-1.5 3.932-1.5 5.432 0l10.616 10.616a.64.64 0 010 .905zM28.8 23.403a.6.6 0 00-.086-.316.076.076 0 00-.01-.026l-.019-.017-.016-.02-.02-.033-.022-.021-.025-.019a.597.597 0 00-.43-.153h-6.566a.6.6 0 000 1.2h4.935c-3.187 2.39-7.26 3.6-12.137 3.6-4.88 0-8.957-1.209-12.145-3.6h4.935a.6.6 0 000-1.2H.628a.599.599 0 00-.353.095l-.077.058a.263.263 0 00-.048.04l-.035.053-.019.017a.076.076 0 00-.01.026.6.6 0 00-.054.505l.003.028 2.4 6.001a.6.6 0 101.113-.445l-1.555-3.887c3.006 2.107 6.695 3.272 11.01 3.477a29.188 29.188 0 002.31.02l.483-.02c4.316-.205 8.005-1.37 11.01-3.477l-1.555 3.887a.6.6 0 101.113.445l2.4-6.001.004-.028a.58.58 0 00.029-.125l.003-.064zM13.76 9.46a.64.64 0 011.28 0v8.535a.64.64 0 01-1.28 0V9.461zm-2.682-.563a.64.64 0 011.242.31l-2.065 8.281a.64.64 0 01-1.242-.31l2.065-8.281zM8.612 7.703a.64.64 0 011.13.601L5.735 15.84a.64.64 0 01-1.13-.6l4.007-7.537zm7.886 1.655a.64.64 0 011.243-.31l2.065 8.282a.64.64 0 01-1.243.31l-2.065-8.282zm2.633-.762a.64.64 0 111.13-.602l4.007 7.537a.64.64 0 11-1.13.6L19.13 8.597z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
