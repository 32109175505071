import Vue from 'vue';

const ADD_PREV = (state, prevData) => {
  if (prevData) {
    const found = state.prev.find(item => item === prevData)
    if (!found) state.prev.push(prevData);
  }
};

const POP_PREV = (state, prevData) => {
  state.prev = prevData
};

const DELETE_PREV = (state) => {
  Vue.delete(state.prev);
};

const ADD_PAGEANDFILTER = (state, pageAndFilterData) => {
  state.pageAndFilter = pageAndFilterData;
};

const DELETE_PAGEANDFILTER = (state) => {
  Vue.delete(state.pageAndFilter);
};

const ADD_USER = (state, userData) => {
  state.user = userData;
};

const DELETE_USER = (state) => {
  Vue.delete(state.user);
};

const ADD_DEVICE = (state, deviceData) => {
  state.device = deviceData;
};

const DELETE_DEVICE = (state) => {
  Vue.delete(state.device);
};

const ADD_INSTALLATION = (state, installationData) => {
  state.installation = installationData;
};

const DELETE_INSTALLATION = (state) => {
  Vue.delete(state.installation);
};

const ADD_FIRMWARE = (state, firmwareData) => {
  state.firmware = firmwareData;
};

const DELETE_FIRMWARE = (state) => {
  Vue.delete(state.firmware);
};

export default {
  ADD_PREV,
  POP_PREV,
  DELETE_PREV,
  ADD_PAGEANDFILTER,
  DELETE_PAGEANDFILTER,
  ADD_USER,
  DELETE_USER,
  ADD_DEVICE,
  DELETE_DEVICE,
  ADD_INSTALLATION,
  DELETE_INSTALLATION,
  ADD_FIRMWARE,
  DELETE_FIRMWARE,
};
