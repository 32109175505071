/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vauto': {
    width: 26,
    height: 28,
    viewBox: '0 0 26 28',
    data: '<path pid="0" d="M12.745.187a.636.636 0 010 .905L2.76 10.998a4.24 4.24 0 01.04 5.071l9.92 9.839a.636.636 0 010 .905.649.649 0 01-.913 0L1.134 16.228a3.817 3.817 0 010-5.43L11.833.188c.252-.25.66-.25.912 0zM9.535 14.14a.643.643 0 01-.645-.64c0-.353.289-.64.645-.64h8.602c.357 0 .646.287.646.64 0 .353-.29.64-.646.64H9.535zm-.567 2.68a.64.64 0 11.312-1.241l8.347 2.064a.64.64 0 11-.312 1.242L8.968 16.82zm-1.204 2.466a.637.637 0 01-.267-.866.648.648 0 01.873-.264l7.595 4.005a.637.637 0 01.267.866.648.648 0 01-.873.264l-7.595-4.005zm1.667-7.883a.64.64 0 11-.312-1.242l8.347-2.064a.64.64 0 11.312 1.242l-8.347 2.064zM16.525 3.9c.168.312.048.7-.266.865L8.663 8.772a.648.648 0 01-.872-.265.637.637 0 01.266-.865l7.596-4.006a.648.648 0 01.872.265zm6.743 21.335h-3.144l-.658 1.893H18L21.066 19h1.266l3.071 8.129h-1.471l-.664-1.893zm-2.748-1.139h2.352l-1.176-3.372-1.176 3.372z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
