/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry-bold': {
    width: 19,
    height: 30,
    viewBox: '0 0 19 30',
    data: '<path pid="0" d="M9.75 29.15c-4.908 0-8.9-3.995-8.9-8.907 0-1.85 1.495-5.707 4.01-10.875.888-1.827 1.848-3.7 2.818-5.526A158.83 158.83 0 019.11 1.204c.263-.472 1.018-.472 1.28 0 .308.55.823 1.493 1.432 2.638.97 1.825 1.93 3.699 2.82 5.526 2.513 5.168 4.009 9.026 4.009 10.875 0 4.911-3.992 8.907-8.9 8.907zm-7.433-8.907c0 4.102 3.335 7.44 7.433 7.44s7.433-3.338 7.433-7.44c0-2.363-3.315-9.62-7.433-17.163-4.118 7.542-7.433 14.8-7.433 17.163zm7.433 5.404a5.408 5.408 0 01-5.4-5.404.734.734 0 111.467 0A3.94 3.94 0 009.75 24.18a.734.734 0 010 1.467z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
