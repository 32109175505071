/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power-bold': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path pid="0" d="M10.812 11.023c-.66 0-1.206-.444-1.206-1.055V1.056C9.606.445 10.153 0 10.812 0c.659 0 1.206.445 1.206 1.056v8.912c0 .61-.547 1.055-1.206 1.055zm0 12.977C4.85 24 0 19.251 0 13.41c0-4.207 2.547-8.014 6.492-9.703a1.216 1.216 0 011.581.61c.27.6-.01 1.3-.622 1.56-3.062 1.311-5.04 4.268-5.04 7.534 0 4.532 3.77 8.223 8.4 8.223 4.632 0 8.401-3.69 8.401-8.223 0-3.266-1.977-6.223-5.038-7.533a1.174 1.174 0 01-.623-1.561 1.218 1.218 0 011.585-.608c3.941 1.687 6.488 5.494 6.488 9.702 0 5.84-4.85 10.589-10.812 10.589z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
