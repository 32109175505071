/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-zones': {
    width: 24,
    height: 31,
    viewBox: '0 0 24 31',
    data: '<path pid="0" d="M7.64 22.45a2.4 2.4 0 012.4 2.4v3.6a2.4 2.4 0 01-2.4 2.4h-4.8a2.4 2.4 0 01-2.4-2.4v-3.6a2.4 2.4 0 012.4-2.4h4.8zm10.8.4c.48 0 .8.32.8.8v2.4h2.4c.48 0 .8.32.8.8 0 .48-.32.8-.8.8h-2.4v2.4c0 .48-.32.8-.8.8-.48 0-.8-.32-.8-.8v-2.4h-2.4c-.48 0-.8-.32-.8-.8 0-.48.32-.8.8-.8h2.4v-2.4c0-.48.32-.8.8-.8zm-10.8 1.4h-4.8a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-3.6a.6.6 0 00-.6-.6zm0-12.6a2.4 2.4 0 012.4 2.4v3.6a2.4 2.4 0 01-2.4 2.4h-4.8a2.4 2.4 0 01-2.4-2.4v-3.6a2.4 2.4 0 012.4-2.4h4.8zm13.2 0a2.4 2.4 0 012.4 2.4v3.6a2.4 2.4 0 01-2.4 2.4h-4.8a2.4 2.4 0 01-2.4-2.4v-3.6a2.4 2.4 0 012.4-2.4h4.8zm-13.2 1.8h-4.8a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-3.6a.6.6 0 00-.6-.6zm13.2 0h-4.8a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-3.6a.6.6 0 00-.6-.6zM7.64.85a2.4 2.4 0 012.4 2.4v3.6a2.4 2.4 0 01-2.4 2.4h-4.8a2.4 2.4 0 01-2.4-2.4v-3.6a2.4 2.4 0 012.4-2.4h4.8zm13.2 0a2.4 2.4 0 012.4 2.4v3.6a2.4 2.4 0 01-2.4 2.4h-4.8a2.4 2.4 0 01-2.4-2.4v-3.6a2.4 2.4 0 012.4-2.4h4.8zm-13.2 1.8h-4.8a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-3.6a.6.6 0 00-.6-.6zm13.2 0h-4.8a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-3.6a.6.6 0 00-.6-.6z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
