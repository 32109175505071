/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'connect': {
    width: 38,
    height: 30,
    viewBox: '0 0 38 30',
    data: '<path pid="0" d="M31.284 16.02l6.316 6.316a.79.79 0 01-.559 1.348h-.79v5.527a.787.787 0 01-.787.789h-3.158a.79.79 0 01-.79-.79v-3.947h-1.579v3.948a.79.79 0 01-.79.789H25.99a.79.79 0 01-.79-.79v-5.526h-.79a.79.79 0 01-.558-1.348l6.316-6.316a.79.79 0 011.116 0zm-.557 1.675l-4.46 4.46a.79.79 0 01.512.74v5.526h1.58v-3.947a.79.79 0 01.789-.79h3.158a.79.79 0 01.79.79v3.947h1.578v-5.526a.79.79 0 01.513-.74l-4.46-4.46zm-12.632 1.252a3.953 3.953 0 013.948 3.948 3.953 3.953 0 01-3.948 3.947 3.953 3.953 0 01-3.947-3.947 3.953 3.953 0 013.947-3.948zm0 1.58a2.371 2.371 0 00-2.368 2.368 2.371 2.371 0 002.368 2.368 2.371 2.371 0 002.369-2.368 2.371 2.371 0 00-2.369-2.369zm-.001-7.894c3.374 0 6.546 1.314 8.932 3.7a.79.79 0 01-1.117 1.116 10.98 10.98 0 00-7.815-3.237c-2.953 0-5.727 1.15-7.816 3.237a.79.79 0 01-1.117-1.116 12.549 12.549 0 018.933-3.7zm13.398-.767a.79.79 0 01-1.116 1.116c-6.772-6.77-17.791-6.77-24.564 0a.79.79 0 01-1.116-1.116c7.388-7.388 19.409-7.388 26.796 0zM18.095 0c6.749 0 13.093 2.629 17.863 7.4a.79.79 0 01-1.117 1.117c-4.473-4.473-10.419-6.938-16.746-6.938A23.531 23.531 0 001.347 8.517.79.79 0 01.231 7.4C5.003 2.629 11.347 0 18.095 0z" _fill="#000" fill-rule="nonzero"/>'
  }
})
