/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-small-60': {
    width: 36,
    height: 30,
    viewBox: '0 0 36 30',
    data: '<path pid="0" d="M12.961 3.897c7.158 0 12.961 5.802 12.961 12.96 0 .448-.023.89-.067 1.327h-2.353c.054-.435.082-.877.082-1.326 0-5.867-4.756-10.624-10.623-10.624-5.867 0-10.623 4.757-10.623 10.624 0 5.867 4.756 10.623 10.623 10.623 2.458 0 4.722-.835 6.522-2.238v2.818a12.901 12.901 0 01-6.522 1.758C5.803 29.819 0 24.016 0 16.858 0 9.699 5.803 3.897 12.961 3.897zm-.297 3.896c.645 0 1.169.523 1.169 1.169v7.309l4.374 4.374a1.169 1.169 0 01-1.653 1.653l-4.394-4.42a1.169 1.169 0 01-.665-1.055V8.962c0-.646.523-1.169 1.169-1.169zM10.452.646c.289.578.055 1.28-.523 1.569L3.64 5.359a1.169 1.169 0 01-1.045-2.09L8.884.123a1.169 1.169 0 011.568.522zm16.27 19.898v1.51h-.177l-.244.01c-.717.047-1.302.26-1.757.636-.505.419-.809 1-.91 1.744.49-.498 1.11-.748 1.858-.748.803 0 1.442.288 1.915.863.474.575.71 1.331.71 2.27 0 .6-.13 1.144-.39 1.63s-.627.865-1.103 1.135A3.206 3.206 0 0125.01 30c-.972 0-1.758-.338-2.356-1.015-.598-.676-.897-1.579-.897-2.708v-.66l.007-.329c.037-.867.224-1.642.56-2.324a4.162 4.162 0 011.63-1.782c.708-.421 1.53-.634 2.464-.638h.304zm5.569-.032c1.006 0 1.775.331 2.308.993.533.662.806 1.612.818 2.85v1.7l-.004.29c-.038 1.136-.3 2.016-.788 2.64-.529.677-1.303 1.015-2.322 1.015-1.006 0-1.775-.332-2.308-.996-.533-.663-.806-1.615-.818-2.854v-1.7l.005-.291c.038-1.146.303-2.028.797-2.645.535-.668 1.305-1.002 2.312-1.002zm-7.357 4.655a1.462 1.462 0 00-1.338.844v.558l.006.224c.026.507.145.91.355 1.212.241.345.58.517 1.015.517.393 0 .711-.155.955-.466.243-.31.364-.713.364-1.208 0-.503-.122-.909-.368-1.218-.245-.308-.575-.463-.989-.463zm7.357-3.17c-.432 0-.75.165-.955.497-.205.332-.314.851-.326 1.557v2.245l.003.244c.02.63.122 1.109.304 1.434.205.365.534.548.986.548.449 0 .772-.175.97-.526.2-.351.303-.888.311-1.61V24.19l-.003-.248c-.021-.639-.124-1.113-.31-1.423-.21-.35-.536-.524-.98-.524zM16.218.123l6.29 3.144a1.169 1.169 0 11-1.046 2.091l-6.29-3.144A1.169 1.169 0 1116.219.124z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
