const ShowMsgOk = {

  methods: {
    /**
     * Muestra ventana de confirmación con título y mensaje
     */
    showMsgOk(title, message) {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', { domProps: { innerHTML: title } })
      // More complex structure
      const messageVNode = h('div', { class: ['h4'] }, [
        h('p', { class: ['text-center'] }, [
          message
        ]),
        // h('p', { class: ['text-center'] }, [h('b-spinner', { props: {variant: 'primary'} } )]),
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        headerBgVariant: 'primary',
        centered: true
      })
    },
  }

}

export default ShowMsgOk