/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outdoor': {
    width: 29,
    height: 30,
    viewBox: '0 0 29 30',
    data: '<path pid="0" d="M21.25 25.42a.627.627 0 01-.595.825l-5.634-.001v3.13a.625.625 0 11-1.251 0l-.001-3.13H8.137a.62.62 0 01-.507-.26.623.623 0 01-.087-.564l1.811-5.435H7.51v3.13a.625.625 0 11-1.251 0v-3.13H.626a.62.62 0 01-.507-.26.622.622 0 01-.087-.565L6.292.383c.171-.51 1.017-.51 1.188 0l4.203 12.615 2.12-6.356c.171-.51 1.017-.51 1.187 0l2.118 6.354L21.312.384c.172-.51 1.018-.51 1.188 0l6.26 18.778a.627.627 0 01-.594.823h-5.634v3.13a.625.625 0 11-1.251 0l-.001-3.13h-1.842l1.812 5.435zm-6.854-16.6l-2.118 6.353v.002l-1.46 4.381-.008.019-1.806 5.418 4.765-.001.001-1.877a.626.626 0 011.252 0l-.001 1.877h4.765l-1.808-5.421a.64.64 0 01-.004-.014l-1.46-4.381-.002-.006-2.116-6.35zM6.885 2.561L1.493 18.734l4.766-.001v-1.877a.626.626 0 011.252 0l-.001 1.877h2.262l1.25-3.755L6.885 2.561zm15.022 0l-4.139 12.416 1.252 3.756h2.26v-1.877a.626.626 0 011.253 0l-.001 1.877h4.765l-5.39-16.172z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
