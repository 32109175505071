/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M25.7 19.1c.4 0 .8.4.8.9v5.7c0 .5-.4.9-.9.9H10.5c-.5 0-.9-.4-.9-.9V20c0-.5.4-.9.9-.9zm-18.8 0c.5 0 .9.4.9.9v5.7c0 .5-.4.9-.9.9H1.3c-.5 0-.9-.4-.9-.9V20c0-.5.4-.9.9-.9zm18.5 1.1H10.8v5.2h14.6v-5.2zm-18.8 0h-5v5.2h5v-5.2zM25.7 9.8c.4 0 .8.4.8.9v5.7c0 .5-.4.9-.9.9H10.5c-.5 0-.9-.4-.9-.9v-5.7c0-.5.4-.9.9-.9zm-18.8 0c.5 0 .9.4.9.9v5.7c0 .5-.4.9-.9.9H1.3c-.5 0-.9-.4-.9-.9v-5.7c0-.5.4-.9.9-.9zm18.5 1.1H10.8v5.2h14.6v-5.2zm-18.8 0h-5v5.2h5v-5.2zM25.7.5c.4 0 .8.4.8.9v5.7c0 .5-.4.9-.9.9H10.5c-.5 0-.9-.4-.9-.9V1.4c0-.5.4-.9.9-.9zM6.9.5c.5 0 .9.4.9.9v5.7c0 .5-.4.9-.9.9H1.3c-.5 0-.9-.4-.9-.9V1.4c0-.5.4-.9.9-.9zm18.5 1.1H10.8v5.2h14.6V1.6zm-18.8 0h-5v5.2h5V1.6z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
