/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'powerfull-off': {
    width: 28,
    height: 30,
    viewBox: '0 0 28 30',
    data: '<path pid="0" d="M23.956 4.79l-2.555 2.6c3.12 3.915 2.869 9.633-.755 13.256l-.1.098 2.541 2.587.123-.121c5.048-5.048 5.297-13.078.746-18.42zm-21.13.506c-4.026 5.21-3.75 12.671.827 17.573l2.543-2.59C3.06 16.81 2.793 11.668 5.4 7.916l-2.574-2.62zm12.25 6.414a2.72 2.72 0 00-3.485-1.625c-1.411.513-7.112 4.761-6.88 5.398.231.636 7.329.226 8.74-.288a2.72 2.72 0 001.625-3.485zM4.03 3.935L6.57 6.52l.182-.176c3.741-3.535 9.549-3.623 13.394-.266l2.545-2.589C17.37-1.307 9.173-1.159 4.03 3.935zm6.887 22.9c0 .635-.112 1.191-.337 1.67a2.533 2.533 0 01-.962 1.106c-.418.26-.896.389-1.436.389a2.68 2.68 0 01-1.43-.385 2.558 2.558 0 01-.974-1.1c-.23-.476-.346-1.024-.35-1.644v-.319c0-.634.115-1.192.344-1.675.228-.482.551-.853.969-1.11a2.666 2.666 0 011.432-.388c.537 0 1.014.13 1.431.387.418.258.741.629.97 1.111.228.483.343 1.04.343 1.67v.289zm-1.346-.292c0-.675-.12-1.189-.363-1.54-.242-.35-.587-.526-1.035-.526-.446 0-.79.173-1.031.52-.242.346-.365.855-.368 1.524v.315c0 .658.121 1.168.363 1.53.242.364.59.545 1.045.545.445 0 .787-.175 1.026-.524.24-.35.36-.86.363-1.53v-.314zm6.188.735h-2.55v2.633h-1.327v-6.444h4.195v1.076H13.21v1.664h2.55v1.071zm4.965 0h-2.549v2.633h-1.328v-6.444h4.196v1.076h-2.868v1.664h2.55v1.071z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
