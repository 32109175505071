/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M23.947 26C10.743 26 0 15.257 0 2.053 0 .92.92 0 2.053 0h4.79c1.13 0 2.052.92 2.052 2.053 0 1.6.252 3.173.746 4.67a2.072 2.072 0 01-.508 2.094l-2.17 2.882c1.785 3.363 3.953 5.53 7.338 7.336l2.952-2.23c.479-.487 1.303-.68 2.03-.43 1.49.494 3.06.744 4.664.744 1.132 0 2.053.92 2.053 2.053v4.775C26 25.08 25.08 26 23.947 26zM2.053 1.368a.685.685 0 00-.685.685c0 12.45 10.13 22.579 22.58 22.579a.685.685 0 00.684-.685v-4.775a.685.685 0 00-.685-.685c-1.748 0-3.464-.273-5.101-.814a.686.686 0 00-.697.163l-3.369 2.553a.681.681 0 01-.724.063c-4.015-2.056-6.48-4.522-8.508-8.508a.684.684 0 01.063-.723L8.1 7.923c.25-.26.315-.524.235-.78a16.244 16.244 0 01-.81-5.09.685.685 0 00-.684-.685h-4.79z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
