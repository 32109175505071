/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear-day-dark': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_clear-day-dark_a"><stop stop-color="#8F571E" offset="0%"/><stop stop-color="#8F501D" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_clear-day-dark_b"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="url(#svgicon_clear-day-dark_a)" cx="21.771" cy="21.771" r="21.771"/><circle pid="1" _fill="url(#svgicon_clear-day-dark_b)" cx="21.771" cy="21.771" r="14.896"/></g>'
  }
})
