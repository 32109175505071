/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-6': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M14.375 10h5c.345 0 .625-.28.625-.625v-3.75A.625.625 0 0019.375 5h-5a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zM15 6.25h3.75v2.5H15v-2.5zm6.875 11.25h5c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h3.75v2.5H22.5v-2.5zM21.875 10h5c.345 0 .625-.28.625-.625v-3.75A.625.625 0 0026.875 5h-5a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h3.75v2.5H22.5v-2.5zM29.375 0H.625A.625.625 0 000 .625v2.5c0 .345.28.625.625.625H11.25v8.75H.625a.625.625 0 000 1.25h.625V15H.625a.625.625 0 00-.625.625v13.75c0 .345.28.625.625.625h28.75c.345 0 .625-.28.625-.625V.625A.625.625 0 0029.375 0zM7.5 13.75h1.25V15H7.5v-1.25zm-2.5 0h1.25V15H5v-1.25zm-2.5 0h1.25V15H2.5v-1.25zm8.75 15h-10v-12.5h10v12.5zm0-13.75H10v-1.25h1.25V15zM22.5 28.75h-3.75v-7.5h3.75v7.5zm6.25 0h-5v-8.125a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v8.125h-5v-25h16.25v25zm0-26.25H1.25V1.25h27.5V2.5zm-14.375 15h5c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zM15 13.75h3.75v2.5H15v-2.5zM3.125 22.5h6.25c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625h-6.25a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625zm.625-3.75h5v2.5h-5v-2.5z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
