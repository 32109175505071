/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'speedbar': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M22.351 25.8h-1.5c-1.5 0-2.8-1.2-2.8-2.8V3.5c0-1.6 1.1-2.8 2.8-2.8h1.5c1.5 0 2.8 1.2 2.8 2.8V23c0 1.5-1.3 2.8-2.8 2.8zm-1.5-23.5c-.8 0-1.2.5-1.2 1.2V23c0 .7.6 1.2 1.2 1.2h1.5c.7 0 1.2-.6 1.2-1.2V3.5c0-.7-.6-1.2-1.2-1.2h-1.5zM13.351 25.8h-1.5c-1.5 0-2.8-1.2-2.8-2.8V7.5c0-1.6 1.1-2.7 2.8-2.7h1.5c1.5 0 2.8 1.2 2.8 2.7V23c0 1.5-1.3 2.8-2.8 2.8zm-1.5-19.5c-.8 0-1.2.5-1.2 1.2V23c0 .7.6 1.2 1.2 1.2h1.5c.7 0 1.2-.6 1.2-1.2V7.5c0-.7-.6-1.2-1.2-1.2h-1.5zM4.404 25.783h-1.5c-1.5 0-2.8-1-2.8-2.3v-13c0-1.3 1.1-2.3 2.8-2.3h1.5c1.5 0 2.8 1 2.8 2.3v12.9c-.1 1.3-1.3 2.4-2.8 2.4zm-1.5-16.3c-.8 0-1.2.4-1.2 1v12.9c0 .6.6 1 1.2 1h1.5c.7 0 1.2-.5 1.2-1v-12.9c0-.6-.6-1-1.2-1h-1.5z"/></g>'
  }
})
