/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'etape-combined': {
    width: 30,
    height: 25,
    viewBox: '0 0 30 25',
    data: '<path pid="0" d="M14.716 7.78a.77.77 0 110 1.54H6.84a1.433 1.433 0 00-.585.186 1.267 1.267 0 00-.576 1.229c-.049.42.149.83.507 1.053.195.109.411.175.634.195h17.472a2.32 2.32 0 012.223 2.29A2.467 2.467 0 0124.3 16.78H12.825v-.05H2.705a1.618 1.618 0 00-.576.176c-.413.269-.636.75-.575 1.238-.045.42.156.826.517 1.044.19.114.403.184.624.204h15.55a2.32 2.32 0 012.224 2.282 2.476 2.476 0 01-2.213 2.515H8.662a.77.77 0 110-1.54h9.447c.145-.031.284-.087.41-.166a.868.868 0 00.4-.81c0-.399-.273-.672-.78-.73H2.656a2.71 2.71 0 01-2.652-2.799 2.779 2.779 0 012.7-2.924h21.45a.907.907 0 00.81-.975c0-.41-.264-.673-.78-.741H6.77a2.71 2.71 0 01-2.642-2.799 2.76 2.76 0 012.7-2.925zm11.74-5.177a3.364 3.364 0 110 6.718h-7.898a.78.78 0 01-.77-.78.77.77 0 01.77-.77h7.897a1.813 1.813 0 10-1.803-1.814.78.78 0 11-1.55 0 3.364 3.364 0 013.353-3.354zM19.055 0a3.354 3.354 0 110 6.708h-7.898a.77.77 0 010-1.54h7.897a1.813 1.813 0 10-1.813-1.814.77.77 0 01-.77.77.77.77 0 01-.78-.77A3.364 3.364 0 0119.054.029z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
