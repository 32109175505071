/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-5': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M5.223 5.804c1.601 0 2.902-1.303 2.902-2.902C8.125 1.302 6.824 0 5.223 0a2.905 2.905 0 00-2.901 2.902c0 1.6 1.3 2.902 2.901 2.902zm0-4.643c.96 0 1.741.78 1.741 1.74s-.78 1.742-1.74 1.742-1.742-.781-1.742-1.741c0-.96.781-1.741 1.741-1.741zm4.442 8.623c-.182-1.608-1.455-2.82-2.96-2.82H3.743c-1.504 0-2.777 1.212-2.96 2.82l-.769 6.741c-.064.564.11 1.125.477 1.536.316.354.736.56 1.192.586l.64 8.673a.581.581 0 00.58.537h4.643a.583.583 0 00.58-.537l.64-8.673a1.713 1.713 0 001.193-.586c.367-.412.54-.972.477-1.536l-.77-6.741zm-.574 7.504a.576.576 0 01-.425.2h-.441a.581.581 0 00-.58.538l-.64 8.67H3.442l-.642-8.67a.581.581 0 00-.58-.538h-.44a.58.58 0 01-.425-.2.81.81 0 01-.19-.632l.77-6.741c.116-1.02.893-1.79 1.806-1.79h2.963c.914 0 1.69.77 1.807 1.79l.77 6.741a.806.806 0 01-.19.632zM25.35 0H13.348c-.96 0-1.74.781-1.74 1.741v20.893c0 .96.78 1.741 1.74 1.741h2.902v.976a2.51 2.51 0 003.494 2.304l6.594-2.826a2.505 2.505 0 001.52-2.304V2.506A2.51 2.51 0 0025.35 0zm-9.1 5.332v17.882h-2.903c-.319 0-.58-.26-.58-.58V1.74c0-.32.261-.58.58-.58h8.779L17.77 3.028a2.505 2.505 0 00-1.52 2.304zm10.446 17.193c0 .538-.32 1.025-.816 1.236l-6.594 2.826a1.347 1.347 0 01-1.876-1.236V5.332c0-.538.32-1.025.816-1.236L24.82 1.27a1.347 1.347 0 011.876 1.236v20.019zm-7.545-9.757a.58.58 0 00-.58.58v2.322a.58.58 0 001.16 0v-2.322a.58.58 0 00-.58-.58z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
