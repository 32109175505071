<template>
  <b-container fluid class="m-0 p-0">
    <GoBack />
    <transition name="fade-in-transition" appear>
      <b-jumbotron class="pb-0 pt-2 box">
        <b-row>
          <b-col>
            <b-card
              border-variant="gray5"
              bg-variant="gray5"
              text-variant="white"
              align="center"
              :title="$t('firmware')"
              title-tag="h3"
            >
              <b-card-header v-if="firmware" header-bg-variant="brand-a20">
                <b-card-title align="center">
                  <transition name="zoom-in-center-transition" appear>
                    <b-avatar size="6rem">
                      <az-icon name="gear" scale="2"></az-icon>
                    </b-avatar>
                  </transition>

                  <b-row class="h4 mt-1">
                    <b-col></b-col>
                    <b-col>
                      <Badge
                        :pillable="'badge-pill'"
                        :text="firmware.version"
                      >
                      </Badge>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row v-if="2">
                    <b-col></b-col>
                    <b-col style="font-size: initial">{{ firmware.date | formatDate }}</b-col>
                    <b-col></b-col>
                  </b-row>
                </b-card-title>

                <b-card-text>
                  <b-spinner v-show="isLoading"></b-spinner>
                  <b-container
                    class=""
                    v-show="!isLoading"
                  >
                    <b-row>
                      <b-col>
                        <b-form class="needs-validation" novalidate>
                          <b-form-group
                            class="mb-0"
                          >

                            <b-row class="mb-4" v-if="isExtraRole()">
                              <b-col sm="2"></b-col>
                              <b-col sm="10" class="text-left">
                                <b-form-checkbox switch v-model="firmware.beta" size="lg">
                                  {{ $t('publishBeta') }}
                                </b-form-checkbox>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col class="text-center">
                                <b-form-group
                                  v-if="oldFiles[0] || oldFiles[1] || isExtraRole()"
                                  :label="`${$t('files')}:`"
                                  label-for="input-loadedFiles"
                                  label-cols-sm="2"
                                  content-cols-sm="10">

                                  <div class="text-left">
                                    <b-form-checkbox switch v-model="editFiles" size="lg" @input="switchEditFiles" v-if="isExtraRole()">
                                      {{ $t('buttons.edit') }}
                                    </b-form-checkbox>

                                    <template lead>
                                      <b-collapse id="edit-files" class="my-3">
                                        <div class="file-drop-area mb-2" :class="{'is-active': isActive}">
                                          <b-form-file
                                            class="mb-2"
                                            id="input-newFiles"
                                            v-model="files"
                                            :disabled="disableInputFile"
                                            @input="inputChanged"
                                            ref="file-input"
                                            multiple
                                            @dragenter="isActive = true"
                                            @click="isActive = true"
                                            @focus="isActive = true"
                                            @dragend="isActive = false"
                                            @drop="isActive = false"
                                            @blur="isActive = false">
                                          </b-form-file>
                                          <span class="fake-btn">{{ $t('buttons.chooseFile') }}</span>
                                          <span class="file-msg">{{$t('buttons.dragDropFiles')}}</span>
                                        </div>
                                        
                                        <b-button id="remove-newFiles" class="btn btn-danger" v-if="loadedFiles.length > 0" style="margin-right: 1rem" @click="remove">{{ $t('buttons.remove') }}</b-button>

                                      </b-collapse>
                                    </template>

                                    <b-row class="my-3" v-if="loadedFiles.length > 0">
                                      <b-col>
                                        <span v-if="!editFiles">
                                          <DetailsButton
                                            v-if="oldFiles[0]"
                                            @click="downloadFile(oldFiles[0])"
                                            :text="oldFiles[0].file_name"
                                            icon='download'
                                            :arrowRight="false"
                                            scale='1.1'
                                            :class="'mr-2'"
                                          >
                                          </DetailsButton>
                                          <DetailsButton
                                            v-if="oldFiles[1]"
                                            @click="downloadFile(oldFiles[1])"
                                            :text="oldFiles[1].file_name"
                                            icon='download'
                                            :arrowRight="false"
                                            scale='1.1'
                                          >
                                          </DetailsButton>
                                        </span>
                                        <span v-if="editFiles">
                                          <b v-if="loadedFiles.length >= 1">
                                            {{ loadedFiles[0].name }}
                                          </b>
                                          <b v-if="loadedFiles.length === 2">
                                            {{ `, ${loadedFiles[1].name}` }}
                                          </b>
                                        </span>
                                      </b-col>
                                    </b-row>
                                  </div>

                                  <b-row>
                                    <b-col>
                                      <div class="error my-1 pb-2" v-if="errors.files">
                                        {{ $t( errorFileMessage, filesName) }}
                                      </div>
                                    </b-col>
                                  </b-row>

                                </b-form-group>
                              </b-col>
                            </b-row>                          
                            
                            <b-row class="mb-4">
                              <b-col sm="2">
                                <label for="textarea-default">{{ $t('changesText') }}:</label>
                              </b-col>
                              <b-col class="text-gray1" v-if="firmware">
                                <b-tabs v-model="tabIndexAdmin" style="background-color: #ccc" fill lazy>
                                  <b-tab
                                    @click="clickTab(1, true)"
                                    id="admin_text_es"
                                    :class="{ active: checkTab(1, true) }"
                                    :title="$t('spanish')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.es" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(2, true)"
                                    id="admin_text_en"
                                    :class="{ active: checkTab(2, true) }"
                                    :title="$t('english')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.en" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(3, true)"
                                    id="admin_text_fr"
                                    :class="{ active: checkTab(3, true) }"
                                    :title="$t('french')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.fr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(4, true)"
                                    id="admin_text_it"
                                    :class="{ active: checkTab(4, true) }"
                                    :title="$t('italian')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.it" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(5, true)"
                                    id="admin_text_pt"
                                    :class="{ active: checkTab(5, true) }"
                                    :title="$t('portuguese')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.pt" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(6, true)"
                                    id="admin_text_de"
                                    :class="{ active: checkTab(6, true) }"
                                    :title="$t('german')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.de" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(7, true)"
                                    id="admin_text_pl"
                                    :class="{ active: checkTab(7, true) }"
                                    :title="$t('polish')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.pl" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(8, true)"
                                    id="admin_text_el"
                                    :class="{ active: checkTab(8, true) }"
                                    :title="$t('greek')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.el" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(9, true)"
                                    id="admin_text_tr"
                                    :class="{ active: checkTab(9, true) }"
                                    :title="$t('turkish')"
                                    >
                                    <vue-editor v-model="firmware.admin_text.tr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                            
                                </b-tabs>
                              </b-col>
                            </b-row>

                            <!--b-row>
                              <b-col sm="2"></b-col>
                              <b-col sm="10">
                                <div class="error my-2 pb-2" v-if="errors.admin_text">
                                  {{ $t("messages.errors.emptyInputError") }}
                                </div>
                              </b-col>
                            </b-row-->

                            <b-row class="mb-4">
                              <b-col sm="2">
                                <label for="textarea-default">{{ $t('versionText') }}:</label>
                              </b-col>
                              <b-col class="text-gray1" v-if="firmware">
                                <b-tabs v-model="tabIndex" style="background-color: #ccc" fill lazy>
                                  <b-tab
                                    @click="clickTab(1)"
                                    id="text_es"
                                    :class="{ active: checkTab(1) }"
                                    :title="$t('spanish')"
                                    >
                                    <vue-editor v-model="firmware.text.es" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(2)"
                                    id="text_en"
                                    :class="{ active: checkTab(2) }"
                                    :title="$t('english')"
                                    >
                                    <vue-editor v-model="firmware.text.en" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(3)"
                                    id="text_fr"
                                    :class="{ active: checkTab(3) }"
                                    :title="$t('french')"
                                    >
                                    <vue-editor v-model="firmware.text.fr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                                  
                                  <b-tab
                                    @click="clickTab(4)"
                                    id="text_it"
                                    :class="{ active: checkTab(4) }"
                                    :title="$t('italian')"
                                    >
                                    <vue-editor v-model="firmware.text.it" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(5)"
                                    id="text_pt"
                                    :class="{ active: checkTab(5) }"
                                    :title="$t('portuguese')"
                                    >
                                    <vue-editor v-model="firmware.text.pt" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(6)"
                                    id="text_de"
                                    :class="{ active: checkTab(6) }"
                                    :title="$t('german')"
                                    >
                                    <vue-editor v-model="firmware.text.de" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(7)"
                                    id="text_pl"
                                    :class="{ active: checkTab(7) }"
                                    :title="$t('polish')"
                                    >
                                    <vue-editor v-model="firmware.text.pl" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(8)"
                                    id="text_el"
                                    :class="{ active: checkTab(8) }"
                                    :title="$t('greek')"
                                    >
                                    <vue-editor v-model="firmware.text.el" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>

                                  <b-tab
                                    @click="clickTab(9)"
                                    id="text_tr"
                                    :class="{ active: checkTab(9) }"
                                    :title="$t('turkish')"
                                    >
                                    <vue-editor v-model="firmware.text.tr" :editor-toolbar="customToolbar" :editor-options="editorSettings"></vue-editor>
                                  </b-tab>
                            
                                </b-tabs>
                              </b-col>
                            </b-row>

                            <!--b-row>
                              <b-col sm="2"></b-col>
                              <b-col sm="10">
                                <div class="error my-2 pb-2" v-if="errors.text">
                                  {{ $t("messages.errors.emptyInputError") }}
                                </div>
                              </b-col>
                            </b-row-->

                            <b-form-group
                              label-for="sumbit-newFirmware"
                              label-align-sm="right"
                            >
                              <b-button
                                id="sumbit-newFirmware"
                                class="btn btn-brand"
                                @click="saveFirmware"
                                >{{ $t("buttons.save") }}</b-button
                              >
                            </b-form-group>
                          </b-form-group>
                        </b-form>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-text>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
      </b-jumbotron>
    </transition>
  </b-container>

</template>

<script>
import WebService from "@/services/web.service";
import S3Service from '@/services/s3.service'
import GoBack from "@/components/GoBack";
import Badge from "@/components/Badge";
import deviceType from "@/mixins/DeviceType.mixin";
import rowClass from "@/mixins/RowClass.mixin";
import wsType from "@/mixins/WSType.mixin";
import showMsgOk from "@/mixins/ShowMsgOk.mixin";
import FirmwareMixin from '@/mixins/Firmware.mixin'
import Role from '@/mixins/Role.mixin'
import CONSTANTS from "@/constant";
import i18n from "@/services/language.service";
import DetailsButton from "@/components/DetailsButton.vue";
import { VueEditor, Quill } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";

Quill.register("modules/htmlEditButton", htmlEditButton)


export default {
  name: "Firmware",
  props: {},
  mixins: [rowClass, deviceType, wsType, showMsgOk, FirmwareMixin, Role],
  components: {
    GoBack,
    Badge,
    VueEditor,
    DetailsButton,
  },

  data() {
    return {
      constants: CONSTANTS,
      firmwareId: this.$route.params.id,
      isLoading: true,
      firmware: null,
      errors: {},
      files: [],
      loadedFiles: [],
      tabIndexAdmin: 0,
      tabIndex: 0,
      tab: 1,
      tabAdmin: 1,
      editorSettings: {
        modules: {
          htmlEditButton: {
            debug: false, // logging, default:false
            msg: "HTML", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: i18n.t('buttons.accept'), // Text to display in the OK button, default: Ok,
            cancelText: i18n.t('buttons.cancel'), // Text to display in the cancel button, default: Cancel
            buttonHTML: "<>",//"&lt;&gt;", // Text to display in the toolbar button, default: <>
            buttonTitle: "HTML", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {} // The default mod
          },
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote", "code-block"],
      ],
      saveEmptyTexts: false,
      withoutAdminTexts: false,
      withoutTexts: false,
      isActive: false,
      editFiles: false,
      oldFiles: [],
    };
  },

  computed: {
    
    errorFileMessage() {
      if(this.firmware.group === CONSTANTS.WSHARD.AIRQSENSOR_WIFI || this.firmware.aidoo_group === CONSTANTS.AIDOO_GROUP.AIDOO_C3_MINI){
        return "messages.errors.extensionSingleError"
      } else {
       return "messages.errors.extensionError" 
      }
    },

    disableInputFile() {
      if(this.firmware.group === CONSTANTS.WSHARD.AIRQSENSOR_WIFI || this.firmware.aidoo_group === CONSTANTS.AIDOO_GROUP.AIDOO_C3_MINI) {
        return this.loadedFiles.length === 1
      } else {
        return this.loadedFiles.length === 2
      }
    },
    
  },

  methods: {
    initializeTexts() {
      if (!this.firmware?.admin_text) this.firmware.admin_text = {}
      if (this.firmware?.admin_text['es'] === undefined) this.firmware.admin_text.es = ''
      if (this.firmware?.admin_text['en'] === undefined) this.firmware.admin_text.en = ''
      if (this.firmware?.admin_text['fr'] === undefined) this.firmware.admin_text.fr = ''
      if (this.firmware?.admin_text['it'] === undefined) this.firmware.admin_text.it = ''
      if (this.firmware?.admin_text['pt'] === undefined) this.firmware.admin_text.pt = ''
      if (this.firmware?.admin_text['de'] === undefined) this.firmware.admin_text.de = ''
      if (this.firmware?.admin_text['pl'] === undefined) this.firmware.admin_text.pl = ''
      if (this.firmware?.admin_text['el'] === undefined) this.firmware.admin_text.el = ''
      if (this.firmware?.admin_text['tr'] === undefined) this.firmware.admin_text.tr = ''
      if (!this.firmware?.text) this.firmware.text = {}
      if (this.firmware?.text['es'] === undefined) this.firmware.text.es = ''
      if (this.firmware?.text['en'] === undefined) this.firmware.text.en = ''
      if (this.firmware?.text['fr'] === undefined) this.firmware.text.fr = ''
      if (this.firmware?.text['it'] === undefined) this.firmware.text.it = ''
      if (this.firmware?.text['pt'] === undefined) this.firmware.text.pt = ''
      if (this.firmware?.text['de'] === undefined) this.firmware.text.de = ''
      if (this.firmware?.text['pl'] === undefined) this.firmware.text.pl = ''
      if (this.firmware?.text['el'] === undefined) this.firmware.text.el = ''
      if (this.firmware?.text['tr'] === undefined) this.firmware.text.tr = ''
    },
    
    setOldFiles() {
      this.loadedFiles = [...this.oldFiles]
    },

    switchEditFiles() {
      this.$root.$emit("bv::toggle::collapse", "edit-files")

      if (this.editFiles) {
        while (this.loadedFiles.length > 0) {
          this.remove()
        }
      } else {
        this.setOldFiles()
      }
    },

    async downloadFile(element) {
      try {
        this.isLoading = true

        let fileType = 'application/zip'
        if (element.file_name.includes('.sh')) fileType = 'text/x-sh'
        
        if (element?.url) await S3Service.download(element.url).then((response) => {  
          let blob = new Blob([response.data], { type: fileType });
          let url = window['URL'].createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = element.file_name;
          a.click();
          window['URL'].revokeObjectURL(url);
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
        })
      } catch(err) {
        console.log(err)
        this.$bvModal.show("bv-modal-error");
      }
    },

    /**
     * Obtiene la información del webserver, configuración de sus dispositivos, las versiones disponibles para actualizar y la instalación a la que pertenece
     */
    async getFirmware() {
      try {
        this.isLoading = true;
        let firmware = {}
        if (this.isTextWriteRole()) {
          firmware = await WebService.getFirmwareText(this.firmwareId);
        } else if (this.isExtraRole()) {
          firmware = await WebService.getFirmware(this.firmwareId);
          // Inicializo copia por valor de los archivos
          // para independizar los antiguos y nuevos ficheros 
          this.oldFiles = [...firmware.files]
          this.setOldFiles()
        }

        this.isLoading = false;
        this.firmware = firmware;
        // Inicializo los nombres de archivo según
        // el tipo de webserver devuelto en la respuesta
        this.filesName = {
          zip: this.constants.FIRMWAREFILES[this.firmware?.ws_type].ota,
          sh: this.constants.FIRMWAREFILES[this.firmware?.ws_type].update,
          bin: this.constants.FIRMWAREFILES[this.firmware?.ws_type].firmware,
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.show("bv-modal-error");
      }
    },

    /**
     * Valido extensión de archivos
     */
     validate() {
      const errors = {};


      /**
       * Validacion de archivo en grupo LAIDO_ESP_C3_MINI
       */
      if(this.firmware.aidoo_group === CONSTANTS.AIDOO_GROUP.AIDOO_C3_MINI){
        if(this.loadedFiles.length  !== 1) {
          errors.files = true
          this.scrollTo('input-newFiles')
          return errors
        } else if(this.loadedFiles.length === 1) {
          if(this.loadedFiles[0].name !== this.filesName.zip){
            errors.files = true
            this.scrollTo('remove-newFiles')
            return errors
          }
        }
        return errors
      }

      if(this.firmware.group === this.constants.WSHARD.AIRQSENSOR_WIFI) {
        if(this.loadedFiles.length < 1) {
          errors.files = true
          this.scrollTo('input-newFiles')
          return errors
        } else if (this.loadedFiles.length === 1) {
          if (this.loadedFiles[0].name !== this.filesName.bin) {
            errors.files = true
            this.scrollTo('remove-newFiles')
            return errors
          }

        }

        return errors;
      }

      if (this.loadedFiles.length < 2) {
        errors.files = true
        this.scrollTo('input-newFiles')
        return errors
      } else if (this.loadedFiles.length === 2){
        this.loadedFiles.forEach(file => {
          if (file.name !== this.filesName.zip && file.name !== this.filesName.sh) {
            errors.files = true
            this.scrollTo('remove-newFiles')
            return errors
          }
        })
      }

      return errors;
    },

    /**
     * Muestra aviso de textos vacíos
     */
    async showEmptyTextsPopup() {
      // Creo promesa para que devuelva valores antes de destruir el modal
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(i18n.t('messages.saveWithoutTexts', {text: this.withoutAdminTexts ? i18n.t('changesText') : i18n.t('versionText') }), {
            title: i18n.t('buttons.save'),
            okVariant: "primary",
            okTitle: i18n.t("buttons.save"),
            cancelTitle: i18n.t("buttons.cancel"),
            headerBgVariant: "primary",
            headerTextVariant: "light",
            headerClass: "p-2 border-bottom-1",
            footerClass: "p-2 border-top-0",
            hideHeaderClose: true,
            centered: true,
          })
          .then((accept) => {
            if (accept) {
              this.saveEmptyTexts = true
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch((error) => {
            console.log(error);
            reject(false)
          });
        })
    },

    /**
     * Comprueba si hay textos vacíos
     */
     async checkEmptyTexts() {
      this.withoutAdminTexts = false
      this.withoutTexts = false

      if (!this.saveEmptyTexts){
        const indexAdmin = Object.keys(this.firmware?.admin_text).findIndex(k => this.firmware.admin_text[k] === '')
        if (indexAdmin !== -1) {
          // errors.admin_text = true
          this.withoutAdminTexts = true
          this.tabIndexAdmin = indexAdmin
          this.clickTab(indexAdmin - 1, true)
          return await this.showEmptyTextsPopup()
        }
      }
      
      if (!this.saveEmptyTexts) {
        const index = Object.keys(this.firmware?.text).findIndex(k => this.firmware.text[k] === '')
        if (index !== -1) {
          // errors.text = true
          this.withoutTexts = true
          this.tabIndex = index
          this.clickTab(index - 1, false)
          return await this.showEmptyTextsPopup()
        }
      }
      return true
    },

    /**
     * Valida y guarda los datos de la version
     */
    async saveFirmware() {
      try {
        //
        // Inicializamos valores
        //
        this.errors = {};

        //
        // Si hay una petición en curso no hago nada
        //
        if (this.isLoading) return;

        //
        // Valido extensiones de archivos del formulario
        //
        if (this.editFiles) this.errors = this.validate();
        if (Object.keys(this.errors).length !== 0) return;

        //
        // Aviso si hay textos vacíos
        //
        const respEmptyTexts = await this.checkEmptyTexts()
        if (!respEmptyTexts) return

        //
        // Preparo datos y realizo la petición
        //
        this.isLoading = true;
        // Asigno al tipo de publicación enduser_visible (escritura), el opuesto del campo beta (lectura)
        const data = {
          enduser_visible: !this.firmware.beta
        }
        
        if (this.editFiles) {
          const files = []
          this.loadedFiles.forEach( file => {
            files.push({ name: file.name, type: file.type })
          })
          data.files = files
        }
        
        data.admin_text = this.firmware.admin_text
        data.text = this.firmware.text
        
        
        if (this.firmware.group === CONSTANTS.WSHARD.AIRQSENSOR_WIFI) {
          data.arch = 'esp32_c3_mini';
          data.group = 'airqsensorWifi';
        }
        
        let response = {}
        let errors = null;
        if (this.isExtraRole()) {
          response = await WebService.editVersion(this.firmwareId, data);
          // Actualizo los datos con la respuesta
          this.firmware = response
          // Subo archivos de firmware al S3
          errors = await this.uploadFirmwareFiles(response, this.loadedFiles)
        } else if (this.isTextWriteRole()) {
          response = await WebService.editVersionText(this.firmwareId, data);
          // Actualizo los datos con la respuesta
          this.firmware = await WebService.getFirmwareText(this.firmwareId)
        }

        if (!errors) {
          // Actualizo archivos con la respuesta si se habían editado
          if (this.editFiles) {
            this.oldFiles = [...this.firmware.files]
            this.setOldFiles()
            this.editFiles = false
          }
          this.showMsgOk(
            this.firmware.enduser_visible ? i18n.t("publishRelease") : i18n.t("publishBeta"),
            i18n.t("messages.firmwareMsg", { version: this.firmware.version })
          );
        }
        this.isLoading = false;
      } catch(error) {
        console.log(error)
        if (error.status === 400) {
          this.modalWarningTitle = i18n.t('messages.errors.versionExistsTitle')
          this.modalWarningMsg = i18n.t('messages.errors.versionExistsMsg', { version: this.newVersion })
          this.showModalWarning = true
        } else {
          this.$bvModal.show("bv-modal-error");
        }
        this.isLoading = false;
      }
    },
    
  },
    
  async mounted() {
    await this.getFirmware();
    // Estilo de icono HTML en las dos barras de herramientas
    this.$nextTick(() => {
      this.initializeTexts()
      setTimeout(() => {
        this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[0])
        this.htmlButtonStyle(document.getElementsByClassName('ql-toolbar')[1])
        this.editorStyle(document.getElementsByClassName('ql-editor')[0])
        this.editorStyle(document.getElementsByClassName('ql-editor')[1])
      }, 0)
    })
  },
};
</script>

<style lang="scss" scoped>
.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 3rem;
  border: 1px dashed var(--gray6);
  border-radius: 5px;
  transition: 0.2s;
  &.is-active {
    background-color: var(--gray8);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: var(--gray7);
  border: 1px solid var(--gray5);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-input {
  height: 100%;
  z-index: 2 !important;
}

.custom-file {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
</style> 