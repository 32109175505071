/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 19,
    height: 14,
    viewBox: '0 0 19 14',
    data: '<path pid="0" d="M16 12a1 1 0 010 2H1a1 1 0 010-2h15zm-6-6a1 1 0 010 2H1a1 1 0 010-2h9zm8-6a1 1 0 010 2H1a1 1 0 010-2h17z" _fill="#202832" fill-rule="evenodd"/>'
  }
})
