/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fog-wind': {
    width: 63,
    height: 45,
    viewBox: '0 0 63 45',
    data: '<path pid="0" d="M43.562 40.833a1.75 1.75 0 010 3.5H29.95a1.75 1.75 0 010-3.5h13.612zm9.212-20.42c8.306 0 11.794 6.523 9.376 12.032-2.816 6.417-12.013 7.29-15.357-.517a1.75 1.75 0 013.217-1.378c2.047 4.779 7.27 4.283 8.935.489.542-1.236.51-3.047-.219-4.408-.906-1.696-2.8-2.718-5.952-2.718H13.42a1.75 1.75 0 110-3.5zM33.839 31.11a1.75 1.75 0 010 3.5h-9.722a1.75 1.75 0 010-3.5h9.722zm-15.556 0a1.75 1.75 0 010 3.5H8.56a1.75 1.75 0 010-3.5h9.723zm23.335 0a1.75 1.75 0 010 3.5h-1.945a1.75 1.75 0 010-3.5h1.945zM.684 5.063c2.867-6.403 12.19-7.27 15.594.52a1.75 1.75 0 01-3.207 1.4c-2.097-4.798-7.488-4.296-9.193-.49-.55 1.23-.518 3.03.222 4.384.927 1.7 2.876 2.73 6.113 2.73h40.155a1.75 1.75 0 110 3.5H10.213c-4.457 0-7.61-1.666-9.186-4.553-1.276-2.34-1.33-5.287-.343-7.491zM55.23 2.914a1.75 1.75 0 010 3.5h-1.945a1.75 1.75 0 010-3.5h1.945zm-7.779 0a1.75 1.75 0 010 3.5H37.73a1.75 1.75 0 010-3.5h9.722zm-15.556 0a1.75 1.75 0 010 3.5h-9.723a1.75 1.75 0 010-3.5h9.723z" _fill="#8999B7" fill-rule="nonzero"/>'
  }
})
