/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eco-a': {
    width: 31,
    height: 31,
    viewBox: '0 0 31 31',
    data: '<path pid="0" d="M30.086.264C18.27.264 10.356 2.45 5.706 6.3 2.328 9.098.96 12.495.96 15.937l.008.44c.087 2.33.862 4.392 2.244 6.028l.168.192.07-.125C1.88 25.312.96 27.89.96 29.39a.875.875 0 001.75 0l.006-.184c.051-.773.409-1.971 1.01-3.374l.32-.718c.113-.244.233-.493.36-.745l.283-.556-.192-.15c1.606 1.301 3.675 2.139 6.056 2.44l.227.029.001-1.59-.173-.199-.437-.07c-1.731-.309-3.239-.957-4.44-1.898l-.165-.135-.087.148c3.392-5.796 8.38-10.903 14.226-13.735a.876.876 0 00.405-1.168.877.877 0 00-1.167-.407l-.567.284c-5.462 2.81-10.354 7.711-13.84 13.27l-.203.33.176.224c-1.176-1.422-1.8-3.217-1.8-5.248 0-2.947 1.191-5.863 4.14-8.305l.285-.23c3.942-3.104 10.427-5.007 19.92-5.338l.986-.028-.446.519c-2.322 2.776-3.975 5.892-4.941 9.869l-.36 1.569c-.478 2.035-.971 3.62-1.605 5l-.131.286h1.805l.186-.124c.102-.242.2-.488.294-.74l.208-.578c.066-.19.13-.379.19-.57l.177-.579c.057-.197.114-.4.17-.61l.169-.658.174-.727.185-.813.1-.445c1.095-4.983 3.195-8.382 6.49-11.678a.874.874 0 00-.619-1.493zm-15.997 30l.893-2.679h4.696l.902 2.679h2.848l-4.866-13H16.08l-4.839 13h2.848zm4.866-4.848h-3.25l1.616-4.866 1.634 4.866z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
