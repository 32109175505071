/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'night': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M11.108.032a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.032zM26.14 22.3a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.137.063 17.717 4.875 23.452 9.687 29.187 18.267 29.937 24 25.125a13.413 13.413 0 002.517-2.797l.055-.088-.432.06z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
