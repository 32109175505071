/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'password-hide': {
    width: 24,
    height: 23,
    viewBox: '0 0 24 23',
    data: '<path pid="0" d="M8.896 18.587L20.475 7.588A11.87 11.87 0 0123 11.5c-1.73 4.39-6 7.5-11 7.5a11.81 11.81 0 01-3.104-.413l2.27-2.156a5.002 5.002 0 005.8-5.51l3.509-3.333zM12 4c1.075 0 2.116.144 3.106.413l-2.27 2.157a5.002 5.002 0 00-5.803 5.51l-3.507 3.333A11.87 11.87 0 011 11.5C2.73 7.11 7 4 12 4zm9.14-2.906l-20 19a1.25 1.25 0 101.72 1.812l20-19a1.25 1.25 0 10-1.72-1.812z" _fill="#1F2933"/>'
  }
})
