/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-7': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" d="M26.016 12.18H5.337L3.104 7.268c.126-.18.217-.388.217-.623l-.001-.006 4.776-2.785-.318.954a.55.55 0 00.525.73h4.428a.56.56 0 00.452-.23.558.558 0 00.075-.5l-1.082-3.246a1.662 1.662 0 00-3.317 0l-.237.714c-.015.007-.031.007-.046.016l-5.83 3.4a1.071 1.071 0 00-.532-.155c-.611 0-1.107.496-1.107 1.108 0 .57.437 1.022.99 1.083l2.025 4.452H.554a.554.554 0 00-.554.553v13.285a.554.554 0 001.107 0v-8.303H15.5v8.303c0 .305.248.553.553.553h9.964a.554.554 0 00.553-.553V12.733a.554.554 0 00-.553-.553zM9.963 1.663a.554.554 0 011.108 0c0 .06.01.118.028.175l.864 2.592H9.07l.864-2.592a.557.557 0 00.028-.175zm15.5 11.624v3.321h-8.857v-3.321h8.856zM1.106 16.608v-3.321H15.5v3.321H1.107zm24.355 1.107v3.321h-8.856v-3.321h8.856zm-8.856 7.75v-3.322h8.856v3.321h-8.856zM20.48 15.5h1.107a.554.554 0 000-1.107H20.48a.554.554 0 000 1.107zm0 4.428h1.107a.554.554 0 000-1.107H20.48a.554.554 0 000 1.107zm1.107 3.321H20.48a.554.554 0 000 1.107h1.107a.554.554 0 000-1.107z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
