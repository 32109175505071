/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'powerfull-old': {
    width: 30,
    height: 26,
    viewBox: '0 0 30 26',
    data: '<path pid="0" d="M3.57 5.285L6.39 8.154c-3.442 4.318-3.165 10.627.833 14.624l.111.108-2.804 2.854-.136-.133c-5.569-5.57-5.843-14.429-.823-20.322zm23.313.558c4.44 5.748 4.137 13.98-.913 19.387l-2.806-2.857c3.461-3.827 3.754-9.5.879-13.64l2.84-2.89zm-13.516 7.076a3 3 0 013.845-1.793c1.557.566 7.846 5.253 7.591 5.955-.256.702-8.086.25-9.643-.317a3 3 0 01-1.793-3.845zM25.554 4.34L22.75 7.194 22.55 7c-4.128-3.9-10.535-3.997-14.777-.293L4.965 3.85c5.87-5.292 14.915-5.129 20.589.491z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
