/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'group-12': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M24.587 0a.63.63 0 010 1.26h-.631v11.349h4.414a.63.63 0 01.63.63v8.826a.63.63 0 01-.63.63h-9.157l2.176 5.441a.632.632 0 01-.063.588.634.634 0 01-.522.276H8.196a.632.632 0 01-.585-.865l2.175-5.44H.63a.63.63 0 01-.63-.63V13.24a.63.63 0 01.63-.63h4.413V1.26h-.63a.63.63 0 010-1.26h20.174zm-6.733 22.695h-6.71l-2.018 5.044h10.747l-2.019-5.044zM22.695 1.26H6.304v11.98a.63.63 0 01-.63.63H1.26v7.565l8.825-.001.001-5.673a.63.63 0 01.63-.63h7.566a.63.63 0 01.63.63v5.673h8.826V13.87h-4.413a.63.63 0 01-.63-.63l-.001-11.98zm-8.826 15.131h-2.521l-.001 5.043h2.522v-5.043zm3.783 0H15.13v5.043h2.522v-5.043zm-9.456-1.26a.63.63 0 01.63.63v3.782a.63.63 0 01-.63.63H3.152a.63.63 0 01-.63-.63v-3.782a.63.63 0 01.63-.63h5.044zm17.652 0a.63.63 0 01.63.63v3.782a.63.63 0 01-.63.63h-5.044a.63.63 0 01-.63-.63v-3.782a.63.63 0 01.63-.63h5.044zM5.043 16.39h-1.26v2.522h1.26v-2.522zm2.522 0H6.304v2.522h1.261v-2.522zm15.13 0h-1.26v2.522h1.26v-2.522zm2.522 0h-1.261v2.522h1.261v-2.522zM13.24 8.826a.63.63 0 01.63.63v3.783a.63.63 0 01-.63.63H8.196a.63.63 0 01-.63-.63V9.457a.63.63 0 01.63-.63h5.043zm7.565 0a.63.63 0 01.63.63v3.783a.63.63 0 01-.63.63h-5.043a.63.63 0 01-.63-.63V9.457a.63.63 0 01.63-.63h5.043zm-10.718 1.26l-1.26.001v2.522l1.26-.001v-2.522zm7.566 0l-1.26.001v2.522l1.26-.001v-2.522zm-5.043.001l-1.262-.001v2.522h1.262v-2.521zm7.565 0l-1.261-.001v2.522h1.26v-2.521zm-6.935-7.565a.63.63 0 01.63.63v3.783a.63.63 0 01-.63.63H8.196a.63.63 0 01-.63-.63V3.152a.63.63 0 01.63-.63h5.043zm2.522 5.043a.63.63 0 01-.63-.63V3.152a.63.63 0 01.63-.63l2.521-.001h2.522a.63.63 0 01.63.631v3.783a.63.63 0 01-.63.63H15.761zm-5.675-3.783h-1.26v2.522h1.26V3.782zm7.566 0h-1.26v2.522h1.26V3.782zm-5.043 0h-1.262v2.522h1.262V3.783zm7.565 0h-1.261v2.522h1.26V3.783z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
