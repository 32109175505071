import store from "@/store/store";

const setStoreFilters = {

  methods: {

    /**
     * Asigna la página o filtro almacenada en el store porque viene de una navegación anterior
     * @param {String} filter - Filtro de búsqueda
     * @return {String} filter - Filtro de búsqueda actualizado
     */
    setStoreFilters() {
      let storeParams = {}

      // Obtengo página y filtro del store
      const pageAndFilter = store.getters["navModule/getPageAndFilter"];
      // Si hay datos los reasigno para actualizar petición de datos por página y filtro
      if (pageAndFilter && Object.keys(pageAndFilter).length > 0) {
        storeParams = {
          filter: pageAndFilter.filter,
          page: pageAndFilter.page
        }
        // Resetea el store para página y filtro
        store.dispatch("navModule/deletePageAndFilter");
      }

      return storeParams
    },

  },

}

export default setStoreFilters