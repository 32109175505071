<template>
  <transition name="fade-in-transition" appear>
    <b-pagination
      v-model="localCurrentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      first-number
      last-number
      :aria-controls="ariaControls"
      :align="align"
    ></b-pagination>
  </transition>
</template>

<script>
export default {
  name: "Pagination",
  components: {},
  props: {
    value: {
      require: true,
    },
    totalRows: Number,
    perPage: Number,
    ariaControls: String,
    align: String,
  },
  data() {
    return {
      localCurrentPage: this.value,
    };
  },
  watch: {
    localCurrentPage(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localCurrentPage = newValue;
    },
  },
};
</script>