/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'switch': {
    width: 22,
    height: 32,
    viewBox: '0 0 22 32',
    data: '<defs><path pid="0" id="svgicon_switch_a" d="M0 .225h21.589V31H0z"/></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(0 .776)"><mask id="svgicon_switch_b" _fill="#fff"><use xlink:href="#svgicon_switch_a"/></mask><path pid="1" d="M2.182 1.143c-.344 0-.69.115-.918.402-.23.23-.403.574-.403.919v26.412c0 .344.115.689.403.918.287.23.574.403.918.403h17.226c.344 0 .688-.116.919-.403.229-.287.4-.574.4-.918V2.406c0-.344-.113-.69-.4-.918-.231-.23-.575-.402-.92-.402H2.183v.057zM19.408 31H2.182c-.574 0-1.091-.23-1.55-.631-.46-.402-.632-.976-.632-1.55V2.406c0-.574.23-1.091.632-1.55.402-.46.919-.631 1.55-.631h17.226c.574 0 1.09.229 1.549.63.403.403.632.92.632 1.55V28.82c0 .574-.23 1.09-.632 1.55-.401.46-.975.63-1.55.63z" _fill="#000" mask="url(#svgicon_switch_b)"/></g><path pid="2" d="M10.22 20.981v2.297c0 .345.23.574.574.574s.574-.229.574-.574v-2.297c0-.344-.23-.574-.574-.574-.345 0-.574.23-.574.574M10.795 11.22c-.345 0-.574-.229-.574-.574s.229-.574.574-.574c.344 0 .574.23.574.574 0 .345-.23.574-.574.574m0-2.297c-.976 0-1.723.747-1.723 1.723 0 .976.747 1.722 1.723 1.722.976 0 1.723-.746 1.723-1.722s-.747-1.723-1.723-1.723" _fill="#000"/><path pid="3" d="M14.182 26.436H7.407c-.115 0-.23-.115-.23-.229V16.79h7.177v9.417c0 .114-.057.229-.172.229M7.407 6.339h6.717c.115 0 .23.116.23.231v9.416h-7.12V6.57c0-.115.058-.231.173-.231m6.775-.861H7.407a1.09 1.09 0 00-1.09 1.092v19.637c0 .574.458 1.09 1.09 1.09h6.717a1.09 1.09 0 001.091-1.09V6.57c0-.632-.459-1.092-1.033-1.092" _fill="#000"/></g>'
  }
})
