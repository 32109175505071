/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M7.5 0a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM5.595 4.324c-.382-.38-.89-.38-1.27 0-.382.381-.382.89 0 1.27L6.23 7.5 4.324 9.405c-.38.382-.38.89 0 1.27.381.382.89.382 1.27 0L7.5 8.77l1.905 1.906c.382.38.89.38 1.27 0 .382-.381.382-.89 0-1.27L8.77 7.5l1.906-1.905c.38-.382.38-.89 0-1.27-.381-.382-.89-.382-1.27 0L7.5 6.23z" _fill="#1F2933" fill-rule="evenodd"/>'
  }
})
