/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile': {
    width: 16,
    height: 26,
    viewBox: '0 0 16 26',
    data: '<path pid="0" d="M12.458 0a2.712 2.712 0 012.709 2.708v20.584A2.712 2.712 0 0112.458 26h-9.75A2.712 2.712 0 010 23.292V2.708A2.712 2.712 0 012.708 0h9.75zm1.625 21.666h-13v1.626c0 .896.73 1.625 1.625 1.625h9.75c.896 0 1.625-.73 1.625-1.625v-1.626zM4.875 22.75a.542.542 0 010 1.083H2.708a.542.542 0 010-1.083h2.167zm7.583 0a.542.542 0 010 1.083h-2.166a.542.542 0 010-1.083h2.166zm1.625-17.334h-13v15.167h13V5.416zm-1.625-4.333h-9.75c-.896 0-1.625.73-1.625 1.625v1.625h13V2.708c0-.896-.729-1.625-1.625-1.625zM8.125 2.167a.542.542 0 010 1.083H7.042a.542.542 0 010-1.083h1.083zm3.272 0a.542.542 0 010 1.083.547.547 0 01-.547-.542c0-.299.237-.541.536-.541h.01z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
