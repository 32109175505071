/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path pid="0" d="M10.8 10.894c-.572 0-1.098-.376-1.098-.947v-9C9.702.374 10.228 0 10.8 0c.572 0 1.098.375 1.098.946v9.001c0 .571-.526.947-1.098.947zM10.8 24C4.844 24 0 19.257 0 13.425c0-4.202 2.543-8.004 6.483-9.69.556-.234 1.2.014 1.442.555.244.545-.01 1.18-.566 1.416-3.137 1.343-5.163 4.372-5.163 7.719 0 4.645 3.86 8.425 8.604 8.425s8.604-3.78 8.604-8.425c0-3.347-2.026-6.376-5.162-7.719a1.066 1.066 0 01-.566-1.417 1.11 1.11 0 011.444-.553c3.937 1.685 6.48 5.487 6.48 9.689C21.6 19.257 16.756 24 10.8 24z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
