/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error-check': {
    width: 16,
    height: 16,
    viewBox: '0 0 150 150',
    data: '<path pid="0" d="M75 .4c41.4 0 75 33.6 75 75s-33.6 75-75 75-75-33.6-75-75S33.6.4 75 .4zm0 6c-38 0-69 31-69 69s31 69 69 69 69-31 69-69-31-69-69-69zm34.8 34.2c2.8 2.8 2.8 7 0 9.8l-25 25 25 25c2.8 2.8 2.8 7 0 9.8s-7 2.8-9.8 0l-25-25-25 25c-2.8 2.8-7 2.8-9.8 0s-2.8-7 0-9.8l25-25-25-25c-2.8-2.8-2.8-7 0-9.8s7-2.8 9.8 0l25 25 25-25c2.8-2.8 7.2-2.8 9.8 0z" _fill="#1d1d1b"/>'
  }
})
