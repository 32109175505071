/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lap': {
    width: 19,
    height: 25,
    viewBox: '0 0 19 25',
    data: '<path pid="0" d="M9.868 21.386a1 1 0 100 2 1 1 0 000-2zm6.5-18.5h-13a.5.5 0 00-.5.5v17a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-17a.5.5 0 00-.5-.5zm-.5 17h-12v-16h12v16zm.5-19h-13a2.503 2.503 0 00-2.5 2.5v19c0 1.378 1.122 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-19c0-1.378-1.122-2.5-2.5-2.5zm1.5 21.5c0 .827-.673 1.5-1.5 1.5h-13c-.827 0-1.5-.673-1.5-1.5v-19c0-.827.673-1.5 1.5-1.5h13c.827 0 1.5.673 1.5 1.5v19z"/>'
  }
})
