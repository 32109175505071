/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chance-sleet-dark': {
    width: 58,
    height: 55,
    viewBox: '0 0 58 55',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_chance-sleet-dark_a"><stop stop-color="#8F571E" offset="0%"/><stop stop-color="#8F501D" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_chance-sleet-dark_b"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#F6F9FF" cx="15.469" cy="44.115" r="1.719"/><circle pid="1" _fill="#F6F9FF" cx="15.469" cy="53.281" r="1.719"/><circle pid="2" _fill="#F6F9FF" cx="25.781" cy="44.115" r="1.719"/><circle pid="3" _fill="#F6F9FF" cx="25.781" cy="53.281" r="1.719"/><circle pid="4" _fill="#F6F9FF" cx="36.094" cy="44.115" r="1.719"/><circle pid="5" _fill="#F6F9FF" cx="36.094" cy="53.281" r="1.719"/><circle pid="6" _fill="#F6F9FF" cx="31.51" cy="48.698" r="1.719"/><circle pid="7" _fill="#F6F9FF" cx="21.198" cy="48.698" r="1.719"/><g transform="translate(28.646)"><circle pid="8" _fill="url(#svgicon_chance-sleet-dark_a)" cx="14.323" cy="14.323" r="14.323"/><circle pid="9" _fill="url(#svgicon_chance-sleet-dark_b)" cx="14.323" cy="14.323" r="9.8"/></g><path pid="10" d="M25.208 38.958V28.04h6.007c.31-5.413 4.743-9.706 10.165-9.706 5.624 0 10.182 4.617 10.182 10.313 0 5.491-4.238 9.98-9.582 10.295v.017H25.207z" _fill="#7484A6"/><path pid="11" d="M9.429 38.958v-.017C4.17 38.634 0 34.231 0 28.844 0 23.258 4.485 18.73 10.018 18.73c.44 0 .875.029 1.301.085C13.281 12.557 19.08 8.02 25.93 8.02c8.461 0 15.321 6.925 15.321 15.469 0 8.42-6.665 15.27-14.96 15.464l-.361.004h-16.5z" _fill="#A2B0CA"/></g>'
  }
})
