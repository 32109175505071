const getPrev = state => state.prev;
const getPageAndFilter = state => state.pageAndFilter;
const getUser = state => state.user;
const getDevice = state => state.device;
const getInstallation = state => state.installation;
const getFirmware = state => state.firmware;

export default {
  getPrev,
  getPageAndFilter,
  getUser,
  getDevice,
  getInstallation,
  getFirmware,
};
