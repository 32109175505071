/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit2': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" d="M27.6 1.028a3.03 3.03 0 01.901 2.17 3.017 3.017 0 01-.903 2.153L24.36 8.553a.573.573 0 01-.278.277A3398.565 3398.565 0 0013.666 19.17l-.673.672-.422.422-.594.594-.187.188-.36.362a308.672 308.672 0 00-3.385 3.43.58.58 0 01-.32.337c-.159.167-.25.269-.298.326a.588.588 0 01-.294.26L1.3 28.093a.578.578 0 01-.629-.128.58.58 0 01-.128-.629l2.333-5.833a.577.577 0 01.129-.196L19.88 4.435a.588.588 0 01.041-.045l.047-.04 3.325-3.322c1.15-1.15 3.156-1.15 4.306 0zM4 21.96l-.085.085-1.784 4.458 4.493-1.797.056-.066L4 21.96zM20.338 5.63L4.826 21.136l2.642 2.643c.757-.8 2.02-2.087 4.128-4.197l.998-.998c2.413-2.41 5.756-5.728 10.387-10.31L20.337 5.63zm3.78-3.779l-2.955 2.955 2.647 2.649 2.968-2.934c.358-.353.556-.824.557-1.326a1.866 1.866 0 00-.552-1.336c-.726-.725-1.954-.717-2.664-.008z" _fill="#000" fill-rule="nonzero"/>'
  }
})
