/* eslint-disable no-undef */
import axios from 'axios';
import Router from '@/router/index';
import AuthService from '@/services/auth.service';
import StorageService from '@/services/storage.service';
import CONSTANTS from '@/constant';

const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

const apiClient = axios.create({
  baseURL
});

const ApiService = {
  /**
   * Inicializa el Servicio API
   *
   * @param {String} baseURL - Url base del API
   */
  init() {
    // Configuración base
    apiClient.defaults.baseURL = `${baseURL}`;
    apiClient.defaults.headers.post['Content-Type'] = 'application/json';
    apiClient.defaults.headers.post.Accept = 'application/json';
    apiClient.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    apiClient.defaults.timeout = 45000;
  },

  setBaseURL() {
    apiClient.defaults.baseURL = `${baseURL}`;
    this.removeHeader();
  },

  request(url, data, method = 'get', baseUrl = baseURL) {
    const request = {
      method,
      url,
      baseURL: baseUrl
    }

    if(data) request.data = data

    return apiClient(request);

  },

  setHeader(token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  removeHeader() {
    apiClient.defaults.headers.common = {};
  },

  getDefaults() {
    return apiClient.defaults
  },

  /**
   * Limpia los datos del usuario almacenados en el navegador
   */
  cleanData() {
    // Reset data
    this.removeHeader();
    StorageService.removeItem('access_token');
    StorageService.removeItem('refresh_token');
  },

  get(resource, data) {
    // console.log('get:', resource);
    apiClient.interceptors.response.use((response) => {
      return response;
    }, async function (error) {
        // Do something with response error
        if (!error.response) {
          console.log('backendDown');
        } else if (error.response.status === 422) {
          console.log('Error: ', error.response.data.msg);
          return error.response
        } else if (error.response.status === 401) {
          console.log('Refresh Token');
          const token = await AuthService.refreshToken();
          console.log("token: ", token);
          if(token === false) return false;
          error.config.headers.Authorization = `Bearer ${token}`;
          return axios(error.config)
        } else if (error.response.status === 403) {
          console.log('sessionClosed');
          Router.push({ name: 'login' })
        } else if (error.response.status === 404) {
          console.log('endPointNotFound');
        } else if (error.response.status === 501) {
          console.log('Error: ', error.response.data.msg);
          return Promise.reject(error.response.data)
        } else {
          console.log('Error', error);
        }
        return Promise.reject(error);
    });
    // console.log('headers:', apiClient.defaults.headers);
    if (data) return apiClient.get(resource, data);
    return apiClient.get(resource);
  },

  post(resource, data) {
    // console.log('post:', resource);
    return apiClient.post(resource, data);
  },

  put(resource, data) {
    return apiClient.put(resource, data);
  },

  patch(resource, data) {
    return apiClient.patch(resource, data);
  },

  delete(resource, data) {
    if (data) return apiClient.delete(resource, { data });
    return apiClient.delete(resource, data);
  },
};

export default ApiService;
