/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal': {
    width: 25,
    height: 18,
    viewBox: '0 0 25 18',
    data: '<path pid="0" d="M12.149 17.285a1.783 1.783 0 110-3.567 1.783 1.783 0 010 3.567zm5.735-6.173a1.147 1.147 0 01-1.535 1.705 6.25 6.25 0 00-4.2-1.612 6.25 6.25 0 00-4.192 1.604 1.147 1.147 0 11-1.532-1.707 8.544 8.544 0 015.724-2.19c2.127 0 4.158.78 5.735 2.2zm2.963-1.36a1.147 1.147 0 01-1.621.064 10.69 10.69 0 00-7.29-2.855c-2.62 0-5.119.943-7.083 2.669A1.147 1.147 0 013.34 7.907a12.98 12.98 0 018.598-3.24c3.294 0 6.434 1.232 8.847 3.465.465.43.493 1.156.063 1.62zm3.12-5.097c.447.425.443 1.11-.007 1.531a1.195 1.195 0 01-1.622-.007c-2.702-2.576-6.344-4.013-10.189-4.013-3.844 0-7.486 1.436-10.188 4.011a1.195 1.195 0 01-1.621.007 1.041 1.041 0 01-.008-1.531C3.465 1.667 7.692 0 12.15 0s8.686 1.668 11.819 4.655z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
