/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-13': {
    width: 26,
    height: 19,
    viewBox: '0 0 26 19',
    data: '<path pid="0" d="M2.679 15H1.607A1.61 1.61 0 010 13.393V4.82a1.61 1.61 0 011.607-1.607H2.68a1.61 1.61 0 011.607 1.607v8.572A1.61 1.61 0 012.679 15zM1.607 4.286a.536.536 0 00-.536.535v8.572c0 .296.24.536.536.536H2.68c.295 0 .535-.24.535-.536V4.82a.536.536 0 00-.535-.535H1.607zm4.286 13.928H4.82a1.61 1.61 0 01-1.607-1.607v-15A1.61 1.61 0 014.821 0h1.072A1.61 1.61 0 017.5 1.607v15a1.61 1.61 0 01-1.607 1.607zM4.82 1.071a.536.536 0 00-.535.536v15c0 .296.24.536.535.536h1.072c.296 0 .536-.24.536-.536v-15a.536.536 0 00-.536-.536H4.82zM24.107 15h-1.071a1.61 1.61 0 01-1.607-1.607V4.82a1.61 1.61 0 011.607-1.607h1.071a1.61 1.61 0 011.607 1.607v8.572A1.61 1.61 0 0124.107 15zM23.036 4.286a.536.536 0 00-.536.535v8.572c0 .296.24.536.536.536h1.071c.296 0 .536-.24.536-.536V4.82a.536.536 0 00-.536-.535h-1.071zm-2.143 13.928H19.82a1.61 1.61 0 01-1.607-1.607v-15A1.61 1.61 0 0119.821 0h1.072A1.61 1.61 0 0122.5 1.607v15a1.61 1.61 0 01-1.607 1.607zM19.82 1.071a.536.536 0 00-.535.536v15c0 .296.24.536.535.536h1.072c.296 0 .536-.24.536-.536v-15a.536.536 0 00-.536-.536H19.82zm-1.071 9.643H6.964a.536.536 0 01-.535-.535V8.036c0-.296.24-.536.535-.536H18.75c.296 0 .536.24.536.536v2.143c0 .295-.24.535-.536.535zM7.5 9.643h10.714V8.57H7.5v1.072z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
