/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.107 20.357C3.66 20.357 0 17.127 0 12.321 0 8.616 2.245 0 23.036 0a.536.536 0 01.379.915c-1.88 1.88-4.171 4.645-5.213 9.38-.774 3.516-2.215 10.062-9.095 10.062zM21.771 1.082c-18.651.328-20.7 7.93-20.7 11.24 0 4.165 3.23 6.964 8.036 6.964 5.554 0 7.066-4.76 8.049-9.222.94-4.28 2.844-7.033 4.615-8.982zM.536 23.572A.536.536 0 010 23.035C0 19.85 5.523 9.63 14.23 5.41a.537.537 0 01.467.964C5.906 10.636 1.072 20.532 1.072 23.035c0 .296-.24.536-.535.536z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
