import Vue from 'vue';

const ADD_USER = (state, userData) => {
  state.user = userData;
};

const DELETE_USER = (state) => {
  Vue.delete(state.user );
};

export default {
  ADD_USER,
  DELETE_USER,
};
