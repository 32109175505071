/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sleep-60': {
    width: 36,
    height: 31,
    viewBox: '0 0 36 31',
    data: '<path pid="0" d="M11.108.732a.852.852 0 011 1.192c-2.326 4.81-1.615 10.557 1.81 14.64 3.426 4.082 8.962 5.78 14.104 4.325a.854.854 0 01.999 1.191 15.145 15.145 0 01-3.924 5.051c-6.454 5.417-16.112 4.572-21.528-1.883-5.417-6.455-4.572-16.113 1.883-21.529A15.145 15.145 0 0111.108.732zM26.14 23a15.31 15.31 0 01-13.24-5.006l-.289-.334a15.304 15.304 0 01-2.92-14.215l.142-.453-.094.04a13.413 13.413 0 00-3.192 1.993C.813 9.837.063 18.417 4.875 24.152 9.687 29.887 18.267 30.637 24 25.825a13.413 13.413 0 002.517-2.797l.055-.088-.432.06zm-5.1-9.291c.84 0 1.593-.19 2.26-.569a3.916 3.916 0 001.544-1.589c.364-.68.546-1.441.546-2.282 0-1.314-.331-2.374-.994-3.179-.663-.805-1.557-1.207-2.682-1.207-1.047 0-1.915.349-2.601 1.047.142-1.041.566-1.855 1.274-2.441.707-.586 1.641-.888 2.801-.906h.249V.47h-.426c-1.309.005-2.458.303-3.45.892-.991.589-1.752 1.42-2.282 2.495-.53 1.074-.795 2.313-.795 3.716v.923c0 1.581.42 2.845 1.257 3.792.837.947 1.937 1.42 3.298 1.42zm-.054-2.07c-.61 0-1.083-.24-1.42-.723-.338-.482-.507-1.153-.507-2.011v-.781c.16-.356.409-.641.746-.857a2.047 2.047 0 011.128-.324c.58 0 1.042.216 1.385.648.343.432.515 1 .515 1.705 0 .692-.17 1.256-.51 1.691-.34.435-.786.653-1.337.653zm10.265 2.07c1.426 0 2.51-.474 3.25-1.421.74-.947 1.11-2.315 1.11-4.102v-2.38c-.018-1.734-.4-3.065-1.146-3.991-.746-.927-1.823-1.39-3.232-1.39-1.409 0-2.488.468-3.237 1.403-.748.935-1.123 2.306-1.123 4.111v2.38c.018 1.734.4 3.066 1.146 3.996.746.929 1.823 1.394 3.232 1.394zm0-2.07c-.634 0-1.094-.255-1.381-.767-.287-.512-.43-1.295-.43-2.349V5.38c.017-.989.17-1.715.457-2.18.287-.465.732-.697 1.336-.697.622 0 1.079.244 1.372.732.293.489.44 1.269.44 2.34v3.072c-.012 1.013-.158 1.764-.436 2.256-.278.491-.73.737-1.358.737z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
