import CONSTANTS from '@/constant'

const DeviceType = {

  methods: {
    /**
      * Devuelve el texto, color, si es airzone o aidoo y el color de fondo para el componente de bootstrap 'bagde', según el tipo de webserver
      * 
      * @param {String} type - Tipo de webserver
      * @return {Object} - Objecto con el estilo del color de fondo, del color del texto, el texto del bagde y booleano si es Airzone o es Aidoo
      */
    wsType(type) {
      let wsType = {};

      switch (type){
        case 'ws_aidoo': 
          wsType.text = CONSTANTS.TAG.WS_AIDOO
          wsType.bgColor = 'light'
          wsType.textColor = 'text-dark'
          wsType.isAidoo = true;
          break
        case 'ws_az': 
          wsType.text = CONSTANTS.TAG.WS_AZ
          wsType.bgColor = 'brand-dark'
          wsType.textColor = 'text-light'
          wsType.isAirzone = true;
          break
        case 'az_8cb': 
          wsType.text = CONSTANTS.TAG.WS_8CB
          wsType.bgColor = 'success-dark'
          wsType.textColor = 'text-light'
          wsType.isCentral = true;
          break
      }
      return wsType
    },

    /**
      * Devuelve el texto, color, si es airzone o aidoo y el color de fondo para el componente de bootstrap 'bagde', según el tipo de webserver
      * 
      * @param {String} type - Tipo de webserver
      * @return {Object} - Objecto con el estilo del color de fondo, del color del texto, el texto del bagde y booleano si es Airzone o es Aidoo
      */
    aidooType(devices) {
      let type = CONSTANTS.TAG.WS_AIDOO
      if (devices.length > 0){
        type = devices[0].device_type === 'aidoo_it' ? CONSTANTS.AIDOOTYPE.AIDOO_PRO : CONSTANTS.AIDOOTYPE.AIDOO
      }
      return type
    }
  }
}

export default DeviceType;