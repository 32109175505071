/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hard-wind-dark': {
    width: 47,
    height: 39,
    viewBox: '0 0 47 39',
    data: '<path pid="0" d="M23.698 21.515c9.45 0 14.229 6.927 10.66 13.06-4.022 6.91-14.239 5.44-16.375-1.74a1.612 1.612 0 013.09-.92c1.345 4.517 7.921 5.463 10.498 1.037 2.238-3.847-.773-8.212-7.873-8.212H6.092a1.613 1.613 0 010-3.225zm-9.096 5.377a2.06 2.06 0 110 4.122 2.06 2.06 0 010-4.122zm23.292-5.375a2.06 2.06 0 11-.001 4.122 2.06 2.06 0 010-4.122zM27.447 1.576c4.288-2.85 9.639-1.82 12.294 2.98 1.53 2.766 1.55 6.463-.297 9.36-2.219 3.484-6.73 5.45-13.36 5.45H1.611a1.613 1.613 0 010-3.226h24.471c5.617 0 9.082-1.509 10.641-3.956 1.187-1.862 1.174-4.298.195-6.068-1.706-3.083-4.91-3.7-7.687-1.854a1.613 1.613 0 01-1.785-2.686zm16.718 11.879a2.06 2.06 0 11-.001 4.122 2.06 2.06 0 010-4.122zm-31.24-7.649C14.615.082 22.37-.983 25.55 4.522c2.842 4.92-.523 10.364-7.852 10.364H3.404a1.613 1.613 0 010-3.225H17.7c4.898 0 6.632-2.804 5.059-5.526-1.744-3.019-5.842-2.456-6.74.585a1.612 1.612 0 01-3.093-.914zm-3.698-.414a2.06 2.06 0 110 4.122 2.06 2.06 0 010-4.122z" _fill="#A2B0CA" fill-rule="nonzero"/>'
  }
})
