/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M11.625.8c5.97 0 10.825 4.855 10.825 10.825 0 1.86-.48 3.652-1.374 5.25l-.198.34 9.56 9.561.14.151c.397.468.622 1.07.622 1.683a2.594 2.594 0 01-4.276 1.969l-.15-.139-9.561-9.56a10.746 10.746 0 01-5.588 1.57C5.655 22.45.8 17.594.8 11.625.8 5.655 5.655.8 11.625.8zm8.285 17.779c-.405.482-.85.927-1.333 1.332l9.364 9.364a.95.95 0 00.666.276.945.945 0 00.667-1.61L19.91 18.58zM11.625 2.45c-5.059 0-9.175 4.116-9.175 9.175 0 5.06 4.115 9.175 9.175 9.175 5.06 0 9.175-4.115 9.175-9.175 0-5.06-4.116-9.175-9.175-9.175z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
