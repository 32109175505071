/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'powerfull': {
    width: 23,
    height: 26,
    viewBox: '0 0 23 26',
    data: '<path pid="0" d="M20.758 10.122a.72.72 0 00-.548.66 5.767 5.767 0 01-2.506 4.454C18.959 8.961 15.707 2.631 9.677.058a.72.72 0 00-1.004.664c0 2.81-2.007 5.15-4.132 7.629-2.114 2.465-4.3 5.013-4.517 8.195a10.201 10.201 0 004.713 9.316.72.72 0 001.072-.83 7.762 7.762 0 01.285-5.545 7.09 7.09 0 001.385 3.686 7.116 7.116 0 006.319 2.797 10.26 10.26 0 008.108-5.64 10.275 10.275 0 00-.357-9.876.72.72 0 00-.791-.332zm-.141 9.563a8.819 8.819 0 01-6.964 4.85 5.658 5.658 0 01-5.02-2.227 5.668 5.668 0 01-.73-5.448.722.722 0 00-1.179-.778 9.184 9.184 0 00-2.7 7.376 8.761 8.761 0 01-2.562-6.814c.185-2.703 2.122-4.962 4.173-7.353 1.955-2.28 3.97-4.63 4.398-7.468 5.227 2.752 7.744 8.826 5.885 14.549a.722.722 0 00.926.903 7.203 7.203 0 004.318-4.167 8.832 8.832 0 01-.545 6.577z" _fill="#000" fill-rule="nonzero"/>'
  }
})
