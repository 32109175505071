/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error-modal': {
    width: 31,
    height: 30,
    viewBox: '0 0 31 30',
    data: '<path pid="0" d="M15.216 0c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15zm0 1.2c-7.608 0-13.8 6.192-13.8 13.8 0 7.608 6.192 13.8 13.8 13.8 7.608 0 13.8-6.192 13.8-13.8 0-7.608-6.192-13.8-13.8-13.8zm-5.019 6.824l5.02 5.019 5.018-5.02a1.384 1.384 0 111.957 1.958L17.173 15l5.02 5.019a1.384 1.384 0 11-1.958 1.957l-5.019-5.019-5.019 5.02a1.384 1.384 0 11-1.957-1.958L13.259 15l-5.02-5.019a1.384 1.384 0 011.958-1.957z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
