const RowClass = {

  methods: {

    /**
     * Devuelve el color de la tabla si hay filas o algún elemento
     * 
     * @param {String} item - Elemento a mostrar en tabla
     * @param {String} type - Tipo de tabla
     * @return {String} - Estilo de la tabla
     */
    rowClass(item, type) {
      if (!item || type !== 'row') return
      else return 'table-secondary'
    },
    
    /**
     * Añade animación en las filas de la tabla
     */
    animateTableRows() {
      const rowsArray = document.getElementsByClassName('table-secondary')
      setTimeout(() => {
        let time = 0
        for (let i = 0; i < rowsArray.length; i++) {
          const seconds = time + 0.1
          rowsArray[i].style.transition = `opacity ${seconds}s`
          rowsArray[i].style.opacity = 1
          rowsArray[i].style.animation = 'bottom-to-top'
          time += .3
        }
      }, 100)
    }
  },


}

export default RowClass;