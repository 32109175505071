/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning-modal': {
    width: 16,
    height: 16,
    viewBox: '0 0 150 150',
    data: '<path pid="0" d="M75 2.3c9.1 0 17.2 4.2 21.6 11.4l50.9 101.9c4.1 6.7 3.9 14.8-.6 21.4-4.5 6.7-12.4 10.6-21.1 10.6H24.2c-8.8 0-16.7-4-21.2-10.6-4.5-6.7-4.7-14.7-.6-21.5L53.2 13.8C57.8 6.6 65.8 2.3 75 2.3zm0 5.6c-7.1 0-13.1 3.1-16.6 8.4l-.2.3L7.4 118.2l-.1.2c-3 4.9-2.8 10.6.4 15.5 3.3 4.9 9.2 8 15.9 8.2h102.3c6.9 0 13.1-3.1 16.5-8.2 3.2-4.7 3.4-10.2.8-14.9l-.3-.5-.1-.2-51-101.8-.2-.3c-3.3-5-9.2-8.1-15.9-8.3H75zM75 110c6.1 0 11.1 4.4 11.1 9.9s-5 9.9-11.1 9.9-11.1-4.4-11.1-9.9 5-9.9 11.1-9.9zm0 5.6c-3.1 0-5.5 2.1-5.5 4.3 0 2.2 2.3 4.3 5.5 4.3 3.1 0 5.5-2.1 5.5-4.3 0-2.2-2.4-4.3-5.5-4.3zm10-73.5c2.6 2.5 3.8 5.9 3.5 9.3l-5.8 50.9c-.2 2-2.1 3.5-4.3 3.5h-6.8c-2.2 0-4-1.5-4.3-3.5l-5.8-50.9c-.4-3.4.9-6.8 3.5-9.3 5.1-5 14.8-5 20 0zm-3.9 4c-2.9-2.9-9.2-2.9-12.1 0-1.3 1.2-1.9 2.8-1.8 4.3v.4l5.6 49.4h4.5l5.6-49.4c.2-1.5-.4-3.1-1.5-4.4l-.3-.3z" _fill="#1d1d1b"/>'
  }
})
