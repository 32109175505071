/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time-small-90': {
    width: 36,
    height: 30,
    viewBox: '0 0 36 30',
    data: '<path pid="0" d="M12.961 3.897c7.158 0 12.961 5.802 12.961 12.96 0 .448-.023.89-.067 1.327h-2.353c.054-.435.082-.877.082-1.326 0-5.867-4.756-10.624-10.623-10.624-5.867 0-10.623 4.757-10.623 10.624 0 5.867 4.756 10.623 10.623 10.623 2.458 0 4.722-.835 6.522-2.238v2.818a12.901 12.901 0 01-6.522 1.758C5.803 29.819 0 24.016 0 16.858 0 9.699 5.803 3.897 12.961 3.897zm-.297 3.896c.645 0 1.169.523 1.169 1.169v7.309l4.374 4.374a1.169 1.169 0 01-1.653 1.653l-4.394-4.42a1.169 1.169 0 01-.665-1.055V8.962c0-.646.523-1.169 1.169-1.169zM10.452.646c.289.578.055 1.28-.523 1.569L3.64 5.359a1.169 1.169 0 01-1.045-2.09L8.884.123a1.169 1.169 0 011.568.522zm21.839 19.866c1.006 0 1.775.331 2.308.993.533.662.806 1.612.818 2.85v1.7l-.004.29c-.038 1.136-.3 2.016-.788 2.64-.529.677-1.303 1.015-2.322 1.015-1.006 0-1.775-.332-2.308-.996-.533-.663-.806-1.615-.818-2.854v-1.7l.005-.291c.038-1.146.303-2.028.797-2.645.535-.668 1.305-1.002 2.312-1.002zm-7.522 0c.609 0 1.15.153 1.624.457.473.304.841.742 1.103 1.313.262.57.396 1.224.4 1.96v.678l-.005.303c-.048 1.396-.429 2.504-1.143 3.325-.765.88-1.85 1.348-3.254 1.408l-.45.006v-1.528l.406-.007.204-.013c1.464-.125 2.258-.869 2.384-2.232-.478.47-1.036.704-1.675.704-.816 0-1.469-.28-1.96-.84-.49-.56-.735-1.316-.735-2.267 0-.605.132-1.16.396-1.665a2.908 2.908 0 011.107-1.18 3.07 3.07 0 011.598-.422zm7.522 1.484c-.432 0-.75.166-.955.498-.205.332-.314.851-.326 1.557v2.245l.003.244c.02.63.122 1.109.304 1.434.205.365.534.548.986.548.449 0 .772-.175.97-.526.2-.351.303-.888.311-1.61V24.19l-.003-.248c-.021-.639-.124-1.113-.31-1.423-.21-.35-.536-.524-.98-.524zm-7.535-.006c-.367 0-.67.168-.906.504-.237.336-.356.758-.356 1.266 0 .503.116.916.346 1.24.23.323.555.485.974.485.296 0 .55-.077.764-.229.213-.152.375-.336.485-.552v-.754l-.006-.227c-.026-.513-.142-.92-.35-1.22-.236-.342-.553-.513-.95-.513zM16.218.124l6.29 3.144a1.169 1.169 0 11-1.046 2.091l-6.29-3.144A1.169 1.169 0 1116.219.124z" _fill="#202832" fill-rule="nonzero"/>'
  }
})
