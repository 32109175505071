/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signal2': {
    width: 13,
    height: 10,
    viewBox: '0 0 13 10',
    data: '<path pid="0" d="M6.105 9.285a1.783 1.783 0 110-3.567 1.783 1.783 0 010 3.567zm5.735-6.173a1.147 1.147 0 01-1.535 1.705 6.25 6.25 0 00-4.2-1.612 6.25 6.25 0 00-4.192 1.604A1.147 1.147 0 11.38 3.102 8.544 8.544 0 016.105.912c2.127 0 4.158.78 5.735 2.2z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
