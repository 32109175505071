<template>
  <b-overlay :show="showLoading" rounded="lg" style="height: 50rem">
    <b-navbar toggleable="lg" type="primary" variant="dark">
      <b-navbar-toggle target="nav-collapse">
        <button
          type="button"
          data-toggle="collapse"
          data-target="#nav-collapse"
          class="custom-toggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </b-navbar-toggle>

      <b-navbar-brand class="px-2 mx-3">
        <img
          src="@/assets/airzoneWhite.svg"
          alt="Airzone logo"
          class="fluid mr-2"
          height="30"
        />
        <span class="version">{{ version }}</span>
      </b-navbar-brand>

      <b-collapse v-model="isCollapseOpen" id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            to="/servers"
            id="webservers"
            class="h5 pt-2 mx-1"
            v-bind="{ disabled: isLink('servers') }"
            :class="{ active: isLink('servers') }"
          >
            <az-icon name="device" scale="0.7"></az-icon>
            {{ $t("airtools.devices") }}
          </b-nav-item>

          <b-nav-item
            v-if="!isAdminVendor()"
            to="/users"
            id="users"
            class="h5 pt-2 mx-1"
            v-bind="{ disabled: isLink('users') }"
            :class="{ active: isLink('users') }"
          >
            <az-icon name="user" scale="0.6"></az-icon>
            {{ $t("user.title") }}
          </b-nav-item>

          <b-nav-item
            v-if="!isAdminVendor()"
            to="/firmwares"
            id="firmwares"
            class="h5 pt-2 mx-1"
            v-bind="{ disabled: isLink('firmwares') }"
            :class="{ active: isLink('firmwares') }"
          >
            <az-icon name="gear" scale="0.6"></az-icon>
            {{ $t("firmwares") }}
          </b-nav-item>

          <b-nav-item
            v-if="!isAdminVendor()"
            to="/versions"
            id="versions"
            class="h5 pt-2 mx-1"
            v-bind="{ disabled: isLink('versions') }"
            :class="{ active: isLink('versions') }"
          >

            <az-icon name="azcloud" scale="0.9"></az-icon>
            {{ $t("Airzone Cloud") }}
          </b-nav-item>

          <b-nav-item
            @click="confirmLogout"
            id="logout"
            class="h5 pt-2 mx-1"
            v-bind="{ disabled: isLink('logout') }"
            :class="{ active: isLink('logout') }"
          >
            <az-icon icon="exit" scale="0.6"></az-icon>
            {{ $t("login.logout") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view @isLoading="isLoading" />

    <template #overlay>
      <div class="text-center">
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem"
          label="Large Spinning"
          type="grow"
        ></b-spinner>
        <p class="h2 mt-3">{{ $t("messages.loading") }}</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
// import store from '@/store/store';
import AuthService from "@/services/auth.service";
import Package from "@/../package.json"
import Role from '@/mixins/Role.mixin'

export default {
  name: "MainLayout",
  mixins: [Role],
  data() {
    return {
      isCollapseOpen: false,
      showLoading: false,
      version: Package.version,
    };
  },
  watch: {
    $route() {
      if (this.isCollapseOpen) {
        this.$root.$emit("bv::toggle::collapse", "nav-collapse");
      }
    },
  },
  methods: {
    /**
     * Muestra u oculta el cargando
     */
    isLoading(value) {
      this.showLoading = value;
    },
    /**
     * Confirma si realmente quiere cerrar la sesión del usuario
     */
    confirmLogout() {
      this.$bvModal
        .msgBoxConfirm(this.$i18n.t("login.logoutConfirm"), {
          title: this.$i18n.t("login.logout"),
          okVariant: "primary",
          okTitle: this.$i18n.t("buttons.accept"),
          cancelTitle: this.$i18n.t("buttons.cancel"),
          headerBgVariant: "warning",
          headerClass: "p-2 border-bottom-1",
          footerClass: "p-2 border-top-0",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            this.showLoading = true;
            await AuthService.logout();
            this.showLoading = false;
            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvModal.show("bv-modal-error");
        });
    },

    /**
     * Devuelve si el nombre es mismo que la ruta donde estoy
     *
     * @param {String} name - Nombre de la ruta a comprobar
     * @return {Boolean} - True si el nombre es mismo que la ruta donde estoy, false en caso contrario
     */
    isLink(name) {
      return this.$route.name === name;
    },
  },

  mounted() {
    // console.log(version)
  },
};
</script>

<style scoped>
.nav-link .az-icon {
  fill: var(--brand);
  margin-top: -0.3rem;
}
.nav-link.disabled .az-icon {
  fill: var(--gray3);
}
.nav-link:hover .az-icon {
  fill: var(--brand-dark);
}

.custom-toggler {
  border-color: var(--brand);
}

/* Setting the stroke to green using rgb values (0, 128, 0) */

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 145, 209, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.version {
  font-size: .7rem;
  color: white;
  position: relative;
  top: 0.1rem;
}

</style>