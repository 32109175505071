/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fan7': {
    width: 32,
    height: 30,
    viewBox: '0 0 32 30',
    data: '<path pid="0" d="M9.659.189c1.287.587 1.319 2.24 1.365 4.331.036 1.68.09 4.219 1.05 4.662a6.12 6.12 0 013.003-.697c.711-.803 3.366-3.741 5.302-5.076 1.193-.823 4.37-2.163 8.13 3.121 1.95 2.742 3.916 8.933 1.587 10.539-1.158.798-2.632.005-4.494-1-1.498-.812-3.762-2.034-4.627-1.439a5.95 5.95 0 01-.884 2.911c.352 1.014 1.607 4.738 1.813 7.055.127 1.428-.282 4.807-6.807 5.366-2.938.255-8.17-.782-9.663-2.881a2.084 2.084 0 01-.4-1.033c-.125-1.388 1.31-2.244 3.127-3.328l.51-.306c1.411-.855 3.145-2 3.066-2.918a5.979 5.979 0 01-2.123-2.211c-1.064-.206-4.971-1-7.114-1.984-.726-.334-1.301-.798-1.716-1.382-.456-.64-.716-1.417-.772-2.315-.086-1.363.308-2.975 1.17-4.793.765-1.615 2.158-3.416 3.73-4.814C6.26.796 8.178-.49 9.66.19zM30.817 21.54v.753l-3.3 7.376H26.07l3.295-7.04h-4.27v-1.09h5.722zm-11.729-2.794l-.13.119a6.117 6.117 0 01-5.935 1.21c-.175.76-.763 1.421-1.657 2.124-.608.477-1.187.854-2.255 1.496l-.255.153C6.82 25.063 6.331 25.51 6.37 25.934c.01.12.06.244.163.39 1.006 1.415 5.45 2.583 8.45 2.324 4.496-.386 5.766-1.924 5.587-3.937-.133-1.498-.743-3.748-1.481-5.964zm-6.874-8.126c-2.135 1.471-2.657 4.385-1.162 6.487 1.495 2.101 4.455 2.615 6.59 1.144 2.136-1.472 2.658-4.386 1.162-6.488-1.495-2.101-4.455-2.615-6.59-1.143zm4.419 2.64a2.03 2.03 0 01-.511 2.853c-.94.648-2.24.422-2.899-.503a2.03 2.03 0 01.511-2.853 2.105 2.105 0 012.899.503zm4.515-8.771c-1.252.862-2.935 2.515-4.51 4.247a6 6 0 013.239 2.288c.489.688.809 1.442.969 2.216.755-.229 1.63-.058 2.694.353.781.3 1.445.634 2.707 1.317 2.082 1.125 2.729 1.32 3.08 1.077 1.185-.816.117-5.842-1.917-8.7-2.591-3.644-4.58-3.957-6.262-2.798zM5.81 2.976c-1.442 1.282-2.727 2.94-3.415 4.392-.774 1.636-1.117 3.039-1.047 4.155.042.661.223 1.205.533 1.64.277.39.669.705 1.184.943 1.386.636 3.686 1.246 5.99 1.721a5.903 5.903 0 011.86-5.837c-.713-.64-.993-1.717-1.133-3.273-.03-.33-.052-.68-.068-1.064a51.5 51.5 0 01-.031-1.104c-.052-2.35-.2-2.986-.591-3.165-.592-.27-1.848.313-3.282 1.592z" _fill="#4C5153" fill-rule="evenodd"/>'
  }
})
