/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hazy-dark': {
    width: 46,
    height: 37,
    viewBox: '0 0 46 37',
    data: '<path pid="0" d="M24.508 33.247a1.71 1.71 0 010 3.42H7.409a1.71 1.71 0 010-3.42h17.099zm13.299 0a1.71 1.71 0 010 3.42h-6.65a1.71 1.71 0 010-3.42h6.65zm4.75-6.65a1.71 1.71 0 010 3.42H2.66a1.71 1.71 0 010-3.42h39.896zm.949-6.649a1.71 1.71 0 010 3.42H1.71a1.71 1.71 0 010-3.42h41.796zm-.95-6.65a1.71 1.71 0 010 3.42H2.66a1.71 1.71 0 010-3.42h39.896zm-2.85-6.649a1.71 1.71 0 010 3.42H5.51a1.71 1.71 0 010-3.42h34.197zM35.906 0a1.71 1.71 0 010 3.42H21.659a1.71 1.71 0 010-3.42h14.249zM15.01 0a1.71 1.71 0 010 3.42h-5.7a1.71 1.71 0 010-3.42h5.7z" _fill="#A2B0CA" fill-rule="nonzero"/>'
  }
})
