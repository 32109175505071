<template>
<div class="notFound">
  <div class="notFound__header">
    <img alt="Airzone logo" src="@/assets/airzone.svg" />
  </div>

  <div class="notFound__wrapper">
    <div class="notFound__title h2 mb-3 font-weight-bold"> {{ this.$i18n.t('messages.errors.notFoundPage') }} </div>
    <div class="notFound__text h4"> {{ this.$i18n.t('messages.errors.notFoundPageMsg') }} </div>
  </div>

  <div class="notFound__footer"><GoBack /></div>
</div>
    
</template>

<script>
import GoBack from '@/components/GoBack';

export default {
  name: 'NotFound',
  components: {
    GoBack
    },
};
</script>

<style scoped>
.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 1rem 0;
  text-align: center;
}

.notFound__header {
  margin-bottom: 1rem;
}

.notFound__wrapper {
  padding: 5rem;
  margin-bottom: 0rem;
}

.notFound__title {
  max-width: 450px;
  margin: 0 auto 0.5rem;
  color: var(--gray1);
}

.notFound__text {
  max-width: 450px;
  margin: 0 auto;
  color: var(--gray2);
}
</style>
