<template>
  <b-badge :class="[pillable, textColor]" :variant="bgBadge">
    <az-icon v-if="name" :name="name" :scale="scale"></az-icon>
    <span v-if="text">
      {{ text }}
    </span>
  </b-badge>
</template>

<script>
export default {
  name: "Badge",
  components: {},
  props: {
    pillable: String,
    bgBadge: {
      type: String,
      default: "light",
    },
    textColor: {
      type: String,
      default: "dark",
    },
    scale: {
      type: String,
      default: "2",
    },
    name: String,
    text: String,
  },
  methods: {},
};
</script>
