/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fog-wind-dark': {
    width: 62,
    height: 44,
    viewBox: '0 0 62 44',
    data: '<path pid="0" d="M42.784 40.104a1.72 1.72 0 010 3.438H29.415a1.72 1.72 0 010-3.438h13.37zm9.047-20.055c8.158 0 11.584 6.406 9.209 11.817-2.765 6.302-11.799 7.16-15.083-.508a1.719 1.719 0 013.16-1.354c2.01 4.694 7.14 4.207 8.775.48.533-1.214.5-2.992-.214-4.329-.89-1.665-2.751-2.669-5.847-2.669h-38.65a1.719 1.719 0 110-3.437zM33.235 30.555a1.72 1.72 0 010 3.437h-9.549a1.72 1.72 0 010-3.437h9.55zm-15.279 0a1.72 1.72 0 010 3.437H8.407a1.72 1.72 0 010-3.437h9.55zm22.919 0a1.72 1.72 0 010 3.437h-1.91a1.72 1.72 0 010-3.437h1.91zM.672 4.973c2.816-6.289 11.972-7.14 15.316.51a1.719 1.719 0 11-3.15 1.376c-2.06-4.713-7.355-4.22-9.029-.481-.54 1.207-.509 2.974.217 4.305.911 1.67 2.826 2.681 6.005 2.681h39.438a1.719 1.719 0 010 3.438H10.03c-4.378 0-7.475-1.637-9.022-4.472C-.245 10.032-.298 7.137.672 4.973zm53.571-2.111a1.72 1.72 0 010 3.438h-1.91a1.72 1.72 0 010-3.438h1.91zm-7.639 0a1.72 1.72 0 010 3.438h-9.55a1.72 1.72 0 010-3.438h9.55zm-15.279 0a1.72 1.72 0 010 3.438h-9.549a1.72 1.72 0 010-3.438h9.55z" _fill="#A2B0CA" fill-rule="nonzero"/>'
  }
})
