/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-3': {
    width: 20,
    height: 28,
    viewBox: '0 0 20 28',
    data: '<path pid="0" d="M13.929 27.857C6.249 27.857 0 21.61 0 13.93 0 6.249 6.248 0 13.929 0c1.969 0 3.886.413 5.698 1.228a.605.605 0 010 1.105 12.74 12.74 0 00-7.515 11.596 12.74 12.74 0 007.515 11.595.607.607 0 010 1.105 13.803 13.803 0 01-5.698 1.228zm0-26.646C6.916 1.211 1.21 6.916 1.21 13.93c0 7.012 5.705 12.717 12.718 12.717 1.359 0 2.69-.216 3.975-.642-4.294-2.466-7.003-7.067-7.003-12.075a13.95 13.95 0 017.003-12.076 12.567 12.567 0 00-3.975-.642zM6.66 18.168a3.032 3.032 0 01-3.027-3.028 3.032 3.032 0 013.027-3.028A3.032 3.032 0 019.69 15.14a3.032 3.032 0 01-3.028 3.028zm0-4.845a1.819 1.819 0 00-1.816 1.817c0 1.001.815 1.817 1.816 1.817a1.819 1.819 0 001.817-1.817 1.819 1.819 0 00-1.817-1.817zm3.634 10.9a1.819 1.819 0 01-1.817-1.816c0-1.002.815-1.817 1.817-1.817s1.817.815 1.817 1.817a1.819 1.819 0 01-1.817 1.817zm0-2.422a.606.606 0 100 1.212.606.606 0 000-1.212z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
