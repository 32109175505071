/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'etape-radiant': {
    width: 30,
    height: 19,
    viewBox: '0 0 30 19',
    data: '<path pid="0" d="M29.991 11.74a2.978 2.978 0 00-2.873-3.208H2.491a1.226 1.226 0 01-1.073-1.283c-.005-.541.418-.99.958-1.015h20.06a2.873 2.873 0 002.873-3.017A2.959 2.959 0 0022.389.01H2.548a.709.709 0 100 1.398h19.82c.27.017.531.096.766.23.53.336.82.946.747 1.57.06.542-.203 1.07-.67 1.35-.23.13-.485.215-.747.249H2.357A2.49 2.49 0 000 7.249a2.624 2.624 0 002.365 2.69h24.676c.276.02.544.102.785.24.526.335.816.94.747 1.56a1.408 1.408 0 01-.68 1.331c-.23.133-.484.217-.747.25H9.251a2.5 2.5 0 00-2.336 2.431 2.652 2.652 0 002.384 2.7h10.954a.709.709 0 100-1.407H9.423a1.724 1.724 0 01-.536-.144c-.37-.23-.58-.647-.546-1.082 0-.538.42-.984.958-1.015h17.848a2.873 2.873 0 002.844-3.064z" _fill="#7B8794" fill-rule="nonzero"/>'
  }
})
