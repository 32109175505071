/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scene-12': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M23.036 25.715H2.679A2.682 2.682 0 010 23.036V12.322a2.682 2.682 0 012.679-2.679h20.357a2.682 2.682 0 012.678 2.679v10.714a2.682 2.682 0 01-2.678 2.679zm-20.357-15a1.61 1.61 0 00-1.608 1.607v10.714a1.61 1.61 0 001.608 1.607h20.357a1.61 1.61 0 001.607-1.607V12.322a1.61 1.61 0 00-1.607-1.607H2.679zm5.357 9.642a2.682 2.682 0 01-2.679-2.678A2.682 2.682 0 018.036 15a2.682 2.682 0 012.678 2.679 2.682 2.682 0 01-2.678 2.678zm0-4.285a1.61 1.61 0 00-1.607 1.607 1.61 1.61 0 001.607 1.607 1.61 1.61 0 001.607-1.607c0-.887-.721-1.607-1.607-1.607zm0 7.5a5.9 5.9 0 01-5.893-5.893 5.9 5.9 0 015.893-5.893 5.9 5.9 0 015.893 5.893 5.9 5.9 0 01-5.893 5.893zm0-10.715a4.827 4.827 0 00-4.822 4.822A4.827 4.827 0 008.036 22.5a4.827 4.827 0 004.821-4.821 4.827 4.827 0 00-4.821-4.822zm15 3.215h-7.5a.536.536 0 010-1.072h7.5a.536.536 0 010 1.072zm0 2.143h-7.5a.536.536 0 010-1.072h7.5a.536.536 0 010 1.072zm0 2.142h-7.5a.536.536 0 010-1.071h7.5a.536.536 0 010 1.071zm0 2.143h-7.5a.536.536 0 010-1.071h7.5a.536.536 0 010 1.071zM2.679 10.715a.536.536 0 01-.368-.925L12.49.147a.535.535 0 11.736.778L3.047 10.568a.539.539 0 01-.368.147zm20.357 0a.536.536 0 01-.536-.536V9.107A1.61 1.61 0 0020.893 7.5H10.179A1.61 1.61 0 008.57 9.107v1.072a.536.536 0 01-1.071 0V9.107a2.682 2.682 0 012.679-2.678h10.714a2.682 2.682 0 012.678 2.678v1.072c0 .296-.24.536-.535.536zm-5.357 3.214a.506.506 0 01-.386-.16.547.547 0 01.01-.76c.182-.184.558-.194.76.01a.544.544 0 01-.01.76.54.54 0 01-.374.15zm-2.143 0a.54.54 0 01-.376-.15.543.543 0 01-.01-.76.54.54 0 01.76 0 .532.532 0 01-.375.91z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
