/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auto': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M1.467 18.53a12.47 12.47 0 01-.217-2.28c0-6.58 5.11-11.975 11.57-12.454l-2.638 2.636a.626.626 0 00.883.884l3.64-3.64a.613.613 0 00.259-.352l.006-.027c.009-.038.026-.073.029-.113.001-.03-.01-.057-.014-.087-.001-.026.01-.05.006-.075-.005-.022-.02-.04-.028-.063-.005-.017-.013-.032-.02-.047a.612.612 0 00-.292-.347l-5-2.5a.627.627 0 00-.837.28.626.626 0 00.28.838l2.885 1.443C5.233 3.5 0 9.269 0 16.249c0 .818.079 1.638.239 2.508a.624.624 0 101.228-.228zm28.445 1.774a.627.627 0 00-.858-.216l-2.696 1.61a13.665 13.665 0 001.14-5.449c0-5.805-3.683-11.01-9.166-12.95a.627.627 0 00-.798.38.627.627 0 00.381.797c4.983 1.765 8.333 6.495 8.333 11.773 0 1.83-.407 3.62-1.177 5.267l-.709-3.514a.625.625 0 10-1.226.246l1.031 5.112a.608.608 0 00.149.383c.001.003.004.004.005.005.037.043.071.087.121.118.016.01.036.006.053.015.02.012.033.034.056.044a.643.643 0 00.574-.034l4.57-2.729a.626.626 0 00.217-.858zm-8.05 5.442a12.494 12.494 0 01-8.113 3.004 12.436 12.436 0 01-10.303-5.457l3.89 1.06a.626.626 0 00.329-1.206l-5.331-1.451a.623.623 0 00-.364-.038c-.018.003-.033.01-.05.014-.039.011-.08.014-.118.033-.02.01-.032.028-.05.04-.017.01-.037.01-.053.023-.024.018-.035.044-.055.064a.616.616 0 00-.053.061.622.622 0 00-.147.355l-.195 5.23a.625.625 0 00.6.647h.025a.626.626 0 00.625-.601l.12-3.224A13.683 13.683 0 0013.749 30c3.266 0 6.436-1.173 8.925-3.304a.624.624 0 10-.81-.95zm-2.515-5.069h-2.64a9.537 9.537 0 00-.347-1.413l-.453-1.293h-4.026l-.453 1.293c-.214.6-.294.96-.36 1.413h-2.64c.213-.4.4-.84.68-1.6l2.906-7.853c.24-.667.347-1.013.427-1.387h2.866c.08.347.16.627.453 1.387l2.933 7.853c.254.68.494 1.267.654 1.6zm-4-4.586l-1.266-3.774c-.04-.12-.094-.253-.2-.613-.08.28-.094.347-.187.613l-1.253 3.774h2.906z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
