/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'stop-lock copia': {
    width: 36,
    height: 30,
    viewBox: '0 0 36 30',
    data: '<defs><path pid="0" id="svgicon_stop-lock copia_a" d="M.6.5H10V13H.6z"/></defs><g _fill="none" fill-rule="evenodd"><path pid="1" _fill="#000" d="M8.7 0l-.2.1L0 14.9v.2l8.5 14.8.2.1h15.4v-2.3H9.9L2.6 15 9.9 2.3h14.7L31.9 15h2.6v-.1L26 .1l-.2-.1z"/><path pid="2" d="M27.7 11.2c-.3-.1-.7-.2-1.1-.2h-2.9l-.2.2v6.6l.1.2h1.6l.2-.2v-2h1.1c.7 0 1.3-.3 1.7-.6.5-.4.8-1 .8-1.7 0-.5-.1-.9-.4-1.3-.1-.5-.5-.8-.9-1m-.9 2.7c-.1.1-.3.2-.5.2h-.8v-1.5h.9c.2 0 .3.1.4.2.1.2.2.4.2.6 0 .2-.1.4-.2.5M16.8 12.4v-1.2l-.3-.3H11l-.2.3v1.2l.2.2h1.7v5.2l.3.2h1.6l.2-.2v-5.2h1.7zM21.5 11.3c-.5-.3-1-.4-1.6-.4-.6 0-1.2.1-1.6.4-.5.3-.9.7-1.1 1.2-.2.5-.4 1-.4 1.5v1c0 .5.2 1 .4 1.4.3.5.6.9 1.1 1.2.5.3 1 .4 1.6.4.6 0 1.1-.1 1.6-.4.5-.2.9-.6 1.2-1.2.2-.4.3-.9.3-1.5V14c0-.5-.2-1-.4-1.5s-.6-.9-1.1-1.2zm-.5 3.3v.2c0 .5-.1.8-.3 1.1-.2.3-.4.4-.7.4-.3 0-.6-.1-.8-.4-.2-.3-.3-.6-.3-1.1v-1c.1-.9.4-1.3 1-1.3.3 0 .6.1.7.4.2.3.3.6.3 1.1v.6h.1zM8 12.5c.2 0 .4.1.5.2.1.1.2.2.2.3v.1l.2.2h1.6l.2-.2v-.3c0-.3-.2-.7-.3-.9-.2-.4-.6-.6-1-.8-.4-.2-.8-.2-1.3-.2s-1 .1-1.4.2c-.4.2-.8.4-1 .7-.3.4-.4.7-.4 1.1 0 .8.5 1.5 1.4 1.9.3.2.6.4 1 .5l.3.1c.3.1.5.2.6.3.1.1.1.2.1.3 0 .1 0 .2-.1.3-.1.1-.3.1-.5.1-.4 0-.6-.1-.7-.2-.2 0-.2-.2-.2-.4v-.2l-.3-.2H5.3l-.2.2v.3c0 .4.2.7.4 1 .2.4.6.7 1.1.9.4.2.9.3 1.5.3.8 0 1.5-.2 1.9-.5.5-.4.8-.9.8-1.6 0-.8-.4-1.5-1.2-1.9-.3-.2-.7-.3-1.2-.5h-.2c-.3-.2-.6-.3-.7-.4l-.2-.2c0-.1 0-.2.2-.3.1-.1.3-.2.5-.2" _fill="#000"/><g transform="translate(25 17)"><mask id="svgicon_stop-lock copia_b" _fill="#fff"><use xlink:href="#svgicon_stop-lock copia_a"/></mask><path pid="3" d="M5.3.5C7.2.5 8.7 2 8.7 3.9v1.8c.7 0 1.3.5 1.3 1.3v4.6c0 .8-.6 1.4-1.3 1.4H1.9c-.7 0-1.3-.6-1.3-1.4V7c0-.8.6-1.3 1.3-1.3V3.9C1.9 2 3.4.5 5.3.5m0 7.1c-.5 0-.8.4-.8.8v2.1c0 .5.4.8.8.8.5 0 .8-.4.8-.8V8.4c0-.4-.3-.8-.8-.8m0-5.3c-.8 0-1.5.6-1.5 1.4v2h2.9v-2c.1-.8-.6-1.4-1.4-1.4" _fill="#000" mask="url(#svgicon_stop-lock copia_b)"/></g></g>'
  }
})
