/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear-day 2': {
    width: 45,
    height: 45,
    viewBox: '0 0 45 45',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_clear-day 2_a"><stop stop-color="#F5D2AE" offset="0%"/><stop stop-color="#F5CCAB" offset="100%"/></linearGradient><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_clear-day 2_b"><stop stop-color="#F9AF28" offset="0%"/><stop stop-color="#F97F27" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="url(#svgicon_clear-day 2_a)" cx="22.167" cy="22.167" r="22.167"/><circle pid="1" _fill="url(#svgicon_clear-day 2_b)" cx="22.167" cy="22.167" r="15.167"/></g>'
  }
})
