/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M23.558 7.529C22.688 3.2 18.83 0 14.375 0 9.205 0 5 4.204 5 9.375c0 .211.009.426.028.656A5.633 5.633 0 000 15.625c0 .501.08 1.006.253 1.587a.625.625 0 101.199-.354 4.281 4.281 0 01-.202-1.233c0-2.413 1.962-4.375 4.335-4.378.206.03.475-.043.614-.203a.62.62 0 00.146-.498 8.078 8.078 0 01-.095-1.171c0-4.479 3.645-8.125 8.125-8.125 4.014 0 7.467 2.997 8.034 6.972.043.314.408.533.716.528a5.632 5.632 0 015.625 5.625 5.637 5.637 0 01-4.511 5.513.626.626 0 00.246 1.224A6.891 6.891 0 0030 14.375c0-3.614-2.801-6.585-6.442-6.846zM19.375 26.25h-5a.625.625 0 000 1.25h5a.625.625 0 000-1.25zm-16.25 0a.625.625 0 100 1.25.625.625 0 000-1.25zm17.5-7.5H1.875A1.877 1.877 0 000 20.625v2.5c0 .481.188.918.487 1.25-.3.334-.487.769-.487 1.25v2.5C0 29.16.841 30 1.875 30h18.75a1.877 1.877 0 001.875-1.875v-2.5c0-.481-.188-.918-.487-1.25.3-.332.487-.769.487-1.25v-2.5a1.877 1.877 0 00-1.875-1.875zm.625 9.375c0 .345-.28.625-.625.625H1.875a.625.625 0 01-.625-.625v-2.5c0-.345.28-.625.625-.625h18.75c.345 0 .625.28.625.625v2.5zm0-5c0 .345-.28.625-.625.625H1.875a.625.625 0 01-.625-.625v-2.5c0-.345.28-.625.625-.625h18.75c.345 0 .625.28.625.625v2.5zm-1.875-1.875h-5a.625.625 0 000 1.25h5a.625.625 0 000-1.25zm-16.25 0a.625.625 0 100 1.25.625.625 0 000-1.25z" _fill="#52606D" fill-rule="nonzero"/>'
  }
})
