import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import CONSTANTS from '@/constant';
import StorageService from '@/services/storage.service';
// import AppError from '@/services/errors.service';

Vue.use(VueI18n);

/**
 * LANGUAGES
 *
 * @constant {Object} - Listado de idiomas disponibles
 */
const LANGUAGES = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Portugues',
  pl: 'Polska',
  el: 'Ελληνική',
  tr: 'Türkçe'
};

/**
 * Devuelve un objeto con el listado de idiomas
 * a partir de un string con los códigos de los idiomas
 * separados por comas
 *
 * @param {String} list - Listado de idiomas
 * @return {Object}
 */
const getListLanguages = list => {
  const languagesList = list.split(',');
  const languagesObject = {};

  languagesList.forEach(lang => {
    const language = lang.trim();
    if (LANGUAGES[language]) languagesObject[language] = LANGUAGES[language];
  });

  return languagesObject;
};

/**
 * Inicializa i18n
 *
 * @return {VueI18n}
 */
const getSettingI18n = () => {
  //
  // Obtengo el idioma de la aplicación
  //
  const localStorageLanguage = StorageService.getItem('language');
  const navigatorLanguage = navigator.language.split('-');
    const locale = localStorageLanguage || navigatorLanguage[0] || 'en';

  //
  // Obtengo el idioma por defecto cuando no se encuentre una traducción
  //
  const fallbackLocale = 'en';

  //
  // Obtengo todos los archivos con las traducciones y los junto
  //
  // eslint-disable-next-line no-undef
  const locales = require.context('@/locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {
    es: {},
    en: {},
    fr: {},
    it: {},
    pt: {},
    de: {},
    pl: {},
    el: {},
    tr: {}
  };
  // console.log('locales: ', locales)
  // prettier-ignore
  locales.keys().forEach(key => {
    // console.log('key :', key);
    // console.log("locales(key): ", locales(key));
    // console.log("es: ", locales(key).es);
    Object.assign(messages.es, locales(key).es);
    Object.assign(messages.en, locales(key).en);
    Object.assign(messages.fr, locales(key).fr);
    Object.assign(messages.it, locales(key).it);
    Object.assign(messages.pt, locales(key).pt);
    Object.assign(messages.de, locales(key).de);
    Object.assign(messages.de, locales(key).pl);
    Object.assign(messages.de, locales(key).el);
    Object.assign(messages.de, locales(key).tr);
  });
  // console.log('messages: ', messages);
  return new VueI18n({ locale, fallbackLocale, messages, silentTranslationWarn: true });
};

const i18n = getSettingI18n();

/**
 * Cambia el idioma de la aplicación
 *
 * @param {String} lang - Código del idioma seleccionado
 * @return {String} - Código del idioma seleccionado
 * @throws {AppError} - langNotFound
 */
const setLanguage = (lang = i18n.locale) => {
  //
  // Si el idioma es el mismo que está seleccionado, no hago nada
  //
  if (i18n.locale === lang) return lang;

  //
  // Compruebo si el nuevo idioma se encuentra entre los idiomas disponibles
  //
  if (!getListLanguages('de,en,es,fr,it,pt,pl,el,tr')[lang]) {
    throw 'langNotFound';// new AppError('langNotFound');
  }

  //
  // Actualizo el idioma
  //
  i18n.locale = lang;
  StorageService.setItem('language', lang);
  document.querySelector('html').setAttribute('lang', lang);

  //
  // Actualizo idioma para el API de Google
  //
  // const scripts = document.getElementsByTagName('script');
  // for (let index = 0; index < scripts.length; index += 1) {
  //   if (scripts[index].src.startsWith('https://maps.googleapis.com')) {
  //     const key = CONSTANTS.CONNECT.GOOGLEMAP.KEY; // eslint-disable-line
  //     const src = `${CONSTANTS.CONNECT.GOOGLEMAP.API_URL}?key=${key}&language=${lang}&libraries=places`;
  //     scripts[index].src = src;
  //   }
  // }

  // return lang;
};

export { LANGUAGES, getListLanguages, setLanguage };

export default i18n;
