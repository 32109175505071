/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'right': {
    width: 9,
    height: 15,
    viewBox: '0 0 9 15',
    data: '<path pid="0" d="M.365 12.954l5.66-5.431-5.657-5.43a1.08 1.08 0 01-.325-.62 1.349 1.349 0 01-.02-.251c0-.072.003-.154.02-.25C.08.75.169.542.365.352a1.263 1.263 0 011.752 0l6.518 6.301c.487.471.487 1.223 0 1.694l-6.518 6.3c-.487.47-1.265.47-1.752 0a1.164 1.164 0 010-1.693z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
