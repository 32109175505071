/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dry': {
    width: 18,
    height: 28,
    viewBox: '0 0 18 28',
    data: '<path pid="0" d="M8.75 28C3.925 28 0 24.072 0 19.243 0 15.104 7.904.88 8.24.277c.206-.37.813-.37 1.019 0C9.596.88 17.5 15.104 17.5 19.243 17.5 24.071 13.575 28 8.75 28zm0-26.232C5.865 7.035 1.167 16.35 1.167 19.243c0 4.185 3.402 7.59 7.583 7.59s7.583-3.405 7.583-7.59c0-2.893-4.698-12.208-7.583-17.475zm0 22.73a5.258 5.258 0 01-5.25-5.255.584.584 0 111.167 0A4.09 4.09 0 008.75 23.33a.584.584 0 010 1.167z" _fill="#1F2933" fill-rule="nonzero"/>'
  }
})
